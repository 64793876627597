import { FC, useEffect, useState } from "react";
import { GalleryModal } from "../../../../../../../../../../../../../widgets/galleryModal";
import styles from "./FormItem.module.scss";
import { MdOutlineAddAPhoto } from "react-icons/md";
import { Input, InputNumber, Select } from "antd";
import { BiMessageDetail } from "react-icons/bi";
import cs from "classnames";
import { IoIosArrowUp } from "react-icons/io";
import { UseFormWatch } from "react-hook-form";
import { IWheelsFormValues } from "../../../../../../interfaces/wheelsFormValues";
import { IImage } from "../../../../../../../../../../../../../entities/image";
import { tireWearoutOptions } from "../../../../../../../../../../../../../entities/product";

interface IProps {
  watch: UseFormWatch<IWheelsFormValues>;
  isDifferentWith: boolean;
  id: number;
  setUniqueOptions: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        images?: number[];
        char_tireWearout?: number;
        price?: number;
        char_comment?: string;
        char_note?: string;
      }[]
    >
  >;
  uniqueOptions: {  
    id: number;
    images?: number[];
    char_tireWearout?: number;
    price?: number;
    char_comment?: string;
    char_note?: string;
  }[];
  isGeneralImagesAndComments: boolean;
  setGeneralPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  generalPrice: number | undefined;
  isNotValid: boolean
}

const FormItem: FC<IProps> = ({
  watch,
  isDifferentWith,
  id,
  setUniqueOptions,
  uniqueOptions,
  isGeneralImagesAndComments,
  isNotValid
}) => {
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState<boolean>(false);
  const [checkedImages, setCheckedImages] = useState<IImage[]>([]);
  const [char_tireWearout, setChar_tireWearout] = useState<number | undefined>(uniqueOptions.find((item)=> item.id === id)?.char_tireWearout);
  const [price, setPrice] = useState<number | undefined>(uniqueOptions.find((item)=> item.id === id)?.price);
  const [char_comment, setChar_comment] = useState<string| undefined>(uniqueOptions.find((item)=> item.id === id)?.char_comment);
  const [char_note, setChar_note] = useState<string | undefined>(uniqueOptions.find((item)=> item.id === id)?.char_note);
  const [isCommentsRowVisible, setIsCommentsRowVisible] = useState<boolean>(false);

  useEffect(() => {
    if(isGeneralImagesAndComments && id === 1 || char_comment) {
      setIsCommentsRowVisible(true);
    } else if(!isGeneralImagesAndComments && !char_comment) {
      setIsCommentsRowVisible(false)
    }
  }, [isGeneralImagesAndComments]);


  useEffect(()=> {
    setIsCommentsRowVisible(false)
    setCheckedImages([])
    setPrice(undefined)
    setChar_tireWearout(undefined)
    setChar_comment(undefined)
    setChar_note(undefined)
  }, [watch('char_wheelPart')])


  useEffect(() => {
    setUniqueOptions((prev) => {
      const existingItem = prev.find((item) => item.id === id);
      if (existingItem) {
        return prev.map((item) =>
          item.id === id
            ? {
                ...item,
                images: checkedImages.map(({id})=> id),
                char_tireWearout,
                price,
                char_comment,
                char_note,
              }
            : item
        );
      } else {
        return [...prev, { id, images: checkedImages.map(({id})=> id) }];
      }
    });
  }, [checkedImages, char_tireWearout, price, char_comment, char_note]);

  useEffect(()=> {
  setPrice(uniqueOptions.find((item)=> item.id === id)?.price)

  }, [uniqueOptions])

 

  return (
    <div className={cs(styles.root, (isGeneralImagesAndComments && id !== 1) && styles.root__disabled)}>
      <GalleryModal
        checkedImages={checkedImages}
        setCheckedImages={setCheckedImages}
        isModalOpen={isGalleryModalOpen}
        setIsModalOpen={setIsGalleryModalOpen}
      />
      {!isGeneralImagesAndComments &&
        isDifferentWith &&
        watch("char_wheelSetNumber") >= 4 &&
        id === 1 && (
          <div className={styles.divider}>
            <div className={styles.divider_title}>
              <div className={styles.circle}></div>
              <p>Передняя ось</p>
            </div>
            <span></span>
          </div>
        )}
      {isDifferentWith && id === 3 && (
        <div className={cs(styles.divider, styles.backAxle)}>
          <div className={styles.divider_title}>
            <div className={styles.circle}></div>
            <p>Задняя ось</p>
          </div>
          <span></span>
        </div>
      )}
      <div key={id} className={styles.listItem}>
        <div className={styles.leftBlock}>
          <div className={styles.orderNumber}>{id}</div>
          <button
            onClick={() => setIsGalleryModalOpen(true)}
            type="button"
            className={styles.addPhotoBtn}
          >
            {checkedImages.length > 0 ? (
              <div>Выбрано {checkedImages.length} фото</div>
            ) : (
              <MdOutlineAddAPhoto />
            )}
          </button>
        </div>

        <div className={styles.rightBlock}>
          {watch("char_wheelPart") !== "Диск" && (
            <div className={cs(styles.formItem, styles.wearout)}>
              <Select
                placeholder="Износ шины"
                className={cs((isNotValid && char_tireWearout === undefined) && styles.inputError)}
                value={uniqueOptions.find((item)=> item.id === id)?.char_tireWearout}
                onChange={(e) => setChar_tireWearout(e)}
                options={tireWearoutOptions}
              />
            </div>
          )}
          {!isGeneralImagesAndComments && (
            <div className={cs(styles.formItem, styles.price)}>
              <InputNumber
                value={
                  uniqueOptions?.find((item) => item.id === id)?.price &&
                  Math.round(
                    uniqueOptions?.find((item) => item.id === id)?.price || 0
                  )
                }
            
                onChange={(value) => setPrice(value ? +value : undefined)}
                controls={false}
                placeholder="Цена"
              />
            </div>
          )}
          {!isGeneralImagesAndComments && (
            <div
              onClick={() => setIsCommentsRowVisible((prev) => !prev)}
              className={styles.commentToggler}
            >
              {isCommentsRowVisible ? (
                <IoIosArrowUp style={{ fill: "black", width: "15px" }} />
              ) : (
                <BiMessageDetail />
              )}
            </div>
          )}
        </div>
      </div>
      {isCommentsRowVisible && (
        <div className={styles.commentsRow}>
          <div className={cs(styles.formItem, styles.comment)}>
            <Input
              value={
                uniqueOptions?.find((item) => item.id === id)?.char_comment
              }
              onChange={(e) => setChar_comment(e.target.value)}
              size="large"
              placeholder="Комментарий (для клиентов)"
            />
          </div>
          <div className={cs(styles.formItem, styles.note)}>
            <Input
              value={uniqueOptions?.find((item) => item.id === id)?.char_note}
              onChange={(e) => setChar_note(e.target.value)}
              size="large"
              placeholder="Заметка (для вас)"
              className={styles.note}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FormItem;
