import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateGenerationDto } from "../dtos/createGenerationDto";
import { UpdateGenerationDto } from "../dtos/updateGenerationDto";
import { IGeneration } from "../interfaces/generation";






const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        getOneGeneration: build.query<IGeneration, string>({
            query: (id)=> `/generations/${id}`,
            providesTags: ['Generation']
        }),
        getAllGenerations: build.query<IGeneration[], string>({
            query: (modelId)=> `/generations?modelId=${modelId}`,
            providesTags: ['Generation']
        }),
        createGeneration: build.mutation<IGeneration, CreateGenerationDto>({
            query: (body)=> ({
                url: '/generations',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Generation']
        }),
        updateGeneration: build.mutation<IGeneration, {id: number, body: UpdateGenerationDto}>({
            query: ({id, body})=> ({
                url: `/generations/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Generation']
        }),
        deleteGeneration: build.mutation<void, string>({
            query: (id)=> ({
                url: `/generations/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Generation']
        })
    })
})


export const {useGetOneGenerationQuery, useGetAllGenerationsQuery, useLazyGetAllGenerationsQuery, useCreateGenerationMutation, useUpdateGenerationMutation, useDeleteGenerationMutation} = extendedApi