import styles from './Wheels.module.scss'
import { Button, Input, Table } from 'antd'
import type { TableColumnsType } from 'antd';
import { IoOptionsOutline } from 'react-icons/io5'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addProduct, IProduct, productsSelector, removeProduct, useGetAllProductsQuery } from '../../../../entities/product';
import { serverUrl } from '../../../../shared/constants/serverUrl';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxToolkit';
import cs from 'classnames'





  const Wheels = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [mode, setMode] = useState<string>('sale')
  const {data: products, isLoading} = useGetAllProductsQuery({pagination: {page, pageSize}, category: 'wheel'})
  const navigate = useNavigate()
  const {pagination} = products?.meta || {}
  const dispatch = useAppDispatch()
  const selectedProducts = useAppSelector(productsSelector)
  

  useEffect(()=> {
    const checkedMode = localStorage.getItem('catalog_changedRadio')
    checkedMode && setMode(checkedMode)
  }, [])

  useEffect(()=> {
    const savedPage = sessionStorage.getItem('catalog-table-page') 
    const savedPageSize = sessionStorage.getItem('catalog-table-pageSize') 
    if(savedPage) {
     setPage(+savedPage)
    }
    if(savedPageSize) {
      setPageSize(+savedPageSize)
    }
},[])


  const onPageChange = (page: number, pageSize: number)=> {
    setPage(page)
    setPageSize(pageSize)
    sessionStorage.setItem('catalog-table-page', String(page))
    sessionStorage.setItem('catalog-table-pageSize', String(pageSize))
  }

  const onPlusClick = (product: IProduct) => {
    dispatch(addProduct(product))
  }

  const onMinusClick = (product: IProduct) => {
    dispatch(removeProduct(product))
  }

  const columns: TableColumnsType<IProduct> = [
    {
      title: 'ID',
      width: '24px',
      dataIndex: 'id',
    },
    {
      title: 'Превью',
      dataIndex: 'images',
      width: '40px',
      render: (item)=> { return <div className={styles.imageHolder} >{item[0]?.url && <img className={styles.previewImage} src={serverUrl + item[0]?.url} />}</div>}
    },
    {
      title: 'Товар',
      dataIndex: 'char_part',
    },
    {
      title: 'Диаметр',
      dataIndex: 'char_tireDiametr'
    },
    {
      title: 'Тип шины',
      dataIndex: 'char_tireVehicleType'
    },
    {
      title: 'Цена',
      dataIndex: 'char_price'
    },
    {
      title: 'Ширина шины',
      dataIndex: 'char_tireWidth'
    },
    {
      title: 'Тип маркировки',
      dataIndex: 'char_tireMarkingType'
    },
    {
      title: 'Тип протектора',
      dataIndex: 'char_tireProtectorType',
    },
    {
      title: 'Тип конструкции',
      dataIndex: 'char_tireCordType',
    },
    {
      title: 'Высота шины',
      dataIndex: 'char_tireHeight'
    },
    {
      title: 'Износ',
      dataIndex: 'char_tireWearout'
    },
    {
      title: 'Склад',
      render: (_, product)=> product?.income?.storage?.name 
    },
    {
      title: 'Наличие',
      dataIndex: 'stock',
      width: '100px',
      onCell: () => ({ onClick: (e) => { e.stopPropagation() } }),
      render: (item, product) => {
      const active = selectedProducts.some((item) => item.product.id === product.id)
      return <div className={cs(styles.stock, active && styles.stock__active)} >
        <div className={styles.quantityCounter} >
          <Button onClick={() => onMinusClick(product)} size='small' >-</Button>
          <div>{selectedProducts.find(({ product: { id } }) => id === product.id)?.quantity || 0}</div>
          <Button onClick={() => onPlusClick(product)} size='small' >+</Button>
        </div>
        <div className={styles.stockValue}>{item}</div>
      </div>}
    }
  
  ]

  return (
    <div className={styles.root} >
    <div className={styles.header} >
      <Input className={styles.search} placeholder='Поиск по номеру' />
      <div className={styles.options} ><IoOptionsOutline/></div>
    </div>
    <div className={styles.content} >
      <Table
      loading={isLoading}
      columns={columns}
      rowSelection={{type: 'checkbox'}}
      onRow={(item)=>({onClick: ()=> navigate(`/catalog/wheel/${item?.id}`)})}
      pagination={{showSizeChanger: true, defaultPageSize: 10, total: pagination?.total, pageSize, current: page, onChange: onPageChange}}
      dataSource={products?.data?.map((item)=> ({...item, key: item?.id}))}
      />
    </div>
    </div>
  )
}

export default Wheels