import styles from './DiskFormPart.module.scss'
import { FC, useEffect, useState } from 'react'
import cs from 'classnames'
import {Input, Select } from 'antd'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { diskDiametrOptions, diskDiaOptions, diskEtOptions, diskPcdOptions, diskWidthOptions, IProduct, UpdateProductDto } from '../../../../../../../entities/product'





interface IProps {
  product?: IProduct
  control: Control<UpdateProductDto, any>
  watch: UseFormWatch<UpdateProductDto>
  setChangedParametrs: React.Dispatch<React.SetStateAction<{
    images: number;
    generalInfo: number;
    mainParameters: number;
    disk: number;
    tire: number;
    donorCar: number;
    placement: number;
}>>
setValue: UseFormSetValue<UpdateProductDto>
}

const DiskFormPart: FC<IProps> = ({product, control, watch, setChangedParametrs, setValue}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
 

  const onItemSelect = (name: string)=> {
     setSelectedItems(prev => [...prev, name])
  
  }

  const onCancelItemSelect = (name: any)=> {
    setValue(name, product?.[name as keyof IProduct])
    setSelectedItems(prev => prev.filter((item)=> name !== item))
  }


  
  useEffect(()=> {
     setChangedParametrs((prev)=> ({...prev, disk: selectedItems.length}))
  }, [selectedItems])

  const itemsList = [
    {
      name: 'char_diskType',
      title: 'Тип диска',
      value: watch('char_diskType') || '-',
      input:  <Controller
              name='char_diskType'
              control={control}
              render={({field})=> (
                <Select {...field} >
                  <Select.Option value={'Литой'} >Литой</Select.Option>
                  <Select.Option value={'Кованый'} >Кованый</Select.Option>
                  <Select.Option value={'Штампованный'} >Штампованный</Select.Option>
                  <Select.Option value={'Разборный'} >Разборный</Select.Option>
                </Select>
              )}
      />
    },
    {
        name: 'char_diskDiametr',
        title: 'Диаметр, дюйм',
        value: watch('char_diskDiametr'),
        input: <Controller
                name='char_diskDiametr'
                control={control}
                render={({field})=> (
                    <Select {...field} options={diskDiametrOptions.map(value=> ({value}))} />
                )}
        />
    },
    {
      name: 'char_diskWidth',
      title: 'Ширина, дюйм',
      value: watch('char_diskWidth'),
      input: <Controller
              name='char_diskWidth'
              control={control}
              render={({field})=> (
                 <Select {...field} options={diskWidthOptions.map(value=> ({value}))} />
              )}
      />
    },
    {
      name: 'char_diskPCD',
      title: 'Сверловка',
      value: (watch('char_diskPCD')?.[1] ?  `${watch('char_diskPCD')?.[0]}, ${watch('char_diskPCD')?.[1]}` : watch('char_diskPCD')?.[0]) || '-',
      input: <div className={styles.diskPcdWrapper} >
          
          <Select onChange={(value)=>{ value === 'Стандартная' ? 
              setValue('char_diskPCD', [watch('char_diskPCD')?.[0] || '', '']):
              setValue('char_diskPCD', [watch('char_diskPCD')?.[0] || '', watch('char_diskPCD')?.[0] || '']  
            )
            }
           } value={watch('char_diskPCD')?.[1] ? 'Двойная': 'Стандартная'}
           options={[{value: 'Стандартная'}, {value: 'Двойная'}]} />

        <Controller
              name='char_diskPCD'
              control={control}
              render={({field})=> (
                <Select onChange={(value)=> field.onChange([value, field.value?.[1] || ''])} value={field.value?.[0]} options={diskPcdOptions.map(value=> ({value}))} />
              )}
      />

       {watch('char_diskPCD')?.[1] && <Controller
              name='char_diskPCD'
              control={control}
              render={({field})=> (
                <Select onChange={(value)=> field.onChange([field.value?.[0] || '', value])} value={field.value?.[1]} options={diskPcdOptions.map(value=> ({value}))} />
              )}
      />}
      </div>
    },
    {
      name: 'char_diskET',
      title: 'Вылет, мм',
      value: watch('char_diskET') || '-',
      input: <Controller
              name='char_diskET'
              control={control}
              render={({field})=> (
                <Select {...field} options={diskEtOptions.map(value=> ({value}))} />
              )}
      />
      
    },
     {
      name: 'char_diskDIA',
      title: 'Диаметр ЦО, мм',
      value: watch('char_diskDIA') || '-',
      input: <Controller
      name='char_diskDIA'
      control={control}
      render={({field})=> (
        <Select {...field} options={diskDiaOptions.map(value=> ({value}))} />
      )}
/>
     },

     {
      name: 'char_diskProducer',
      title: 'Производитель',
      value: watch('char_diskProducer'),
      input: <Controller
              name='char_diskProducer'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Производитель' />
              )}
      />
     },

    {
      name: 'char_diskModel',
      title: 'Модель диска',
      value: watch('char_diskModel'),
      input: <Controller
              name='char_diskModel'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Модель диска' />
              )}
      />
    },
    {
      name: 'char_diskMfrnumber',
      title: 'Номер производителя',
      value: watch('char_diskMfrnumber'),
      input: <Controller
              name='char_diskMfrnumber'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Номер производителя' />
              )}
      />
    }
  ]


  return (
    <div className={styles.root} >
      <div className={styles.header} >
        <h3 className={styles.title} >{ watch('char_part') === 'Диск' ? 'Характеристики' : 'Диск'}</h3>
      </div>
      <div className={styles.content} >
        {itemsList.map(({title, name, value, input})=>(
              <div key={name} className={cs(styles.formItem, selectedItems.includes(name) && styles.formItem__selected)} >
              <div className={styles.formItem_title} >{title}</div>
              {selectedItems.includes(name) ?<div className={styles.formItem_input} >{input}</div> :
              <div className={styles.formItem_value} onClick={()=> onItemSelect(name)} >{value}</div>}
              {selectedItems.includes(name) ?
              <button onClick={()=> {onCancelItemSelect(name)}} type='button' className={styles.formItem_cancelBtn} >Отменить</button> :
              <button onClick={()=> onItemSelect(name)} type='button' className={styles.formItem_editBtn} >Изменить</button>}
            </div>
        ))}
      </div>
    </div>
  )
}

export default DiskFormPart