import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProduct } from "../interfaces/product";




interface InitialState {
    selectedProducts: { product: IProduct, quantity: number }[]
}


const initialState: InitialState = {
    selectedProducts: []
}


const productsSlice = createSlice({
    initialState,
    name: 'products',
    reducers: {
        addProduct: (state, action: PayloadAction<IProduct>) => {
            const product = action.payload
            const findedItem = state.selectedProducts.find(({ product: { id } }) => id === product.id)
            if (findedItem) {
              findedItem.quantity += 1
            } else {
                state.selectedProducts.push({product, quantity: 1})
            }
        },
        removeProduct: (state, action: PayloadAction<IProduct>)=> {
             const product = action.payload
             const findedItem = state.selectedProducts.find(({product: {id}})=> id === product.id)
             if(findedItem && findedItem.quantity > 1) {
                 findedItem.quantity -= 1
             } else {
                state.selectedProducts = state.selectedProducts.filter(({product: {id}})=> id !== product.id)
             }
        },
        setSelectedProducts: (state, action: PayloadAction<{ product: IProduct, quantity: number }[]>)=> {
           state.selectedProducts = action.payload
        },
        clearSelectedProducts: (state)=> {
            state.selectedProducts = []
        }
    }
})


export const { addProduct, removeProduct, setSelectedProducts, clearSelectedProducts } = productsSlice.actions
export default productsSlice.reducer