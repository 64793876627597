import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateProductDto } from "../dtos/createProductDto";
import { UpdateProductDto } from "../dtos/updateProductDto";
import { IProduct, IProducts } from "../interfaces/product";
import qs from 'qs'


type GetAllProductsQuery = { 
    pagination?: { page?: number, pageSize?: number }, 
    category?: 'part' | 'wheel' | 'oil',
    applicability?: {mark?: number, model?: number, generation?: number, body?: number, engine?: number}
 }

 


export const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getOneProduct: build.query<IProduct, string>({
            query: (id) => `/products/${id}`,
            providesTags: ['Product']
        }),
        getAllProducts: build.query<IProducts, GetAllProductsQuery | void>({
            query: (query) => {
                const { pagination, category, applicability } = query || {}
                const queryString = qs.stringify({
                    pagination,
                    category,
                    applicability
                })
                return `/products?${queryString}`
            },
            providesTags: ['Product']
        }),
        createProduct: build.mutation<IProduct, CreateProductDto | CreateProductDto[]>({
            query: (body) => ({
                url: '/products',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Product', 'Income', 'Sale', 'SaleItem']
        }),
        updateProduct: build.mutation<IProduct, {id: number, body: UpdateProductDto}>({
            query: ({ id, body }) => ({
                url: `/products/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Product', 'Income', 'Sale', 'SaleItem']
        }),
        deleteProduct: build.mutation<void, number>({
            query: (id) => ({
                url: `/products/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Product', 'Income', 'Sale', 'SaleItem']
        })
    })
})



export const { useGetOneProductQuery,
    useGetAllProductsQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation
} = extendedApi