import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateModelDto } from "../dtos/createModelDto";
import { UpdateModelDto } from "../dtos/updateModelDto";
import { IModel } from "../interfaces/model";





const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        getOneModel: build.query<IModel, string>({
            query: (id)=> `/models/${id}`,
            providesTags: ['Model']
        }),
        getAllModels: build.query<IModel[], string>({
            query: (markId)=> `/models?markId=${markId}`,
            providesTags: ['Model']
        }),
        createModel: build.mutation<IModel, CreateModelDto>({
            query: (body)=> ({
                url: '/models',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Model']
        }),
        updateModel: build.mutation<IModel, {id: number, body: UpdateModelDto}>({
            query: ({id, body})=> ({
                url: `/models/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Model']
        }),
        deleteModel: build.mutation<void, string>({
            query: (id)=> ({
                url: `/models/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Model']
        })
    })
})


export const {useGetOneModelQuery, useGetAllModelsQuery, useLazyGetAllModelsQuery, useCreateModelMutation, useUpdateModelMutation, useDeleteModelMutation} = extendedApi