import { Button, message, Modal, notification } from 'antd'
import styles from './EditWheelModal.module.scss'
import { MdOutlineEdit } from 'react-icons/md'
import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IProduct, UpdateProductDto, useUpdateProductMutation } from '../../../../../../../entities/product'
import ImagesFormPart from '../imagesFormPart'
import { IImage } from '../../../../../../../entities/image'
import GeneralInfoFormPart from '../generalInfoFormPart'
import MainParametersFormPart from '../mainParametersFormPart'
import DonorCarFormPart from '../donorCarFormPart'
import DiskFormPart from '../diskFormPart'
import { TireFormPart } from '../tireFormPart'




interface IProps {
  product?: IProduct
}



export const EditWheelModal: FC<IProps> = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isError, setIsError] = useState<{height: boolean, width: boolean}>({height: false, width: false})
  const [changedParametrs, setChangedParametrs] =
  useState<{ images: number, generalInfo: number, mainParameters: number, disk: number, tire: number, donorCar: number, placement: number }>(
    {
      images: 0,
      generalInfo: 0,
      mainParameters: 0,
      disk: 0,
      tire: 0,
      donorCar: 0,
      placement: 0
    }
   )
  const { images } = product || {}
  const [checkedImages, setCheckedImages] = useState<IImage[]>([])
  const [updateProduct] = useUpdateProductMutation()
  const { control, watch, setValue, handleSubmit } = useForm<UpdateProductDto>({
    defaultValues: {
      isUsed: undefined,
      mfr_number: undefined,
      manufacturer: undefined,
      cross_number: undefined,
      char_fb: undefined,
      char_lr: undefined,
      char_part: undefined,
      char_marking: undefined,
      char_color: undefined,
      char_price: undefined,
      char_installationPrice: undefined,
      char_oldBarcode: undefined,
      char_note: undefined,
      char_comment: undefined,
      char_wheelPart: undefined,
      char_diskType: undefined,
      wheelsSetId: undefined,
      char_diskDiametr: undefined,
      char_diskWidth: undefined,
      char_diskPCD: undefined,
      char_diskDIA: undefined,
      char_diskET: undefined,
      char_diskProducer: undefined,
      char_diskModel: undefined,
      char_diskMfrnumber: undefined,
      char_tireLT: false,
      char_tireWidth: undefined,
      char_tireWidthUnits: undefined,
      char_tireHeight: undefined,
      char_tireHeightUnits: undefined,
      char_tireCordType: undefined,
      char_tireDiametr: undefined,
      char_tireDiametrUnits: undefined,
      char_tireSpeedIndex: undefined,
      char_tireLoadCoef: undefined,
      char_tireProduceDate: undefined,
      char_tireProducer: undefined,
      char_tireModel: undefined,
      char_tireWearout: undefined,
      char_tireSeason: undefined,
      char_tireVehicleType: undefined,
      char_tireProtectorType: undefined,
      char_tireRunFlat: undefined,
      char_tireMarkingType: undefined
    }
  })

  useEffect(() => {
    const keys = Object.keys(watch())
    if(product) {
       keys.map((key: any)=> {
        setValue(key, product[key as keyof IProduct])
       })
    }
  }, [product])



  const onSubmit: SubmitHandler<UpdateProductDto> = async (data) => {
 
    if(product && !isError.height && !isError.width ) {
      const res = await updateProduct({id: product.id, body: {...data, images: checkedImages.map(({id})=> id)}})
      if('data' in res) {
        message.success('Изменения созранены')
        setIsModalOpen(false)
      } else {
        message.error('Ошибка сервера')
      }
    } else if(isError.height || isError.width) {
       notification.error({
        message: 'Не удалось сохранить',
        description : <div className={styles.notification} >
          {isError.height && <p>Поле «Высота шины» должно быть выбрано из списка</p>}
          {isError.width && <p> Поле «Ширина шины» должно быть выбрано из списка</p>}
        </div>,
        placement: 'bottom'
       })
    }
  }


  
  const changedParametersCount = useMemo(()=> { return Object.values(changedParametrs).reduce((summ, item)=> summ + item, 0)}, [changedParametrs])
 


  



 

  return (
    <>
      <button onClick={() => setIsModalOpen(true)} className={styles.openModalBtn} ><MdOutlineEdit /></button>
      <Modal destroyOnClose footer={false} width={832} centered className={styles.root} open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
        <div className={styles.inner} >
          <div className={styles.header} >
            <h1>Редактирование товара</h1>
          </div>
          <div className={styles.content} >
            <form className={styles.form} >
              <div className={styles.formPart} >
                <ImagesFormPart setChangedParametrs={setChangedParametrs} checkedImages={checkedImages} setCheckedImages={setCheckedImages} images={images} />
              </div>
              <div className={styles.formPart} >
                <GeneralInfoFormPart setValue={setValue} product={product} setChangedParametrs={setChangedParametrs} watch={watch} control={control} />
              </div>
              <div className={styles.formPart} >
               <MainParametersFormPart setValue={setValue} product={product} setChangedParametrs={setChangedParametrs} watch={watch} control={control} />
              </div>
              { (watch('char_part') === 'Диск' || watch('char_part') === 'Колесо' ) && <div className={styles.formPart} >
                <DiskFormPart setValue={setValue} product={product} setChangedParametrs={setChangedParametrs} watch={watch} control={control} />
              </div>}
              { (watch('char_part') === 'Шина' || watch('char_part') === 'Колесо' ) && <div className={styles.formPart} >
                <TireFormPart setIsError={setIsError} setValue={setValue} product={product} setChangedParametrs={setChangedParametrs} watch={watch} control={control} />
              </div>}
              <div className={styles.formPart} >
               <DonorCarFormPart setValue={setValue} product={product} setChangedParametrs={setChangedParametrs} watch={watch} control={control} />
              </div>
            </form>
          </div>

          <div className={styles.footer} >
            <div className={styles.footer_ButtonsWrapper} >
              {changedParametersCount > 0 && <div className={styles.changedParametersCount} >{`Изменено ${changedParametersCount} параметров`}</div>}
              <Button type='primary' size='large' onClick={handleSubmit(onSubmit)} >Сохранить изменения</Button>
              <Button onClick={() => setIsModalOpen(false)} size='large' >Отменить</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>

  )
}
