import { Button } from 'antd'
import IncomesTable from '../components/incomesTable'
import Navbar from '../components/navbar'
import styles from './IncomesPage.module.scss'
import { Link } from 'react-router-dom'
import {useState } from 'react'
import AddShipmentModal from '../components/addShipmentModal'
import EditShipmentModal from '../components/editShipmentModal'
import ShipmentsTable from '../components/shipmentsTable'




export const IncomesPage = () => {
  const [selectedItem, setSelectedItem] = useState<'draft'| 'completed' | 'canceled' | 'all'| 'supplies'>('draft')
  const [isAddShipmentModalOpen, setIsAddShipmentModalOpen] = useState<boolean>(false);
  const [isEditShipmentModalOpen, setEditShipmentModalOpen] = useState<boolean>(false);
  const [editShipmentId, setEditShipmentId] = useState<number>();





  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1 className={styles.title} >Поступления</h1>
      </div>
      <div className={styles.inner} >
        <Navbar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <div className={styles.content} >
          <div className={styles.contentHeader} >
            <Button className={styles.filtersResetBtn} disabled={true} >Фильтры не заданы</Button>
            {selectedItem === 'supplies' ?
              <Button onClick={()=> setIsAddShipmentModalOpen(true)} type='primary' >Создать поставку</Button> :
              <Link to={'new'} ><Button type='primary' className={styles.addIncomeBtn} >Новое поступление</Button></Link>}
          </div>
          <div className={styles.table} >
            { selectedItem !== 'supplies' ? 
             <IncomesTable status={selectedItem}/>
              :
               <ShipmentsTable setEditShipmentId={setEditShipmentId} setEditShipmentModalOpen={setEditShipmentModalOpen} />}
            
          </div>
        </div>
      </div>
        <AddShipmentModal isModalOpen={isAddShipmentModalOpen} setIsModalOpen={setIsAddShipmentModalOpen} />
        <EditShipmentModal shipmentId={editShipmentId} isEditShipmentModalOpen={isEditShipmentModalOpen} setEditShipmentModalOpen={setEditShipmentModalOpen} />
    </main>
  )
}
