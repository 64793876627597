import { Link } from 'react-router-dom'
import styles from './AddReturnPage.module.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, notification } from 'antd'
import { useEffect, useState } from 'react'
import ItemsTable from '../itemsTable'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { ISaleItem, } from '../../../../../entities/saleItem'
import { saleItemsSelector, selectedSaleSelector, setSaleSelectedItems } from '../../../../(sales)/salePage'
import ReturnUpdateForm from '../returnUpdateForm'
import { ReturnPaymentModal } from '../returnPaymentModal'




export const AddReturnPage = () => {
    const saleSelectedItems = useAppSelector(saleItemsSelector)
    const dispatch = useAppDispatch()
    const [selectedItems, setSelectedItems] = useState<ISaleItem[]>([])
    const [deletedItems, setDeletedItems] = useState<number[]>([])
    const sale = useAppSelector(selectedSaleSelector)
    const [isReturnModalOpen, setIsReturnModalOpen] = useState<boolean>(false)
    const returns = sale?.returns
    const saleItems = sale?.saleItems
    const [comment, setComment] = useState<string>()

    useEffect(() => {
        setSelectedItems(saleSelectedItems)
        dispatch(setSaleSelectedItems([]))
    }, [])



const calculateReturnQuantity = (saleItemId: number) => {
    const returnItems = returns?.map((item)=>  item?.returnItems?.filter((item)=> item?.saleItem?.id === saleItemId))
   return returnItems?.flat().reduce((sum, item)=> sum + item.quantity, 0) || 0
   }

   const onReturn = ()=> {
    const isValid = selectedItems.every(({id, quantity})=>
         (saleItems?.find((item)=> item.id === id)?.quantity || 0) - calculateReturnQuantity(id) >=  quantity)
     if(!isValid) {
        notification.warning({message: 'Невозможно провести возврат',
             description: 'Вы пытаетесь вернуть больше, чем было выдано по сделке. Уменьшите количество возвращаемых товаров.',  placement: 'bottom'})
     } else {
         setIsReturnModalOpen(true)
     }
   }

   const onDeleteItems = ()=> {
      setSelectedItems((prev)=> prev.filter((item)=> !deletedItems.includes((item.id))))
      setDeletedItems([])
   }


    return (
        <main className={styles.root} >
            <ReturnPaymentModal comment={comment} items={selectedItems} sale={sale} isModalOpen={isReturnModalOpen} setIsModalOpen={setIsReturnModalOpen} />
            <div className={styles.inner} >
                <div className={styles.header} >
                    <Link to='/returns' className={styles.returnLink} ><IoIosArrowBack /></Link>
                    <h1 className={styles.title} >{sale?.client?.name || 'Частное лицо' }</h1>
                </div>
                <div className={styles.content} >
                    <div className={styles.content_inner} >
                        <div className={styles.return} >
                            {saleSelectedItems.length > 0 ?
                                <div className={styles.return_header} >
                                    <h2>{`Выбрано ${saleSelectedItems.length}`}</h2>
                                    <div className={styles.return_btnHolder} >
                                        <button className={styles.noticeButton} >
                                            Удалить из возврата
                                        </button>
                                    </div>
                                    <Button type='text' onClick={() => dispatch(setSaleSelectedItems([]))} >Отменить</Button>
                                </div>
                                :
                                <div className={styles.return_header} >

                                    <div className={styles.return_headerTitle} >

                                        <h2>Новый возврат</h2>

                                    </div>
                                 { selectedItems?.length > 0 &&  (  deletedItems.length > 0 ? <button onClick={onDeleteItems} className={styles.noticeButton} >Удалить из возврата</button>  : <div className={styles.return_btnHolder} >
                                        <Button onClick={onReturn} type='primary' > Провести возврат </Button>
                                        <Button type='default' > Отменить </Button>


                                    </div>)}
                                    <div className={styles.return_settings} ></div>
                                </div>}

                            <div className={styles.return_content} >
                                <ReturnUpdateForm comment={comment} setComment={setComment} sale={sale} saleItems={selectedItems} />
                                <div className={styles.return_tableHolder} >
                                    <div className={styles.return_table} >
                                        {selectedItems.length > 0 ? <ItemsTable  deletedItems={deletedItems} setDeletedItems={setDeletedItems} setItems={setSelectedItems} items={selectedItems} sale={sale} />
                                            :
                                            <div className={styles.emptyTableNotice} >
                                                <div className={styles.emptyTableNotice_inner} >
                                                    <h4>Документ пуст</h4>
                                                    <p>Откройте нужную сделку
                                                        <br />
                                                        и выберите товары для возрата</p>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.history} >
                            <div className={styles.history_header} >
                                <h2>История документа</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}