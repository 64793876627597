


const tireDiametrInchOptions = [12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]

const tireDiametrMmOptions = [90, 100, 105, 106, 115, 120, 140, 145, 150, 160, 164, 165, 170,
    180, 190, 194, 200, 203, 220, 225, 240, 254, 267, 270, 280, 286, 300, 305, 308, 320,
    330, 370, 381, 405, 406, 410, 430.9, 457, 463, 470, 480, 485, 500, 508, 509, 509.5,
    533, 546, 555, 559, 600, 611, 635, 640, 670, 685, 713
]

const tireHeightPercentOptions = [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85]

const tireHeightMmOptions = [228, 250, 284, 295, 300, 305, 310, 313, 315, 320, 325,
    330, 335, 340, 343, 350, 355, 360, 365, 370, 380, 381, 385, 400, 405, 406,
    413, 415, 420, 425, 440, 445, 450, 455, 460, 465, 480, 495, 500, 505, 520,
    525, 540, 550, 559, 560, 580, 600, 610, 620, 630, 645, 650, 660, 680, 700,
    705, 710, 725, 750, 760, 775, 780, 800, 840, 850, 854, 870, 875, 900, 926,
    1000, 1025, 1050, 1100, 1200, 1300, 1350, 1500, 1600]

const tireHeightInchOptions = [21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26,
    26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30, 30.5, 31, 31.5, 32, 32.5, 33, 33.5,
    34, 34.5, 35, 35.5, 36, 36.5, 37, 38, 38.5, 39, 40, 41, 42
]

const tireWidthMmOptions = [ 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245,
    255, 265, 275, 285, 295, 305, 315, 325, 335
]

const tireWidthInchOptions = [5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12,
    12.5, 13, 13.5, 14, 14.5, 15, 15.5]

export {
    tireDiametrInchOptions,
    tireDiametrMmOptions,
    tireHeightPercentOptions,
    tireHeightMmOptions,
    tireHeightInchOptions,
    tireWidthMmOptions,
    tireWidthInchOptions
}