import { Button, Checkbox, Image, message, Modal, Pagination, Popconfirm, Upload, UploadFile } from 'antd'
import styles from './GalleryModal.module.scss'
import { FC, useEffect, useState } from 'react'
import { IoMdArrowBack } from 'react-icons/io'
import { IImage, useDeleteImageMutation, useGetAllImagesQuery, useUploadImageMutation } from '../../../entities/image'
import { serverUrl } from '../../../shared/constants/serverUrl'


interface IProps {
  checkedImages: IImage[]
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCheckedImages: React.Dispatch<React.SetStateAction<IImage[]>>
}

export const GalleryModal: FC<IProps> = ({isModalOpen, checkedImages, setCheckedImages, setIsModalOpen }) => {
  const [isGallery, setIsGallery] = useState<boolean>(true)
  const [uploadImage] = useUploadImageMutation()
  const [images, setImages] = useState<UploadFile<any>[]>()
  const [messageApi, contextHolder] = message.useMessage()
  const { data: imagesList } = useGetAllImagesQuery()
  const [deleteImage] = useDeleteImageMutation()
  
  const onSubmit = () => {
    if (images) {
      images.forEach((item) => {
        const formData = new FormData()
        formData.append('image', item.originFileObj as Blob)
        uploadImage(formData)
      });
      messageApi.success('Изображения успешно загружены')
      setIsGallery(true)
    }
  }

  useEffect(() => {
    if (isGallery) {
      setImages(undefined)
    }
  }, [isGallery])



  const onImageCheck = (checked: boolean, image: IImage) => {
    if (checked) {
      setCheckedImages((prev) => [...prev, image])
    } else {
      const filteredArr = checkedImages.filter(({id}) => id !== image.id)
      setCheckedImages(filteredArr)
    }
  }

  const handleDelete = async () => {
    if (checkedImages.length > 0) {
      checkedImages.map(async ({id}) => {
        await deleteImage(id)
      })
      setCheckedImages([])
      messageApi.success('Изображения успешно удалены')
    }

  }


  return (
    <Modal  className={styles.root} title='Фотографии' centered width='98vw' footer={false} open={isModalOpen} onCancel={()=> setIsModalOpen(false)} >
    <div className={styles.body} >
      {contextHolder}
      {
        isGallery ?
          <div className={styles.inner} >
            <div className={styles.header} >
              {checkedImages.length > 0 && <div className={styles.contextBtnHolder} >
                <Button onClick={() => setIsModalOpen(false)} type='primary' >Добавить к продукту</Button>
                <Button onClick={() => setCheckedImages([])}  > Отменить выбор </Button>
                <Popconfirm
                  title="Удаление изображений"
                  description="Вы действительно хоти удалить изображения?"
                  onConfirm={handleDelete}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button danger type='primary' >Удалить</Button>
                </Popconfirm>
              </div>}
              <Button type='primary' onClick={() => setIsGallery(false)} >Загрузить новые</Button>
            </div>
            <ul className={styles.gallery} >
              {
                imagesList?.map((image) => (

                  <li className={styles.galleryItem} key={image.id} >
                    <Checkbox checked={checkedImages.some(({id})=> id === image.id) ? true : false} onChange={(e) => onImageCheck(e.target.checked, image)} className={styles.checkbox} />
                    <Image className={styles.image} src={serverUrl + image.url} />
                  </li>

                ))
              }
            </ul>
            <Pagination className={styles.pagination} align='center' />
          </div>
          :
          <div className={styles.inner} >
            <div className={styles.header} ><Button icon={<IoMdArrowBack />} onClick={() => setIsGallery(true)} >Назад</Button></div>
            <div className={styles.content} >
              <div className={styles.uploadHolder} >
                <Upload showUploadList={{ showPreviewIcon: false }} onChange={(e) => setImages(e.fileList)} beforeUpload={file => false} multiple listType='picture-card'   >
                  Добавить фотографии
                </Upload>
              </div>
              {images && <Button type='primary' className={styles.uploadBtn} onClick={() => onSubmit()} >Загрузить</Button>}
            </div>
          </div>
      }

    </div>
 </Modal>
  )
}

