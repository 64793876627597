import { FC, useState } from 'react'
import styles from './Navbar.module.scss'
import cs from 'classnames'




const navList = [
  {
    id: 1, 
    title: 'Черновики',
    value: 'draft'
  },
  {
    id: 2, 
    title: 'Выполненные',
    value: 'completed'
  },
  {
    id: 3, 
    title: 'Отменённные',
    value: 'canceled'
  },
  {
    id: 4, 
    title: 'Все поступления',
    value: 'all'
  },
]

interface IProps {
  selectedItem: 'draft'| 'completed' | 'canceled' | 'all'| 'supplies'
  setSelectedItem : React.Dispatch<React.SetStateAction<'draft'| 'completed' | 'canceled' | 'all'| 'supplies'>>
}

const Navbar: FC<IProps> = ({selectedItem, setSelectedItem}) => {
  

  return (
    <div className={styles.root} >
        <nav className={styles.inner} >
            <ul className={styles.incomesNavList} >
               {
                navList.map(({id, title, value})=> (
                  <li key={id} onClick={()=> setSelectedItem(value as 'draft'| 'completed' | 'canceled' | 'all'| 'supplies')} className={cs(styles.navItem, value === selectedItem && styles.navItem__active)} >{title}</li>
                ))
               }
            </ul>
            <div className={styles.supplies} >
              <div onClick={()=> setSelectedItem('supplies')} className={cs(styles.navItem, 'supplies' === selectedItem && styles.navItem__active)} >Поставки</div>
            </div>
        </nav>
    </div>
  )
}

export default Navbar