import styles from './GeneralInfoFormPart.module.scss'
import { FC, useEffect, useState } from 'react'
import cs from 'classnames'
import { Input, InputNumber, Select } from 'antd'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { IProduct, UpdateProductDto } from '../../../../../../../entities/product'
import TextArea from 'antd/es/input/TextArea'
import { CiLock } from 'react-icons/ci'



interface IProps {
  product?: IProduct
  control: Control<UpdateProductDto, any>
  watch: UseFormWatch<UpdateProductDto>
  setChangedParametrs: React.Dispatch<React.SetStateAction<{
    images: number;
    generalInfo: number;
    mainParameters: number;
    donorCar: number;
    placement: number;
}>>
setValue: UseFormSetValue<UpdateProductDto>
}

const GeneralInfoFormPart: FC<IProps> = ({product, control, watch, setChangedParametrs, setValue}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
 

  const onItemSelect = (name: string)=> {
    if(name !== 'id') {
      setSelectedItems(prev => [...prev, name])
    }
  }

  const onCancelItemSelect = (name: any)=> {
    setValue(name, product?.[name as keyof IProduct])
    setSelectedItems(prev => prev.filter((item)=> name !== item))
  }

  
  useEffect(()=> {
     setChangedParametrs((prev)=> ({...prev, generalInfo: selectedItems.length}))
  }, [selectedItems])

  const itemsList = [
    {
      name: 'id',
      title: 'Номер товара',
      value: product?.id
     },
    {
      name: 'isUsed',
      title: 'Состояние',
      value: watch('isUsed') ? 'БУ': 'Новый',
      input:  <Controller
              name='isUsed'
              control={control}
              render={({field})=> (
                <Select {...field} >
                  <Select.Option value={true} >БУ</Select.Option>
                  <Select.Option value={false} >Новый</Select.Option>
                </Select>
              )}
      />
    },
    {
      name: 'char_price',
      title: 'Цена',
      value: watch('char_price') || '-',
      input: <Controller
              name='char_price'
              control={control}
              render={({field})=> (
                <InputNumber placeholder='Введите сумму' controls={false} {...field} />
              )}
      />
    },
    {
      name: 'char_installationPrice',
      title: 'Стоимость установки',
      value: watch('char_installationPrice') || '-',
      input: <Controller
              name='char_installationPrice'
              control={control}
              render={({field})=> (
                <InputNumber {...field} controls={false} placeholder='Введите сумму' />
              )}
      />
    },
    {
      name: 'char_comment',
      title: 'Комментарий (для клиентов)',
      value: watch('char_comment') || '-',
      input: <Controller
              name='char_comment'
              control={control}
              render={({field})=> (
                <TextArea autoSize={{minRows: 2}} placeholder='Комментарий к товару виден клиентам'  {...field} />
              )}
      />
    },{
    name: 'char_note',
    title: 'Заметка (для вас)',
    value: watch('char_note') || '-',
    input: <Controller
            name='char_note'
            control={control}
            render={({field})=> (
              <TextArea autoSize={{minRows: 2}} placeholder='Заметка видна только вам'  {...field} />
            )}
    />
    },
    {
      name: 'char_oldBarcode',
      title: 'Старый баркод',
      value: watch('char_oldBarcode') || '-',
      input: <Controller
              name='char_oldBarcode'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Введите баркод' />
              )}
      />
    }
  ]


  return (
    <div className={styles.root} >
      <div className={styles.header} >
        <h3 className={styles.title} >Общая информация</h3>
      </div>
      <div className={styles.content} >
        {itemsList.map(({title, name, value, input})=>(
              <div key={name} className={cs(styles.formItem, selectedItems.includes(name) && styles.formItem__selected)} >
              <div className={styles.formItem_title} >{title}</div>
              {selectedItems.includes(name) ?<div className={styles.formItem_input} >{input}</div> :
              <div className={styles.formItem_value} style={{...(name === 'id' && {color:  'rgb(18 18 18 / 50%)'})}} onClick={()=> onItemSelect(name)} >{value}</div>}
              {selectedItems.includes(name) ?
              <button onClick={()=> onCancelItemSelect(name)} type='button' className={styles.formItem_cancelBtn} >Отменить</button> : 
              name !== 'id' ?  <button onClick={()=> onItemSelect(name)} type='button' className={styles.formItem_editBtn} >Изменить</button>:
              <div className={styles.lock} ><CiLock /></div>
              }
            </div>
        ))}
      </div>
    </div>
  )
}

export default GeneralInfoFormPart