import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './shared/store/store';
import './shared/styles/global.scss'
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

    <ConfigProvider locale={ru_RU} >
    <Provider store={store} >
    <BrowserRouter>
    <App />
    </BrowserRouter>
    </Provider>
    </ConfigProvider>
 
);
