import { FC, useEffect, useState } from 'react'
import styles from './EditShipmentModal.module.scss'
import { Button, Input, message, Modal, Popconfirm } from 'antd'
import { UpdateShipmentDto, useDeleteShipmentMutation, useGetOneShipmentQuery, useUpdateShipmentMutation } from '../../../../../../entities/shipment'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import TextArea from 'antd/es/input/TextArea'
import { useNavigate } from 'react-router-dom'



interface IProps {
    isEditShipmentModalOpen: boolean
    shipmentId?: number
    setEditShipmentModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}



const EditShipmentModal: FC<IProps> = ({shipmentId, isEditShipmentModalOpen, setEditShipmentModalOpen}) => {
    const [isUpdateShipmentForm, setIsUpdateShipmentForm] = useState<boolean>(false)
    const {data: shipment} = useGetOneShipmentQuery(String(shipmentId), {skip: !shipmentId})
    const {title, description} = shipment || {}
    const navigate = useNavigate()
    const [updateShipment] = useUpdateShipmentMutation()
    const [deleteShipment] = useDeleteShipmentMutation()
    const {control, handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            title: '',
            description: ''
        }
    }) 

    useEffect(()=> {
        if(shipment) {
            title && setValue('title', title)
            description && setValue('description', description)
        }
    }, [shipment, isEditShipmentModalOpen])

    const hadleCancel = ()=> {
        setEditShipmentModalOpen(false)
        setIsUpdateShipmentForm(false)
    }

    const onSubmit: SubmitHandler<UpdateShipmentDto> = async (data)=> {
        if(watch('title')) {
            await updateShipment({body: data, id: String(shipmentId)})
            message.success('Поставка успешно сохранена')
            setIsUpdateShipmentForm(false)
            setEditShipmentModalOpen(false)
        }
         
    }

    const onDelete =  ()=> {
        deleteShipment(String(shipmentId))
        message.success('Поставка успешно удалена')
        setEditShipmentModalOpen(false)
    }
    
  return (
   <Modal title={!isUpdateShipmentForm ? `Поставка ${title}`: 'Редактирование поставки'} centered width={400} footer={false} onCancel={hadleCancel} open={isEditShipmentModalOpen} >
{!isUpdateShipmentForm ? <div className={styles.inner} >
    
        <div className={styles.content} >
           <div className={styles.item} >
            <div className={styles.itemTitle} >Название</div>
            <div className={styles.itemValue} >{title}</div>
           </div>
           <div className={styles.item} >
            <div className={styles.itemTitle} >Описание</div>
            <div className={styles.itemValue} >{description}</div>
           </div>
        </div>
        <div className={styles.footer} >
        <Popconfirm
    title="Удаление поставки"
    description="Уверены что хотите удалить?"
    onConfirm={onDelete}
    okText="Да"
    cancelText="Нет"
  >
                <Button htmlType='button' danger type='primary'>Удалить</Button>
                </Popconfirm>
                <Button onClick={()=> setIsUpdateShipmentForm(true)} type='primary'>Редактировать</Button>
                <Button onClick={hadleCancel}>Закрыть</Button>
    
                 </div>
</div>
:
<div className={styles.inner} >
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form} action="">
        <div className={styles.formContent} >
        <div className={styles.formItem} >
            <Controller
            name='title'
            control={control}
            render={({field})=> (
                <Input {...field} placeholder='Название' />
            )}
            />
        </div>
        <div className={styles.formItem} >
            <Controller
            name='description'
            control={control}
            render={({field})=> (
                <TextArea autoSize={{ minRows: 5, maxRows: 5 }} {...field} placeholder='Описание' />
            )}
            />
        </div>
        </div>
        <div className={styles.footer} >
        <Button onClick={hadleCancel} >Закрыть</Button>
        <Button htmlType='submit' type='primary'>Сохранить</Button>
        </div>
    </form>
</div>
}
   </Modal>

  
  )
}

export default EditShipmentModal