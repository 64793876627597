import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import styles from './WriteoffUpdateForm.module.scss'
import { Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC, useEffect } from 'react'
import { useGetAllStoragesQuery } from '../../../../../entities/storage'
import { CreateWriteoffDto } from '../../../../../entities/writeoff'




interface IProps {
  control: Control<CreateWriteoffDto, any>
  setValue: UseFormSetValue<CreateWriteoffDto>
}

const WriteoffUpdateForm: FC<IProps> = ({ control, setValue }) => {
  const { data: storages } = useGetAllStoragesQuery()

  useEffect(()=> {
    setValue('storageId', storages?.[0]?.id)
  }, storages)

  return (
    <form className={styles.root} >

      <div className={styles.formPart} >
        <div className={styles.formItem} >
          <label>Склад списания</label>
          <Controller
            name='storageId'
            control={control}
            render={({ field }) =>
              <Select
                value={field.value}
                onChange={(value) => {
                field.onChange(value)
                }}
                options={storages?.map(({ id, name }) => ({ label: name, value: id })) || []} />
            }
          />
        </div>
        <div className={styles.formItem} >
          <Controller
            name='comment'
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                autoSize={{ minRows: 3 }} placeholder='Комментарий к списанию' />
            )}
          />

        </div>


      </div>


    </form>
  )
}

export default WriteoffUpdateForm