import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateClientDto } from "../dtos/createClientDto";
import { UpdateClientDto } from "../dtos/updateClientDto";
import { IClient, IClients } from "../interfaces/client";
import qs from 'qs'

interface QueryParams {
    pagination: {
        page: number
        pageSize: number
    }
}

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getAllClients: build.query<IClients, QueryParams | void>({
            query: (query)=> {
                const queryString = qs.stringify(query)
                return `/clients?${queryString}`
            },
            providesTags: ['Client']
        }),
        getOneClient: build.query<IClient, string>({
            query: (id)=> `/clients/${id}`,
            providesTags: ['Client']
        }),
        createClient: build.mutation<IClient, CreateClientDto>({
            query: (body)=> ({
                url: '/clients',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Client']
        }),
        updateClient: build.mutation<IClient, {id: string, body: UpdateClientDto}>({
            query: ({id, body})=> ({
                url: `/clients/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Client']
        }),
        deleteClient: build.mutation<void, string>({
            query: (id)=> ({
                url: `/clients/${id}`,
                method: 'DELETE'
            }),
             invalidatesTags: ['Client']
        })

    })
})

export const {useGetAllClientsQuery, useCreateClientMutation, useGetOneClientQuery, useUpdateClientMutation, useDeleteClientMutation} = extendedApi