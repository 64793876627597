import { Button, Modal, Table, TableColumnsType } from 'antd'
import styles from './ApplicabilityPage.module.scss'
import { useState } from 'react'
import AddMarkForm from '../components/addMarkForm'
import { IMark, useGetAllMarksQuery } from '../../../../../entities/mark'
import { useNavigate } from 'react-router-dom'


export const ApplicabilityPage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
 const {data: marks} = useGetAllMarksQuery()
 const navigate = useNavigate()
  const onModalCancel = ()=> {
    setIsModalOpen(false)
  }

 
  const columns: TableColumnsType<IMark> = [
    {
     title: 'ID',
     dataIndex: 'id'
    },
    {
      title: 'Название',
      dataIndex: 'title'
    }
  ]

  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1>Применимость</h1>
      </div>
      <div className={styles.content} >
        <Modal footer={false}  title='Добавить марку'  open={isModalOpen} onCancel={onModalCancel} >
          <AddMarkForm setIsModalOpen={setIsModalOpen} />
        </Modal>
        <div className={styles.contentHeader} >
          <div className={styles.tableTitle}  >
            <h2>Марки</h2>
          </div>
          <Button onClick={()=> setIsModalOpen(true)} type='primary' >Добавить марку</Button>
        </div>
        <div className={styles.tableHolder} >
          <Table
          columns={columns}
          onRow={(item)=> ({onClick: ()=> navigate(`/applicability/mark/${item.id}`)})}
          dataSource={marks?.map((item)=> ({...item, key: item.id}))}
          />
        </div>
      </div>
    </main>
  )
}
