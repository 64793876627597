import { FC, useState } from 'react'
import styles from './Navbar.module.scss'
import cs from 'classnames'




const navList = [
  {
    id: 1, 
    title: 'Все ордера',
    value: 'all'
  },
  {
    id: 2, 
    title: 'Приходные ордера',
    value: 'gt'
  },
  {
    id: 3, 
    title: 'Расходные ордера',
    value: 'lt'
  }
]

interface IProps {
  selectedItem: "all" | "gt" | "lt" | "checks"
  setSelectedItem: React.Dispatch<React.SetStateAction<"all" | "gt" | "lt"| "checks">>
}

const Navbar: FC<IProps> = ({selectedItem, setSelectedItem}) => {
  

  return (
    <div className={styles.root} >
        <nav className={styles.inner} >
            <ul className={styles.incomesNavList} >
               {
                navList.map(({id, title, value})=> (
                  <li key={id} onClick={()=> setSelectedItem(value as "all" | "gt" | "lt" | "checks")} className={cs(styles.navItem, value === selectedItem && styles.navItem__active)} >{title}</li>
                ))
               }
            </ul>
            <div className={styles.supplies} >
              <div onClick={()=> setSelectedItem('checks')} className={cs(styles.navItem, 'checks' === selectedItem && styles.navItem__active)} >Чеки</div>
            </div>
        </nav>
    </div>
  )
}

export default Navbar