import { configureStore } from '@reduxjs/toolkit'
import { emptySplitApi } from '../configs/rtkBase'
import { applicabilitySlice } from '../../entities/applicability'
import { incomePageSlice } from '../../pages/(incomes)/incomePage'
import productsSlice from '../../entities/product/store/productsSlice'
import { salePageSlice } from '../../pages/(sales)/salePage'



export const store = configureStore({
    reducer: {
      applicability: applicabilitySlice,
      incomePage: incomePageSlice,
      salePage: salePageSlice,
      products: productsSlice,
      [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    },
  
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(emptySplitApi.middleware),
  })
  


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch