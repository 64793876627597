import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { IImage } from "../interfaces/image";




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getAllImages: build.query<IImage[], void>({
          query: ()=> '/images',
          providesTags: ['Image']
        }),
        uploadImage: build.mutation<IImage, FormData>({
            query: (body)=>  ({
                url: '/uploads',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Image']
        }),
        deleteImage: build.mutation<void, Number>({
            query: (id)=> ({
                url: `/images/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Image']
        })
    })
})


export const { useGetAllImagesQuery, useUploadImageMutation, useDeleteImageMutation} = extendedApi