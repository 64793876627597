import { FC, useState } from 'react';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import styles from './ProductGallery.module.scss'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import Swiper from 'swiper';
import {FreeMode, Thumbs, Navigation, Mousewheel} from 'swiper/modules'

import { Image } from 'antd';
import { IImage } from '../../../../../entities/image';
import { serverUrl } from '../../../../../shared/constants/serverUrl';

interface IProps {
  images: IImage[]
}

const ProductGallery: FC<IProps> = ({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<Swiper | null | undefined>(null);




  return (
    <div className={styles.root} >
      <SwiperComponent
        spaceBetween={10}
        navigation={true}
        modules={[Thumbs, FreeMode, Navigation]}  
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        className={styles.swiper1}
      >
        {
          images.map(({ id, url }) => (
            <SwiperSlide className={styles.slide} key={id} >

              <div className={styles.slideInner} >
                <Image className={styles.slideImage} src={serverUrl + url} />
              </div>

            </SwiperSlide>
          ))
        }
      </SwiperComponent>
      <SwiperComponent
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={'auto'}
        freeMode={true}
        watchSlidesProgress
        mousewheel
        modules={[Thumbs, FreeMode, Navigation, Mousewheel]}
        className={styles.swiper2}
      >
        {
          images.map(({ id, url }) => (
            <SwiperSlide className={styles.thumbSlide} key={id} >
              <div className={styles.thumbSlideInner}>
                <img src={serverUrl + url} className={styles.thumbImage} />
              </div>
            </SwiperSlide>
          ))
        }
      </SwiperComponent>
    </div>
  )
}


export default ProductGallery