import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { IWheelsSet } from "../interfaces/wheelsSet";



const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({
        createWheelsSet: build.mutation<IWheelsSet, void>({
            query: (data)=> ({
                url: '/wheels-set',
                method: 'POST'
            }),
            invalidatesTags: ['WheelsSet']
        })
    })
})


export const {useCreateWheelsSetMutation} = extendedApi