import { FC, useEffect, useState } from 'react'
import styles from './ApplicabilitySearch.module.scss'
import { IoExtensionPuzzleOutline } from 'react-icons/io5'
import { Modal } from 'antd'
import cs from 'classnames'
import Search from 'antd/es/transfer/search'
import { IMark, useLazyGetAllMarksQuery } from '../../../../../entities/mark'
import { IModel, useLazyGetAllModelsQuery } from '../../../../../entities/model'
import { IGeneration, useLazyGetAllGenerationsQuery } from '../../../../../entities/generation'
import { IBody, useLazyGetAllBodysQuery } from '../../../../../entities/body'
import { IEngine, useLazyGetAllEnginesQuery } from '../../../../../entities/engine'


interface IProps {
    setSearchParams: React.Dispatch<React.SetStateAction<{
        mark?: IMark;
        model?: IModel;
        generation?: IGeneration;
        body?: IBody;
        engine?: IEngine;
    }>>
    searchParams: {
        mark?: IMark;
        model?: IModel;
        generation?: IGeneration;
        body?: IBody;
        engine?: IEngine;
    }
}

const ApplicabilitySearch: FC<IProps> = ({setSearchParams, searchParams}) => {
    const [params, setParams] = useState<
  {mark?: IMark, model?: IModel, generation?: IGeneration, body?: IBody, engine?: IEngine}
  >({mark: undefined, model: undefined, generation: undefined, body: undefined, engine: undefined})
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [itemsList, setItemsList] = useState<{id: number, title: string}[]>([])
    const [activeParam, setActiveParam] = useState<keyof typeof params>('mark')
    const [getAllMarks] = useLazyGetAllMarksQuery()
    const [getAllModel] = useLazyGetAllModelsQuery()
    const [getAllGenerations] = useLazyGetAllGenerationsQuery()
    const [getAllBodys] = useLazyGetAllBodysQuery()
    const [getAllEngines] = useLazyGetAllEnginesQuery()


    const {mark, model, generation, body, engine} = params || {}


    useEffect(()=> {
        const setData = async ()=> {
            const {data: marks} = await getAllMarks()
  
        if(!mark && marks) {
            setActiveParam('mark')
            setItemsList(marks.map(({id, title})=> ({id, title})))
        }
        if(mark && !model) {
             const {data: models} = await getAllModel(String(mark.id))
             setActiveParam('model')
             models && setItemsList(models.map(({id, title})=> ({id, title})))
        }  
        if(model && !generation) {
            const {data: generations} = await getAllGenerations(String(model.id))
            setActiveParam('generation')
            generations && setItemsList(generations.map(({id, title})=> ({id, title})))
        }
        if(generation && !body) {
            const {data: bodys} = await getAllBodys(String(generation.id))
            setActiveParam('body')
            bodys && setItemsList(bodys.map(({id, title})=> ({id, title})))
        }
        if(body) {
            const {data: engines} = await getAllEngines(String(body.id))
            setActiveParam('engine')
            engines && setItemsList(engines.map(({id, title})=> ({id, title})))
        }
       
        }


        setData()
    }, [activeParam, params])


    
    useEffect(()=> {
          
    }, [searchText])

    const paramsList = [
        {
            name: 'mark',
            title: 'Марка',
            value: mark
        },
        {
            name: 'model',
            title: 'Модель',
            value: model
        },
        {
            name: 'generation',
            title: 'Поколение',
            value: generation
        },
        {
            name: 'body',
            title: 'Кузов',
            value: body
        },
        {
            name: 'engine',
            title: 'Двигатель',
            value: engine
        }
    ]

  
    const onCancel = ()=> {
        setIsModalOpen(false)
        setParams({mark: undefined, model: undefined, generation: undefined, body: undefined, engine: undefined})
    }

    const handleParamChange = (name: typeof activeParam)=> {
        setActiveParam(name)
        switch(name) {
            case 'mark' : setParams({mark: undefined, model: undefined, generation: undefined, body: undefined, engine: undefined})
            break
            case 'model' : setParams(prev=> ({...prev, model: undefined, generation: undefined, body: undefined, engine: undefined}))
            break
            case 'generation' : setParams(prev => ({...prev, generation: undefined, body: undefined, engine: undefined}))
            break
            case 'body' : setParams(prev => ({...prev, body: undefined, engine: undefined}))
        }
    }

    const handleItemChange = (item: {
        id: number;
        title: string;
    })=>  {
        setSearchText('')
        setParams(prev => ({...prev, [activeParam]: item}))
    }

    const onConfirm = ()=> {
        setSearchParams(params)
        setIsModalOpen(false)
    }


  return (
       <div className={styles.root} > 
        <button onClick={()=> setIsModalOpen(true)} className={styles.openBtn} >
        <IoExtensionPuzzleOutline/> Поиск по применимости
        </button>

        <Modal onOk={onConfirm} onClose={onCancel}  centered width={800} className={styles.antModal} okText='Показать запчасти' open={isModalOpen} onCancel={onCancel}>
            <div className={styles.header} >
                <h1>Поиск по применимости</h1>
            </div>
            <div className={styles.content} >
                    <div className={styles.paramsWrapper} >
                        {
                            paramsList.map(({name, title, value})=> (
                                <div key={name} onClick={()=> handleParamChange(name as typeof activeParam )} className={cs(styles.param, activeParam === name && styles.param__active, value && name !== 'engine' && styles.param__checked)} >{value?.title ? value.title : title }</div>
                            ))
                        }
                    </div>

                    <div className={styles.selector} >
                        <Search value={searchText} onChange={(e: any)=> setSearchText(e.target.value)} placeholder='Поиск' />
                        <ul className={styles.list} >
                         {itemsList?.filter(({title})=> title.toLowerCase().includes(searchText.toLowerCase())).map((item)=> (
                        <li key={item.id} onClick={()=> handleItemChange(item)} className={cs(styles.listItem, params[activeParam]?.id === item.id && styles.listItem__active)} >{item.title}</li>
                     ))}
                        
                    </ul>
                    </div>

                  
            </div>
        </Modal>
        </div>
  )
}

export default ApplicabilitySearch