import { Cascader, Modal, Segmented } from 'antd'
import styles from './WheelPage.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetOneProductQuery } from '../../../../../entities/product'
import cs from 'classnames'
import TextArea from 'antd/es/input/TextArea'
import ProductGallery from '../productGallery'
import { EditWheelModal } from '../editWheelModal'

export const WheelPage = () => {
 const navigate = useNavigate()
 const location = useLocation()
 const productId = location.pathname.split('/')[3]
 const {data: product} = useGetOneProductQuery(productId)
  const onCancel = ()=> {
     navigate('/catalog/wheels')
  }

  const {char_part, char_price, id, char_comment, char_note, images, income, stock, isUsed,
     char_diskType, char_tireVehicleType, char_diskDIA, char_diskET, char_diskPCD, char_diskModel,
     char_diskMfrnumber, char_diskProducer, char_diskWidth, char_diskDiametr, char_tireHeight,
      char_tireModel, char_tireWidth, char_tireDiametr, char_tireLT, char_tireSpeedIndex, 
      char_tireLoadCoef, char_tireProduceDate, char_tireProducer, char_tireWearout, wheelsSet
    
  } = product || {}
  const {shipment} = income || {}
  const {storage} = income || {}


  const discDescriptonItems: {name: string, value?: string | number}[] = [
    {
      name: 'Тип',
      value: char_diskType
    },
    {
      name: 'Параметры',
      value: `${char_diskDiametr}/${char_diskWidth} ${char_diskPCD?.[0]} ${char_diskPCD?.[1]}`
    },
    {
      name: 'Вылет',
      value: char_diskET
    },
    {
      name: 'Диаметр ЦО, мм',
      value: char_diskDIA
    },
    {
      name: 'Производитель',
      value: char_diskProducer
    },
    {
      name: 'Модель диска',
      value: char_diskModel
    },
    {
      name: 'Номер производителя',
      value: char_diskMfrnumber
    }
  ]

  const tyreDescritionItems: {name: string, value?: string | number}[] = [
    {
      name: 'Тип шины',
      value: char_tireVehicleType
    },
    {
      name: 'Параметры',
      value: `${char_tireWidth}/${char_tireHeight}D${char_tireDiametr} ${char_tireLT ? 'LT' : ''}`
    },
    {
      name: 'Индекс скорости',
      value: char_tireSpeedIndex
    },
    {
      name: 'Индекс нагрузки',
      value: char_tireLoadCoef
    },
    {
      name: 'Год производства',
      value: char_tireProduceDate
    },
    {
      name: 'Производитель',
      value: char_tireProducer
    },
    {
      name: 'Модель шины',
      value: char_tireModel
    },
    {
      name: 'Износ, %',
      value: char_tireWearout
    }
  ]

  return (

      <Modal className={styles.root} centered width='100vw' footer={false} open onCancel={onCancel} >
        <div className={styles.inner} >
        <div className={styles.header} >
            <h1>{char_part}</h1>
            <div className={styles.controls} >
              <EditWheelModal product={product} />
            </div>
          </div>
          <div className={styles.content} >
            <div className={styles.productDescription} >
              
            {product && wheelsSet?.products && <div className={styles.wheelsSet} >
                  <Segmented
                  className={styles.segmented}
                  onChange={(value)=> navigate(`/catalog/wheel/${value}`)}
                  defaultValue={String(product.id)}
                  options={wheelsSet.products.map(({id})=>(String(id))).sort()}
                  />
              </div>}

            <div className={styles.storage} >
                <div className={styles.storageName} >{storage?.name}</div>
                <div className={styles.storageCount} >{stock}</div>
              </div>
              <div className={styles.ProductInfo} >
              <div className={cs(styles.productInfo_item, styles.price)} >
                 <p>Цена</p><p className={styles.priceCount} >{char_price}
                  
                     {isUsed ? <span style={{background: '#eaaa08'}} className={styles.badge} >Б/У</span>
                      : 
                      <span  style={{background: '#2e90fa'}} className={styles.badge} >Новый</span>} </p>
                    
        
                </div>
                <div className={cs(styles.productInfo_item, styles.productId)} >
                  <p>Номер товара</p>
                  <p>{id}</p>
                </div>
                <div className={cs(styles.productInfo_item, styles.shipment)} >
                  <p>Поставка</p>
                  <p>{shipment?.title}</p>
                </div>
                <div className={styles.comment} >
                  <TextArea value={char_comment} autoSize={{minRows: 5}} />
                </div>
              </div>
              <div className={styles.note} >
                <p className={styles.noteTitle} >Заметка (для вас)</p>
                <p className={styles.noteText} >{char_note}</p>
              </div>
              <div className={styles.description} >
               {char_part === 'Колесо' && <div className={styles.descriptionTitle} >
                  <div>Диск</div>
                  <div className={styles.descriptionDivider} ></div>
                </div>}
                {
                 (char_part === 'Колесо' || char_part === 'Диск') && discDescriptonItems.map(({name, value})=> {
                    if(value) {
                      return <div key={name} className={styles.description_item} >
                      <p className={styles.description_name} >{name}</p>
                      <p className={styles.description_value} >{value}</p>
                    </div>
                    }
                  })
                }
                 { char_part === 'Колесо' && <div className={styles.descriptionTitle} >
                  <div>Шина</div>
                  <div className={styles.descriptionDivider} ></div>
                </div>}
                {
                 (char_part === 'Колесо' || char_part === 'Шина') && tyreDescritionItems.map(({name, value})=> {
                    if(value) {
                      return <div key={name} className={styles.description_item} >
                      <p className={styles.description_name} >{name}</p>
                      <p className={styles.description_value} >{value}</p>
                    </div>
                    }
                  })
                }
              </div>
            </div>
            <div className={styles.productGallery} >
                {images && images.length > 0 && <ProductGallery images={images} />}
            </div>
          </div>
        </div>
      </Modal>

  )
}
