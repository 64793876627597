import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISaleItem } from "../../../../entities/saleItem";
import { ISale } from "../../../../entities/sale";





interface IntitialState {
    saleMessage?: string,
    selectedItems: ISaleItem[],
    selectedSale?: ISale
}

const initialState: IntitialState = {
    saleMessage: undefined,
    selectedItems: [],
    selectedSale: undefined
}


const salePageSlice_ = createSlice({
    initialState,
    name: 'salePage',
    reducers: {
        setSaleMessage: (state, action: PayloadAction<string | undefined>)=> {
            state.saleMessage = action.payload
        },
        setSaleSelectedItems: (state, action: PayloadAction<ISaleItem[]> )=> {
            state.selectedItems = action.payload
        },
        setSelectedSale: (state, action: PayloadAction<ISale>) => {
            state.selectedSale = action.payload
        }
    
    }
})

export const {setSaleMessage, setSaleSelectedItems, setSelectedSale} = salePageSlice_.actions
export const salePageSlice = salePageSlice_.reducer