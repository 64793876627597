import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreatePaymentDto } from "../dtos/createPaymentDto";
import { UpdatePaymentDto } from "../dtos/updatePaymentDto";
import { IPayment, IPayments } from "../interfaces/payment";
import qs from 'qs'


interface QueryParams {
    filters?: {sum: 'all'|'lt'|'gt'}
    pagination: {
        page: number
        pageSize: number
    }
}

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        createPayment: build.mutation<IPayment, CreatePaymentDto>({
            query: (body) => ({
                url: '/payments',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Payment', 'Sale']
        }),
        updatePayment: build.mutation<IPayment, { id: number, body: UpdatePaymentDto }>({
            query: ({ id, body }) => ({
                url: `/payments/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Payment', 'Sale']
        }),
        deletePayment: build.mutation<IPayment, number>({
            query: (id) => ({
                url: `/payments/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Payment', 'Sale']
        }),
        getAllPayments: build.query<IPayments, QueryParams>({
            query: (query) => {
                const queryString = qs.stringify(query)
                return query ? `/payments?${queryString}` : '/payments'
            },
            providesTags: ['Payment']
        }),
        getOnePayment: build.query<IPayment, number>({
            query: (id) => `/payments/${id}`,
            providesTags: ['Payment']
        })
    })
})


export const { useCreatePaymentMutation,
    useUpdatePaymentMutation,
    useDeletePaymentMutation,
    useGetAllPaymentsQuery,
    useGetOnePaymentQuery
} = extendedApi