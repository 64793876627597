import { FC } from 'react'
import styles from './AddEngineForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input, InputNumber, Select } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import cs from 'classnames'
import { CreateEngineDto, useCreateEngineMutation } from '../../../../../../entities/engine'
import { useLocation } from 'react-router-dom'


interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddEngineForm: FC<IProps> = ({setIsModalOpen}) => {
  const location = useLocation()
  const bodyId = location.pathname.split('/')[3]
  const [messageApi, contextHolder] = useMessage()
  const [createEngine] = useCreateEngineMutation()
const {control, handleSubmit, reset} = useForm({
  defaultValues: {
    title: '',
    type: 'gasoline',
    capacity: undefined,
    bodyId: +bodyId
  }
})

const onSubmit: SubmitHandler<CreateEngineDto> = async (data)=> {
      await createEngine(data)
      messageApi.success('Поколение успешно создано')
      reset()
      setIsModalOpen(false)
}



  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input  id='title' {...field}/>
        )}
        />
      </div>
      <div className={cs(styles.formItem, styles.engineType)} >
        <label htmlFor="type">Тип двигателя</label>
        <Controller
        name='type'
        control={control}
        render={({field})=> (
          <Select
          className={styles.typeSelect}
          defaultValue='gasoline'
          {...field}
          options={[
            {value: 'gasoline', label: 'бензин'},
            {value: 'gas', label: 'газ'},
            {value: 'diesel', label: 'дизель'},
            {value: 'electric', label: 'электрический'},
            {value: 'hybrid', label: 'гибридный'}
          ]}
          />
        )}
        />
      </div>
      <div className={cs(styles.formItem, styles.capacity)} >
        <label htmlFor="capacity">Объем</label>
        <Controller
        name='capacity'
        control={control}
        render={({field})=> (
          <InputNumber controls={false} className={styles.capacityInput} id='capacity' {...field}   />
        )}
        />
      </div>
      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default AddEngineForm