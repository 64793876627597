import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IMark } from "../../mark"
import { IModel } from "../../model"
import { IGeneration } from "../../generation"
import { IBody } from "../../body"
import { IEngine } from "../../engine"






interface InitalState {
    marks: IMark[]
    models: IModel[]
    generations: IGeneration[]
    bodys: IBody[]
    engines: IEngine[]
}


const initialState: InitalState = {
    marks: [],
    models: [],
    generations: [],
    bodys: [],
    engines: []
}


const applicabilitySlice = createSlice({
    initialState,
    name: 'applicability',
    reducers: {
        setMarks: (state, action: PayloadAction<IMark[]>)=> {
            state.marks = action.payload
        },
        deleteMark: (state, action: PayloadAction<number>)=> {
                 const markId = action.payload
                 const filteredMarks = state.marks.filter(({id})=> id !== markId)
                 const filteredModels = state.models.filter((model)=> model.markId !== markId)
                 const filteredGenerations = state.generations.filter(({modelId})=> !filteredModels.some(({id})=> id === modelId))
                 const filteredBodys = state.bodys.filter(({generationId})=> !filteredGenerations.some(({id})=> id === generationId))
                 const filteredEngines = state.engines.filter(({bodyId})=> filteredBodys.some(({id})=> id === bodyId))
                 state.marks = filteredMarks
                 state.models = filteredModels
                 state.generations = filteredGenerations
                 state.bodys = filteredBodys
                 state.engines = filteredEngines
        },
        setModels: (state, action: PayloadAction<IModel[]>)=> {
            state.models = action.payload
        },
        deleteModel: (state, action: PayloadAction<number>) => {
                const modelId = action.payload
                const filteredModels = state.models.filter(({id})=> id !== modelId)
                const filteredGenerations = state.generations.filter(({modelId})=> !filteredModels.some(({id})=> id === modelId))
                const filteredBodys = state.bodys.filter(({generationId})=> !filteredGenerations.some(({id})=> id === generationId))
                const filteredEngines = state.engines.filter(({bodyId})=> filteredBodys.some(({id})=> id === bodyId))
                state.models = filteredModels
                state.generations = filteredGenerations
                state.bodys = filteredBodys
                state.engines = filteredEngines
        },
        setGenerations: (state, action: PayloadAction<IGeneration[]>)=> {
            state.generations = action.payload
        },
        setBodys: (state, action: PayloadAction<IBody[]>)=> {
            state.bodys = action.payload
            const arr = state.engines.filter(({bodyId})=> state.bodys.some(({id})=> id === bodyId ))
            state.engines = arr
        },
        setEngines: (state, action: PayloadAction<IEngine[]>)=> {
            state.engines = action.payload
        },
        resetApplicabity: (state)=> {
            state.marks = []
            state.models = []
            state.generations = []
            state.bodys = []
            state.engines = []
        }
        
    }
})


export const {setMarks, deleteMark, setModels, deleteModel, setGenerations, setBodys, setEngines, resetApplicabity} = applicabilitySlice.actions
export default applicabilitySlice.reducer