import styles from './CatalogPage.module.scss'
import { Input, Table } from 'antd'
import type { TableColumnsType } from 'antd';
import { IoOptionsOutline } from 'react-icons/io5'
import { IProduct, useGetAllProductsQuery } from '../../../../../entities/product'
import { useEffect, useState } from 'react';
import { serverUrl } from '../../../../../shared/constants/serverUrl';
import { useNavigate } from 'react-router-dom';
import ApplicabilitySearch from '../applicabilitySearch';
import { IMark } from '../../../../../entities/mark';
import { IModel } from '../../../../../entities/model';
import { IGeneration } from '../../../../../entities/generation';
import { IBody } from '../../../../../entities/body';
import { IEngine } from '../../../../../entities/engine';



const columns: TableColumnsType<IProduct> = [
  {
    title: 'ID',
    width: '24px',
    dataIndex: 'id',
  },
  {
    title: 'Превью',
    dataIndex: 'images',
    width: '40px',
    render: (item)=> { return <div className={styles.imageHolder} >{item[0]?.url && <img className={styles.previewImage} src={serverUrl + item[0]?.url} />}</div>}
  },
  {
    title: 'Запчасть',
    dataIndex: 'char_part',
  },
  {
    title: 'Марка',
    dataIndex: 'char_carsrc_mark'
  },
  {
    title: 'Модель',
    dataIndex: 'char_carsrc_model'
  },
  {
    title: 'Цена',
    dataIndex: 'char_price'
  },
  {
    title: 'Двигатель',
    dataIndex: 'char_carsrc_engine'
  },
  {
    title: 'Год выпуска',
    dataIndex: 'char_carsrc_year'
  },
  {
    title: 'Передний/Задний',
    dataIndex: 'char_fb',
    render: (item)=> item && (item === 'forward' ? 'передний': 'задний')
  },
  {
    title: 'Левый/Правый',
    dataIndex: 'char_lr',
    render: (item)=> item && (item === 'right' ? 'правый': 'левый')
  },
  {
    title: 'Склад',
    render: (_, product)=> product?.income?.storage?.name
  },
  {
    title: 'Наличие',
    dataIndex: 'stock'
  }

]

export const CatalogPage = () => {
  const [searchParams, setSearchParams] = useState<
  {mark?: IMark, model?: IModel, generation?: IGeneration, body?: IBody, engine?: IEngine}
  >({mark: undefined, model: undefined, generation: undefined, body: undefined, engine: undefined})
  const {mark, model, generation, body, engine} = searchParams
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [mode, setMode] = useState<string>('sale')
  const {data: products, isLoading} = useGetAllProductsQuery({pagination: {page, pageSize}, category: 'part',
     applicability: {
      mark: mark?.id,
      model: model?.id,
      generation: generation?.id,
      body: body?.id,
      engine: engine?.id
     } })
  const navigate = useNavigate()
  const {pagination} = products?.meta || {}
  


  useEffect(()=> {
    const checkedMode = localStorage.getItem('catalog_changedRadio')
    checkedMode && setMode(checkedMode)
  }, [])

  useEffect(()=> {
    const savedPage = sessionStorage.getItem('catalog-table-page') 
    const savedPageSize = sessionStorage.getItem('catalog-table-pageSize') 
    if(savedPage) {
     setPage(+savedPage)
    }
    if(savedPageSize) {
      setPageSize(+savedPageSize)
    }
},[])


  const onPageChange = (page: number, pageSize: number)=> {
    setPage(page)
    setPageSize(pageSize)
    sessionStorage.setItem('catalog-table-page', String(page))
    sessionStorage.setItem('catalog-table-pageSize', String(pageSize))
  }



  return (
    <div className={styles.root} >
    <div className={styles.header} >
      <div className={styles.searchWrapper} >
        <Input className={styles.searchById} placeholder='Поиск по номеру' />
        <ApplicabilitySearch searchParams={searchParams} setSearchParams={setSearchParams} />
        </div>
      <div className={styles.options} ><IoOptionsOutline/></div>
    </div>
    <div className={styles.content} >
      <Table
      loading={isLoading}
      columns={columns}
      rowSelection={{type: 'checkbox'}}
      onRow={(item)=>({onClick: ()=> navigate(`/catalog/product/${item?.id}`)})}
      pagination={{showSizeChanger: true, defaultPageSize: 10, total: pagination?.total, pageSize, current: page, onChange: onPageChange}}
      dataSource={products?.data?.map((item)=> ({...item, key: item?.id}))}
      />
    </div>
    </div>
  )
}
