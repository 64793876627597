import { useLocation, useNavigate } from 'react-router-dom'
import styles from './MarkPage.module.scss'
import { useDeleteMarkMutation, useGetOneMarkQuery } from '../../../../../entities/mark'
import { Button, message, Modal, Popconfirm, Table } from 'antd'
import AddModelForm from '../components/addModelForm'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { TableColumnsType } from 'antd/lib'
import { IModel, useGetAllModelsQuery } from '../../../../../entities/model'
import EditMarkForm from '../components/editMarkForm'
import { MdDelete, MdEdit } from "react-icons/md";



const columns: TableColumnsType<IModel> = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'Название',
    dataIndex: 'title'
  }
]


export const MarkPage = () => {
  const [isAddModelModalOpen, setIsAddModelModalOpen] = useState<boolean>(false)
  const [isEditMarkModalOpen, setIsEditMarkModalOpen] = useState<boolean>(false)
  const location = useLocation()
  const markId = location.pathname.split('/')[3]
  const { data: mark } = useGetOneMarkQuery(markId)
  const { title } = mark || {}
  const { data: models } = useGetAllModelsQuery(markId)
  const navigate = useNavigate()
  const [deleteMark] = useDeleteMarkMutation()
 
  const handleDeleteMark = ()=> {
    message.success('Марка успешно удалена')
         deleteMark(markId)
         navigate(-1)
  }

  return (
    <main className={styles.root} >
      <Modal title='Добавить модель' footer={false} onCancel={()=> setIsAddModelModalOpen(false)} open={isAddModelModalOpen} >
        <AddModelForm setIsModalOpen={setIsAddModelModalOpen} />
      </Modal>
      <Modal footer={false} open={isEditMarkModalOpen} onCancel={()=> setIsEditMarkModalOpen(false)} title='Редактирование марки' >
        <EditMarkForm mark={mark} setIsModalOpen={setIsEditMarkModalOpen} />
      </Modal>
      <div className={styles.header} >
        <button onClick={() => navigate(-1)} className={styles.returnBtn} ><IoIosArrowBack /></button>
        <h1>Марка {title}</h1>
        <div className={styles.header_buttonsHolder} >
          <Button onClick={()=> setIsEditMarkModalOpen(true)} size='large' type='primary' icon={<MdEdit />} ></Button>
          <Popconfirm onConfirm={handleDeleteMark} title='Удаление марки' description='Вы действительно хотите удалить марку?' ><Button size='large' danger type='primary' icon={<MdDelete />} ></Button></Popconfirm>
        </div>
      </div>
      <div className={styles.content} >
        <div className={styles.contentHeader} >
          <div className={styles.tableTitle}  >
            <h2>Модели</h2>
          </div>
          <Button onClick={() => setIsAddModelModalOpen(true)} type='primary' >Добавить модель</Button>
        </div>
        <div className={styles.tableHolder} >
          <Table
            columns={columns}
            dataSource={models?.map((item) => ({ ...item, key: item.id }))}
            onRow={(item) => ({ onClick: () => navigate(`/applicability/model/${item.id}`) })}
          />
        </div>
      </div>
    </main>
  )
}
