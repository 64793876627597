import { Link, useNavigate } from 'react-router-dom'
import styles from './AddIncomePage.module.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, Select } from 'antd'
import { useState } from 'react'
import { useGetAllShipmentQuery } from '../../../../entities/shipment'
import { useCreateIncomeMutation } from '../../../../entities/income'
import { useGetAllStoragesQuery } from '../../../../entities/storage'
import TextArea from 'antd/es/input/TextArea'


export const AddIncomePage = () => {
  const [selectedShipment, setSelectedShipment] = useState<number>()
  const [selectedStorage, setSelectedStorage] = useState<number>()
  const [comment, setComment] = useState<string>()
  const { data: shipments } = useGetAllShipmentQuery()
  const { data: storages} = useGetAllStoragesQuery()
  const [createIncome] = useCreateIncomeMutation()
  const navigate = useNavigate()


   

  const onSubmit = async (selectedShipment?: number) => {
    const res = await createIncome({ status: 'draft', shipment: selectedShipment, storage: selectedStorage, comment})
    if ('data' in res && res.data) {
      const id = res.data.id
      navigate(`/incomes/${id}`, { replace: true })
    }
  }


  const shipmentOptions: { label: string; value: number | undefined }[] =
  shipments
    ? [
        ...shipments.map(({ id, title }) => ({
          label: title,
          value: id,
        })),
      ]
    : [];

const storageOtions: { label: string; value: number | undefined }[] = storages
  ? [
      ...storages.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ]
  : [];

  return (
    <main className={styles.root} >
      <div className={styles.inner} >
        <div className={styles.header} >
          <Link to='/incomes' className={styles.returnLink} ><IoIosArrowBack /></Link>
          <h1 className={styles.title} >Поступления</h1>
        </div>
        <div className={styles.content} >
          <div className={styles.content_inner} >
            <div className={styles.income} >
              <div className={styles.income_header} >
                <h2>Новое поступление</h2>
                <div className={styles.income_btnHolder} >
                  <Button onClick={()=> onSubmit(selectedShipment)} type='primary' >Создать поступление</Button>
                  <Button onClick={() => navigate('/incomes')} type='default' >Отменить</Button>
                </div>
                <div className={styles.income_settings} ></div>
              </div>
              <div className={styles.income_content} >
                <div className={styles.leftBlock} >
                  <div className={styles.countersHolder} >
                    <div className={styles.counter} >
                      <p className={styles.counterTitle} >Сумма</p>
                      <p className={styles.counterCount} >0</p>
                    </div>
                    <div className={styles.counter} >
                      <p className={styles.counterTitle} >Наклад. расх</p>
                      <p className={styles.counterCount} >0</p>
                    </div>
                  </div>
                  <div className={styles.formItem} >
                    <label htmlFor="shipment">Поставка</label>
                    <Select id='shipment' placeholder='Выберите поставку' value={selectedShipment} onChange={(value)=> setSelectedShipment(value)} className={styles.formItem_select}
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      showSearch
                      options={shipmentOptions} />

                  </div>
                  <div className={styles.formItem} >
                    <label htmlFor="storage">Склад оприходования</label>
                    <Select id='storage' placeholder='Выберите склад' value={selectedStorage} onChange={(value)=> setSelectedStorage(value)} className={styles.formItem_select}
                      optionFilterProp="label"
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      }
                      showSearch
                      options={storageOtions} />

                  </div>
                  <div className={styles.formItem} >
                   <TextArea value={comment} onChange={(e)=> setComment(e.target.value)} autoSize={{minRows: 4}} placeholder='Комментарий к поступлению' />

                  </div>
                  
                </div>
                <div className={styles.income_table} >
                  <p className={styles.income_tableInfo} >Создайте поступление, чтобы добавить товары</p>
                </div>
              </div>
            </div>
            <div className={styles.history} >
              <div className={styles.history_header} >
                <h2>История документа</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
