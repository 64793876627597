import { Table } from 'antd'
import styles from './ItemsTable.module.scss'
import { ColumnsType } from 'antd/es/table'
import { useUpdateSaleItemMutation } from '../../../../../entities/saleItem'
import { FC} from 'react'
import { useForm } from 'react-hook-form'
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci'
import { TableRowSelection } from 'antd/es/table/interface'
import { addProduct, IProduct, productsSelector, removeProduct } from '../../../../../entities/product'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import cs from 'classnames'


interface IProps {

}
  

interface FormValues {
  quantity?: number,
  price?: number,
  discountedPrice?: number
}

const ItemsTable: FC<IProps> = () => {
  const dispatch = useAppDispatch()
  const selectedProducts = useAppSelector(productsSelector)
  const { control } = useForm<FormValues>({
    defaultValues: {
      quantity: 0,
      price: 0,
      discountedPrice: 0
    }
  })




 

  const columns: ColumnsType<{product: IProduct, quantity: number}> = [
    {
      title: 'Номер',
      render: (_, { product }) => product?.id
    },
    {
      title: 'Наименование',
      render: (_, { product }) => product?.char_part
    },
    {
      title: 'Кол-во',
      align: 'center',
      render: (_, {product, quantity }) => {
      return <div className={styles.quantity} >
        <div className={styles.quantityCounter} >
          <button onClick={()=> dispatch(removeProduct(product))}  >{<CiSquareMinus />}</button>
          <div>{quantity}</div>
          <button onClick={()=> dispatch(addProduct(product))} >{<CiSquarePlus />}</button>
        </div>
       <div className={cs(styles.stock, product?.stock < quantity && styles.stock__error)}  >Остаток {product?.stock}</div>
      </div> 
      }
    }
  ]


  const rowSelection: TableRowSelection<{product: IProduct, quantity: number}> = {
    type: 'checkbox',
    selectedRowKeys: selectedProducts.map(({product})=> product?.id),
    // onChange: (value, saleItem) => setDeletedItems(saleItem.map(({id})=> id ))
  }

  return (
    <Table
      className={styles.root}
      columns={columns}
      rowKey={(item) => item?.product?.id}
      // rowSelection={rowSelection}
      dataSource={selectedProducts}
    
    />
  )
}

export default ItemsTable