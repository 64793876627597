import styles from './DiskFormPart.module.scss'
import TypesFormPart from '../typesFormPart'
import { FC, useEffect, useState } from 'react'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { IWheelsFormValues } from '../../../../interfaces/wheelsFormValues'
import ManufacturerFormPart from '../manufacturerFormPart'
import { SizesFormPart } from '../sizesFormPart'


interface IProps {
  watch: UseFormWatch<IWheelsFormValues>
  control: Control<IWheelsFormValues, any>
  setIsDifferentWidth: React.Dispatch<React.SetStateAction<boolean>>
  isDifferentWidth: boolean
  setValue: UseFormSetValue<IWheelsFormValues>
  isNotValid: boolean
}

export const DiskFormPart: FC<IProps> = ({watch, control, setIsDifferentWidth, isDifferentWidth, setValue, isNotValid}) => {
  const [isDoublePCD, setIsDoublePCD] = useState<boolean>(false)

    useEffect(()=> {
         setValue('char_diskPCD_F', ['', ''])
         setValue('char_diskPCD_B', ['', ''])
    }, [isDoublePCD])

    useEffect(()=> {
         setIsDoublePCD(false)
    }, [watch('char_wheelPart')])

  return (
    <div className={styles.root}>
     <TypesFormPart isDoublePCD={isDoublePCD} setIsDoublePCD={setIsDoublePCD} setIsDifferentWidth={setIsDifferentWidth} isDifferentWidth={isDifferentWidth} watch={watch} control={control} />
     <SizesFormPart isNotValid={isNotValid} isDoublePCD={isDoublePCD} isDifferentWidth={isDifferentWidth} watch={watch} control={control} />
     <ManufacturerFormPart isDifferentWidth={isDifferentWidth} watch={watch} control={control} />
    </div>
  )
}
