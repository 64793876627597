import { FC, useEffect, useState } from 'react'
import { IImage } from '../../../../../../../entities/image'
import styles from './ImagesFormPart.module.scss'
import { serverUrl } from '../../../../../../../shared/constants/serverUrl'
import { MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBinLine } from "react-icons/ri";
import { GalleryModal } from '../../../../../../../widgets/galleryModal'
import { Checkbox } from 'antd'
import { IProduct } from '../../../../../../../entities/product'


interface IProps {
  images?: IImage[]
  checkedImages: IImage[]
  setCheckedImages: React.Dispatch<React.SetStateAction<IImage[]>>
  setChangedParametrs: React.Dispatch<React.SetStateAction<{
    images: number;
    generalInfo: number;
    mainParameters: number;
    donorCar: number;
    placement: number;
}>>

}

const ImagesFormPart: FC<IProps> = ({images, checkedImages, setCheckedImages, setChangedParametrs}) => {
   const [selectedImages, setSelectedImages] = useState<number[]>([])
   const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false)

   useEffect(()=> {
      images && setCheckedImages(images)
   }, [images])

   const onImageSelect = (id: number, checked: boolean)=> {
       if(checked) {
        setSelectedImages(prev=> [...prev, id])
       } else {
        setSelectedImages((prev)=> prev.filter((item)=> item !== id ))
       }
   }
 
    const handleDeleteSelected = ()=> {
        setCheckedImages((prev)=> prev.filter(({id})=> !selectedImages.some((item)=> item === id)))
    }

    const handleDeleteAll = ()=> {
      setCheckedImages([])
      setSelectedImages([])
    }
   
    useEffect(()=> {
      if(images) {
         const isArraysEqual = checkedImages.every(({id})=> images.some((item)=> item.id === id))
         if((images.length !== checkedImages.length) || !isArraysEqual) {
          setChangedParametrs((prev)=> ({...prev, images: 1}))
         } else {
          setChangedParametrs((prev)=> ({...prev, images: 0}))
         }
      }
      
    }, [checkedImages, images])
   

  return (
    <div className={styles.root} >
      <GalleryModal checkedImages={checkedImages} setCheckedImages={setCheckedImages} isModalOpen={isGalleryOpen} setIsModalOpen={setIsGalleryOpen} />
        <div className={styles.header} >
            <h3 className={styles.title} >Фотографии</h3>
            <div onClick={()=> setIsGalleryOpen(true)} className={styles.edit} ><MdOutlineEdit />  Редактировать</div>
            {selectedImages.length > 0 && <div onClick={handleDeleteSelected} className={styles.delete} ><RiDeleteBinLine />  Удалить выбранные</div>}
            <div onClick={handleDeleteAll} className={styles.deleteAll} ><RiDeleteBinLine />  Удалить все</div>
        </div>
        <div className={styles.images} >
            {checkedImages?.map(({id, url})=> (
              <div className={styles.imageHolder} key={id} ><img src={serverUrl + url} />
               <Checkbox onChange={(e)=> onImageSelect(id, e.target.checked)} className={styles.imageCheckbox} />
                </div>
            )) }
        </div>
    </div>
  )
}

export default ImagesFormPart