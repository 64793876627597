import { FC } from 'react'
import styles from './SizesFormPart.module.scss'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { Select, Space } from 'antd'
import cs from 'classnames'
import { IWheelsFormValues } from '../../../../../interfaces/wheelsFormValues'
import { diskDiametrOptions, diskDiaOptions, diskEtOptions, diskPcdOptions, diskWidthOptions } from '../../../../../../../../../../../../entities/product'



interface IProps {
    watch: UseFormWatch<IWheelsFormValues>
    control: Control<IWheelsFormValues, any>
    isDifferentWidth: boolean
    isDoublePCD: boolean
    isNotValid: boolean
}

export const SizesFormPart: FC<IProps> = ({control, watch, isDifferentWidth, isDoublePCD, isNotValid}) => {




  return (
    <div>
                <div
        className={cs(
          styles.fronAxle,
          isDifferentWidth && styles.frontAxle__alternative
        )}
      >
        {isDifferentWidth && (
          <div className={styles.divider}>
            <div className={styles.divider_title}>
              <div className={styles.circle}></div>
              <p>Передняя ось</p>
            </div>
            <span></span>
          </div>
        )}
       
            <Space.Compact className={styles.rowWrapper} >
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskDiametr_F'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        showSearch
                        filterOption={(input, option) =>
                            (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                          }
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Диаметр, дюйм'
                        options={diskDiametrOptions.map((value)=> ({value}))}
                        />
                    )}
                    />
                </div>
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskWidth_F'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        showSearch
                        filterOption={(input, option) =>
                            (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                          }
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Ширина, дюйм'
                        options={diskWidthOptions.map((value)=> ({value}))}
                        />
                    )}
                    />
                </div>
                {!isDoublePCD && <div className={(styles.formItem)} >
                    <Controller
                    name='char_diskPCD_F'
                    control={control}
                    render={({field})=> (
                        <Select
                        size='large'
                        showSearch
                        filterOption={(input, option) =>
                            (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                          }
                        className={cs((isNotValid && !field.value[0]) && styles.inputError)}
                        value={watch('char_diskPCD_F')[0] || undefined }
                        onChange={(value)=> field.onChange([value, field.value[1]])}
                        placeholder='Сверловка'
                        options={diskPcdOptions.map((value)=> ({value}))}
                        />
                    )}
                    />
                </div>}
                {
                   isDoublePCD && <div className={cs(styles.pcd,  isDifferentWidth && styles.pcd_f)} >
                    <p>Сверловка</p>
                    <div className={styles.pcdSelectsWrapper} >
                        
                            <div className={styles.smallSelect} >
                                <Controller
                                name='char_diskPCD_F'
                                control={control}
                                render={({field})=> (
                                    <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                                      }
                                    value={watch('char_diskPCD_F')?.[0] || undefined}
                                    className={cs((isNotValid && !field.value[0]) && styles.inputError)}
                                    onChange={(value)=> field.onChange([value, field.value[1]])}
                                    placeholder='Выбе...'
                                    options={diskPcdOptions.map((value)=> ({value}))}
                                    />
                                )}
                                />
                            </div>
                            <div className={styles.smallSelect} >
                                <Controller
                                name='char_diskPCD_F'
                                control={control}
                                render={({field})=> (
                                    <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                                      }
                                    value={watch('char_diskPCD_F')?.[1] || undefined}
                                    onChange={(value)=> field.onChange([field.value[0], value])}
                                    placeholder='Выбе...'
                                    options={diskPcdOptions.map((value)=> ({value}))}
                                    />
                                )}
                                />
                            </div>
                        
                    </div>
                   </div>
                }
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskET_F'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        showSearch
                        filterOption={(input, option) =>
                            (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                          }
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Вылет'
                        options={diskEtOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>
          
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskDIA_F'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Диаметр ЦО'
                        options={diskDiaOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>
            </Space.Compact>
            </div>
            {isDifferentWidth && 
           <div className={styles.backAxle}>
              <div className={styles.divider}>
             <div className={styles.divider_title}>
               <div className={styles.circle}></div>
               <p>Задняя ось</p>
             </div>
             <span></span>
           </div>
           
             <Space.Compact className={styles.rowWrapper} >
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskDiametr_B'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Диаметр, дюйм'
                        options={diskDiametrOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskWidth_B'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Ширина, дюйм'
                        options={diskWidthOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>
              
               {!isDoublePCD && <div className={styles.formItem} >
                    <Controller
                    name='char_diskPCD_B'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        className={cs((isNotValid && !field.value[0]) && styles.inputError)}
                        value={watch('char_diskPCD_B')[0] || undefined }
                        onChange={(value)=> field.onChange([value, field.value[1]])}
                        size='large'
                        placeholder='Сверловка'
                        options={diskPcdOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>}
                {
                   isDoublePCD && <div className={cs(styles.pcd, isDifferentWidth && styles.pcd_b)} >
                    <p>Сверловка</p>
                    <div className={styles.pcdSelectsWrapper} >
                        
                            <div className={styles.smallSelect} >
                                <Controller
                                name='char_diskPCD_B'
                                control={control}
                                render={({field})=> (
                                    <Select
                                    value={watch('char_diskPCD_B')?.[0] || undefined}
                                    className={cs((isNotValid && !field.value[0]) && styles.inputError)}
                                    onChange={(value)=> field.onChange([value, field.value[1]])}
                                    placeholder='Выбе...'
                                    options={diskPcdOptions.map(value=> ({value}))}
                                    />
                                )}
                                />
                            </div>
                            <div className={styles.smallSelect} >
                                <Controller
                                name='char_diskPCD_B'
                                control={control}
                                render={({field})=> (
                                    <Select
                                    value={watch('char_diskPCD_B')?.[1] || undefined}
                                    onChange={(value)=> field.onChange([field.value[0], value])}
                                    placeholder='Выбе...'
                                    options={diskPcdOptions.map(value=> ({value}))}
                                    />
                                )}
                                />
                            </div>
                        
                    </div>
                   </div>
                }
                  <div className={styles.formItem} >
                    <Controller
                    name='char_diskET_B'
                    
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Вылет'
                        options={diskEtOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>
                <div className={styles.formItem} >
                    <Controller
                    name='char_diskDIA_B'
                    control={control}
                    render={({field})=> (
                        <Select
                        {...field}
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        size='large'
                        placeholder='Диаметр ЦО'
                        options={diskDiaOptions.map(value=> ({value}))}
                        />
                    )}
                    />
                </div>
            </Space.Compact>
            </div>}
        </div>

  )
}

