


export const tireLoadCoefOptions = [
    {
      value: undefined,
      label: "- Не выбран -",
    },
    {
      value: 60,
      label: "60",
      desc: "250",
    },
    {
      value: 61,
      label: "61",
      desc: "257",
    },
    {
      value: 62,
      label: "62",
      desc: "265",
    },
    {
      value: 63,
      label: "63",
      desc: "272",
    },
    {
      value: 64,
      label: "64",
      desc: "280",
    },
    {
      value: 65,
      label: "65",
      desc: "290",
    },
    {
      value: 66,
      label: "66",
      desc: "300",
    },
    {
      value: 67,
      label: "67",
      desc: "307",
    },
    {
      value: 68,
      label: "68",
      desc: "315",
    },
    {
      value: 69,
      label: "69",
      desc: "320",
    },
    {
      value: 70,
      label: "70",
      desc: "335",
    },
    {
      value: 71,
      label: "71",
      desc: "345",
    },
    {
      value: 72,
      label: "72",
      desc: "355",
    },
    {
      value: 73,
      label: "73",
      desc: "365",
    },
    {
      value: 74,
      label: "74",
      desc: "375",
    },
    {
      value: 75,
      label: "75",
      desc: "385",
    },
    {
      value: 76,
      label: "76",
      desc: "400",
    },
    {
      value: 77,
      label: "77",
      desc: "412",
    },
    {
      value: 78,
      label: "78",
      desc: "425",
    },
    {
      value: 79,
      label: "79",
      desc: "437",
    },
    {
      value: 80,
      label: "80",
      desc: "450",
    },
    {
      value: 81,
      label: "81",
      desc: "250",
    },
    {
      value: 82,
      label: "82",
      desc: "475",
    },
    {
      value: 83,
      label: "83",
      desc: "487",
    },
    {
      value: 84,
      label: "84",
      desc: "500",
    },
    {
      value: 85,
      label: "85",
      desc: "515",
    },
    {
      value: 86,
      label: "86",
      desc: "530",
    },
    {
      value: 87,
      label: "87",
      desc: "545",
    },
    {
      value: 88,
      label: "88",
      desc: "560",
    },
    {
      value: 89,
      label: "89",
      desc: "580",
    },
    {
      value: 90,
      label: "90",
      desc: "600",
    },
    {
      value: 91,
      label: "91",
      desc: "615",
    },
    {
      value: 92,
      label: "92",
      desc: "630",
    },
    {
      value: 93,
      label: "93",
      desc: "650",
    },
    {
      value: 94,
      label: "94",
      desc: "670",
    },
    {
      value: 95,
      label: "95",
      desc: "690",
    },
    {
      value: 96,
      label: "96",
      desc: "710",
    },
    {
      value: 97,
      label: "97",
      desc: "730",
    },
    {
      value: 98,
      label: "98",
      desc: "750",
    },
    {
      value: 99,
      label: "99",
      desc: "775",
    },
    {
      value: 100,
      label: "100",
      desc: "800",
    },
    {
      value: 101,
      label: "101",
      desc:  "825"
    },
    {
      value:  102,
      label: "102",
      desc: "850"
    },
    {
      value:  103,
      label: "103",
      desc: "875"
    },
    {
      value:  104,
      label: "104",
      desc: "900"
    },
    {
      value:  105,
      label: "105",
      desc: "925"
    },
    {
      value:  106,
      label: "106",
      desc: "950"
    },
    {
      value:  107,
      label: "107",
      desc: "975"
    },
    {
      value:  108,
      label: "108",
      desc: "1000"
    },
    {
      value:  109,
      label: "109",
      desc: "1030"
    },
    {
      value:  110,
      label: "110",
      desc: "1060"
    },
    {
      value:  111,
      label: "111",
      desc: "1090"
    },
    {
      value:  112,
      label: "112",
      desc: "1120"
    },
    {
      value:  113,
      label: "113",
      desc: "1150"
    },
    {
      value:  114,
      label: "114",
      desc: "1180"
    },
    {
      value:  115,
      label: "115",
      desc: "1215"
    },
    {
      value:  116,
      label: "116",
      desc: "1250"
    },
    {
      value:  117,
      label: "117",
      desc: "1285"
    },
    {
      value:  118,
      label: "118",
      desc: "1320"
    },
    {
      value:  119,
      label: "119",
      desc: "1360"
    },
    {
      value:  120,
      label: "120",
      desc: "1400"
    },
    {
      value:  121,
      label: "121",
      desc: "1450"
    },
    {
      value:  122,
      label: "122",
      desc: "1500"
    },
    {
      value:  123,
      label: "123",
      desc: "1550"
    },
    {
      value:  124,
      label: "124",
      desc: "1600"
    },
    {
      value:  125,
      label: "125",
      desc: "1650"
    },
    {
      value:  126,
      label: "126",
      desc: "1700"
    },
    {
      value:  127,
      label: "127",
      desc: "1750"
    },
    {
      value:  128,
      label: "128",
      desc: "1800"
    },
    {
      value:  129,
      label: "129",
      desc: "1850"
    },
    {
      value:  130,
      label: "130",
      desc: "1900"
    },
    {
      value:  131,
      label: "131",
      desc: "1950"
    },
    {
      value:  132,
      label: "132",
      desc: "2000"
    },
    {
      value:  133,
      label: "133",
      desc: "2060"
    },
    {
      value:  134,
      label: "134",
      desc: "2120"
    },
    {
      value:  135,
      label: "135",
      desc: "2180"
    },
    {
      value:  136,
      label: "136",
      desc: "2180"
    },
    {
      value:  137,
      label: "137",
      desc: "2240"
    },
    {
      value:  138,
      label: "138",
      desc: "2360"
    },
    {
      value:  139,
      label: "139",
      desc: "2430"
    },
    {
      value:  140,
      label: "140",
      desc: "2500"
    },
    {
      value:  141,
      label: "141",
      desc: "2575"
    },
    {
      value:  142,
      label: "142",
      desc: "2650"
    },
    {
      value:  143,
      label: "143",
      desc: "2725"
    },
    {
      value:  144,
      label: "144",
      desc: "2800"
    },
    {
      value:  145,
      label: "145",
      desc: "2900"
    },
    {
      value:  146,
      label: "146",
      desc: "3000"
    },
    {
      value:  147,
      label: "147",
      desc: "3075"
    },
    {
      value:  148,
      label: "148",
      desc: "3159"
    },
    {
      value:  149,
      label: "149",
      desc: "3250"
    },
    {
      value:  150,
      label: "150",
      desc: "3350"
    },
    {
      value:  151,
      label: "151",
      desc: "3450"
    },
    {
      value:  152,
      label: "152",
      desc: "3550"
    },
    {
      value:  153,
      label: "153",
      desc: "3650"
    },
    {
      value:  154,
      label: "154",
      desc: "3750"
    },
    {
      value:  155,
      label: "155",
      desc: "3875"
    },
    {
      value:  156,
      label: "156",
      desc: "4000"
    },
    {
      value:  157,
      label: "157",
      desc: "4125"
    },
    {
      value:  158,
      label: "158",
      desc: "4250"
    },
    {
      value:  159,
      label: "159",
      desc: "4375"
    },
    {
      value:  160,
      label: "160",
      desc: "4500"
    },
    {
      value:  161,
      label: "161",
      desc: "4625"
    },
    {
      value:  162,
      label: "162",
      desc: "4750"
    },
    {
      value:  163,
      label: "163",
      desc: "4857"
    },
    {
      value:  164,
      label: "164",
      desc: "5000"
    },
    {
      value:  165,
      label: "165",
      desc: "5150"
    },
    {
      value:  166,
      label: "166",
      desc: "5300"
    },
    {
      value:  167,
      label: "167",
      desc: "5450"
    },
    {
      value:  168,
      label: "168",
      desc: "5600"
    },
    {
      value:  169,
      label: "169",
      desc: "5800"
    },
    {
      value:  170,
      label: "170",
      desc: "6000"
    }
  ]