import { Controller, SubmitHandler, useForm } from "react-hook-form";
import styles from "./ProductForm.module.scss";
import {
  Input,
  InputNumber,
  message,
  notification,
  Radio,
  Segmented,
  Select,
  Space,
  Switch,
} from "antd";
import cs from "classnames";
import { MdOutlineAddAPhoto } from "react-icons/md";
import React, { useState } from "react";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import { useAppSelector } from "../../../../../../../../shared/hooks/reduxToolkit";
import {
  bodysSelector,
  enginesSelector,
  generationsSelector,
  marksSelector,
  modelsSelector,
} from "../../../../../../../../entities/applicability";
import { GalleryModal } from "../../../../../../../../widgets/galleryModal";
import { ApplicabilityModal } from "../../../../../../../../widgets/applicabilityModal";
import { useLocation } from "react-router-dom";
import { useCreateProductMutation } from "../../../../../../../../entities/product";
import { IDefaultValues } from "../interfaces/defaultValues";
import { IImage } from "../../../../../../../../entities/image";




interface IProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const ProductForm = React.forwardRef<HTMLFormElement, IProps>(({setIsModalOpen}, ref) => {
  const [isDonorCarManualy, setIsDonorCarManualy] = useState<boolean>(true);
  const [checkedImages, setCheckedImages] = useState<IImage[]>([]);
  const [isApplicabilityModalOpen, setIsApplicabilityModalOpen] =
    useState<boolean>(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState<boolean>(false);
  const location = useLocation();
  const checkedMarks = useAppSelector(marksSelector);
  const checkedModels = useAppSelector(modelsSelector);
  const checkedGenerations = useAppSelector(generationsSelector);
  const checkedBodys = useAppSelector(bodysSelector);
  const checkedEngines = useAppSelector(enginesSelector);
  const incomeId = +location.pathname.split("/")[2];
  const [createProduct] = useCreateProductMutation();
  const [isValid, setIsValid] = useState<boolean>(true)
  const { control, handleSubmit, watch, setValue } = useForm<IDefaultValues>({
    defaultValues: {
      char_carsrc_mark: undefined,
      char_carsrc_model: undefined,
      char_carsrc_body: undefined,
      char_carsrc_engine: undefined,
      char_carsrc_year: undefined,
      char_fb: undefined,
      char_lr: undefined,
      char_marking: undefined,
      char_color: undefined,
      char_note: undefined,
      char_comment: undefined,
      donorCarId: undefined,
      isContract: false,
      char_part: undefined,
      mfr_number: undefined,
      manufacturer: undefined,
      cross_number: undefined,
      char_price: undefined,
      isUsed: true,
    },
  });

  const onSubmit: SubmitHandler<IDefaultValues> = async (data) => {
    if(watch('char_part')) {
      const markIds = checkedMarks.map(({ id }) => id);
      const modelIds = checkedModels.map(({ id }) => id);
      const generationIds = checkedGenerations.map(({ id }) => id);
      const bodyIds = checkedBodys.map(({ id }) => id);
      const engineIds = checkedEngines.map(({ id }) => id);
          const res = await createProduct({ ...data, incomeId, images: checkedImages.map(({id})=> id), markIds, modelIds, generationIds, bodyIds, engineIds });
          if('data' in res) {
            message.success('Продукт успешно создан')
            setIsModalOpen(false)
          } else if('error' in res) {
            message.error('Ошибка сервера')
          }
    } else {
      setIsValid(false)
      notification.warning({placement: 'bottom', message: 'Не удалось сохранить', description: 'Заполните обязательные поля'})
    }
 
  };

  const donorCarInputsList = [
    {
      id: 1,
      name: "char_carsrc_mark",
      placeholder: "Марка",
    },
    {
      id: 2,
      name: "char_carsrc_model",
      placeholder: "Модель",
    },
    {
      id: 3,
      name: "char_carsrc_body",
      placeholder: "Модель кузова",
    },
    {
      id: 4,
      name: "char_carsrc_engine",
      placeholder: "Модель двигателя",
    },
    {
      id: 5,
      name: "char_carsrc_year",
      placeholder: "Год выпуска",
    },
  ];

  return (
    <form ref={ref} onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <GalleryModal
        checkedImages={checkedImages}
        setCheckedImages={setCheckedImages}
        isModalOpen={isGalleryModalOpen}
        setIsModalOpen={setIsGalleryModalOpen}
      />
      <ApplicabilityModal
        isModalOpen={isApplicabilityModalOpen}
        setIsModalOpen={setIsApplicabilityModalOpen}
      />
      <div className={styles.donorCar}>
        <div className={styles.donorCar_header}>
          <div className={styles.donorCar_header_title}>Автомобиль-донор</div>
          <Segmented
            onChange={(value) =>
              setIsDonorCarManualy(value === "Вручную" ? true : false)
            }
            options={["Вручную", "Из справочника"]}
            defaultValue="Вручную"
            size="small"
          />
        </div>
        <div className={styles.donorCar_formItems}>
          {isDonorCarManualy ? (
            <Space.Compact className={styles.donorCar_space}>
              {donorCarInputsList.map(({ id, name, placeholder }) => (
                <div key={id} className={styles.formItem}>
                  <Controller
                    name={
                      name as
                        | "char_carsrc_mark"
                        | "char_carsrc_model"
                        | "char_carsrc_body"
                        | "char_carsrc_engine"
                        | "char_carsrc_year"
                    }
                    control={control}
                    render={({ field }) => (
                      <Input placeholder={placeholder} height={55} {...field} />
                    )}
                  />
                </div>
              ))}
            </Space.Compact>
          ) : (
            <div className={cs(styles.formItem, styles.donorCarNumber)}>
              <Controller
                name="donorCarId"
                control={control}
                render={({ field }) => (
                  <Input placeholder="Номер автомобиля-донора" {...field} />
                )}
              />
            </div>
          )}
        </div>
        {isDonorCarManualy && (
          <p className={styles.donorCar_info}>
            Укажите марку и модель для выбора рестайлинга
          </p>
        )}
      </div>
      <div className={styles.description}>
        <div className={styles.description_header}>
          <div>Описание б/у запчасти</div>
        </div>
        <div className={styles.description_formItems}>
          <div className={cs(styles.formItem, styles.char_fb)}>
            <Controller
              name="char_fb"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} size="large" buttonStyle="solid">
                  <Radio.Button
                    onClick={() =>
                      watch("char_fb") === "Передний" &&
                      setValue("char_fb", undefined)
                    }
                    value="Передний"
                  >
                    Передний
                  </Radio.Button>
                  <Radio.Button
                    onClick={() =>
                      watch("char_fb") === "Задний" &&
                      setValue("char_fb", undefined)
                    }
                    value="Задний"
                  >
                    Задний
                  </Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
          <div className={cs(styles.formItem, styles.char_rl)}>
            <Controller
              name="char_lr"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} size="large" buttonStyle="solid">
                  <Radio.Button
                    onClick={() =>
                      watch("char_lr") === "left" &&
                      setValue("char_lr", undefined)
                    }
                    value="left"
                  >
                    Левый
                  </Radio.Button>
                  <Radio.Button
                    onClick={() =>
                      watch("char_lr") === "right" &&
                      setValue("char_lr", undefined)
                    }
                    value="right"
                  >
                    Правый
                  </Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
          <div className={cs(styles.formItem, styles.isContract)}>
            <Controller
              name="isContract"
              control={control}
              render={({ field }) => <Switch id="isContract" {...field} />}
            />
            <label htmlFor="isContract">Контрактная</label>
          </div>

          <div className={cs(styles.formItem, styles.char_part)}>
            <Controller
              name="char_part"
              control={control}
              render={({ field }) => (
                <Select
                  size="large"
                  showSearch
                  placeholder="Запчасть"
                  optionFilterProp="label"
                  {...field}
                  status={!isValid && !field.value ? 'error' : undefined}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "Blow off",
                      label: "Blow off",
                    },
                    {
                      value: "CD-Чейнджер",
                      label: "CD-Чейнджер",
                    },
                  ]}
                />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="char_marking"
              control={control}
              render={({ field }) => (
                <Input placeholder="Маркировка" {...field} />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="char_color"
              control={control}
              render={({ field }) => <Input placeholder="Цвет" {...field} />}
            />
          </div>
          <Space.Compact className={styles.space}>
            <div className={styles.formItem}>
              <Controller
                name="mfr_number"
                control={control}
                render={({ field }) => (
                  <Input placeholder="Оригинальный номер" {...field} />
                )}
              />
            </div>
            <div className={styles.formItem}>
              <Controller
                name="manufacturer"
                control={control}
                render={({ field }) => (
                  <Input placeholder="Производитель" {...field} />
                )}
              />
            </div>
          </Space.Compact>

          <div className={cs(styles.formItem, styles.cross_number)}>
            <Controller
              name="cross_number"
              control={control}
              render={({ field }) => (
                <Input placeholder="Кросс-номера" {...field} />
              )}
            />
          </div>
          <div className={cs(styles.formItem, styles.char_note)}>
            <Controller
              name="char_note"
              control={control}
              render={({ field }) => (
                <Input placeholder="Заметка (для вас)" {...field} />
              )}
            />
          </div>
          <div className={styles.formItem}>
            <Controller
              name="char_price"
              control={control}
              render={({ field }) => (
                <InputNumber
                  controls={false}
                  placeholder='Цена в рознице'
                  style={{ width: "100%" }}
                  {...field}
                />
              )}
            />
          </div>
          <div className={cs(styles.formItem, styles.char_comment)}>
            <Controller
              name="char_comment"
              control={control}
              render={({ field }) => (
                <Input placeholder="Комментарий (для клиентов)" {...field} />
              )}
            />
          </div>
        </div>
        <button
          onClick={() => setIsGalleryModalOpen(true)}
          type="button"
          className={styles.addPhotoBtn}
        >
          {checkedImages.length > 0 ? (
            <div>Выбрано {checkedImages.length} фото</div>
          ) : (
            <MdOutlineAddAPhoto />
          )}
        </button>
        <div className={styles.applicability}>
          <div>
            {checkedMarks.length > 0
              ? `${checkedMarks.length} марок, ${checkedModels.length} моделей`
              : "Применимость не задана"}
          </div>
          <button
            onClick={() => setIsApplicabilityModalOpen(true)}
            type="button"
          >
            {" "}
            <IoExtensionPuzzleOutline />
            {checkedMarks.length > 0 ? "Посмотреть / Изменить" : " Задать"}
          </button>
        </div>
      </div>
    </form>
  );
});
