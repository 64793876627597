import { InputNumber, Table, TableColumnsType } from "antd";
import styles from "./ProductsTable.module.scss";
import { useLocation } from "react-router-dom";
import {
  IProduct,
  useUpdateProductMutation,
} from "../../../../../entities/product";
import { useGetOneIncomeQuery } from "../../../../../entities/income";
import React, { FC, useEffect, useState } from "react";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { useAppDispatch } from "../../../../../shared/hooks/reduxToolkit";
import { setIncomeMessage, setIncomeSelectedProducts } from "../../store/incomePageSlice";
import { TableRowSelection } from "antd/es/table/interface";

interface IProps {
  activeTab: "Все" | "Новые" | "Б/У";
}

const ProductsTable: FC<IProps> = ({ activeTab }) => {
  const [updateProduct] = useUpdateProductMutation();
  const [productId, setProductId] = useState<number>();
  const [purchasePrice, setPurchasePrice] = useState<number>();
  const [overheadExpenses, setOverHeadExpenses] = useState<number>();
  const [stock, setStock] = useState<number>();
  const location = useLocation();
  const incomeId = location.pathname.split("/")[2];
  const { data: income, isLoading } = useGetOneIncomeQuery(incomeId);
  const { products } = income || {};
  const dispatch = useAppDispatch();
  let itemsList: {
    id: number;
    isUsed?: boolean;
    char_part?: string;
    char_price?: number;
    stock: number;
    purchasePrice: number;
    overheadExpenses: number;
  }[] = [];
  products?.map((item) => itemsList.push(item));

  const onSubmit = async (
    id: number,
    body: { purchasePrice?: number; overheadExpenses?: number; stock?: number }
  ) => {
    await updateProduct({ id, body });
  };

  useEffect(() => {
    if (
      purchasePrice !== undefined &&
      productId &&
      income?.products?.find(({ id }) => id === productId)?.purchasePrice !==
        purchasePrice
    ) {
      onSubmit(productId, { purchasePrice });
      dispatch(setIncomeMessage("Закупочная цена изменена"));
    }
    if (
      overheadExpenses !== undefined &&
      productId &&
      income?.products?.find(({ id }) => id === productId)?.overheadExpenses !==
        overheadExpenses
    ) {
      onSubmit(productId, { overheadExpenses });
      dispatch(setIncomeMessage("Накладные расходы изменены"));
    }
    if (
      stock !== undefined &&
      productId &&
      income?.products?.find(({ id }) => id === productId)?.stock !== stock
    ) {
      onSubmit(productId, { stock });
      dispatch(setIncomeMessage("Кол-во изменено"));
    }
  }, [purchasePrice, overheadExpenses, stock, productId]);

  const columns: TableColumnsType<IProduct> = [
    {
      title: "Номер",
      dataIndex: "id",
    },
    {
      title: "Наименование",
      dataIndex: "char_part",
    },
    {
      title: "Кол-во",
      dataIndex: "stock",
      render: (value, item) => (
        <div className={styles.count}>
          <button
            onClick={() => {
              setProductId(item.id);
              setStock(() => (value > 1 ? value - 1 : value));
            }}
          >
            <CiSquareMinus />
          </button>
          <div>{value}</div>
          <button
            onClick={() => {
              setProductId(item.id);
              setStock(() => value + 1);
            }}
          >
            <CiSquarePlus />
          </button>
        </div>
      ),
    },
    {
      title: "Закупочная цена",
      dataIndex: "purchasePrice",
      render: (value, item) => (
        <InputNumber
          onBlur={(e) => {
            setStock(undefined)
            setOverHeadExpenses(undefined)
            setProductId(item.id);
            setPurchasePrice(+e.target.value);
          }}
          controls={false}
          defaultValue={value}
        />
      ),
    },
    {
      title: "Накладные расходы",
      dataIndex: "overheadExpenses",
      render: (value, item) => (
        <InputNumber
          onBlur={(e) => {
            setStock(undefined)
            setOverHeadExpenses(undefined)
            setProductId(item.id);
            setOverHeadExpenses(+e.target.value);
          }}
          controls={false}
          defaultValue={value}
        />
      ),
    },
  ];

  const rowSelection: TableRowSelection<IProduct> = {
    type: 'checkbox',
    onChange: (value)=> dispatch(setIncomeSelectedProducts(value))
  }

   

  return (
    <Table
      className={styles.root}
      loading={isLoading}
      columns={columns}
      dataSource={itemsList
        ?.sort((a, b) => a.id - b.id)
        .filter(({ isUsed }) =>
          activeTab === "Новые" ? !isUsed : activeTab === "Б/У" ? isUsed : true
        )
        .map((item: any) => ({ ...item, key: item?.id }))}
      rowSelection={rowSelection}
      pagination={{defaultCurrent: 1, pageSize: 15}}
    />
  );
};

export default ProductsTable;
