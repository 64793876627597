import { FC, useEffect, useRef, useState } from 'react';
import styles from './updateClientForm.module.scss'
import { message, notification, NotificationArgsProps, Switch } from 'antd';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateClientDto, IClient, useDeleteClientMutation, useUpdateClientMutation } from '../../../../../../entities/client';
import PhysicalPersonForm from '../physicalPersonFormPart';
import LegalPersonForm from '../legalPersonFormPart';
import { useNavigate } from 'react-router-dom';




interface IProps {
  client?: IClient,
  saveBtnTrigger: boolean
  deleteBtnTrigger: boolean
  setSaveBtnTrigger: React.Dispatch<React.SetStateAction<boolean>>
}

type NotificationPlacement = NotificationArgsProps['placement'];

const UpdateClientForm: FC<IProps> = ({ client, saveBtnTrigger, deleteBtnTrigger, setSaveBtnTrigger }) => {
  const [api, notificationContextHolder] = notification.useNotification();
  const [isLegalPerson, setIsLegalPerson] = useState<boolean>(false)
  const formRef = useRef<HTMLFormElement>(null)
  const [updateClient] = useUpdateClientMutation()
  const [deleteClient] = useDeleteClientMutation()
  const navigate = useNavigate()
  const { control, setValue, handleSubmit, watch } = useForm<CreateClientDto>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      note: '',
      comment: '',
      organization: '',
      legalAddress: '',
      actualAddress: '',
      inn: '',
      kpp: '',
      model: '',
      vin: '',
      bankName: '',
      bic: '',
      checkingAccount: '',
      correspondentAccount: ''
    }
  })

  const openNotification = (placement: NotificationPlacement) => {
    api.warning({
      message: 'Не удалось сохранить',
      description:
        'Не заполнено ни одно из обязательных полей. Укажите имя клиента или телефон, или электронную почту.',
      placement,
    });
  };


  useEffect(() => {

    if (client) {
      for (const [key, value] of Object.entries(client)) {
        setValue(key as any, value)
      }
      setIsLegalPerson(client.isLegalPerson ? true : false)
    }
  }, [client])

  const onSubmit: SubmitHandler<CreateClientDto> = async (data) => {

    await updateClient({ id: String(client?.id), body: { ...data, isLegalPerson } })

  }

  const handleDelete = async () => {
    await deleteClient(String(client?.id))
  }

  useEffect(() => {
    const { name, phone, email } = watch()
    if (saveBtnTrigger && (name || phone || email)) {
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
      setIsLegalPerson(false);
      message.success('Запись успешно сохранена!')
      navigate('/clients', { state: { showMessage: true } })
    } else if (saveBtnTrigger) {
      openNotification('top')
    }
    setSaveBtnTrigger(false)
  }, [saveBtnTrigger])

  useEffect(() => {
    if (deleteBtnTrigger) {
      handleDelete()
      message.success('Запись успешно удалена!')
      navigate('/clients')
    }
  }, [deleteBtnTrigger])

  return (
    <>
      {notificationContextHolder}
      <div className={styles.root} >
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={styles.form} action="">
          <div className={styles.physicalPerson} >
            <h3 className={styles.physicalPerson_title} >Основная информация</h3>
            <PhysicalPersonForm control={control} />
          </div>
          <div className={styles.legalPerson} >
            <div className={styles.legalPerson_header} >
              <h3 className={styles.legalPerson_title} >Данные юридического лица</h3>

              <Switch value={isLegalPerson} onChange={(value) => setIsLegalPerson(value)} />
            </div>
            {isLegalPerson && <LegalPersonForm control={control} />}
          </div>
        </form>
      </div></>


  )
}

export default UpdateClientForm