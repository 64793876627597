import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Key } from "react"





interface InitialState {
    incomeMessage?: string,
    incomeSelectedProducts: Key[]
}


const initialState: InitialState = {
    incomeMessage: undefined,
    incomeSelectedProducts: []
}

const incomePageSlice_ = createSlice({
    initialState,
    name: 'incomePage',
    reducers: {
        setIncomeMessage: (state, action: PayloadAction<string | undefined>) => {
           state.incomeMessage = action.payload
        },
        setIncomeSelectedProducts: (state, action: PayloadAction<Key[]>) => {
            state.incomeSelectedProducts = action.payload
        }
    }
})


export const {setIncomeMessage, setIncomeSelectedProducts} = incomePageSlice_.actions
export const incomePageSlice = incomePageSlice_.reducer