import { Key } from "react";
import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateSaleItemDto } from "../dtos/createSaleItemDto";
import { UpdateSaleItemDto } from "../dtos/updateSaleItemDto";
import { ISaleItem } from "../interfaces/saleItem";




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: build => ({
        createSaleItems: build.mutation<ISaleItem[], CreateSaleItemDto[]>({
            query: (body) => ({
                method: 'POST',
                url: '/sale-items',
                body
            }),
            invalidatesTags: ['SaleItem', 'Sale']
        }),
        updateSaleItem: build.mutation<ISaleItem, { id: number, body: UpdateSaleItemDto }>({
            query: ({ id, body }) => ({
                method: 'PATCH',
                url: `/sale-items/${id}`,
                body
            }),
            invalidatesTags: ['SaleItem', 'Sale']
        }),
        deleteManySaleItems: build.mutation<ISaleItem, Key[]>({
            query: (body) => ({
                url: '/sale-items',
                method: 'DELETE',
                body
            }),
            invalidatesTags: ['SaleItem', 'Sale']
        }),
        getOneSaleItem: build.query<ISaleItem, number>({
            query: (id) => `/sale-items/${id}`,
            providesTags: ['SaleItem', 'Sale']
        }),
        getAllSaleItems: build.query<ISaleItem[], void>({
            query: () => '/sale-items',
            providesTags: ['SaleItem', 'Sale']
        })
    })
})

export const { useCreateSaleItemsMutation,
    useUpdateSaleItemMutation,
    useDeleteManySaleItemsMutation,
    useGetOneSaleItemQuery,
    useGetAllSaleItemsQuery } = extendedApi

