import { Button, Input, InputNumber, message, Modal, notification, Select } from 'antd'
import styles from './PaymentModal.module.scss'
import { FC, useMemo, useState } from 'react'
import { FaRubleSign } from 'react-icons/fa'
import { VscClose } from "react-icons/vsc";
import { CreatePaymentDto, useCreatePaymentMutation } from '../../../../../../../entities/payment';
import { ISale } from '../../../../../../../entities/sale';


const sourceOptions = [
  {
    label: 'Безналичный расчет',
    value: 'cashless'
  },
  {
    label: 'Касса в офисе',
    value: 'officeCashbox'
  },
  {
    label: 'Банк рассчётный счет',
    value: 'bankAccount'
  }
]

interface IProps {
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  sale?: ISale
}


export const PaymentModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, sale }) => {
  const [isCommentVisible, setIsCommentVisible] = useState<boolean>()
  const [paymentComment, setPaymentComment] = useState<string>()
  const [sourceItems, setSourceItems] = useState<CreatePaymentDto[]>([{ source: undefined, sum: undefined, type: 'sale' }])
  const [createPayment] = useCreatePaymentMutation()
  const totalPayd = useMemo(() => (sourceItems.reduce((sum, item) => sum + (item?.sum || 0), 0)), [sourceItems])
  const needToPay = useMemo(() => {
    if (sale && sale?.sumFull && sale.payments)
      return ((sale?.sumFull || 0) - sale?.payments?.reduce((sum, item) => sum + (item.sum || 0), 0))
  }, [sale])
  const [validationError, setValidationError] = useState<boolean>()
  const client = sale?.client

  const onCancel = () => {
    setPaymentComment(undefined)
    setSourceItems([{ source: undefined, sum: undefined, type: 'sale' }])
    setIsModalOpen(false)
  }

  const handleAddSourceItem = () => {
    setSourceItems((prev) => [...prev, { source: undefined, sum: undefined, type: 'sale' }])
  }

  const handleDeleteSourceItem = (index: number) => {
    setSourceItems((prev) => prev.filter((_, i) => index !== i))
  }

  const handleSourceSelect = (index: number, value: CreatePaymentDto['source']) => {
    setSourceItems((prev) => prev.map((item, i) => {
      if (index === i) {
        item.source = value
      }
      return item
    }))
  }

  const handleSumChange = (index: number, value: number) => {
    setSourceItems((prev) => prev.map((item, i) => {
      if (index === i) {
        item.sum = value
      }
      return item
    })
    )
  }

  const onSubmit = async () => {
    const isEveryPropertiesDefined = (array: CreatePaymentDto[], properties: (keyof CreatePaymentDto)[]) => {
      return array.every(item => properties.every((property) => item[property] !== undefined))
    }
    const isPropertiesDefined = isEveryPropertiesDefined(sourceItems, ['sum', 'source'])
    setValidationError(isPropertiesDefined)
    
    if (isPropertiesDefined && sale && needToPay && totalPayd <= needToPay) {
      sourceItems.forEach(async (item) => {
        const res = await createPayment({ saleId: sale?.id, clientId: client?.id , comment: paymentComment, ...item })
        if ('error' in res) {
          message.error('Ошибка сервера')
        }
      })
      onCancel()
    } else if (totalPayd >= (needToPay || 0)) {
      notification.warning({ message: 'Ошибка', description: 'Сумма оплаты не совпадает с суммой заказа', placement: 'bottom' })
    } else if (!isPropertiesDefined) {
      notification.warning({ message: 'Ошибка', description: 'Заполните обязательные поля', placement: 'bottom' })
    }




  }



  return (
    <Modal destroyOnClose={true} width={580} closable={false} footer={false} className={styles.root} open={isModalOpen}>
      <div className={styles.header} >
        <FaRubleSign />  <h3> {`Внести оплату по сделке № ${sale?.id}`} </h3>
      </div>
      <div className={styles.content} >
        <div className={styles.payment} >
          <label htmlFor="payment">Принято в:</label>
          <div className={styles.sourceInputsList} >

            {
              sourceItems.map(({ sum, source }, index) => (
                <div key={index} className={styles.sourceInputsItem} >
                  {sourceItems.length > 1 && <button onClick={() => handleDeleteSourceItem(index)} > <VscClose /> </button>}
                  <Select status={(validationError === false && (source === undefined) ? 'error' : undefined)} value={source} onChange={(value) => handleSourceSelect(index, value)} placeholder={'Выберите...'} size='large' options={sourceOptions} />
                  <InputNumber status={(validationError === false && (sum === undefined) ? 'error' : undefined)} value={sum} onChange={(value) => handleSumChange(index, Number(value))} placeholder='Сумма' controls={false} />
                </div>
              ))
            }
            <div onClick={handleAddSourceItem} className={styles.addSourceItem} >Добавить источник</div>
          </div>
        </div>
        <div className={styles.total} >
          <div> <p>Итого принято</p> <p>{totalPayd}</p> </div>
          <div> <p>Требуется</p> <p>{needToPay}</p> </div>
        </div>
        <div className={styles.commentWrapper}  >
          {isCommentVisible ?
            <Input value={paymentComment} onChange={(e) => setPaymentComment(e.target.value)} placeholder='Комментарий' size='large' /> :
            <div onClick={() => setIsCommentVisible(true)} className={styles.comment} >Добавить комментарий</div>}</div>
      </div>
      <div className={styles.footer} >

        <Button onClick={onSubmit} type='primary' >Внести оплату</Button>
        <Button onClick={onCancel} >Отмена</Button>
      </div>
    </Modal>
  )
}

