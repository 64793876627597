import { useLocation, useNavigate } from 'react-router-dom'
import styles from './ModelPage.module.scss'
import { Button, Modal, Popconfirm, Table } from 'antd'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { TableColumnsType } from 'antd/lib'
import { useDeleteModelMutation, useGetOneModelQuery } from '../../../../../entities/model'
import { IGeneration, useGetAllGenerationsQuery } from '../../../../../entities/generation'
import AddGenerationForm from '../components/addGenerationForm'
import EditModelForm from '../components/EditModelForm'
import { MdDelete, MdEdit } from 'react-icons/md'



const columns: TableColumnsType<IGeneration> = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '№ Поколения',
    dataIndex: 'genVersion'
  },
  {
    title: 'Название',
    dataIndex: 'title'
  },
  {
    title: 'Года',
    dataIndex: 'years'
  }
]


export const ModelPage = () => {
  const [isAddGenerationModalOpen, setIsAddGenerationModalOpen] = useState<boolean>(false)
  const [isEditModelModalOpen, setIsEditModelModalOpen] = useState<boolean>(false)
  const location = useLocation()
  const modelId = location.pathname.split('/')[3]
  const { data: model } = useGetOneModelQuery(modelId)
  const { title } = model || {}
  const { data: generations } = useGetAllGenerationsQuery(modelId)
  const navigate = useNavigate()
  const [deleteModel] = useDeleteModelMutation()

  const handleDeleteModel = ()=> {
       deleteModel(modelId)
       navigate(-1)
  }


  return (
    <main className={styles.root} >
      <Modal title='Добавить поколение' footer={false} onCancel={() => setIsAddGenerationModalOpen(false)} open={isAddGenerationModalOpen} >
        <AddGenerationForm setIsModalOpen={setIsAddGenerationModalOpen} />
      </Modal>
      <Modal title='Редактирование модели' open={isEditModelModalOpen} onCancel={() => setIsEditModelModalOpen(false)} footer={false}>
        <EditModelForm setIsModalOpen={setIsEditModelModalOpen} model={model} />
      </Modal>
      <div className={styles.header} >
        <button onClick={() => navigate(-1)} className={styles.returnBtn} ><IoIosArrowBack /></button>
        <h1>Модель {title}</h1>
        <div className={styles.header_buttonsHolder} >
          <Button onClick={()=> setIsEditModelModalOpen(true)} size='large' type='primary' icon={<MdEdit />} ></Button>
          <Popconfirm onConfirm={handleDeleteModel} title='Удаление модели' description='Вы действительно хотите удалить модель?' ><Button size='large' danger type='primary' icon={<MdDelete />} ></Button></Popconfirm>
        </div>
      </div>
      <div className={styles.content} >
        <div className={styles.contentHeader} >
          <div className={styles.tableTitle}  >
            <h2>Поколения</h2>
          </div>
          <Button onClick={() => setIsAddGenerationModalOpen(true)} type='primary' >Добавить поколение</Button>
        </div>
        <div className={styles.tableHolder} >
          <Table
            columns={columns}
            dataSource={generations?.map((item) => ({ ...item, key: item.id }))}
            onRow={(item) => ({ onClick: () => navigate(`/applicability/generation/${item.id}`) })}
          />
        </div>
      </div>
    </main>
  )
}
