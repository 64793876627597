import { Route, Routes } from 'react-router-dom'
import { HomePage } from './pages/homePage'
import { AuthPage } from './pages/authPage'
import MainLayout from './mainLayout'
import { ClientsPage } from './pages/(clients)/clientsPage'
import { ClientPage } from './pages/(clients)/clientPage'
import { IncomesPage } from './pages/(incomes)/incomesPage'
import { AddIncomePage } from './pages/(incomes)/addIncomePage'
import { IncomePage } from './pages/(incomes)/incomePage'
import { CatalogPage } from './pages/(catalog)/catalogPage'
import CatalogLayout from './pages/(catalog)/catalogLayout'
import { WheelsPage } from './pages/(catalog)/wheelsPage'
import { TestPage } from './pages/testPage'
import { ServicesPage } from './pages/(catalog)/servicesPage'
import { ProductPage } from './pages/(catalog)/productPage'
import { ApplicabilityPage } from './pages/(applicability)/applicabilityPage'
import { ModelPage } from './pages/(applicability)/modelPage'
import { MarkPage } from './pages/(applicability)/markPage'
import { GenerationPage } from './pages/(applicability)/generationPage'
import { EnginePage } from './pages/(applicability)/enginePage'
import { BodyPage } from './pages/(applicability)/bodyPage'
import { StoragesPage } from './pages/(storages)/storagesPage'
import { StoragePage } from './pages/(storages)/storagePage'
import { WheelPage } from './pages/(catalog)/wheelPage'
import { SalesPage } from './pages/(sales)/salesPage'
import { SalePage } from './pages/(sales)/salePage'
import { AddSalePage } from './pages/(sales)/addSalePage'
import { AddReturnPage } from './pages/(returns)/addReturnPage'
import { ReturnsPage } from './pages/(returns)/returnsPage'
import { ReturnPage } from './pages/(returns)/returnPage'
import { PaymentsPage } from './pages/paymentsPage'
import { AddWriteoffPage } from './pages/(writeoffs)/addWriteoffPage'
import { WriteoffsPage } from './pages/(writeoffs)/writeoffsPage'
import { WriteoffPage } from './pages/(writeoffs)/writeoffPage'





const Router = () => {
  return (
    <Routes>
      <Route path='/auth' element={<AuthPage />} />
      <Route element={<MainLayout />} >
        <Route path='/' element={<HomePage />} />
        <Route element={<CatalogLayout />} >
          <Route path='/catalog' element={<CatalogPage />} />
          <Route path='/catalog/wheels' element={<WheelsPage />} />
          <Route path='/catalog/services' element={<ServicesPage />} />
        </Route>
        <Route path='/catalog/product/:id' element={<ProductPage />} />
        <Route path='/catalog/wheel/:id' element={<WheelPage />} />
        <Route path='/sales' element={<SalesPage />} />
        <Route path='/returns' element={<ReturnsPage />} />
        <Route path='/incomes' element={<IncomesPage />} />
        <Route path='/incomes/new' element={<AddIncomePage />} />
        <Route path='/incomes/:id' element={<IncomePage />} />
        <Route path='/storages' element={<StoragesPage />} />
        <Route path='/storage' element={<StoragePage />} />
        <Route path='/carsrc' element={<TestPage />} />
        <Route path='/clients' element={<ClientsPage />} />
        <Route path='/clients/:id' element={<ClientPage />} />
        <Route path='/clients/:id/sales/new' element={<AddSalePage />} />
        <Route path='/clients/:id/sales/:id' element={<SalePage />} />
        <Route path='/clients/:id/returns/new' element={<AddReturnPage/>}/>
        <Route path='/clients/:id/sales/:id/returns/:id' element={<ReturnPage/>} />
        <Route path='/writeoffs/new' element={<AddWriteoffPage/>} />
        <Route path='/writeoffs' element={<WriteoffsPage/>} />
        <Route path='/writeoffs/:id' element={<WriteoffPage/>} />
        <Route path='/payments' element={<PaymentsPage />} />
        <Route path='/analytics' element={<TestPage />} />
        <Route path='/stocktakings' element={<TestPage />} />
        <Route path='/applicability' element={<ApplicabilityPage />} />
        <Route path='/applicability/mark/:id' element={<MarkPage />} />
        <Route path='/applicability/model/:id' element={<ModelPage />} />
        <Route path='/applicability/generation/:id' element={<GenerationPage />} />
        <Route path='/applicability/body/:id' element={<BodyPage />} />
        <Route path='/applicability/engine/:id' element={<EnginePage />} />
        <Route path='/settings' element={<TestPage />} />
      </Route>
    </Routes>
  )
}

export default Router