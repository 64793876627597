import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styles from './SaleUpdateForm.module.scss'
import { Button, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC, useEffect, useMemo, useState } from 'react'
import { ISaleItem } from '../../../../../../../entities/saleItem'
import { useGetAllClientsQuery } from '../../../../../../../entities/client'
import { ISale, UpdateSaleDto, useUpdateSaleMutation } from '../../../../../../../entities/sale'
import { useGetAllStoragesQuery } from '../../../../../../../entities/storage'
import { useAppDispatch } from '../../../../../../../shared/hooks/reduxToolkit'
import { setSaleMessage } from '../../../../store/salePageSlice'
import { useNavigate } from 'react-router-dom'
import { PaymentModal } from '../paymentModal'
import { ReturnPaymentModal } from '../returnPaymentModal'



interface IProps {
  saleItems: ISaleItem[]
  sale?: ISale
}

export const SaleUpdateForm: FC<IProps> = ({ sale}) => {
  const [isCommentVisible, setIsCommentVisible] = useState<boolean>(false)
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false)
  const [isReturnPaymentModalOpen, setIsReturnPaymentModalOpen] = useState<boolean>(false)
  const [updateSale] = useUpdateSaleMutation()
  const { data: clients } = useGetAllClientsQuery()
  const {data: storages} = useGetAllStoragesQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const payd = useMemo(() => {
    if (sale && sale?.sumFull && sale.payments)
      return sale?.payments?.reduce((sum, item) => sum + (item.sum || 0), 0)
  }, [sale])
   const {control, setValue, handleSubmit} = useForm<UpdateSaleDto>({
    defaultValues: {
      clientId: undefined,
      managerComment: undefined,
      storageId: undefined,
      deliveryComment: undefined
    }
   })

   useEffect(()=> {
    setValue('clientId', sale?.client?.id)
    setValue('storageId', sale?.storage?.id)
    setValue('managerComment', sale?.managerComment)
    setValue('deliveryComment', sale?.deliveryComment)
   }, [sale])

    const onSubmit: SubmitHandler<UpdateSaleDto> = async (body)=> {
                if(sale) {
                    const res = await updateSale({id: sale.id, body})
                }
    }

  return (
    <form className={styles.root} >
        <PaymentModal sale={sale} isModalOpen={isPaymentModalOpen} setIsModalOpen={setIsPaymentModalOpen} />
        <ReturnPaymentModal sale={sale} isModalOpen={isReturnPaymentModalOpen} setIsModalOpen={setIsReturnPaymentModalOpen} />
    <div className={styles.formPart} >
        <div className={styles.countersHolder} >
            <div className={styles.counter} >
                <p className={styles.counterTitle} >Сумма сделки</p>
                <p className={styles.counterCount} >
                    {sale?.sumFull || 0}
                </p>
            </div>
            <div className={styles.counter} >
                <p className={styles.counterTitle} >Оплачено</p>
                <p className={styles.counterCount} >{sale?.status === 'issued' ? sale?.sumFull :  (payd || 0)}</p>
            </div>
        </div>
        { sale?.status === 'reserve' && <div className={styles.btnsHolder} >
            <Button onClick={()=> setIsPaymentModalOpen(true)} type='primary' >{payd ? 'Внести доплату': 'Внести оплату'}</Button>
            <Button onClick={()=> setIsReturnPaymentModalOpen(true)} type='text' >Вернуть предоплату</Button> </div>}
        <div className={styles.formItem} >
            <Controller
                name='clientId'
                control={control}
                render={({ field }) => (
                    <Select disabled={sale?.status === 'issued'} value={field.value} onChange={(value)=> {
                        field.onChange(value)
                        handleSubmit(onSubmit)()
                        dispatch(setSaleMessage('Клиент изменен'))
                        navigate(`/client/${value}/sales/${sale?.id}`)
                    }} options={clients?.data?.map(({ id, name }) => ({ label: name, value: id })) || []} placeholder='Выберите клиента' className={styles.formItem_select}
                        optionFilterProp="label"
                    />
                )}
            />
        </div>
        <div className={styles.formItem} >
            <Controller
                name='managerComment'
                control={control}
                render={({ field }) => (
                    <TextArea {...field} 
                    onBlur={()=> {
                        handleSubmit(onSubmit)()
                        dispatch(setSaleMessage('Комментарий изменен'))
                    }}
                    autoSize={{ minRows: 3 }} placeholder='Комментарий к сделке' />
                )}
            />
        </div>
    </div>
    <div className={styles.formPart} >
        <div className={styles.formItem} >
            <label>Склад выдачи</label>
            <Controller
                name='storageId'
                control={control}
                render={({ field }) =>
                    <Select disabled={sale?.status === 'issued'}
                        value={field.value}
                       onChange={(value)=> {
                        field.onChange(value)
                        handleSubmit(onSubmit)()
                        dispatch(setSaleMessage('Склад изменен'))
                    }}
                options={storages?.map(({id, name})=> ({label: name, value: id})) || []} />
                }
            />
        </div>
        <div className={styles.divider} />
        <div className={styles.formItem} >
            {!isCommentVisible ? <div onClick={() => setIsCommentVisible(true)} className={styles.showComment} > Комментарий к доставке </div> :
                <div className={styles.deliveryComment} >
                    <label >Комментарий к доставке</label>
                    <Controller
                        name='deliveryComment'
                        control={control}
                        render={({ field }) => (
                            <TextArea {...field}
                            onBlur={()=> {
                                handleSubmit(onSubmit)()
                                dispatch(setSaleMessage('Комментарий к доставке изменен'))
                            }} 
                            placeholder='Комментарий к доставке' autoSize={{ minRows: 3, maxRows: 3 }} />
                        )}
                    />
                </div>}
        </div>
    </div>


</form>
  )
}

