import styles from './DonorCarFormPart.module.scss'
import { FC, useEffect, useState } from 'react'
import cs from 'classnames'
import { Input, InputNumber, Segmented, Select } from 'antd'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { IProduct, UpdateProductDto } from '../../../../../../../entities/product'




interface IProps {
  product?: IProduct
  control: Control<UpdateProductDto, any>
  watch: UseFormWatch<UpdateProductDto>
  setChangedParametrs: React.Dispatch<React.SetStateAction<{
    images: number;
    generalInfo: number;
    mainParameters: number;
    donorCar: number;
    placement: number;
}>>
setValue: UseFormSetValue<UpdateProductDto>
}

const DonorCarFormPart: FC<IProps> = ({product, control, watch, setChangedParametrs, setValue}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
 

  const onItemSelect = (name: string)=> {
     setSelectedItems(prev => [...prev, name])
  
  }

  const onCancelItemSelect = (name: any)=> {
    setValue(name, product?.[name as keyof IProduct])
    setSelectedItems(prev => prev.filter((item)=> name !== item))
  }

  
  useEffect(()=> {
     setChangedParametrs((prev)=> ({...prev, mainParameters: selectedItems.length}))
  }, [selectedItems])

  const itemsList = [
    {
      name: 'carDonorId',
      title: 'Номер донора',
      value: watch('carDonorId') || '-',
      input:  <Controller
              name='carDonorId'
              control={control}
              render={({field})=> (
                <Input placeholder='Введите номер автомобиля донора' {...field} />
              )}
      />
    },
    {
      name: 'char_carsrc_mark',
      title: 'Марка',
      value: watch('char_carsrc_mark') || '-',
      input:  <Controller
              name='char_carsrc_mark'
              control={control}
              render={({field})=> (
                <Input placeholder='Марка' {...field} />
              )}
      />
    },
    {
      name: 'char_carsrc_model',
      title: 'Модель',
      value: watch('char_carsrc_model') || '-',
      input:  <Controller
              name='char_carsrc_model'
              control={control}
              render={({field})=> (
                <Input placeholder='Модель' {...field} />
              )}
      />
    },
    {
      name: 'char_carsrc_body',
      title: 'Модель кузова',
      value: watch('char_carsrc_body') || '-',
      input:  <Controller
              name='char_carsrc_body'
              control={control}
              render={({field})=> (
                <Input placeholder='Модель кузова' {...field} />
              )}
      />
    },
    {
      name: 'char_carsrc_engine',
      title: 'Модель двигателя',
      value: watch('char_carsrc_engine') || '-',
      input:  <Controller
              name='char_carsrc_engine'
              control={control}
              render={({field})=> (
                <Input placeholder='Модель двигателя' {...field} />
              )}
      />
    },
    {
      name: 'char_carsrc_year',
      title: 'Год выпуска',
      value: watch('char_carsrc_year') || '-',
      input:  <Controller
              name='char_carsrc_year'
              control={control}
              render={({field})=> (
                <InputNumber controls={false} placeholder='Год выпуска' {...field} />
              )}
      />
    }
    
   
  ]


  return (
    <div className={styles.root} >
      <div className={styles.header} >
        <h3 className={styles.title} >Автомобиль-донор</h3>
      </div>
      <div className={styles.content} >
        {itemsList.map(({title, name, value, input})=>(
              <div key={name} className={cs(styles.formItem, selectedItems.includes(name) && styles.formItem__selected)} >
              <div className={styles.formItem_title} >{title}</div>
              {selectedItems.includes(name) ?<div className={styles.formItem_input} >{input}</div> :
              <div onClick={()=> onItemSelect(name)} className={styles.formItem_value} >{value}</div>}
              {selectedItems.includes(name) ?
              <button onClick={()=> {onCancelItemSelect(name)}} type='button' className={styles.formItem_cancelBtn} >Отменить</button> :
              <button onClick={()=> onItemSelect(name)} type='button' className={styles.formItem_editBtn} >Изменить</button>}
            </div>
        ))}
      </div>
    </div>
  )
}

export default DonorCarFormPart