import { Button, Input, InputNumber, message, Modal, notification, Select } from 'antd'
import styles from './ReturnPaymentModal.module.scss'
import { FC, useEffect, useMemo, useState } from 'react'
import { FaRubleSign } from 'react-icons/fa'
import { VscClose } from "react-icons/vsc";
import cs from 'classnames'
import { ISale } from '../../../../../entities/sale';
import { CreatePaymentDto, useCreatePaymentMutation } from '../../../../../entities/payment';
import { ISaleItem } from '../../../../../entities/saleItem';
import { useCreateReturnMutation } from '../../../../../entities/return';
import { useNavigate } from 'react-router-dom';



interface IProps {
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  sale?: ISale
  items: ISaleItem[]
  comment?: string
}


export const ReturnPaymentModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, sale, items, comment }) => {
  const [isCommentVisible, setIsCommentVisible] = useState<boolean>()
  const [paymentComment, setPaymentComment] = useState<string>()
  const [sourceItems, setSourceItems] = useState<CreatePaymentDto[]>([{ source: undefined, sum: undefined, type: 'return' }])
  const [createPayment] = useCreatePaymentMutation()
  const navigate = useNavigate()
  const returningSum = useMemo(() => (items.reduce((sum, item) => sum + (item?.discountedPrice || item.product.char_price || 0) * item.quantity, 0)), [items])
  const payd = useMemo(()=> sourceItems.reduce((sum, item)=> sum + (item.sum || 0), 0), [sourceItems])
  const [validationError, setValidationError] = useState<boolean>()
  const [wrongSumError, setWrongSumError] = useState<boolean>(false)
  const client = sale?.client
  const cashlessSum = sale?.payments?.filter(({ source }) => source === 'cashless').reduce((sum, item) => sum + (item.sum || 0), 0)
  const officeCashboxSum = sale?.payments?.filter(({ source }) => source === 'officeCashbox').reduce((sum, item) => sum + (item.sum || 0), 0)
  const bankAccountSum = sale?.payments?.filter(({ source }) => source === 'bankAccount').reduce((sum, item) => sum + (item.sum || 0), 0)
  const [createReturn] = useCreateReturnMutation()
  const onCancel = () => {
    setPaymentComment(undefined)
    setSourceItems([{ source: undefined, sum: undefined, type: 'return' }])
    setIsModalOpen(false)
  }


  const handleAddSourceItem = () => {
    setSourceItems((prev) => [...prev, { source: undefined, sum: undefined, type: 'return' }])
  }

  const handleDeleteSourceItem = (index: number) => {
    setSourceItems((prev) => prev.filter((_, i) => index !== i))
  }
  const handleSourceSelect = (index: number, value: CreatePaymentDto['source']) => {
    setSourceItems((prev) => prev.map((item, i) => {
      if (index === i) {
        item.source = value
      }
      return item
    }))
  }

  const handleSumChange = (index: number, value: number) => {
    setSourceItems((prev) => prev.map((item, i) => {
      if (index === i) {
        item.sum = value
      }
      return item
    })
    )
  }


  const sourceOptions = [
    {
      label: <div className={cs(styles.selectLabel, cashlessSum && styles.selectLabel__active)} >{cashlessSum ? `Безналичный (оплачено: ${cashlessSum})` : 'Безналичный'}</div>,
      value: 'cashless'
    },
    {
      label: <div className={cs(styles.selectLabel, officeCashboxSum && styles.selectLabel__active)} >{officeCashboxSum ? `Касса в офисе (оплачено: ${officeCashboxSum})` : 'Касса в офисе'}</div>,
      value: 'officeCashbox'
    },
    {
      label: <div className={cs(styles.selectLabel, bankAccountSum && styles.selectLabel__active)} >{bankAccountSum ? `Банк расчетный счет (оплачено: ${bankAccountSum})` : 'Банк расчетный счет'}</div>,
      value: 'bankAccount'
    }
  ]


  const onSubmit = async () => {
    const isEveryPropertiesDefined = (array: CreatePaymentDto[], properties: (keyof CreatePaymentDto)[]) => {
      return array.every(item => properties.every((property) => item[property] !== undefined))
    }

    const isPropertiesDefined = isEveryPropertiesDefined(sourceItems, ['sum', 'source'])
    setValidationError(isPropertiesDefined)
     
    if (isPropertiesDefined && sale && payd && (returningSum === payd)) {
      sourceItems.forEach(async ({ sum, ...item }) => {
        const paymentRes = await createPayment({ saleId: sale?.id, clientId: client?.id, comment: paymentComment, sum: sum ? -sum : undefined, ...item })
        const returnRes = await createReturn({
          saleId: sale?.id,
          comment,
          returnItems: items.map(({ id, quantity }) => ({ saleItemId: id, quantity }))
        })
        navigate('/sales')
        if ('error' in paymentRes || 'error' in returnRes) {
          message.error('Ошибка сервера')
        }
      })
      onCancel()
    } else if (payd && sale?.sumFull && (returningSum !== payd)) {
      notification.warning({ message: 'Ошибка', description: 'Сумма возврата не совпадает с суммой заказа', placement: 'bottom' })
    } else if (!isPropertiesDefined) {
      notification.warning({ message: 'Ошибка', description: 'Заполните обязательные поля', placement: 'bottom' })
    }

  }

  useEffect(() => {
    const sources = [{ source: 'cashless', sum: cashlessSum },
    {
      source: 'officeCashbox',
      sum: officeCashboxSum
    },
    {
      source: 'bankAccount',
      sum: bankAccountSum
    }
    ]
    const sumError = (source: string, sum?: number) => {
      if (sourceItems.some((item) => item.source === source)) {
        return sourceItems.filter((item) => item.source === source).reduce((sum, item) => sum + (item.sum || 0), 0) > (sum ? sum : 0)
      } else {
        return false
      }
    }

    const result = sources.some(({ source, sum }) => sumError(source, sum))

    setWrongSumError(result)

  }, [sourceItems])

  return (
    <Modal destroyOnClose={true} width={580} closable={false} footer={false} className={styles.root} open={isModalOpen}>
      <div className={styles.header} >
        <FaRubleSign />  <h3> {`Вернуть оплату по сделке № ${sale?.id}`} </h3>
      </div>
      <div className={styles.content} >
        <div className={styles.payment} >
          <label htmlFor="payment">Принято в:</label>
          <div className={styles.sourceInputsList} >

            {
              sourceItems.map(({ sum, source }, index) => (
                <div key={index} className={styles.sourceInputsItem} >
                  {sourceItems.length > 1 && <button onClick={() => handleDeleteSourceItem(index)} > <VscClose /> </button>}
                  <Select status={(validationError === false && (source === undefined) ? 'error' : undefined)} value={source} onChange={(value) => handleSourceSelect(index, value)} placeholder={'Выберите...'} size='large' options={sourceOptions} />
                  <InputNumber status={(validationError === false && (sum === undefined) ? 'error' : undefined)} value={sum} onChange={(value) => handleSumChange(index, Number(value))} placeholder='Сумма' controls={false} />
                </div>
              ))
            }
            <div onClick={handleAddSourceItem} className={styles.addSourceItem} >Добавить источник</div>
          </div>
        </div>
        <div className={styles.totalWrapper} >
          {wrongSumError ? <p className={styles.errorMessage} >  Не соответствие источников оплаты и возврата!
            <br />
            Учет оплаты будет не корректным! </p> : <p></p>}
          <div className={styles.total} >
            <div> <p>Итого выдано:</p> <p>{payd}</p> </div>
            <div> <p>Оплачено:</p> <p>{sale?.sumFull}</p> </div>
          </div>
        </div>
        <div className={styles.commentWrapper}  >
          {isCommentVisible ?
            <Input value={paymentComment} onChange={(e) => setPaymentComment(e.target.value)} placeholder='Комментарий' size='large' /> :
            <div onClick={() => setIsCommentVisible(true)} className={styles.comment} >Добавить комментарий</div>}</div>
      </div>
      <div className={styles.footer} >

        <Button onClick={onSubmit} type='primary' >Провести возврат</Button>
        <Button onClick={onCancel} >Отмена</Button>
      </div>
    </Modal>
  )
}

