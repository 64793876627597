import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styles from './AddShipmentModal.module.scss'
import { Input, message, Modal, notification } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { CreateShipmentDto, useCreateShipmentMutation } from '../../../../../../entities/shipment'
import { FC, useEffect, useRef } from 'react'


interface IProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddShipmentModal: FC<IProps> = ({isModalOpen, setIsModalOpen}) => {
    const [createShipment] = useCreateShipmentMutation()
    const formRef = useRef<HTMLFormElement>(null)
    const {control, handleSubmit, reset, watch} = useForm({
        defaultValues: {
            title: '',
            description: ''
        }
    })

   

    const handleOk = () => {
        if(watch('title')) {
            formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
            message.success('Поставка успешно создана')
            reset()
            setIsModalOpen(false)
        }
   
      };
    
      
      const handleCancel = () => {
        setIsModalOpen(false);
      };

    const onSubmit: SubmitHandler<CreateShipmentDto> = async (data)=> {
        await createShipment(data)
    } 


    
  return (
    <Modal title="Новая поставка" cancelText='Закрыть' okText='Добавить' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
<form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={styles.root} >
        <div className={styles.formItem} >
            <Controller
            name='title'
            control={control}
            render={({field})=> (
                <Input {...field} placeholder='Название' />
            )}
            />
        </div>
        <div className={styles.formItem} >
            <Controller
            name='description'
            control={control}
            render={({field})=> (
                <TextArea autoSize={{ minRows: 5, maxRows: 5 }} {...field} placeholder='Описание' />
            )}
            />
        </div>
    </form>
    </Modal>
    
  )
}

export default AddShipmentModal