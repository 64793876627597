import { FC, useEffect, useMemo, useState } from 'react'
import styles from './ApplicabilityGeneration.module.scss'
import { Checkbox } from 'antd'
import cs from 'classnames'
import ApplicabilityEngineModal from '../applicabilityEngineModal'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { bodysSelector, generationsSelector, modelsSelector, setBodys, setGenerations } from '../../../../../entities/applicability'
import { IBody } from '../../../../../entities/body'
import { IGeneration } from '../../../../../entities/generation'


interface IProps {
    activeModel?: number
    isEngineImportant: boolean
}

const ApplicabilityGeneration: FC<IProps> = ({ activeModel, isEngineImportant }) => {
    const models = useAppSelector(modelsSelector)
    const [isEngineModalOpen, setIsEngineModalOpen] = useState<boolean>(false)
    const checkedModel = useMemo(() => models.find(({ id }) => id === activeModel), [models, activeModel])
    const dispatch = useAppDispatch()
    const checkedBodys = useAppSelector(bodysSelector)
    const checkedModels = useAppSelector(modelsSelector)
    const checkedGenerations = useAppSelector(generationsSelector)
    const [generationId, setGenerationId] = useState<number>()
    

    const handleSelectBody = (body: IBody) => {
        const findedItem = checkedBodys.find(({ id }) => id === body.id)
        if (findedItem) {
            const arr = checkedBodys.filter(({ id }) => id !== body.id)
            dispatch(setBodys(arr))
        } else {
            dispatch(setBodys([...checkedBodys, body]))
        }
    }

    const handleSelectGeneration = (generation: IGeneration) => {
        const { bodys } = generation
        if (checkedBodys.filter(({ generationId }) => generationId === generation.id).length === bodys?.length) {
            const array = checkedBodys.filter(({ id }) => !bodys.some(body => body.id === id))
            dispatch(setBodys(array))
        } else {
            const arr = bodys?.filter((body) => !checkedBodys.some(({ id }) => id === body.id))
            if (arr) {
                dispatch(setBodys([...checkedBodys, ...arr]))
            }
        }

    }

    useEffect(() => {
          const array: IGeneration[] = []
          const generations = array.concat(...checkedModels.map(({generations})=> generations))
          const set = new Set(checkedBodys.map(({generationId})=> generationId))
          if(generations) {
                const arr = generations.filter(({id})=> set.has(id))
                dispatch(setGenerations(arr))
          }



    }, [checkedBodys])


 

    return (
        <div className={styles.root} >
                    <ApplicabilityEngineModal generationId={generationId} isModalOpen={isEngineModalOpen} setIsModalOpen={setIsEngineModalOpen}  />
            <div className={styles.inner} >
                <ul className={styles.generationsList} >
                    {
                        checkedModel?.generations?.map((generation) => {
                            const { id, title, genVersion, years, bodys } = generation
                            if (bodys && bodys.length > 0) {
                                return <li className={styles.listItem} key={id}>
                                    <Checkbox
                                        onClick={() => handleSelectGeneration(generation)}
                                        checked={bodys?.length === checkedBodys.filter(({ generationId }) => generationId === id).length}
                                        indeterminate={checkedBodys.filter(({ generationId }) => generationId === id).length > 0 && bodys?.length !== checkedBodys.filter(({ generationId }) => generationId === id).length}
                                    />

                                    <div className={styles.listItem_content} >
                                        <div className={styles.generation} >
                                            <p>{genVersion} Поколение <span>/</span></p>
                                            {title &&
                                                <p>{title} <span>/</span> </p>}
                                            <p>{years}</p>
                                        </div>
                                        <ul className={styles.bodysList} >
                                            {
                                                bodys?.map((body) => (
                                                    <li
                                                        onClick={() => handleSelectBody(body)}
                                                        className={cs(styles.bodyTag, checkedBodys.some(({ id }) => id === body.id) && styles.bodyTag__active)}
                                                        key={body.id}
                                                    >
                                                        {body.title}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    {(checkedGenerations.some((item)=> item.id === id) && isEngineImportant) && <button onClick={()=> {setIsEngineModalOpen(true); setGenerationId(id)}} className={styles.selectEngineBtn} >Выберите двигатель</button>}
                                </li>
                            }
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default ApplicabilityGeneration