import { useLocation, useNavigate } from 'react-router-dom'
import styles from './BodyPage.module.scss'
import { Button, message, Modal, Popconfirm, Table } from 'antd'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { TableColumnsType } from 'antd/lib'
import AddEngineForm from '../components/addEngineForm'
import { IEngine, useGetAllEnginesQuery } from '../../../../../entities/engine'
import { useDeleteBodyMutation, useGetOneBodyQuery } from '../../../../../entities/body'
import { MdDelete, MdEdit } from 'react-icons/md'
import EditBodyForm from '../components/editBodyForm'



const columns: TableColumnsType<IEngine> = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'Название',
    dataIndex: 'title'
  },
  {
    title: 'Тип',
    dataIndex: 'type'
  },
  {
    title: 'Объем',
    dataIndex: 'capacity',
    render: (text)=> `${text} L`
  }
  
]


export const BodyPage = () => {
  const [isAddEngineModalOpen, setIsAddEngineModalOpen] = useState<boolean>(false)
  const [isEditBodyModalOpen, setIsEditBodyModalOpen] = useState<boolean>(false)
  const location = useLocation()
  const bodyId = location.pathname.split('/')[3]
  const {data: body} = useGetOneBodyQuery(bodyId)
  const {title} = body || {}
  const {data: engines} = useGetAllEnginesQuery(bodyId)
const navigate = useNavigate()
  const [deleteBody] = useDeleteBodyMutation()
  
 const handleDeleteBody = ()=> {
        deleteBody(bodyId)
        message.success('Кузов успешно удален')
        navigate(-1)
 }

  return (
    <main className={styles.root} >
      <Modal title='Добавить двигатель' footer={false} onCancel={()=> setIsAddEngineModalOpen(false)} open={isAddEngineModalOpen} >
        <AddEngineForm setIsModalOpen={setIsAddEngineModalOpen} />
      </Modal>
      <Modal title='Редактирование кузова' footer={false} onCancel={()=> setIsEditBodyModalOpen(false)} open={isEditBodyModalOpen} >
        <EditBodyForm setIsModalOpen={setIsEditBodyModalOpen} body={body} />
      </Modal>
        <div className={styles.header} >
        <button onClick={()=>navigate(-1)} className={styles.returnBtn} ><IoIosArrowBack /></button>
          <h1>Кузов {title}</h1>
          <div className={styles.header_buttonsHolder} >
          <Button onClick={()=> setIsEditBodyModalOpen(true)} size='large' type='primary' icon={<MdEdit />} ></Button>
          <Popconfirm onConfirm={handleDeleteBody} title='Удаление кузова' description='Вы действительно хотите удалить кузов?' ><Button size='large' danger type='primary' icon={<MdDelete />} ></Button></Popconfirm>
        </div>
        </div>
        <div className={styles.content} >
          <div className={styles.contentHeader} >
          <div className={styles.tableTitle}  >
            <h2>Двигатели</h2>
          </div>
            <Button onClick={()=> setIsAddEngineModalOpen(true)} type='primary' >Добавить двигатель</Button>
          </div>
          <div className={styles.tableHolder} >
            <Table 
            columns={columns}
            dataSource={engines?.map((item)=> ({...item, key: item.id}))}
            onRow={(item)=> ({onClick:()=> navigate(`/applicability/engine/${item.id}`)})}
            />
          </div>
        </div>
    </main>
  )
}
