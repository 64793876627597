import { useRef, useState } from 'react';
import styles from './AddClientModal.module.scss'
import { Button, message, Modal, notification, NotificationArgsProps, Switch } from 'antd';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateClientDto, useCreateClientMutation } from '../../../../../../entities/client';
import PhysicalPersonFormPart from '../physicalPersonFormPart';
import LegalPersonFormPart from '../legalPersonFormPart';




type NotificationPlacement = NotificationArgsProps['placement'];


const AddClientModal = () => {
  const [api, notificationContextHolder] = notification.useNotification();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLegalPerson, setIsLegalPerson] = useState<boolean>(false)
  const [createClient] = useCreateClientMutation()
  const formRef = useRef<HTMLFormElement>(null)
  const { control, handleSubmit, reset, watch } = useForm<CreateClientDto>({
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      note: '',
      comment: '',
      organization: '',
      legalAddress: '',
      actualAddress: '',
      inn: '',
      kpp: '',
      bankName: '',
      model: '',
      vin: '',
      bic: '',
      checkingAccount: '',
      correspondentAccount: ''
    }
  })

  const info = () => {
    messageApi.success('Запись успешно сохранена!');
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.warning({
      message: 'Не удалось сохранить',
      description:
        'Не заполнено ни одно из обязательных полей. Укажите имя клиента или телефон, или электронную почту.',
      placement,
    });
  };

  const onSubmit: SubmitHandler<CreateClientDto> = async (data) => {

    await createClient({ ...data, isLegalPerson })
    setIsModalOpen(false)
  }


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const { name, phone, email } = watch()
    if (name || phone || email) {
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
      reset()
      info()
      setIsLegalPerson(false);
      setIsModalOpen(false);
    } else {
      openNotification('top')
    }


  };

  const handleCancel = () => {
    setIsLegalPerson(false);
    setIsModalOpen(false);
  };



  return (
    <>
      {notificationContextHolder}
      {messageContextHolder}
      <Button onClick={showModal} type='primary' >Добавить клиента</Button>
      <Modal width={800} className={styles.root} title="Создание клиента" okText={'Создать клиента'} cancelText={'Отменить'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className={styles.content} >
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={styles.form} action="">
            <div className={styles.physicalPerson} >
              <h3 className={styles.physicalPerson_title} >Основная информация</h3>
              <PhysicalPersonFormPart control={control} />
            </div>
            <div className={styles.legalPerson} >
              <div className={styles.legalPerson_header} >
                <h3 className={styles.legalPerson_title} >Данные юридического лица</h3>

                <Switch value={isLegalPerson} onChange={(value) => setIsLegalPerson(value)} />
              </div>
              {isLegalPerson && <LegalPersonFormPart control={control} />}
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default AddClientModal