import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styles from './NewProductModal.module.scss'
import { Button, Input, InputNumber, message, Modal, notification, Radio, Select, Space } from 'antd'
import { FC, useRef, useState } from 'react'
import { IoIosArrowRoundDown } from 'react-icons/io'
import cs from 'classnames'
import { useLocation } from 'react-router-dom'
import NewProductFormOptions from '../optionsFormPart'
import { CreateProductDto, useCreateProductMutation } from '../../../../../../../entities/product'
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/hooks/reduxToolkit'
import { bodysSelector, enginesSelector, generationsSelector, marksSelector, modelsSelector, resetApplicabity } from '../../../../../../../entities/applicability'
import { GalleryModal } from '../../../../../../../widgets/galleryModal'
import { IImage } from '../../../../../../../entities/image'







interface IProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const NewProductModal: FC<IProps> = ({isModalOpen, setIsModalOpen}) => {
  const location = useLocation()
  const incomeId = location.pathname.split('/')[2]
  const [showFullForm, setShowFullForm] = useState<boolean>(false)
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false)
  const formRef = useRef<HTMLFormElement>(null)
  const [createProduct] = useCreateProductMutation()
  const [messageApi, contextHolder] = message.useMessage();
  const [checkedImages, setCheckedImages] = useState<IImage[]>([])
  const checkedMarks = useAppSelector(marksSelector)
  const checkedModels = useAppSelector(modelsSelector)
  const checkedGenerations = useAppSelector(generationsSelector)
  const checkedBodys = useAppSelector(bodysSelector)
  const checkedEngines = useAppSelector(enginesSelector)
  const dispatch = useAppDispatch()
  const [isValid, setIsValid] = useState<boolean>(true)
  const {control, watch, setValue, handleSubmit, reset} = useForm<CreateProductDto>({
    defaultValues: {
      mfr_number: '',
      manufacturer: undefined,
      cross_number: '',
      char_fb: '',
      char_lr: '',
      char_part: undefined,
      char_marking: '',
      char_color: '',
      char_price: undefined,
      char_note: '',
      char_comment: '',
      char_carsrc_mark: '',
      char_carsrc_model: '',
      char_carsrc_body: '',
      char_carsrc_engine: '',
      char_carsrc_year: ''

    }
  })

  const onSubmit: SubmitHandler<CreateProductDto> = async (data) => {
    if(watch('char_part')) {
      const markIds = checkedMarks.map(({id})=> id)
      const modelIds = checkedModels.map(({id})=> id)
      const generationIds = checkedGenerations.map(({id})=> id)
      const bodyIds = checkedBodys.map(({id})=> id)
      const engineIds = checkedEngines.map(({id})=> id)
      const res = await createProduct({...data, incomeId: +incomeId, images: checkedImages.map(({id})=> id), markIds, modelIds, generationIds, bodyIds, engineIds})
      if('data' in res) {
        reset()
        setCheckedImages([])
        messageApi.success('Продукт успешно добавлен')
        dispatch(resetApplicabity())
        setIsModalOpen(false)
      } else if('error' in res) {
        message.error('Ошибка сервера')
      }
    } else {
      setIsValid(false)
      notification.warning({placement: 'bottom', message: 'Не удалось сохранить', description: 'Заполните обязательные поля'})
    }

}




  

  return (
 
         <Modal footer={false} centered={true} className={styles.root} title="Добавление нового товара" width='100vw' open={isModalOpen} onCancel={()=> setIsModalOpen(false)}>
   {contextHolder}
    <div className={styles.root} >
    
        <GalleryModal isModalOpen={isGalleryOpen} setIsModalOpen={setIsGalleryOpen} checkedImages={checkedImages} setCheckedImages={setCheckedImages} />
     
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={styles.form} >
      <div className={cs(styles.inner, showFullForm && styles.inner__full)} >
        <div className={styles.mainParams} >
          <Space.Compact className={styles.mainParams_manufacturerHolder} size='large' >
            <div className={styles.mainParams_formItem} >
              <Controller
              name='mfr_number'
              control={control}
              render={({field})=> (
                <Input  size='large' placeholder='Номер производителя' {...field} />
              )}
              />
            </div>
            <div className={styles.mainParams_formItem} >
              <Controller
              name='manufacturer'
              control={control}
              render={({field})=> (
                <Select
                size='large'
                showSearch
                placeholder="Производитель"
                optionFilterProp="label"
                {...field}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={[
                  {
                    value: 'AUTOWELT',
                    label: 'AUTOWELT',
                  },
                  {
                    value: 'BRAVE',
                    label: 'BRAVE',
                  }
                ]}
              />
              )}
              />
            </div>
          </Space.Compact>
          <div className={styles.mainParam_crossNumbersHolder} >
            <div className={styles.mainParams_formItem} >
              <Controller
              name='cross_number'
              control={control}
              render={({field})=> (
                <Input size='large' placeholder='Кросс-номера' {...field} />
              )}
              />
            </div>
          </div>
        </div>
        <div className={styles.secondaryParams} >
          <h3 className={styles.secondaryParams_title} >Описание новой запчасти</h3>
          <div className={styles.secondaryParams_content} >
            <div className={styles.secondaryParams_itemsRow} >
              <div className={styles.secondaryParams_formItem} >
              <Controller
              name='char_fb'
              control={control}
              render={({field})=> (
                <Radio.Group  size='large' {...field}  buttonStyle='solid' >
                  <Radio.Button  onClick={()=> watch('char_fb') === 'Передний' && setValue('char_fb', '')} value='Передний' >Передний</Radio.Button>
                  <Radio.Button  onClick={()=> watch('char_fb') === 'Задний' && setValue('char_fb', '')} value='Задний' >Задний</Radio.Button>
                </Radio.Group>
              )}
              />
              </div>
              <div className={styles.secondaryParams_formItem} >
              <Controller
              name='char_lr'
              control={control}
              render={({field})=> (
                <Radio.Group size='large' {...field} buttonStyle='solid' >
                  <Radio.Button onClick={()=> watch('char_lr') === 'Левый' && setValue('char_lr', '')} value='Левый' >Левый</Radio.Button>
                  <Radio.Button  onClick={()=> watch('char_lr') === 'Правый' && setValue('char_lr', '')} value='Правый' >Правый</Radio.Button>
                </Radio.Group>
              )}
              />
              </div>
              <div className={styles.secondaryParams_formItem} >
                <Button onClick={()=> setIsGalleryOpen(true)} htmlType='button' className={styles.secondaryParams_imgUpload} >{checkedImages.length > 0 ? `${checkedImages.length} фотографий`: 'Добавить фотографии'}</Button>
              </div>
            </div>
            <div className={styles.secondaryParams_itemsRow} >
            <div className={cs(styles.secondaryParams_formItem, styles.secondaryParams_formItemLong)} >
              <Controller
              name='char_part'
              control={control}
              render={({field})=> (
                <Select
                status={!isValid && !field.value ? 'error' : undefined}
                size='large'
                showSearch
                placeholder="Запчасть"
                optionFilterProp="label"
                {...field}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={[
                  {
                    value: 'Blow off',
                    label: 'Blow off',
                  },
                  {
                    value: 'CD-Чейнджер',
                    label: 'CD-Чейнджер',
                  }
                ]}
              />
              )}
              />
              </div>
              <div className={styles.secondaryParams_formItem} >
              <Controller
              name='char_marking'
              control={control}
              render={({field})=> (
                <Input size='large' {...field} placeholder='Маркировка' />
              )}
              />
              </div>
              <div className={styles.secondaryParams_formItem} >
              <Controller
              name='char_color'
              control={control}
              render={({field})=> (
                <Input size='large' {...field} placeholder='Цвет' />
              )}
              />
              </div>
            </div>
            <div className={styles.secondaryParams_itemsRow} >
            <div className={cs(styles.secondaryParams_formItem, styles.secondaryParams_formItemLong)} >
              <Controller
              name='char_note'
              control={control}
              render={({field})=> (
                <Input size='large' {...field} placeholder='Заметка (для вас)' />
              )}
              />
              </div>
              <div className={styles.secondaryParams_formItem} >
              <Controller
              name='char_price'
              control={control}
              render={({field})=> (
                <InputNumber {...field} controls={false} size='large'  onChange={(value)=> field.onChange(value)} placeholder='Цена в рознице' />
              )}
              />
              </div>
            </div>
            <div className={styles.secondaryParams_itemsRow} >
            <div className={cs(styles.secondaryParams_formItem, styles.secondaryParams_formItemLong)} >
              <Controller
              name='char_comment'
              control={control}
              render={({field})=> (
                <Input size='large' {...field}  placeholder='Комментарий для клиентов' />
              )}
              />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.options} >
          <NewProductFormOptions control={control} />
        </div>
    </div>
       <button onClick={()=> setShowFullForm((prev)=> !prev)} type='button' className={cs(styles.showFormBtn, showFullForm && styles.showFormBtn__active)} ><IoIosArrowRoundDown /> Развернуть</button>
    </form>
    </div>
       </Modal>
  
  )
}

