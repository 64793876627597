import styles from './TyresForm.module.scss'
import { FC, useState } from 'react'
import { SizesFormPart } from '../sizesFormPart'
import TypesFormPart from '../typesFormPart'
import { SpeedIndexFormPart } from '../speesIndexFormPart'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { IWheelsFormValues } from '../../../../interfaces/wheelsFormValues'



interface IProps {
  control: Control<IWheelsFormValues, any>
  watch: UseFormWatch<IWheelsFormValues>
  isDifferentWidth: boolean
  setIsDifferentWidth: React.Dispatch<React.SetStateAction<boolean>>
  setValue: UseFormSetValue<IWheelsFormValues>
  setUniqueOptions: React.Dispatch<React.SetStateAction<{
    id: number;
    images?: number[];
    char_tireWearout?: number;
    price?: number;
    char_comment?: string;
    char_note?: string;
  }[]>>
  isNotValid: boolean
}

export const TyresFormPart: FC<IProps> = ({ control, watch, isDifferentWidth, setIsDifferentWidth, setValue, setUniqueOptions, isNotValid }) => {
  const [sizesTemplate, setSizesTemplate] = useState<number>(1)
  return (
    <div>
      <TypesFormPart  sizesTemplate={sizesTemplate} setValue={setValue} setSizesTemplate={setSizesTemplate} watch={watch} control={control} isDifferentWith={isDifferentWidth} setIsDifferentWidth={setIsDifferentWidth} />
      <SizesFormPart isNotValid={isNotValid} setSizesTemplate={setSizesTemplate} sizesTemplate={sizesTemplate} setValue={setValue} isDifferentWidth={isDifferentWidth} watch={watch} control={control} />
      <SpeedIndexFormPart control={control} />
    </div>
  )
}
