import { Checkbox, Modal } from 'antd'
import styles from './ApplicabilityEngineModal.module.scss'
import { FC, useEffect, useState } from 'react'
import Search from 'antd/es/input/Search'
import { FaGasPump } from "react-icons/fa";
import { FaDroplet } from "react-icons/fa6";
import { MdElectricBolt } from 'react-icons/md'
import { GiGasStove } from "react-icons/gi";
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit';
import { IEngine } from '../../../../../entities/engine';
import { bodysSelector, enginesSelector, setEngines } from '../../../../../entities/applicability';


interface IProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    generationId?: number
}


const ApplicabilityEngineModal: FC<IProps> = ({isModalOpen, setIsModalOpen, generationId}) => {
  const checkedBodys = useAppSelector(bodysSelector)
  const [enginesList, setEnginesList] = useState<IEngine[]>([])
  const [searchParam, setSearchParam] = useState<string>('')
  const [selectedEngines, setSelectedEngines] = useState<IEngine[]>([])
  const dispatch = useAppDispatch()
  const checkedEngines = useAppSelector(enginesSelector)
    const onCancel = ()=> {
        setIsModalOpen(false)
    }

    useEffect(()=> {
      const arr: IEngine[] = []
      if(checkedBodys.length > 0 && generationId) {
        const filteredArray = checkedBodys.filter((item)=> item.generationId === generationId).map(({engines})=> engines || [] )
        if(filteredArray.length > 0) {
          const newArr = arr.concat(...filteredArray) 
          setEnginesList(newArr)
        }
      }
    }, [checkedBodys, generationId])

    const hadleEngineSelect = (checked: boolean, engine: IEngine)=> {
      if(checked) {
         setSelectedEngines([...selectedEngines, engine])
      } else {
        const arr = selectedEngines.filter(({id})=>id !== engine.id)
        setSelectedEngines(arr)
      }
       
    }


    const onOk = ()=> {
          dispatch(setEngines(selectedEngines))
          setIsModalOpen(false)
    }


    useEffect(()=> {
      setSelectedEngines(checkedEngines)
    }, [checkedEngines, isModalOpen])
    

    const typeIcon = (type: "gasoline" | "gas" | "diesel" | "electric" | "hybrid")=> {
      switch(type) {
        case 'gasoline': return <FaGasPump fill='#2e90fa' />
        case 'diesel': return <FaDroplet />
        case 'electric': return <MdElectricBolt  fill='rgb(228, 191, 45)' />
        case 'gas': return <GiGasStove fill='red' />
        case 'hybrid': return <div style={{display: 'flex', gap: '5px'}} ><FaGasPump fill='#2e90fa' /><MdElectricBolt  fill='rgb(228, 191, 45)' /></div>
        default: return <FaGasPump fill='#2e90fa' />
    }}




  return (

    <Modal onOk={onOk} title='Выберите двигатель' okText={'Выбрать'} onCancel={onCancel} className={styles.root} open={isModalOpen} >
      <div className={styles.inner} >
        <div className={styles.search} >
          <Search
          onChange={(e)=>setSearchParam(e.target.value)}
          variant='filled'
          />
        </div>
        <ul className={styles.list} >
          {
            enginesList?.filter(({title})=> title.toLowerCase().includes(searchParam.toLowerCase())).map((engine)=> {
              const {id, title, type, capacity} = engine
              return <li key={id} className={styles.listItem} >
                 <Checkbox
                checked={selectedEngines.some((engine)=> engine.id === id)}
                onChange={(e)=>hadleEngineSelect(e.target.checked, engine)}
                >{title}</Checkbox>
                <div className={styles.engineDescription} >
                  <p className={styles.engineType} >{typeIcon(type)}</p>
                  <p>{capacity.toFixed(1)}</p>
                </div>
              </li>
            })
          }
        </ul>
      </div>
    </Modal>
  )
}

export default ApplicabilityEngineModal