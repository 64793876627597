import { Control, Controller } from 'react-hook-form'
import styles from './PhysicalPersonFormPart.module.scss'
import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { CreateClientDto } from '../../../../../../entities/client'
import cs from 'classnames'
import { PatternFormat } from 'react-number-format'

const PhysicalPersonFormPart = ({control}: {control: Control<CreateClientDto, any>}) => {
  return (
    <div className={styles.physicalPerson_content} >
    <div className={cs(styles.formItem, styles.formItem_wide)} >
      <label htmlFor="name">ФИО или название</label>
      <Controller
       name='name'
       control={control}
       render={({field})=> (
          <Input size='large' id='name' placeholder='Введите имя' {...field} />
       )}
      />
    </div>
    <div className={styles.formItem} >
      <label htmlFor="phone">Телефон</label>
      <Controller
       name='phone'
       control={control}
       render={({ field: { onChange, onBlur, value, ref } })=> (
        <PatternFormat 
        customInput={Input} 
        onValueChange={(values) => {
          onChange(values.value);
        }} 
        format="+7 (###) ### ## ##" 
        value={value}
        placeholder="+7 (___) ___-__-__" 
        mask="_" 
        onBlur={onBlur}
        getInputRef={ref} /> 
       )}
      />

    </div>
    <div className={styles.formItem} >
      <label htmlFor="email">Электронная почта</label>
      <Controller
       name='email'
       control={control}
       render={({field})=> (
          <Input size='large' id='email' placeholder='myemail@gmail.com' {...field} />
       )}
      />
    </div>
    <div className={styles.formItem} >
      <label htmlFor="model">Марка/модель</label>
      <Controller
       name='model'
       control={control}
       render={({field})=> (
          <Input size='large' id='model' placeholder='Ведите марку/модель' {...field} />
       )}
      />
    </div>
    <div className={styles.formItem} >
      <label htmlFor="vin">Vin</label>
      <Controller
       name='vin'
       control={control}
       render={({field})=> (
          <Input size='large' id='vin' placeholder='Введите vin' {...field} />
       )}
      />
    </div>
    <div className={styles.formItem} >
      <label htmlFor="note">Примечание (видно только клиентам)</label>
      <Controller
       name='note'
       control={control}
       render={({field})=> (
          <TextArea id='note' {...field} placeholder='Введите текст' autoSize={{minRows: 4}} />
       )}
      />
    </div>
    <div className={styles.formItem} >
      <label htmlFor="comment">Заметка (видно только вам)</label>
      <Controller
       name='comment'
       control={control}
       render={({field})=> (
          <TextArea id='comment' {...field} placeholder='Введите текст' autoSize={{minRows: 4}} />
       )}
      />
    </div>
  </div>
  )
}

export default PhysicalPersonFormPart