import { Link, useLocation, useNavigate } from 'react-router-dom'
import styles from './SalePage.module.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, message, notification, Popconfirm } from 'antd'
import { useEffect, useState } from 'react'
import { UpdateSaleDto, useGetOneSaleQuery, useUpdateSaleMutation } from '../../../../../entities/sale'
import { GoPlus } from 'react-icons/go'
import { CatalogModal } from '../../../../../widgets/catalogModal'
import ItemsTable from '../itemsTable'
import { FcOk } from 'react-icons/fc'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { saleItemsSelector, saleMessageSelector } from '../../store/selectors'
import { setSaleMessage, setSaleSelectedItems, setSelectedSale } from '../../store/salePageSlice'
import { FaRegTrashCan } from 'react-icons/fa6'
import { useDeleteManySaleItemsMutation } from '../../../../../entities/saleItem'
import { SaleUpdateForm } from '../saleUpdateForm'




export const SalePage = () => {
    const location = useLocation()
    const saleId = location.pathname.split('/')[4]
    const { data: sale } = useGetOneSaleQuery(+saleId)
    const date = sale?.createdAt && new Date(sale?.createdAt.toString());
    const month = date?.toLocaleString("ru-RU", { month: "long" });
    const localeDate = date?.toLocaleString();
    const saleItems = sale?.saleItems || []
    const [isCatalogModalOpen, setIsCatalogModalOpen] = useState<boolean>(false)
    const [updateSale] = useUpdateSaleMutation()
    const saleMessage = useAppSelector(saleMessageSelector)
    const saleSelectedItems = useAppSelector(saleItemsSelector)
    const dispatch = useAppDispatch()
    const [quantityError, setQuantityError] = useState<boolean>(false)
    const [deleteSaleItem] = useDeleteManySaleItemsMutation()
    const navigate = useNavigate()

    const onStatusUpdate = async (status: UpdateSaleDto['status']) => {
        const isSummCorrect = (sale?.sumFull && sale.payments && status === 'issued' && sale.sumFull === sale.payments.reduce((sum, item)=> sum + (item.sum || 0), 0))
        if ( isSummCorrect || status !== 'issued' && sale && (status !== 'reserve' || status === 'reserve' && !quantityError && saleItems.length > 0)) {
            const res = await updateSale({ id: sale.id, body: { status } })
            if ('error' in res) {
                message.error('Ошибка сервера')
            }
        } 

        if (status === 'reserve' && quantityError) {
            notification.warning({
                message: 'Недостаточно товаров на складе',
                description: 'Недостаточно свободного товара для резервирования',
                placement: 'bottom'
            })
        }

        if (status === 'reserve' && !quantityError && saleItems.length < 1) {
            notification.warning({
                message: 'В документе нет товаров',
                description: 'Добавьте товары, чтобы провести документ',
                placement: 'bottom'
            })
        }

        if(!isSummCorrect && status === 'issued') {
            notification.warning({
                message: 'Заказ не оплачен',
                description: 'Внесите оплату, чтобы выдать заказ',
                placement: 'bottom'
            })
        } 

    }


    useEffect(()=> {
    dispatch(setSaleSelectedItems([]))
    }, [])


    useEffect(() => {
        if (saleMessage) {
            const timer = 1000
            setTimeout(() => dispatch(setSaleMessage(undefined)), timer)
        }
    }, [saleMessage])

    const onSaleItemsDelete = async () => {
        const res = await deleteSaleItem(saleSelectedItems.map(({id})=> id))
        if ('data' in res) {
            message.success('Товары успешно удалены')
            dispatch(setSaleSelectedItems([]))
        } else if ('error' in res) {
            message.error('Ошибка сервера')
        }
    }


   

    return (
        <main className={styles.root} >
            <CatalogModal page='sale' isModalOpen={isCatalogModalOpen} setIsModalOpen={setIsCatalogModalOpen} />
            <div className={styles.inner} >
                <div className={styles.header} >
                    <Link to='/sales' className={styles.returnLink} ><IoIosArrowBack /></Link>
                    <h1 className={styles.title} >{sale?.client?.name}</h1>
                </div>
                <div className={styles.content} >
                    <div className={styles.content_inner} >
                        <div className={styles.sale} >
                            {saleSelectedItems.length > 0 ?
                                <div className={styles.sale_header} >
                                    <h2>{`Выбрано ${saleSelectedItems.length}`}</h2>
                                    <div className={styles.sale_btnHolder} >
                                        { sale?.status !== 'issued' ? <Popconfirm onConfirm={() => onSaleItemsDelete()} okText='Да' cancelText='Нет' title='Вы действительно хотите удалить выбранные товары?' >
                                            <button className={styles.noticeButton} >
                                                <FaRegTrashCan /> Удалить из сделки
                                            </button>
                                        </Popconfirm> : 
                                     
                                            <button onClick={()=>{dispatch(setSelectedSale(sale)); navigate(`/clients/${sale?.client?.id}/returns/new`)}} className={styles.noticeButton} >
                                                Оформить возврат
                                                </button>
                                       
                                        
                                    }
                                    </div>
                                    <Button type='text' onClick={() => dispatch(setSaleSelectedItems([]))} >Отменить</Button>
                                </div>
                                :
                                <div className={styles.sale_header} >

                                    <div className={styles.sale_headerTitle} >
                                    
                                            <h2  >{`Сделка № ${sale?.id}`}</h2>
                                    {saleMessage ? <p className={styles.saleMessage} ><FcOk /><span>{saleMessage}</span></p> : 
                                    <p>{`Заявка от ${localeDate?.split(",")[0].slice(0, 2)} ${month?.slice(0, 3)} в ${localeDate?.split(",")[1].slice(0, 6)} `}
                                    </p> }
                                    </div>
                                    <div className={styles.sale_btnHolder} >
                                        {sale?.status === 'draft' && <Button onClick={() => onStatusUpdate('reserve')} type='primary' >Зарезервировать</Button>}
                                        {sale?.status === 'reserve' && <Button onClick={() => onStatusUpdate('issued')} type='primary' >Выдать</Button>}
                                        {sale?.status !== 'canceled' && <Popconfirm onConfirm={() => onStatusUpdate('canceled')} title='Вы дейстивтельно хотите отменить сделку?' okText='Да' cancelText='Нет'  >
                                        {sale?.status !== 'issued' &&  <Button type='default' >Отменить сделку</Button>}
                                        </Popconfirm>}
                                        {sale?.status === 'canceled' && <Button onClick={() => onStatusUpdate('draft')} type='primary' >Вернуть в работу</Button>}
                                    </div>
                                    <div className={styles.sale_settings} ></div>
                                </div>}

                            <div className={styles.sale_content} >
                               <SaleUpdateForm sale={sale} saleItems={saleItems} />
                                <div className={styles.sale_tableHolder} >
                                    { sale?.status !== 'issued' && <div className={styles.addProduct} > <button onClick={() => setIsCatalogModalOpen(true)} >  <GoPlus /> Добавить </button></div>}
                                    <div className={styles.sale_table} >
                                       <ItemsTable sale={sale} setQuantityError={setQuantityError} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.history} >
                            <div className={styles.history_header} >
                                <h2>История документа</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}