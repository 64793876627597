import { Control, Controller } from 'react-hook-form'
import styles from './WriteoffUpdateForm.module.scss'
import { Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC, useEffect, useState} from 'react'
import { useGetAllStoragesQuery } from '../../../../../entities/storage'
import { CreateWriteoffDto, IWriteoff } from '../../../../../entities/writeoff'




interface IProps {
  writeoff?: IWriteoff
  status?: 'draft' | 'complete' | 'canceled'
  control: Control<CreateWriteoffDto, any>
  setWriteoffMesage: React.Dispatch<React.SetStateAction<string | undefined>>
}

const WriteoffUpdateForm: FC<IProps> = ({control, status, setWriteoffMesage, writeoff}) => {
  const {data: storages} = useGetAllStoragesQuery()
  const [comment, setComment] = useState<string>()
    

  useEffect(()=> {
   setComment(writeoff?.comment)
  }, [writeoff])


  return (
    <form className={styles.root} >
    
    <div className={styles.formPart} >
        <div className={styles.formItem} >
            <label>Склад списания</label>
            <Controller
                name='storageId'
                control={control}
                render={({ field }) =>
                    <Select
                        disabled={status !== 'draft'}
                        value={field.value}
                       onChange={(value)=> {
                        field.onChange(value); setWriteoffMesage('Склад изменен')
                    }}
                options={storages?.map(({id, name})=> ({label: name, value: id})) || []} />
                }
            />
        </div>
        <div className={styles.formItem} >
                    <Controller
                    name='comment'
                    control={control}
                    render={({field})=> (
                      <TextArea
                    value={comment}
                    disabled={status === 'canceled'}
                    onChange={(e)=> setComment(e.target.value)}
                    onBlur={()=> {field.onChange(comment); setWriteoffMesage('Комментарий изменен')}}
                    autoSize={{ minRows: 3 }} placeholder='Комментарий к списанию' />
                    )}
                    />
     
        </div>
    
    
    </div>


</form>
  )
}

export default WriteoffUpdateForm