import { Navigate } from "react-router-dom"



export const HomePage = () => {

    return (
      <Navigate to='/catalog' replace={true}/>
    )

}
