import { useLocation, useNavigate } from 'react-router-dom'
import styles from './EnginePage.module.scss'
import { Button, message, Modal, Popconfirm } from 'antd'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useDeleteEngineMutation, useGetOneEngineQuery } from '../../../../../entities/engine'
import { MdDelete, MdEdit } from 'react-icons/md'
import EditEngineForm from '../components/editEngineForm'



export const EnginePage = () => {
  const [isEditEngineModalOpen, setIsEditEngineModalOpen] = useState<boolean>(false)
  const location = useLocation()
  const engineId = location.pathname.split('/')[3]
  const {data: engine} = useGetOneEngineQuery(engineId)
  const {title} = engine || {}
  const navigate = useNavigate()
  const [deleteEngine] = useDeleteEngineMutation()
  
 const handleDeleteBody = ()=> {
        deleteEngine(engineId)
        message.success('Двигатель успешно удален')
        navigate(-1)
 }

  return (
    <main className={styles.root} >
      <Modal title='Редактирование двигателя' footer={false} onCancel={()=> setIsEditEngineModalOpen(false)} open={isEditEngineModalOpen} >
        <EditEngineForm engine={engine} setIsModalOpen={setIsEditEngineModalOpen}  />
      </Modal>
        <div className={styles.header} >
        <button onClick={()=>navigate(-1)} className={styles.returnBtn} ><IoIosArrowBack /></button>
          <h1>Двигатель {title}</h1>
          <div className={styles.header_buttonsHolder} >
          <Button onClick={()=> setIsEditEngineModalOpen(true)} size='large' type='primary' icon={<MdEdit />} ></Button>
          <Popconfirm onConfirm={handleDeleteBody} title='Удаление двигателя' description='Вы действительно хотите удалить двигатель?' ><Button size='large' danger type='primary' icon={<MdDelete />} ></Button></Popconfirm>
        </div>
        </div>
    </main>
  )
}
