import styles from './AuthPage.module.scss'
import cs from 'classnames'
import { Auth } from '../../../widgets/auth/ui/root'
import { useGetMeQuery } from '../../../entities/auth'
import { Navigate } from 'react-router-dom'

export const AuthPage = () => {
  const { data: user, isLoading } = useGetMeQuery()
  if (!isLoading && !user) {
    return (
      <main className={styles.root} >
        <div className={cs(styles.inner, 'container')} >
          <Auth />
        </div>
      </main>
    )
  } else if (!isLoading && user) {
    return <Navigate to='/' replace={true} />
  } else {
    return null
  }
}
