import styles from './StoragesPage.module.scss'
import React from 'react';
import {Input, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { useNavigate } from 'react-router-dom';
import AddStorageModal from '../addStorageModal';
import { useGetAllStoragesQuery } from '../../../../../entities/storage';




const columns: TableColumnsType<any> = [

  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'Название',
    dataIndex: 'name',
  },
  {
    title: 'Адрес',
    dataIndex: 'location',
  }
];


export const StoragesPage: React.FC = () => {
  const navigate = useNavigate()
 const {data: storages} = useGetAllStoragesQuery()

  
  return (
    <main className={styles.root} >
     <div className={styles.header} >
    <h1>Склады</h1>
   
     </div>
    <div className={styles.content} >
      <div className={styles.content_header} >
        <Input className={styles.search} />
        <AddStorageModal/>
      </div>
    <Table
    className={styles.table}
        columns={columns}
        dataSource={storages?.map((item)=>({...item, key: item.id}))}  
        onRow={(item)=> ({onClick: ()=> navigate(`/storages/${item.id}`)})}
      />
    </div>
    </main>

  );
};
