import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateWriteoffDto } from "../dtos/createWriteoffDto";
import { UpdateWriteoffDto } from "../dtos/updateWiteoffDto";
import { IWriteoff, IWriteoffs } from "../interfaces/writeoff";
import qs from 'qs'


interface QueryParams {
    status: 'draft' | 'complete' | 'canceled' | 'all'
    pagination: {
        page: number
        pageSize: number
    }
}

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        createWriteoff: build.mutation<IWriteoff, CreateWriteoffDto>({
            query: (body) => ({
                url: '/writeoffs',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Writeoff']
        }),
        updateWriteoff: build.mutation<IWriteoff, { id: number, body: UpdateWriteoffDto }>({
            query: ({ id, body }) => ({
                url: `/writeoffs/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Writeoff', 'Product']
        }),
        getAllWriteoffs: build.query<IWriteoffs, QueryParams | void>({
            query: (params) => {
                const queryString = qs.stringify(params)
                return `/writeoffs?${queryString}`
            },
            providesTags: ['Writeoff']
        }),
        getOneWriteoff: build.query<IWriteoff, number>({
            query: (id) => `/writeoffs/${id}`,
            providesTags: ['Writeoff']
        }),
        deleteWriteoff: build.mutation<void, number>({
            query: (id) => ({
                url: `/writeoffs/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Writeoff']
        })
    })
})

export const { useCreateWriteoffMutation,
    useUpdateWriteoffMutation,
    useGetAllWriteoffsQuery,
    useGetOneWriteoffQuery,
    useDeleteWriteoffMutation
} = extendedApi