import { Table } from 'antd'
import styles from './ItemsTable.module.scss'
import { ColumnsType } from 'antd/es/table'
import { FC} from 'react'
import { TableRowSelection } from 'antd/es/table/interface'
import { IReturn } from '../../../../../entities/return'
import { IReturnItem } from '../../../../../entities/returnItem'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { productsSelector, setSelectedProducts } from '../../../../../entities/product'



interface IProps {
  returnData?: IReturn
}


const ItemsTable: FC<IProps> = ({returnData}) => {
  const items = returnData?.returnItems
  const selectedProducts = useAppSelector(productsSelector)
  const dispatch = useAppDispatch()

  const columns: ColumnsType<IReturnItem> = [
    {
      title: 'Номер',
      render: (_,  item) => item?.id
    },
    {
      title: 'Наименование',
      render: (_, item)=> item?.saleItem?.product?.char_part
    },
    {
      title: 'Кол-во',
      render: (_, item)=> item?.quantity
    },
    {
      title: 'Цена',
      render: (_, item)=> item?.saleItem?.discountedPrice || item?.saleItem?.product?.char_price || 0
    }
  
  ]


  const rowSelection: TableRowSelection<IReturnItem> = {
    type: 'checkbox',
    selectedRowKeys: selectedProducts.map(({product})=> product?.id),
    onChange: (_, items) => dispatch(dispatch(setSelectedProducts(items.map((item)=> ({product: item?.saleItem?.product, quantity: 1})))))
  }

  return (
    <Table
      className={styles.root}
      columns={columns}
      rowKey={(item) => item?.saleItem?.product?.id}
      rowSelection={rowSelection}
      dataSource={items}
    
    />
  )
}

export default ItemsTable