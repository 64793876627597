import { useState } from 'react'
import styles from './Auth.module.scss'
import SignInForm from '../components/signInForm'
import SignUpForm from '../components/signUpForm'



export const Auth = () => {
  const [isSignIn, setIsSignIn] = useState<boolean>(true)
  return (
    <div className={styles.root} >
      {isSignIn ? <SignInForm /> : <SignUpForm />}
      {
        isSignIn ?
          <div className={styles.changeForm} >Нет аккаунта? <span onClick={() => setIsSignIn(!isSignIn)} >Зарегистрироваться</span></div>
          :
          <div className={styles.changeForm} >Есть аккаунта? <span onClick={() => setIsSignIn(!isSignIn)} >Войти в систему</span></div>
      }
    </div>
  )
}
