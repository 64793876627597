import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import styles from "./TypesFormPart.module.scss";
import { Radio, Segmented, Space, Switch } from "antd";
import { FC } from "react";
import { IWheelsFormValues } from "../../../../interfaces/wheelsFormValues";

interface IProps {
  control: Control<IWheelsFormValues, any>;
  isDifferentWith: boolean;
  watch: UseFormWatch<IWheelsFormValues>;
  setIsDifferentWidth: React.Dispatch<React.SetStateAction<boolean>>;
  setSizesTemplate: React.Dispatch<React.SetStateAction<number>>;
  setValue: UseFormSetValue<IWheelsFormValues>;
  sizesTemplate: number;
}

const TypesFormPart: FC<IProps> = ({
  control,
  isDifferentWith,
  setIsDifferentWidth,
  watch,
  setSizesTemplate,
  setValue,
  sizesTemplate,
}) => {
  const onTemplateSelect = (value: number) => {
    setSizesTemplate(value);
  };

  return (
    <>
      <div className={styles.type}>
        <div className={styles.rowWrapper}>
          <div className={styles.title}>Шина</div>
          <div className={styles.formItem}>
            <Controller
              name="char_tireVehicleType"
              control={control}
              render={({ field }) => (
                <Segmented
                  onChange={(value) => field.onChange(value)}
                  value={watch("char_tireVehicleType")}
                  size="small"
                  options={["Легковая", "Грузовая", "Спецтехника"]}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.width}>
          <Switch
            disabled={watch("char_wheelSetNumber") < 4}
            id="differentWidthSwitch"
            value={isDifferentWith}
            onChange={(value) => setIsDifferentWidth(value)}
            size="small"
          />
          <label htmlFor="differentWidthSwitch">Разноширокие</label>
        </div>
      </div>
      {watch("char_tireVehicleType") === "Легковая" && (
        <div className={styles.rowWrapper}>
          <div className={styles.formItem}>
            <Controller
              name="char_tireProtectorType"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} size="large" buttonStyle="solid">
                  <Radio.Button value="Стандартная">Стандартная</Radio.Button>
                  <Radio.Button value="Грязевая (АТ)">Грязевая (АТ)</Radio.Button>
                  <Radio.Button value="Грязевая (МТ)">Грязевая (МТ)</Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
          <div className={styles.switchHolder}>
            <Controller
              name="char_tireLT"
              control={control}
              render={({ field }) => (
                <Switch
                  disabled={watch("char_tireProtectorType") !== "Стандартная"}
                  id="isLightTruckSwicth"
                  {...field}
                />
              )}
            />
            <label htmlFor="isLightTruckSwicth"> Легкогрузовая (LT)</label>
          </div>
          <div className={styles.switchHolder}>
            <Controller
              name="char_tireRunFlat"
              control={control}
              render={({ field }) => (
                <Switch
                  disabled={watch("char_tireProtectorType") !== "Стандартная"}
                  id="isRunflatSwitch"
                  {...field}
                />
              )}
            />
            <label htmlFor="isRunflatSwitch">Runflat</label>
          </div>
        </div>
      )}
      {watch("char_tireVehicleType") === "Легковая" && (
        <div className={styles.rowWrapper}>
          <div className={styles.formItem}>
            <Controller
              name="char_tireSeason"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} size="large" buttonStyle="solid">
                  <Radio.Button
                    disabled={
                      field.value === "Всесезонная" ||
                      watch("char_tireProtectorType") !== "Стандартная"
                    }
                    value="Летняя"
                  >
                    Летняя
                  </Radio.Button>
                  <Radio.Button
                    disabled={
                      field.value === "Всесезонная" ||
                      watch("char_tireProtectorType") !== "Стандартная"
                    }
                    value="Зимняя (липучка)"
                  >
                    Зимняя (липучка)
                  </Radio.Button>
                  <Radio.Button
                    disabled={
                      field.value === "Всесезонная" ||
                      watch("char_tireProtectorType") !== "Стандартная"
                    }
                    value="Зимняя с шипами"
                  >
                    Зимняя с шипами
                  </Radio.Button>
                </Radio.Group>
              )}
            />
          </div>
          <div className={styles.switchHolder}>
            <Controller
              name="char_tireSeason"
              control={control}
              render={({ field }) => (
                <Switch
                  disabled={watch("char_tireProtectorType") !== "Стандартная"}
                  id="isAllSeasonSwitch"
                  checked={field.value === "Всесезонная"}
                  onChange={(value) => field.onChange(value ? "Всесезонная" : "Летняя")}
                />
              )}
            />

            <label htmlFor="isAllSeasonSwitch"> Всесезонная</label>
          </div>
        </div>
      )}

      {(watch("char_tireVehicleType") === "Грузовая" ||
        watch("char_tireVehicleType") === "Спецтехника") && (
        <div className={styles.rowWrapper}>
          <Space.Compact>
            <div className={styles.formItem}>
              <Radio.Group
                value={sizesTemplate}
                onChange={(e) => onTemplateSelect(e.target.value)}
                defaultValue={1}
                size="large"
                buttonStyle="solid"
              >
                <Radio.Button value={1}>12.00R16</Radio.Button>
                <Radio.Button value={2}>340/55R16</Radio.Button>
                <Radio.Button value={3}>13.0/55R16</Radio.Button>
                <Radio.Button value={4}>1200x500R508</Radio.Button>
              </Radio.Group>
            </div>
          </Space.Compact>
          {watch("char_tireVehicleType") === "Грузовая" && (
            <div className={styles.switchHolder}>
              <Controller
                name="char_tireLT"
                control={control}
                render={({ field }) => (
                  <Switch
                    disabled={watch("char_tireProtectorType") !== "Стандартная"}
                    id="isLightTruckSwicth"
                    {...field}
                  />
                )}
              />
              <label htmlFor="isLightTruckSwicth"> Легкогрузовая (LT)</label>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TypesFormPart;
