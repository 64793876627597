import { Table, TableColumnsType } from 'antd'
import styles from './ShipmentsTable.module.scss'
import { IShipment, useGetAllShipmentQuery } from '../../../../../../entities/shipment'
import { FC } from 'react';



const columns: TableColumnsType<IShipment> = [

  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    render: (text)=> text.slice(0,10).split('-').reverse().join('-')
  },
  {
    title: 'Дата изменения',
    dataIndex: 'updatedAt',
    render: (text)=> text.slice(0,10).split('-').reverse().join('-')
  },

  {
    title: 'Название',
    dataIndex: 'title',
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    render: (text)=> <p style={{maxWidth: '200px', overflow: 'hidden', textWrap: 'nowrap', textOverflow: 'ellipsis'}} >{text}</p>
  }

];

interface IProps {
  setEditShipmentId: React.Dispatch<React.SetStateAction<number | undefined>>
  setEditShipmentModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ShipmentsTable: FC<IProps> = ({setEditShipmentModalOpen, setEditShipmentId}) => {
  const {data: shipments} = useGetAllShipmentQuery()
  return (
      <Table
      className={styles.root}
          columns={columns}
          dataSource={shipments?.map((item)=>({...item, key: item.id}))}
          pagination={{defaultPageSize: 15, showSizeChanger: true}}
          onRow={(item)=> ({onClick: ()=>{ setEditShipmentModalOpen(true); setEditShipmentId(item.id)}})}
        />
  )
}

export default ShipmentsTable