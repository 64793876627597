import { Button, Input, Modal, Space } from 'antd'
import styles from './FormOptions.module.scss'
import { Controller } from 'react-hook-form'
import { FC, useState } from 'react'
import { useAppSelector } from '../../../../../../../shared/hooks/reduxToolkit'
import { marksSelector, modelsSelector } from '../../../../../../../entities/applicability'
import { ApplicabilityModal } from '../../../../../../../widgets/applicabilityModal'




interface IProps {
  control: any
}

const FormOptions: FC<IProps> = ({control}) => {
   const [isApplycabilityModalOpen, setIsApplycabilityModalOpen] = useState<boolean>(false)
   const checkedMarks = useAppSelector(marksSelector)
   const checkedModels = useAppSelector(modelsSelector)

 

  return (
    <div className={styles.root} >

        <ApplicabilityModal  isModalOpen={isApplycabilityModalOpen}  setIsModalOpen={setIsApplycabilityModalOpen} />

      <div className={styles.forAds} >
        <h3 className={styles.title} >Для составления обьявлений</h3>
      <Space.Compact  size='large' >
            <div className={styles.formItem} >
              <Controller
              name='char_carsrc_mark'
              control={control}
              render={({field})=> (
                <Input   placeholder='Марка' {...field} />
              )}
              />
            </div>
            <div className={styles.formItem} >
              <Controller
              name='char_carsrc_model'
              control={control}
              render={({field})=> (
                <Input  placeholder='Модель' {...field} />
              )}
              />
            </div>
          </Space.Compact>
          <Space.Compact size='large' >
            <div className={styles.formItem} >
              <Controller
              name='char_carsrc_body'
              control={control}
              render={({field})=> (
                <Input   placeholder='Кузов' {...field} />
              )}
              />
            </div>
            <div className={styles.formItem} >
              <Controller
              name='char_carsrc_engine'
              control={control}
              render={({field})=> (
                <Input  placeholder='Двигатель' {...field} />
              )}
              />
            </div>
            <div className={styles.formItem} >
              <Controller
              name='char_carsrc_year'
              control={control}
              render={({field})=> (
                <Input  placeholder='Год' {...field} />
              )}
              />
            </div>
          </Space.Compact>
      </div>
      <div className={styles.applicability} >
             {checkedMarks.length > 0 ? <div style={{display: 'flex'}} >{checkedMarks.length} марки {checkedModels.length > 0 && <div>, {checkedModels.length} модели</div>}  </div> : <h3>Применимость не задана</h3>}
             <Button onClick={()=> setIsApplycabilityModalOpen(true)} className={styles.applicability_btn} >{ checkedMarks.length > 0 ? 'Посмотреть / Изменить' : 'Задать применимость'}</Button>
      </div>
      <Button htmlType='submit' className={styles.submitBtn}  size='large' type='primary' >Создать запчасть</Button>
    </div>
  )
}

export default FormOptions