import { Table, TableColumnsType } from 'antd'
import styles from './SalesTable.module.scss'
import { useNavigate } from 'react-router-dom';
import { FC, useState } from 'react';
import { ISale, useGetAllSalesQuery } from '../../../../../entities/sale';



const columns: TableColumnsType<ISale> = [
  {
    title: 'Номер',
    dataIndex: 'id',
  },
  {
    title: 'Дата',
    width: 100,
    render: (_, item)=> {
      const date = item.createdAt && new Date(item.createdAt.toString());
      const day = date?.toLocaleString("ru-Ru", {day: 'numeric'})
      const month = date?.toLocaleString("ru-Ru", { month: "long" });
      const year = date?.toLocaleString("ru-Ru", {year: 'numeric'})
      return <div className={styles.date} >{`${day} ${month?.slice(0, 3)} ${year?.slice(2)}`}</div>
    
    }
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    render: (text)=> <div className={styles.client} >{text?.name}</div>
  },
  {
    title: 'Склад Выдачи',
    dataIndex: 'storage',
    render: (text)=> <div className={styles.storage} >{text?.name}</div>
  },
  {
    title: 'Комментарий к доставке',
    dataIndex: 'deliveryComment',
    render: (text)=> <div className={styles.comment} >{text}</div>
  },
  {
    title: 'Сумма',
    dataIndex: 'sumFull',
    render: (text)=> text
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (item)=> {
         switch(item) {
        case 'draft': return 'Новый'
        case 'reserve': return 'В работе'
        case 'issued': return 'Выдан'
        case 'canceled': return 'Отменен'
      }
    }
  },
  {
    title: 'Комментарий к сделке',
    dataIndex: 'managerComment',
    render: (text)=> <div className={styles.comment} >{text}</div>
  },
];


interface IProps  {
    status: "draft" | "reserve" | "issued" | "canceled" | "all"
}

const SalesTable: FC<IProps> = ({status}) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data: salesData, isLoading} = useGetAllSalesQuery({params: {status, pagination: {page, pageSize}}})
  const pagination = salesData?.meta?.pagination
  const sales = salesData?.data
  const navigate = useNavigate()
  
   const onPageChange = (page: number, pageSize: number)=> {
    setPage(page)
    setPageSize(pageSize)
   }

  return (
   
    <Table
    loading={isLoading}
    className={styles.table}
        columns={columns}
        dataSource={sales?.map((item)=>({...item, key: item.id}))}  
        pagination={{defaultPageSize: 10, showSizeChanger: true, total: pagination?.total, pageSize, current: page, onChange: onPageChange}}
        onRow={(item)=> ({onClick: ()=> navigate(`/clients/${item?.client?.id}/sales/${item.id}`)})}
      />
  )
}

export default SalesTable