import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateEngineDto } from "../dtos/createEngineDto";
import { UpdateEngineDto } from "../dtos/updateEngineDto";
import { IEngine } from "../interfaces/engine";






const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        getOneEngine: build.query<IEngine, string>({
            query: (id)=> `/engines/${id}`,
            providesTags: ['Engine']
        }),
        getAllEngines: build.query<IEngine[], string>({
            query: (bodyId)=> `/engines?bodyId=${bodyId}`,
            providesTags: ['Engine']
        }),
        createEngine: build.mutation<IEngine, CreateEngineDto>({
            query: (body)=> ({
                url: '/engines',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Engine']
        }),
        updateEngine: build.mutation<IEngine, {id: number, body: UpdateEngineDto}>({
            query: ({id, body})=> ({
                url: `/engines/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Engine']
        }),
        deleteEngine: build.mutation<void, string>({
            query: (id)=> ({
                url: `/engines/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Engine']
        })
    })
})


export const {useGetOneEngineQuery, useGetAllEnginesQuery, useLazyGetAllEnginesQuery, useCreateEngineMutation, useUpdateEngineMutation, useDeleteEngineMutation} = extendedApi