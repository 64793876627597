import { FC, useEffect, useState } from 'react'
import styles from './ApplicabilityModelModal.module.scss'
import { Checkbox, Modal } from 'antd'
import Search from 'antd/es/input/Search'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { marksSelector, modelsSelector, setModels } from '../../../../../entities/applicability'
import { IModel } from '../../../../../entities/model'



interface IProps {
    activeMark?: number
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    isModalOpen: boolean
}

const ApplicabilityModelModal: FC<IProps> = ({ activeMark, setIsModalOpen, isModalOpen }) => {
    const [searchParam, setSearchParam] = useState<string>('')
    const marks = useAppSelector(marksSelector)
    const [selectedModels, setSelectedModels] = useState<IModel[]>([])
    const dispatch = useAppDispatch()
    const models = marks.find(({ id }) => id === activeMark)?.models
    const checkedModels = useAppSelector(modelsSelector)


    const onCheck = (model: IModel) => {
        if (model) {
            const findedItem = selectedModels.find(({ id }) => model.id === id)
            if (findedItem) {
                const arr = selectedModels.filter(({ id }) => id !== model.id)
                setSelectedModels(arr)
            } else {
                setSelectedModels((prev) => [...prev, model])
            }
        }
    }

    const onOk = () => {
        if (selectedModels.length > 0) {
            dispatch(setModels(selectedModels))
            setIsModalOpen(false)
        }
    }

    const onCancel = () => {
        setSelectedModels([])
        setIsModalOpen(false)
    }

    useEffect(() => {
        setSelectedModels(checkedModels)
    }, [checkedModels, isModalOpen])


    return (
        <Modal className={styles.root} onOk={onOk} okText='Добавить' title='Выберите модель' centered width='50vw' onCancel={onCancel} open={isModalOpen} >
            <div className={styles.inner} >
                <div className={styles.search} >
                    <Search onChange={(e) => setSearchParam(e.target.value)} size='large' variant='filled' placeholder='Поиск по модели' />
                </div>
                <ul className={styles.modelsList} >
                    {
                        models?.filter(({ title }) => title.toLowerCase().includes(searchParam.toLowerCase())).map((item) => (
                            <li key={item.id} ><Checkbox checked={selectedModels.some(({ id }) => id === item.id)} onChange={() => onCheck(item)} >{item.title}</Checkbox> </li>
                        ))
                    }
                </ul>
            </div>
        </Modal>
    )
}

export default ApplicabilityModelModal