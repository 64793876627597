import { Link, useNavigate } from 'react-router-dom'
import styles from './AddSalePage.module.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, message, Select } from 'antd'
import { useEffect, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import { useGetAllClientsQuery } from '../../../../../entities/client'
import { CreateSaleDto, useCreateSaleMutation } from '../../../../../entities/sale'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { CatalogModal } from '../../../../../widgets/catalogModal'
import { useGetAllStoragesQuery } from '../../../../../entities/storage'


export const AddSalePage = () => {
    const [isCommentVisible, setIsCommentVisible] = useState<boolean>(false)
    const [isCatalogModalOpen, setIsCatalogModalOpen] = useState<boolean>(false)
    const { data: clients } = useGetAllClientsQuery()
    const navigate = useNavigate()
    const [createSale] = useCreateSaleMutation()
    const {data: storages} = useGetAllStoragesQuery()
    const { control, handleSubmit, watch, setValue } = useForm<CreateSaleDto>({
        defaultValues: {
            clientId: undefined,
            managerComment: undefined,
            deliveryComment: undefined,
            storageId: undefined,
        }
    })


    const onSubmit: SubmitHandler<CreateSaleDto> = async (data) => {
        const res = await createSale(data)
        if ('data' in res) {
            navigate(`/clients/${data?.clientId}/sales/${res?.data?.id}`)
        } else if ('error' in res) {
            message.error('Ошибка сервера')
        }
    }

    useEffect(() => {
        setValue('clientId', clients?.data[0]?.id)
    }, [clients])

    useEffect(()=> {
         setValue('storageId', storages?.[0]?.id)
    }, [storages])

    return (
        <main className={styles.root} >
            <CatalogModal page='sale' isModalOpen={isCatalogModalOpen} setIsModalOpen={setIsCatalogModalOpen} />
            <div className={styles.inner} >
                <div className={styles.header} >
                    <Link to='/sales' className={styles.returnLink} ><IoIosArrowBack /></Link>
                    <h1 className={styles.title} >{clients?.data[0]?.name}</h1>
                </div>
                <div className={styles.content} >
                    <div className={styles.content_inner} >
                        <div className={styles.sale} >
                            <div className={styles.sale_header} >
                                <h2>Новая сделка</h2>
                                <div className={styles.sale_btnHolder} >
                                    <Button onClick={handleSubmit(onSubmit)} type='primary' >Создать</Button>
                                    <Button onClick={() => navigate('/sales')} type='default' >Отменить</Button>
                                </div>
                                <div className={styles.sale_settings} ></div>
                            </div>
                            <div className={styles.sale_content} >
                                <div className={styles.leftBlock} >
                                    <div className={styles.leftBlock_formPart} >
                                        <div className={styles.countersHolder} >
                                            <div className={styles.counter} >
                                                <p className={styles.counterTitle} >Сумма сделки</p>
                                                <p className={styles.counterCount} >0</p>
                                            </div>
                                            <div className={styles.counter} >
                                                <p className={styles.counterTitle} >Оплачено</p>
                                                <p className={styles.counterCount} >0</p>
                                            </div>
                                        </div>
                                        <div className={styles.formItem} >
                                            <Controller
                                                name='clientId'
                                                control={control}
                                                render={({ field }) => (
                                                    <Select  {...field} options={clients?.data?.map(({ id, name }) => ({ label: name, value: id })) || []} placeholder='Выберите клиента' className={styles.formItem_select}
                                                        optionFilterProp="label"
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={styles.formItem} >
                                            <Controller
                                                name='managerComment'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextArea {...field} autoSize={{ minRows: 3 }} placeholder='Комментарий к сделке' />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.leftBlock_formPart} >
                                        <div className={styles.formItem} >
                                            <label>Склад выдачи</label>
                                            <Controller
                                                name='storageId'
                                                control={control}
                                                render={({ field }) =>
                                                    <Select {...field} options={storages?.map(({id, name})=> ({label: name, value: id})) || []} />
                                                }
                                            />
                                        </div>
                                        <div className={styles.divider} />
                                        <div className={styles.formItem} >
                                            {!isCommentVisible ? <div onClick={()=> setIsCommentVisible(true)} className={styles.showComment} > Комментарий к доставке </div> :
                                             <div className={styles.deliveryComment} >
                                                <label >Комментарий к доставке</label>
                                                <Controller
                                                name='deliveryComment'
                                                control={control}
                                                render={({field})=> (
                                                    <TextArea placeholder='Комментарий к доставке' {...field} autoSize={{minRows: 3, maxRows: 3}} />
                                                ) }
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.sale_tableHolder} >
                                    <div className={styles.sale_table} >
                                        <p className={styles.sale_tableInfo} >Документ пуст</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.history} >
                            <div className={styles.history_header} >
                                <h2>История документа</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
