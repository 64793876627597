import { Button} from 'antd'
import styles from './navbar.module.scss'
import { FC, useState } from 'react'
import { useAppSelector } from '../../../../../../shared/hooks/reduxToolkit'
import { FiShoppingBag } from "react-icons/fi";
import { productsSelector } from '../../../../../../entities/product';
import SelectedProductsModal from '../selectedProductsModal';

type NavItem = {
  id: number,
  title: string,
  value: "parts" | "wheels" | "services"
}

const navItems: NavItem[] = [
  {
    id: 1,
    title: 'Автозапчасти',
    value: 'parts'
  },
  {
    id: 2,
    title: 'Шины и диски',
    value: 'wheels'
  },
  {
    id: 3,
    title: 'Услуги',
    value: 'services'
  }
]

interface IProps {
  selectedTab: "parts" | "wheels" | "services"
  setSelectedTab: React.Dispatch<React.SetStateAction<"parts" | "wheels" | "services">>
  setIsCatalogModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  page: 'sale'| 'addWriteoff' | 'writeoff'
}

export const Navbar: FC<IProps> = ({ selectedTab, setSelectedTab, setIsCatalogModalOpen, page }) => {
    const selectedProducts = useAppSelector(productsSelector)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)



  return (
    <div className={styles.root} >
      <SelectedProductsModal page={page} setIsCatalogModalOpen={setIsCatalogModalOpen} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <div className={styles.leftSide}  >
        <h1>Добавить товары</h1>
        <nav className={styles.navbar} >
          {navItems.map(({ id, title, value }) => (
            <Button onClick={() => setSelectedTab(value)} key={id} type={selectedTab === value ? 'primary' : 'text'} >{title}</Button>
          ))}

        </nav>
      </div>
      <div className={styles.rightSide} >
        {selectedProducts.length > 0 ?  <div onClick={()=> setIsModalOpen(true)} className={styles.selectedItems} ><FiShoppingBag /> {`${selectedProducts.length} товаров`} </div> : <div className={styles.selectedItems_empty} > <FiShoppingBag />  Список пуст</div>}
      </div>
    </div>
  )
}
