import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styles from './IncomeUpdateForm.module.scss'
import { UpdateIncomeDto, useGetOneIncomeQuery, useUpdateIncomeMutation } from '../../../../../entities/income';
import { FC, useEffect, useState} from 'react';
import { useGetAllStoragesQuery } from '../../../../../entities/storage';
import { useGetAllShipmentQuery } from '../../../../../entities/shipment';
import { Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useAppDispatch } from '../../../../../shared/hooks/reduxToolkit';
import { setIncomeMessage } from '../../store/incomePageSlice';




interface IProps {
  incomeId: string
}

const IncomeUpdateForm:FC<IProps> = ({incomeId}) => {
  const {control, watch, setValue, handleSubmit} = useForm<UpdateIncomeDto>({
    defaultValues: {
       shipment: undefined,
       storage: undefined,
       comment: undefined
    }
  })
const [updateIncome] = useUpdateIncomeMutation()
const {data: income} = useGetOneIncomeQuery(incomeId)
const {data: storages} = useGetAllStoragesQuery()
const {data: shipments} = useGetAllShipmentQuery()
const dispatch = useAppDispatch()
const summ = income?.products?.reduce((summ, {purchasePrice, stock})=> summ + (purchasePrice * stock) ,0) 
const overheadExpenses = income?.products?.reduce((summ, {overheadExpenses, stock})=> summ + (overheadExpenses * stock) ,0)

  const onSubmit: SubmitHandler<UpdateIncomeDto> = async (data) => {
     await updateIncome({id: incomeId, body: data})
  };
  

  useEffect(() => {
    setValue('shipment', income?.shipment?.id);
    setValue('storage', income?.storage?.id);
    setValue('comment', income?.comment);
  }, [income]);




  const shipmentOptions: { label: string; value: number | undefined }[] =
    shipments
      ? [
          ...shipments.map(({ id, title }) => ({
            label: title,
            value: id,
          })),
        ]
      : [];

  const storageOtions: { label: string; value: number | undefined }[] = storages
    ? [
        ...storages.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      ]
    : [];


   useEffect(()=> {
    updateIncome({id: incomeId, body: {summ, overheadExpenses}})
   }, [summ, overheadExpenses])

  


  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
    <div className={styles.countersHolder}>
      <div className={styles.counter}>
        <p className={styles.counterTitle}>Сумма</p>
        <p className={styles.counterCount}>
          {summ}
        </p>
      </div>
      <div className={styles.counter}>
        <p className={styles.counterTitle}>Наклад. расх</p>
        <p className={styles.counterCount}>
        {overheadExpenses}
          </p>
      </div>
    </div>

      <div className={styles.inputsWrapper} >
        <div className={styles.formItem}>
          <label htmlFor="shipment">Поставка</label>
           <Controller
           name='shipment'
           control={control}
           render={({field})=> (
            <Select
            id="shipment"
            placeholder='Выберите поставку'
            onChange={(value) =>{
              field.onChange(value === 0 ? undefined : value);
              handleSubmit(onSubmit)()
              dispatch(setIncomeMessage('Поставка изменена'))
            }
            }
            value={field.value}
            className={styles.formItem_select}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            showSearch
            options={shipmentOptions}
          />
           )}
           />
        </div>
        <div className={styles.formItem}>
          <label htmlFor="storage">Склад оприходования</label>
           <Controller
           name='storage'
           control={control}
           render={({field})=>(
            <Select
            id="storage"
            placeholder='Выберите склад'
            value={field.value}
            onChange={(value) =>{
              field.onChange(value === 0 ? undefined : value);
              handleSubmit(onSubmit)()
              dispatch(setIncomeMessage('Склад изменен'))
            }
            }
            className={styles.formItem_select}
            optionFilterProp="label"
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            showSearch
            options={storageOtions}
          />
           )}
           />
        </div>
        <div className={styles.formItem}>
          <Controller
           name='comment'
           control={control}
           render={({field})=> (
            <TextArea
            {...field}
            onBlur={()=> {
              handleSubmit(onSubmit)()
              dispatch(setIncomeMessage(field.value !== income?.comment ? 'Комментарий изменен' : undefined))
            }}
            autoSize={{ minRows: 4 }}
            placeholder="Комментарий к поступлению"
          />
           )}
          />
        </div>

      </div>

  </form>
  )
}

export default IncomeUpdateForm