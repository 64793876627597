import { Input, Segmented, Select, Space } from "antd";
import styles from "./SizesFormPart.module.scss";
import { Control, Controller, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { FC, useEffect, useMemo } from "react";
import cs from "classnames";
import { IWheelsFormValues } from "../../../../../interfaces/wheelsFormValues";
import { tireDiametrInchOptions, tireDiametrMmOptions, tireHeightInchOptions, tireHeightMmOptions, tireHeightPercentOptions, tireWidthInchOptions, tireWidthMmOptions } from "../../../../../../../../../../../../entities/product";



interface IProps {
  control: Control<IWheelsFormValues, any>;
  watch: UseFormWatch<IWheelsFormValues>;
  isDifferentWidth: boolean;
  setValue: UseFormSetValue<IWheelsFormValues>;
  sizesTemplate: number;
  setSizesTemplate: React.Dispatch<React.SetStateAction<number>>;
  isNotValid: boolean;
}

export const SizesFormPart: FC<IProps> = ({
  control,
  watch,
  isDifferentWidth,
  setValue,
  sizesTemplate,
  setSizesTemplate,
  isNotValid
}) => {

  useEffect(() => {
    if (watch("char_tireVehicleType") === "Легковая") {
      setValue("char_tireWidthUnits", "мм");
      setValue("char_tireHeightUnits", "%");
      setValue("char_tireDiametrUnits", "дюйм");
      setSizesTemplate(1);
    } else {
      if (sizesTemplate === 1) {
        setValue("char_tireWidthUnits", "дюйм");
        setValue("char_tireHeightUnits", "%");
        setValue("char_tireDiametrUnits", "дюйм");
      }
      if (sizesTemplate === 2) {
        setValue("char_tireWidthUnits", "мм");
        setValue("char_tireHeightUnits", "%");
        setValue("char_tireDiametrUnits", "дюйм");
      }
      if (sizesTemplate === 3) {
        setValue("char_tireWidthUnits", "дюйм");
        setValue("char_tireHeightUnits", "%");
        setValue("char_tireDiametrUnits", "дюйм");
      }
      if (sizesTemplate === 4) {
        setValue("char_tireWidthUnits", "мм");
        setValue("char_tireHeightUnits", "мм");
        setValue("char_tireDiametrUnits", "мм");
      }
    }
    setValue('char_tireWidth_F', undefined)
    setValue('char_tireWidth_B', undefined)
    setValue('char_tireHeight_F', undefined)
    setValue('char_tireHeight_B', undefined)
    setValue('char_tireCordType_F', 'R')
    setValue('char_tireCordType_B', 'R')
    setValue('char_tireDiametr_F', undefined)
    setValue('char_tireDiametr_B', undefined)
  }, [watch("char_tireVehicleType"), sizesTemplate]);


  const widthOptions = useMemo(() => {
    const options = watch('char_tireWidthUnits') === 'мм' ? tireWidthMmOptions : tireWidthInchOptions
    return options?.map(value => ({ value }))
  }, [watch('char_tireWidthUnits')])

  const heightOptions = useMemo(() => {
    const options = watch('char_tireHeightUnits') === '%' ? tireHeightPercentOptions : watch('char_tireHeightUnits') === 'мм' ? tireHeightMmOptions : tireHeightInchOptions
    return options.map(value => ({ value }))
  }, [watch('char_tireHeightUnits')])

  const diametrOptions = useMemo(() => {
    const options = watch('char_tireDiametrUnits') === 'мм' ? tireDiametrMmOptions : tireDiametrInchOptions
    return options.map(value => ({ value }))
  }, [watch('char_tireDiametrUnits')])

  return (
    <>
      {watch("char_tireVehicleType") === "Легковая" && (
        <div className={styles.rowWrapper}>
          <div className={styles.formItem}>
            <Controller
              name="char_tireMarkingType"
              control={control}
              render={({ field }) => (
                <Segmented
                  value={watch('char_tireMarkingType')}
                  onChange={(value) => {
                    field.onChange(value);
                    setValue('char_tireWidth_F', undefined)
                    setValue('char_tireWidth_B', undefined)
                    setValue('char_tireHeight_F', undefined)
                    setValue('char_tireHeight_B', undefined)
                    setValue('char_tireDiametr_F', undefined)
                    setValue('char_tireDiametr_B', undefined)

                    if (value === "Метрическая") {
                      setValue("char_tireWidthUnits", "мм");
                      setValue("char_tireHeightUnits", "%");
                    } else {
                      setValue("char_tireWidthUnits", "дюйм");
                      setValue("char_tireHeightUnits", "дюйм");
                    }
                  }}
                  size="small"
                  options={["Метрическая", "Дюймовая"]}
                />
              )}
            />
          </div>
        </div>
      )}
      <div
        className={cs(
          styles.fronAxle,
          isDifferentWidth && styles.frontAxle__alternative
        )}
      >
        {isDifferentWidth && (
          <div className={styles.divider}>
            <div className={styles.divider_title}>
              <div className={styles.circle}></div>
              <p>Передняя ось</p>
            </div>
            <span></span>
          </div>
        )}
        <div className={styles.rowWrapper}>
          <Space.Compact>
            <div className={styles.formItem}>
              <Controller
                name="char_tireWidth_F"
                control={control}

                render={({ field }) => (
                  <Select
                    {...field}
                    className={cs((isNotValid && !field.value) && styles.inputError)}
                    showSearch
                    filterOption={(input, option) =>
                      (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={(() => {
                      switch (watch("char_tireWidthUnits")) {
                        case "мм":
                          return "Ширина, мм";
                        case "дюйм":
                          return "Ширина, дюйм";
                      }
                    })()}
                    options={widthOptions}
                  />
                )}
              />
            </div>
            {(watch("char_tireVehicleType") === "Легковая" ||
              sizesTemplate !== 1) && (
                <div className={styles.formItem}>
                  <Controller
                    name="char_tireHeight_F"
                    control={control}
                    render={({ field }) => (
                      <Select
                        showSearch
                        className={cs((isNotValid && !field.value) && styles.inputError)}
                        filterOption={(input, option) =>
                          (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                        }
                        value={watch('char_tireHeight_F')}
                        onChange={(value) => field.onChange(value)}
                        placeholder={(() => {
                          switch (watch("char_tireHeightUnits")) {
                            case "%":
                              return "Высота профиля, %";
                            case "мм":
                              return "Высота колеса, мм";
                            case "дюйм":
                              return "Высота колеса, дюйм";
                          }
                        })()}
                        options={heightOptions}
                      />
                    )}
                  />{" "}
                </div>
              )}
            <div className={styles.formItem}>
              {watch("char_tireVehicleType") === "Легковая" ? (
                <Input style={{ width: "65px" }} disabled placeholder="R" />
              ) : (
                <Controller
                  name="char_tireCordType_F"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className={styles.smallSelect}
                      style={{ width: "65px" }}
                      value={watch("char_tireCordType_F")}
                      onChange={(value) => field.onChange(value)}
                      options={[{ value: "R" }, { value: "D" }, { value: "-" }]}
                    />
                  )}
                />
              )}
            </div>
            <div className={styles.formItem}>
              <Controller
                name="char_tireDiametr_F"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={cs((isNotValid && !field.value) && styles.inputError)}
                    showSearch
                    filterOption={(input, option) =>
                      (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                    }
                    placeholder={(() => {
                      switch (watch("char_tireDiametrUnits")) {
                        case "мм":
                          return "Диаметр, мм";
                        case "дюйм":
                          return "Диаметр, дюйм";
                      }
                    })()}
                    options={diametrOptions}
                  />
                )}
              />{" "}
            </div>
          </Space.Compact>
          <div className={styles.sizesResult}>
            <p>Результат</p>
            <div>
              {watch('char_tireWidth_F') ? <div>{watch('char_tireWidth_F')}</div> : <span>__</span>}
              {(sizesTemplate !== 1 || watch('char_tireVehicleType') === 'Легковая') && <div>
                {sizesTemplate === 4 ? 'x' : '/'}
                {(watch('char_tireHeight_F') ? <div>{watch('char_tireHeight_F')}</div> : <span>__</span>)}
              </div>}
              {watch('char_tireCordType_F')}
              {watch('char_tireDiametr_F') ? <div>{watch('char_tireDiametr_F')}</div> : <span>__</span>}
              {watch('char_tireLT') && <div>{'LT'}</div>}
            </div>
          </div>
        </div>
      </div>
      {isDifferentWidth && (
        <div className={styles.backAxle}>
          <div className={styles.divider}>
            <div className={styles.divider_title}>
              <div className={styles.circle}></div>
              <p>Задняя ось</p>
            </div>
            <span></span>
          </div>
          <div className={styles.rowWrapper}>
            <Space.Compact>
              <div className={styles.formItem}>
                <Controller
                  name="char_tireWidth_B"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={cs((isNotValid && !field.value) && styles.inputError)}
                      showSearch
                      filterOption={(input, option) =>
                        (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                      }
                      placeholder={(() => {
                        switch (watch("char_tireWidthUnits")) {
                          case "мм":
                            return "Ширина, мм";
                          case "дюйм":
                            return "Ширина, дюйм";
                        }
                      })()}
                      options={widthOptions}
                    />
                  )}
                />
              </div>
              {(watch("char_tireVehicleType") === "Легковая" ||
                sizesTemplate !== 1) && (
                  <div className={styles.formItem}>
                    <Controller
                      name="char_tireHeight_B"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={cs((isNotValid && !field.value) && styles.inputError)}
                          showSearch
                          filterOption={(input, option) =>
                            (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                          }
                          placeholder={(() => {
                            switch (watch("char_tireHeightUnits")) {
                              case "%":
                                return "Высота профиля, %";
                              case "мм":
                                return "Высота колеса, мм";
                              case "дюйм":
                                return "Высота колеса, дюйм";
                            }
                          })()}
                          options={heightOptions}
                        />
                      )}
                    />{" "}
                  </div>
                )}
              <div className={styles.formItem}>
                {watch("char_tireVehicleType") === "Легковая" ? (
                  <Input style={{ width: "65px" }} disabled placeholder="R" />
                ) : (
                  <Controller
                    name="char_tireCordType_B"
                    control={control}
                    render={({ field }) => (
                      <Select
                        className={styles.smallSelect}
                        style={{ width: "65px" }}
                        value={watch("char_tireCordType_F")}
                        onChange={(value) => field.onChange(value)}
                        options={[
                          { value: "R" },
                          { value: "D" },
                          { value: "-" },
                        ]}
                      />
                    )}
                  />
                )}
              </div>
              <div className={styles.formItem}>
                <Controller
                  name="char_tireDiametr_B"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={cs((isNotValid && !field.value) && styles.inputError)}
                      showSearch
                      filterOption={(input, option) =>
                        (String(option?.value) ?? '').toLocaleLowerCase().includes(input.toLowerCase())
                      }
                      placeholder={(() => {
                        switch (watch("char_tireDiametrUnits")) {
                          case "мм":
                            return "Диаметр, мм";
                          case "дюйм":
                            return "Диаметр, дюйм";
                        }
                      })()}
                      options={diametrOptions}
                    />
                  )}
                />{" "}
              </div>
            </Space.Compact>
            <div className={styles.sizesResult}>
              <p>Результат</p>
              <div>
                {watch('char_tireWidth_B') ? <div>{watch('char_tireWidth_B')}</div> : <span>__</span>}
                {(sizesTemplate !== 1 || watch('char_tireVehicleType') === 'Легковая') && <div>
                  {sizesTemplate === 4 ? 'x' : '/'}
                  {(watch('char_tireHeight_B') ? <div>{watch('char_tireHeight_B')}</div> : <span>__</span>)}
                </div>}
                {watch('char_tireCordType_B')}
                {watch('char_tireDiametr_B') ? <div>{watch('char_tireDiametr_B')}</div> : <span>__</span>}
                {watch('char_tireLT') && <div>{'LT'}</div>}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

