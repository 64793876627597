import { Link, useLocation } from 'react-router-dom'
import styles from './WriteoffPage.module.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, message, notification, Popconfirm} from 'antd'
import { PiWarningCircleFill } from "react-icons/pi";
import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../../shared/hooks/reduxToolkit'
import { setSaleSelectedItems } from '../../../../(sales)/salePage'
import ItemsTable from '../itemsTable'
import WriteoffUpdateForm from '../writeoffUpdateForm'
import { CreateWriteoffDto, useGetOneWriteoffQuery, useUpdateWriteoffMutation, } from '../../../../../entities/writeoff'
import { useForm } from 'react-hook-form';
import { GoPlus } from 'react-icons/go';
import { CatalogModal } from '../../../../../widgets/catalogModal';
import {  useDeleteWriteoffItemMutation } from '../../../../../entities/writeoffItem';
import { FcOk } from 'react-icons/fc';





export const WriteoffPage = () => {
    const [isCatalogModalOpen, setIsCatalogModalOpen] = useState<boolean>(false)
    const [writeoffMessage, setWriteoffMesage] = useState<string>()
    const location = useLocation()
    const writeoffId = +location.pathname.split('/')[2]
    const dispatch = useAppDispatch()
    const [deletedItems, setDeletedItems] = useState<number[]>([])
    const { data: writeoff } = useGetOneWriteoffQuery(+writeoffId)
    const writeoffItems = writeoff?.writeoffItems
    const [deleteWriteoffItem] = useDeleteWriteoffItemMutation()
    const [updateWriteoff] = useUpdateWriteoffMutation()
    const { control, setValue, watch } = useForm<CreateWriteoffDto>({
        defaultValues: {
            storageId: undefined,
            comment: undefined,
        }
    })
  
      useEffect(()=> {
         setValue('comment', writeoff?.comment)
         setValue('storageId', writeoff?.storageId)
      }, [writeoff])

      useEffect(() => {

        if (writeoffMessage) {
            const timer = 1000
            setTimeout(() => setWriteoffMesage(undefined), timer)
        }
    }, [writeoffMessage])
     
      useEffect(()=> {
        const update = async ()=> {
            const res = await updateWriteoff({id: writeoffId, body: {storageId: watch('storageId'), comment: watch('comment')}})
            if('error' in res) {
                message.error('Ошибка сервера')
            }
        }
          update()
      }, [watch('storageId'), watch('comment')])

       const handleDeleteItems = async ()=> {
        deletedItems.map(async (id)=> await deleteWriteoffItem(id as number))
        setDeletedItems([])
      }
    
     const handleUpdateStatus = async (status: 'complete' | 'canceled')=> {
        const isValid = writeoffItems?.every(({product, quantity})=> (product.stock >= quantity) && (product.stock - quantity >= 0))
        console.log(isValid)
        if(isValid && (status === 'canceled' || (status === 'complete' && writeoffItems && writeoffItems.length > 0))) {
            const res = await updateWriteoff({id: writeoffId, body: {status}})
            if('error' in res) {
                message.error('Ошибка сервера')
            }
        } else if(isValid) {
            notification.warning({
                placement: 'bottom', 
                message: 'В документе нет товаров', 
                description: 'Добавьте товары, чтобы провести документ'})
        } else {
            notification.warning({
                placement: 'bottom', 
                message: 'Недостаточно товаров на складе',
                description: 'Недостаточно товаров на складе для списания'})
        }

     }

     useEffect(()=> {
        const isValid = writeoffItems?.every(({product, quantity})=> (product.stock >= quantity) && (product.stock - quantity >= 0))
        console.log(isValid)
     }, [writeoff])


    return (
            <main className={styles.root} >
                <CatalogModal page='writeoff' isModalOpen={isCatalogModalOpen} setIsModalOpen={setIsCatalogModalOpen} />
                <div className={styles.inner} >
                    <div className={styles.header} >
                        <Link to='/writeoffs' className={styles.writeoffLink} ><IoIosArrowBack /></Link>
                        <h1 className={styles.title} >Списания</h1>
                    </div>
                    <div className={styles.content} >
                        <div className={styles.content_inner} >
                             <div className={styles.writeoff} >
                                {deletedItems.length > 0 ?
                                    <div className={styles.writeoff_header} >
            
                                        <div className={styles.writeoff_headerTitle} >
                                        <h2>{`Выбрано ${deletedItems.length}`}</h2>
                                            {writeoffMessage && <p className={styles.message} ><FcOk /><span>{writeoffMessage}</span></p>}
                                        </div>
                                        <div className={styles.writeoff_btnHolder} >
                                            <Popconfirm onConfirm={handleDeleteItems} okText='да' cancelText='нет' title='Вы действительно хотите удалить выбранные товары?' >
                                                <button  className={styles.noticeButton} >
                                                    Удалить из списания
                                                </button>
                                            </Popconfirm>
                                        </div>
                                        <Button type='text' onClick={() => dispatch(setSaleSelectedItems([]))} >Отменить</Button>
                                    </div>
                                    :
                                    <div className={styles.writeoff_header} >
                                        <div className={styles.writeoff_headerTitle} >
            
                                            <div className={styles.writeoff_headerTitle} >
                                            <h2>{`Списание №${writeoff?.id}`}</h2>
                                            {writeoffMessage && <p className={styles.message} ><FcOk /><span>{writeoffMessage}</span></p>}
                                        </div>
                                        </div>
                                        { writeoff?.status === 'draft' && <div className={styles.writeoff_btnHolder} >
                                            <Popconfirm onConfirm={()=> handleUpdateStatus('complete')} okText='да' cancelText='нет' title='Вы действительно хотите списать выбранные товары'><Button type='primary' > Списать </Button></Popconfirm>
                                            <Popconfirm onConfirm={()=> handleUpdateStatus('canceled')} okText='да' cancelText='нет' title='Вы действительно хотите отменить списание' ><Button type='default' > Отменить списание</Button></Popconfirm>
                                        </div>}
                                        <div className={styles.writeoff_settings} ></div>
                                    </div>}
                                <div className={styles.notice} > <PiWarningCircleFill /> Перед проводкой документа проверьте список товаров. Списанные товары нельзя восстановить</div>
                                <div className={styles.writeoff_content} >
                                    <WriteoffUpdateForm writeoff={writeoff} setWriteoffMesage={setWriteoffMesage} status={writeoff?.status} control={control} />
                                    <div className={styles.writeoff_tableHolder} >
                                        { writeoff?.status === 'draft' && <div className={styles.addBtnWrapper} >
                                            <button onClick={()=> setIsCatalogModalOpen(true)} >
                                                <GoPlus />
                                                Добавить
                                            </button>
                                        </div>}
                                        <div className={styles.writeoff_table} >
                                            {writeoffItems && writeoffItems.length > 0 ?
                                                <ItemsTable status={writeoff?.status} deletedItems={deletedItems} setDeletedItems={setDeletedItems} writeoffItems={writeoffItems} />
                                                :
                                                <div className={styles.emptyTableNotice} >
                                                    <div className={styles.emptyTableNotice_inner} >
                                                        <h4>Документ пуст</h4>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.history} >
                                <div className={styles.history_header} >
                                    <h2>История документа</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
    )
}