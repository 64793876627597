import { useState } from 'react'
import Navbar from '../navbar'
import styles from './SalesPage.module.scss'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import SalesTable from '../salesTable'
import { useGetAllClientsQuery } from '../../../../../entities/client'


export const SalesPage = () => {
  const [selectedItem, setSelectedItem] = useState<"draft" |"reserve" |"issued" |"canceled"| "all">('draft')
  const {data: clients} = useGetAllClientsQuery()
  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1 className={styles.title} >Сделки</h1>
      </div>
      <div className={styles.inner} >
        <Navbar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <div className={styles.content} >
          <div className={styles.contentHeader} >
            <Button  className={styles.filtersResetBtn} disabled={true} >Фильтры не заданы</Button>
              <Link to={`/clients/${clients?.data[0]?.id}/sales/new`} ><Button type='primary' className={styles.addIncomeBtn} >Новая сделка</Button></Link>
          </div>
          <div className={styles.table} >
             <SalesTable status={selectedItem} />
          </div>
        </div>
      </div>
    </main>
  )
}

