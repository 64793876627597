import { Button } from 'antd'
import styles from './ReturnPage.module.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'
import { useGetOneReturnQuery } from '../../../../../entities/return'
import ReturnUpdateForm from '../returnUpdateForm'
import ItemsTable from '../itemsTable'
import { useEffect, useState } from 'react'
import { FcOk } from 'react-icons/fc'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { ImBoxRemove } from "react-icons/im";
import { clearSelectedProducts, productsSelector } from '../../../../../entities/product'


export const ReturnPage = () => {
  const location = useLocation()
  const returnId = location.pathname.split('/').splice(-1)
  const {data: returnData} = useGetOneReturnQuery(+returnId)
  const selectedProducts = useAppSelector(productsSelector)
  const dispatch = useAppDispatch()
  const [returnMessage, setReturnMessage] = useState<string>()
  const navigate = useNavigate()

 
  useEffect(() => {

    if (returnMessage) {
        const timer = 1000
        setTimeout(() => setReturnMessage(undefined), timer)
    }
}, [returnMessage])


    const onAddWriteoff = ()=> {
            navigate('/writeoffs/new') 
    }


  return (
    <main className={styles.root} >
            <div className={styles.inner} >
                <div className={styles.header} >
                    <Link to='/returns' className={styles.returnLink} ><IoIosArrowBack /></Link>
                    <h1 className={styles.title} >{ returnData?.sale?.client?.name  || 'Частное лицо'}</h1>
                </div>
                <div className={styles.content} >
                    <div className={styles.content_inner} >
                        <div className={styles.return} >
                         
                               {selectedProducts.length > 0 ?  
                               <div className={styles.return_header} >
                                   <h2>{`Выбрано ${selectedProducts.length}`}</h2>
                                   <div className={styles.return_btnHolder} >
                                       <button onClick={onAddWriteoff} className={styles.noticeButton} >
                                       <ImBoxRemove />
                                           Списать
                                       </button>
                                   </div>
                                   <Button onClick={()=> dispatch(clearSelectedProducts())} type='text'  >Отменить</Button>
                               </div>
                               :
                               <div className={styles.return_header} >
                                    <div className={styles.return_headerTitle} >
                                        <h2>{`Возврат №${returnData?.id} `}</h2>
                                        {returnMessage && <p className={styles.message} ><FcOk /><span>{returnMessage}</span></p>}
                                    </div>
                                </div> } 
                                
                                
                             
                                    <div className={styles.return_settings} ></div>
                                    <div className={styles.return_content} >
                                <ReturnUpdateForm setReturnMessage={setReturnMessage} returnData={returnData} />
                                <div className={styles.return_tableHolder} >
                                    <div className={styles.return_table} >
                                      <ItemsTable  returnData={returnData} />
                                          
                                      
                                    </div>
                                </div>
                            </div>
                                </div>

                          
                            <div className={styles.history} >
                            <div className={styles.history_header} >
                                <h2>История документа</h2>
                            </div>
                        </div>
                        </div>
                       
                    </div>
                </div>
          
        </main>
  )
}




