import { Button, message, Modal, Table, TableColumnsType } from 'antd'
import styles from './SelectedProducts.module.scss'
import { FC } from 'react'
import { addProduct, clearSelectedProducts, IProduct, productsSelector, removeProduct, setSelectedProducts } from '../../../../../../entities/product'
import { useAppDispatch, useAppSelector } from '../../../../../../shared/hooks/reduxToolkit'
import { CreateSaleItemDto, ISaleItem, useCreateSaleItemsMutation } from '../../../../../../entities/saleItem'
import { useLocation } from 'react-router-dom'
import { CreateWriteoffItemDto, IWriteoffItem, useCreateWriteoffItemsMutation } from '../../../../../../entities/writeoffItem'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'





interface IProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsCatalogModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  page: 'sale'| 'addWriteoff' | 'writeoff'
}

const SelectedProductsModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, setIsCatalogModalOpen, page}) => {
  const { pathname } = useLocation()
  const selectedProducts = useAppSelector(productsSelector)
  const [createSaleItems] = useCreateSaleItemsMutation()
  const [createWriteoffItems] = useCreateWriteoffItemsMutation()
  const dispatch = useAppDispatch()
  const saleId = +pathname.split('/')[4]
  const writeoffId = +pathname.split('/')[2]
  const onPlusClick = (product: IProduct) => {
    dispatch(addProduct(product))
  }

  const onMinusClick = (product: IProduct) => {
    dispatch(removeProduct(product))
  }

  const onClear = () => {
    dispatch(clearSelectedProducts())
    setIsModalOpen(false)
  }

 

  const checkResponse = (res: {
    data: ISaleItem[] | IWriteoffItem[];
    error?: undefined;
} | {
    data?: undefined;
    error: FetchBaseQueryError | SerializedError;
})=> {
  if ('data' in res) {
    dispatch(clearSelectedProducts())
    setIsModalOpen(false)
    setIsCatalogModalOpen(false)
    message.success('Товары добавлены')
  } else if ('error' in res) {
    message.error('Ошибка сервера')
  }
  }

  const onSubmit = async () => {
  if(page==='sale') {
    const saleItems: CreateSaleItemDto[] = selectedProducts.map(({ product, quantity }) => ({ saleId, productId: product?.id, quantity }))
    const res = await createSaleItems(saleItems)
    checkResponse(res)
  }
  if(page === 'addWriteoff') {
    setIsModalOpen(false)
    setIsCatalogModalOpen(false)
  }

  if(page === 'writeoff') {
     const writeoffItems: CreateWriteoffItemDto[] = selectedProducts.map(({product, quantity})=> (
      {writeoffId, productId: product?.id, quantity}
     ) )
     const res = await createWriteoffItems(writeoffItems)
     checkResponse(res)
  }
  }

  const columns: TableColumnsType<{ product: IProduct, quantity: number }> = [
    {
      title: 'Номер',
      render: (_, { product }) => product.id
    },
    {
      title: 'Наименование',
      render: (_, { product }) => product.char_part
    },
    {
      title: 'Кол-во',
      width: '120px',
      align: 'center',
      render: (_, { product }) => (
        <div className={styles.counter} >
          <Button onClick={() => onMinusClick(product)} size='small' >-</Button>
          <div>{selectedProducts.find(({ product: { id } }) => id === product.id)?.quantity || 0}</div>
          <Button onClick={() => onPlusClick(product)} size='small' >+</Button>
        </div>
      )
    },
    {
      title: 'Цена',
      width: '150px',
      align: 'center',
      render: (_, { product: { char_price }, quantity }) => char_price ? char_price * quantity : 0
    }

  ]
  return (
    <Modal width={800} onCancel={() => setIsModalOpen(false)} className={styles.root} footer={false} open={isModalOpen} >
      <div className={styles.header} >
        <h1>Выбранные товары и услуги</h1>
      </div>
      <div className={styles.content} >
        <div className={styles.tableHolder} >
          <Table rowKey={(item)=> item.product.id} scroll={{ y: 185 }} pagination={false} dataSource={selectedProducts} columns={columns} />
        </div>
        <div className={styles.totalPrice} >
          <p>Итого</p>
          <p>{selectedProducts.reduce((sum, { product: { char_price }, quantity }) => char_price ? sum + (char_price * quantity) : sum + 0, 0)}</p>
        </div>
      </div>
      <div className={styles.footer} >
        <div onClick={onClear} className={styles.clearList} >Очистить список</div> <Button onClick={onSubmit} size='large' type='primary' >Добавить в документ</Button>
      </div>
    </Modal>
  )
}

export default SelectedProductsModal