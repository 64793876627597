import { Control, Controller, UseFormWatch } from 'react-hook-form'
import styles from './ManufacturerFormPart.module.scss'
import { IWheelsFormValues } from '../../../../interfaces/wheelsFormValues'
import { FC } from 'react'
import { Input, Space } from 'antd'
import cs from 'classnames'


interface IProps {
  watch: UseFormWatch<IWheelsFormValues>
  control: Control<IWheelsFormValues, any>
  isDifferentWidth: boolean
}


const ManufacturerFormPart: FC<IProps> = ({watch, control, isDifferentWidth}) => {
  return (
    <div>

      <div className={styles.rowWrapper} >
        <Space.Compact className={styles.spaceCompact} >
          <div className={styles.formItem} >
           <Controller
           name='char_diskProducer'
           control={control}
           render={({field})=> (
            <Input
            {...field}
            placeholder='Производитель'
            />
           )}
           />
          </div>
          <div className={styles.formItem} >
            <Controller
            name='char_diskModel'
            control={control}
            render={({field})=> (
              <Input
              {...field}
              placeholder='Модель'
              />
            )}
            />
          </div>
        </Space.Compact>
        <div className={cs(styles.formItem, styles.mfrNumberF, isDifferentWidth && styles.mfrNumberF__alternative)} >
          <Controller
          name='char_diskMfrnumber_F'
          control={control}
          render={({field})=> (
            <Input
            {...field}
            placeholder={isDifferentWidth ? 'Ориг. номер': 'Оригинальный номер' }
            />
          )}
          />
        </div>
        {isDifferentWidth && <div className={cs(styles.formItem, styles.mfrNumberB)} >
          <Controller
          name='char_diskMfrnumber_B'
          control={control}
          render={({field})=> (
            <Input
            {...field}
            placeholder='Ориг. номер'
            />
          )}
          />
        </div>}
      </div>
  
    </div>
  )
}

export default ManufacturerFormPart