import { Table, TableColumnsType } from 'antd'
import styles from './PaymentsTable.module.scss'
import { FC, useState } from 'react';
import { IPayment, useGetAllPaymentsQuery } from '../../../../entities/payment';



const columns: TableColumnsType<IPayment> = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Дата',
    width: 80,
    render: (_, item)=> {
      const date = item.createdAt && new Date(item.createdAt.toString());
      const day = date?.toLocaleString("ru-Ru", {day: 'numeric'})
      const month = date?.toLocaleString("ru-Ru", { month: "long" });
      const year = date?.toLocaleString("ru-Ru", {year: 'numeric'})
      return <div>{`${day} ${month.slice(0, 3)} ${year.slice(2)}`}</div>
    }
  },
  {
    title: 'Тип документа',
    dataIndex: 'type',
    render: (text)=> text === 'sale' ? 'Сделка': 'Возврат'
  },
  {
    title: 'Номер документа',
    width: 50,
    render: (_, item)=> item?.sale?.id
  },
  {
    title: 'Клиент',
    render: (_, item) =>  <div className={styles.client} >{item?.client?.name}</div>
  },
  {
    title: 'Сумма',
    dataIndex: 'sum'
  },
  {
    title: 'Источник',
    dataIndex: 'source',
    render: (text)=> {
         switch(text) {
         case 'cashless': return <div>Безналичный</div>
         case 'officeCashbox': return <div>Касса в офисе</div>
         case 'bankAccount': return <div >Банк расчетный счет</div>
      }
    } 
  },
  {
    title: 'Статус',
    render: ()=> <div>Проведен</div>
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment'
  }
];


interface IProps  {
selectedItem: "all" | "gt" | "lt"
}

const PaymentsTable: FC<IProps> = ({selectedItem}) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data: paymentsData, isLoading} = useGetAllPaymentsQuery({filters: {sum: selectedItem}, pagination: {page, pageSize}})
  const payments = paymentsData?.data
  const pagination = paymentsData?.meta?.pagination
  
  
  const onChangePage = (page: number, pageSize: number)=> {
    setPage(page)
    setPageSize(pageSize)
  }
  
  return (
   
    <Table
    size='small'
    loading={isLoading}
    className={styles.table}
        columns={columns}
        dataSource={payments?.map((item)=>({...item, key: item.id}))}  
        pagination={{defaultPageSize: 15, showSizeChanger: true, total: pagination?.total, current: page, pageSize, onChange: onChangePage}}
      />
  )
}

export default PaymentsTable