import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateShipmentDto } from "../dtos/createShipmentDto";
import { UpdateShipmentDto } from "../dtos/updateShipmentDto";
import { IShipment } from "../interfaces/shipment";




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getAllShipment: build.query<IShipment[], void>({
            query: () => '/shipments',
            providesTags: ['Shipment']
        }),
        getOneShipment: build.query<IShipment, string>({
            query: (id) => `/shipments/${id}`,
            providesTags: ['Shipment']
        }),
        createShipment: build.mutation<IShipment, CreateShipmentDto>({
            query: (body) => ({
                url: '/shipments',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Shipment']
        }),
        updateShipment: build.mutation<IShipment, { body: UpdateShipmentDto, id: string }>({
            query: ({ body, id }) => ({
                url: `/shipments/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Shipment']
        }),
        deleteShipment: build.mutation<void, string>({
            query: (id)=> ({
                url: `/shipments/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Shipment']
        })
    })
})


export const { useGetAllShipmentQuery, useGetOneShipmentQuery, useCreateShipmentMutation, useUpdateShipmentMutation, useDeleteShipmentMutation } = extendedApi