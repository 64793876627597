import { Link, useLocation } from "react-router-dom";
import styles from "./IncomePage.module.scss";
import { IoIosArrowBack } from "react-icons/io";
import { Button, ConfigProvider, Dropdown, MenuProps, Popconfirm, Segmented } from "antd";
import {
  useGetOneIncomeQuery,
  useUpdateIncomeMutation,
} from "../../../../../entities/income";
import { GoPlus } from "react-icons/go";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { IoPricetagsOutline } from "react-icons/io5";
import { NewProductModal } from "../newProductModal";
import { UsedProductModal } from "../usedProductModal";
import ProductsTable from "../productsTable";
import IncomeUpdateForm from "../incomeUpdateForm";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../shared/hooks/reduxToolkit";
import { setIncomeMessage, setIncomeSelectedProducts } from "../../store/incomePageSlice";
import { FcOk } from "react-icons/fc";
import { useDeleteProductMutation } from "../../../../../entities/product";






export const IncomePage = () => {
  const location = useLocation();
  const incomeId = location.pathname.split("/")[2];
  const { data: income } = useGetOneIncomeQuery(incomeId);
  const { id, createdAt } = income || {};
  const date = createdAt && new Date(createdAt.toString());
  const month = date?.toLocaleString("ru-RU", { month: "long" });
  const localeDate = date?.toLocaleString();
  const [isNewProductModalOpen, setNewProductModalOpen] = useState(false);
  const [isUsedProductModalOpen, setIsUsedProductModalOpen] = useState(false);
  const dispatch = useAppDispatch()
  const { incomeMessage } = useAppSelector((state) => state.incomePage)
  const [updateIncome] = useUpdateIncomeMutation();
  const { status } = income || {};
  const [activeTab, setActiveTab] = useState<"Все" | "Новые" | "Б/У">("Все");
  const { incomeSelectedProducts } = useAppSelector(state => state.incomePage)
  const [deleteProdut] = useDeleteProductMutation()
  const items: MenuProps["items"] = [
    {
      label: (
        <div className={styles.dropdownItem}>
          <IoPricetagsOutline /> Б/У товары
        </div>
      ),
      onClick: () => setIsUsedProductModalOpen(true),
      key: "0",
    },
    {
      label: (
        <div className={styles.dropdownItem}>
          <IoPricetagsOutline /> Новые товары
        </div>
      ),
      onClick: () => setNewProductModalOpen(true),
      key: "1",
    },
  ];


  const handleProductsDelete = async () => {
    incomeSelectedProducts.map(async (id) => { await deleteProdut(id as number) })
    dispatch(setIncomeSelectedProducts([]))
  }

  useEffect(() => {
    if (incomeMessage) {
      const timer = 1000
      setTimeout(() => dispatch(setIncomeMessage(undefined)), timer)
    }
  }, [incomeMessage])



  useEffect(() => {
    return () => { dispatch(setIncomeSelectedProducts([])) }
  }, [])

  return (
    <main className={styles.root}>
      <NewProductModal
        isModalOpen={isNewProductModalOpen}
        setIsModalOpen={setNewProductModalOpen}
      />
      <UsedProductModal
        isModalOpen={isUsedProductModalOpen}
        setIsModalOpen={setIsUsedProductModalOpen}
      />
      <div className={styles.inner}>
        <div className={styles.header}>
          <Link to="/incomes" className={styles.returnLink}>
            <IoIosArrowBack />
          </Link>
          <h1 className={styles.title}>Поступления</h1>
        </div>
        <div className={styles.content}>
          <div className={styles.content_inner}>
            <div className={styles.income}>
              <div className={styles.income_header}>

                <div className={styles.income_headerTitle}>
                  {id && <h2>{`Поступление № ${id} `}</h2>}
                  {incomeMessage ?
                    <p className={styles.incomeMessage} ><FcOk /><span>{incomeMessage}</span></p> :
                    createdAt && (
                      <p>{`Черновик от ${localeDate?.split(",")[0].slice(0, 2)} ${month?.slice(0, 3)} в ${localeDate?.split(",")[1].slice(0, 6)} `}</p>
                    )}
                </div>
                {incomeSelectedProducts?.length > 0 ?
                  <Popconfirm
                    title='Удаление позиций'
                    description='Вы уверены что хотите удалить выбранные позиции?'
                    onConfirm={handleProductsDelete}
                  >
                    <div>
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              colorPrimary: "#fed245",
                              algorithm: true
                            }
                          }
                        }}
                      >

                        <Button style={{ color: 'black' }} type='primary' >Удалить из поступления</Button>
                      </ConfigProvider>
                    </div>
                  </Popconfirm>
                  : <div className={styles.income_btnHolder}>
                    {status === "draft" && (
                      <>
                        <Button
                          onClick={() =>
                            updateIncome({
                              id: incomeId,
                              body: { status: "completed" },
                            })
                          }
                          type="primary"
                        >
                          Провести документ
                        </Button>
                        <Popconfirm
                          title='Отмена поступления'
                          description='Вы действительно хотите отменить поступление?'
                          onConfirm={() =>
                            updateIncome({
                              id: incomeId,
                              body: { status: "canceled" },
                            })}
                        >
                          <Button
                            type="default"
                          >
                            Отменить поступление
                          </Button>
                        </Popconfirm>
                      </>
                    )}
                    {status === "completed" && (
                      <Popconfirm
                        title='Подтвердите отмену проводки'
                        description='Вы действительно хотите отменить проводку?'
                        onConfirm={() =>
                          updateIncome({
                            id: incomeId,
                            body: { status: "draft" },
                          })
                        }
                      >
                        <Button

                          type="primary"
                        >
                          Отменить проводку
                        </Button>
                      </Popconfirm>
                    )}
                    {status === "canceled" && (
                      <Button
                        onClick={() =>
                          updateIncome({
                            id: incomeId,
                            body: { status: "draft" },
                          })
                        }
                        type="primary"
                      >
                        Вернуть в работу
                      </Button>
                    )}
                  </div>}
                <div className={styles.income_settings}></div>
              </div>
              <div className={styles.income_content}>
                <IncomeUpdateForm incomeId={incomeId} />

                <div className={styles.income_tableHolder}>
                  <div className={styles.navbar}>
                    <Segmented<string>
                      size="small"
                      options={["Все", "Новые", "Б/У"]}
                      onChange={(value) => {
                        setActiveTab(value as "Все" | "Новые" | "Б/У");
                      }}
                    />

                    <div className={styles.income_tableButtonsHolder}>
                      <Dropdown
                        menu={{ items }}
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <button>
                          <GoPlus />
                          Добавить
                        </button>
                      </Dropdown>
                      <button>
                        <BsArrowsAngleExpand />
                        Подробнее
                      </button>
                    </div>
                  </div>
                  <div className={styles.income_table}>
                    <ProductsTable activeTab={activeTab} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.history}>
              <div className={styles.history_header}>
                <h2>История документа</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
