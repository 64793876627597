import styles from './WheelsPage.module.scss'
import { Input, Table } from 'antd'
import type { TableColumnsType } from 'antd';
import { IoOptionsOutline } from 'react-icons/io5'
import { IProduct, useGetAllProductsQuery } from '../../../../../entities/product'
import { useEffect, useState } from 'react';
import { serverUrl } from '../../../../../shared/constants/serverUrl';
import { useNavigate } from 'react-router-dom';
import ProductsTable from '../../../../(incomes)/incomePage/ui/productsTable';



const columns: TableColumnsType<IProduct> = [
  {
    title: 'ID',
    width: '24px',
    dataIndex: 'id',
  },
  {
    title: 'Превью',
    dataIndex: 'images',
    width: '40px',
    render: (item)=> { return <div className={styles.imageHolder} >{item[0]?.url && <img className={styles.previewImage} src={serverUrl + item[0]?.url} />}</div>}
  },
  {
    title: 'Товар',
    dataIndex: 'char_part',
  },
  {
    title: 'Диаметр',
    dataIndex: 'char_tireDiametr'
  },
  {
    title: 'Тип шины',
    dataIndex: 'char_tireVehicleType'
  },
  {
    title: 'Цена',
    dataIndex: 'char_price'
  },
  {
    title: 'Ширина шины',
    dataIndex: 'char_tireWidth'
  },
  {
    title: 'Тип маркировки',
    dataIndex: 'char_tireMarkingType'
  },
  {
    title: 'Тип протектора',
    dataIndex: 'char_tireProtectorType',
  },
  {
    title: 'Тип конструкции',
    dataIndex: 'char_tireCordType',
  },
  {
    title: 'Высота шины',
    dataIndex: 'char_tireHeight'
  },
  {
    title: 'Износ',
    dataIndex: 'char_tireWearout'
  },
  {
    title: 'Остаток',
    dataIndex: 'stock'
  }

]

export const WheelsPage = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [mode, setMode] = useState<string>('sale')
  const {data: products, isLoading} = useGetAllProductsQuery({pagination: {page, pageSize}, category: 'wheel'})
  const navigate = useNavigate()
  const {pagination} = products?.meta || {}
  


  useEffect(()=> {
    const checkedMode = localStorage.getItem('catalog_changedRadio')
    checkedMode && setMode(checkedMode)
  }, [])

  useEffect(()=> {
    const savedPage = sessionStorage.getItem('catalog-table-page') 
    const savedPageSize = sessionStorage.getItem('catalog-table-pageSize') 
    if(savedPage) {
     setPage(+savedPage)
    }
    if(savedPageSize) {
      setPageSize(+savedPageSize)
    }
},[])


  const onPageChange = (page: number, pageSize: number)=> {
    setPage(page)
    setPageSize(pageSize)
    sessionStorage.setItem('catalog-table-page', String(page))
    sessionStorage.setItem('catalog-table-pageSize', String(pageSize))
  }


  return (
    <div className={styles.root} >
    <div className={styles.header} >
      <Input className={styles.search} placeholder='Поиск по номеру' />
      <div className={styles.options} ><IoOptionsOutline/></div>
    </div>
    <div className={styles.content} >
      <Table
      loading={isLoading}
      columns={columns}
      rowSelection={{type: 'checkbox'}}
      onRow={(item)=>({onClick: ()=> navigate(`/catalog/wheel/${item?.id}`)})}
      pagination={{showSizeChanger: true, defaultPageSize: 10, total: pagination?.total, pageSize, current: page, onChange: onPageChange}}
      dataSource={products?.data?.map((item)=> ({...item, key: item?.id}))}
      />
    </div>
    </div>
  )
}

