import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styles from './EditMarkForm.module.scss'
import { Button, Input } from 'antd'
import { CreateMarkDto, IMark, useUpdateMarkMutation } from '../../../../../../entities/mark'
import { FC, useEffect } from 'react'
import useMessage from 'antd/es/message/useMessage'


interface IProps  {
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    mark?: IMark
}

const EditMarkForm: FC<IProps> = ({setIsModalOpen, mark}) => {
    const [messageApi, contextHolder] = useMessage()
    const [updateMark] = useUpdateMarkMutation()
    const {control, handleSubmit, setValue} = useForm({
        defaultValues: {
            title: ''
        }
    })


    const onSubmit: SubmitHandler<CreateMarkDto> = async (data)=> {
        if(mark) {
            await updateMark({id:mark.id, body:data})
            messageApi.success('Марка успешно обновлена')
            setIsModalOpen(false)
        }
    
    }

    useEffect(()=> {
            if(mark) {
                setValue('title', mark.title)
            }
    }, [mark])


  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
        {contextHolder}
        <div className={styles.formItem} >
            <label htmlFor="title">Название</label>
            <Controller
            name='title'
            control={control}
            render={({field})=> (
                <Input id='title' placeholder='Название' {...field} />
            )}
            />
        </div>
        <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button htmlType='submit' type='primary' >Сохранить</Button></div>
    </form>
  )
}

export default EditMarkForm