import { FC } from 'react'
import styles from './AddBodyForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import { CreateBodyDto, useCreateBodyMutation } from '../../../../../../entities/body'
import { useLocation } from 'react-router-dom'


interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddBodyForm: FC<IProps> = ({setIsModalOpen}) => {
  const location = useLocation()
  const generationId = location.pathname.split('/')[3]
  const [messageApi, contextHolder] = useMessage()
  const [createBody] = useCreateBodyMutation()
const {control, handleSubmit, reset} = useForm({
  defaultValues: {
    title: '',
    generationId: +generationId
  }
})

const onSubmit: SubmitHandler<CreateBodyDto> = async (data)=> {
      await createBody(data)
      messageApi.success('Кузов успешно создан')
      reset()
      setIsModalOpen(false)
}



  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input  id='title' {...field}/>
        )}
        />
      </div>

      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default AddBodyForm