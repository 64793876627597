import { IoPricetagsOutline } from "react-icons/io5";
import { GoBriefcase } from "react-icons/go";
import { VscFlame } from "react-icons/vsc";
import { TbFilterCog, TbTruckReturn } from "react-icons/tb";
import { LuPlusCircle, LuWallet, LuWarehouse } from "react-icons/lu";
import { IoCarSportOutline } from "react-icons/io5";
import { IoMdContacts } from "react-icons/io";
import { GrAnalytics } from "react-icons/gr";
import { HiOutlineDocumentCheck } from "react-icons/hi2";
import { MdOutlineWarehouse } from "react-icons/md";


export const menuList = [
    {
      id: 1,
      title: 'Товары и услуги',
      url: 'catalog',
      icon: <IoPricetagsOutline />
    },
    {
      id:2,
      title: 'Сделки',
      url: 'sales',
      icon: <GoBriefcase />
    },
    {
      id: 4,
      title: 'Возвраты',
      url: 'returns',
      icon: <TbTruckReturn />
    },
    {
      id: 5,
      title: 'Списания',
      url: 'writeoffs',
      icon: <VscFlame />
    },
    {
      id: 6,
      title: 'Поступления',
      url: 'incomes',
      icon: <LuPlusCircle />
    },
    {
      id: 7,
      title: 'Склады',
      url: 'storages',
      icon: <LuWarehouse />

    },
    {
      id: 8,
      title: 'Автомобили-доноры',
      url: 'carsrc',
      icon: <IoCarSportOutline />
    },
    {
      id: 9,
      title: 'Клиенты',
      url: 'clients',
      icon: <IoMdContacts />
    },
    {
      id: 10,
      title: 'Платежи',
      url: 'payments',
      icon: <LuWallet />
    },
    {
      id: 11,
      title: 'Аналитика',
      url: 'analytics',
      icon: <GrAnalytics />
    },
    {
      id: 12,
      title: 'Инвентаризация',
      url: 'stocktakings',
      icon: <HiOutlineDocumentCheck />
    },
    {
      id: 13,
      title: 'Применимость',
      url: 'applicability',
      icon: <TbFilterCog  />
    },
  ]