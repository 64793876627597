import { emptySplitApi } from "../../../shared/configs/rtkBase"
import { CreateWriteoffItemDto } from "../dtos/createWriteoffItemDto"
import { UpdateWriteoffItemDto } from "../dtos/updateWriteoffItmeDto"
import { IWriteoffItem } from "../interfaces/writeoffItem"




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        createWriteoffItems: build.mutation<IWriteoffItem[], CreateWriteoffItemDto[]>({
            query: (body) => ({
                url: '/writeoff-items',
                method: 'POST',
                body
            }),
            invalidatesTags: ['WriteoffItem', 'Writeoff']
        }),
        updateWriteoffItem: build.mutation<IWriteoffItem, { id: number, body: UpdateWriteoffItemDto }>({
            query: ({ id, body }) => ({
                url: `/writeoff-items/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['WriteoffItem', 'Writeoff']
        }),
        getAllWriteoffItems: build.query<IWriteoffItem[], void>({
            query: () => '/writeoff-items',
            providesTags: ['WriteoffItem']
        }),
        getOneWriteoffItem: build.query<IWriteoffItem, number>({
            query: (id) => `/writeoff-items/${id}`,
            providesTags: ['WriteoffItem']
        }),
        deleteWriteoffItem: build.mutation<void, number>({
            query: (id) => ({
                url: `/writeoff-items/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['WriteoffItem', 'Writeoff']
        })
    })
})

export const {
    useCreateWriteoffItemsMutation,
    useUpdateWriteoffItemMutation,
    useGetOneWriteoffItemQuery,
    useGetAllWriteoffItemsQuery,
    useDeleteWriteoffItemMutation
} = extendedApi