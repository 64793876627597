import { FC, useEffect } from 'react'
import styles from './EditBodyForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import {IBody, UpdateBodyDto, useUpdateBodyMutation } from '../../../../../../entities/body'



interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
body?: IBody
}

const EditBodyForm: FC<IProps> = ({setIsModalOpen, body}) => {
  const [messageApi, contextHolder] = useMessage()
  const [updateBody] = useUpdateBodyMutation()
const {control, handleSubmit, reset, setValue} = useForm({
  defaultValues: {
    title: ''
  }
})

const onSubmit: SubmitHandler<UpdateBodyDto> = async (data)=> {
  if(body) {
    await updateBody({id: body.id, body: data})
    messageApi.success('Кузов успешно обновлен')
    reset()
    setIsModalOpen(false)
  }
    
}

   useEffect(()=> {
    if(body) {
      setValue('title', body.title)
    }
   }, [body])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input  id='title' {...field}/>
        )}
        />
      </div>

      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default EditBodyForm