import { useRef, useState } from 'react';
import styles from './AddStorageModal.module.scss'
import { Button, Input, message, Modal, notification, NotificationArgsProps, Switch } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CreateStorageDto, useCreateStorageMutation } from '../../../../../entities/storage';




type NotificationPlacement = NotificationArgsProps['placement'];


const AddStorageModal = () => {
  const [api, notificationContextHolder] = notification.useNotification();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(true)
  const [createStorage] = useCreateStorageMutation()
  const formRef = useRef<HTMLFormElement>(null)
  const { control, handleSubmit, reset, watch } = useForm<CreateStorageDto>({
    defaultValues: {
      name: '',
      location: undefined
    }
  })

  const info = () => {
    messageApi.success('Запись успешно сохранена!');
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.warning({
      message: 'Не удалось сохранить',
      description:
        'Пожалйста заполните обязательные поля',
      placement,
    });
  };

  const onSubmit: SubmitHandler<CreateStorageDto> = async (data) => {

    await createStorage(data)
    setIsModalOpen(false)
  }


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    const {name}  = watch()
    if (name) {
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
      reset()
      info()
      setIsModalOpen(false);
    } else {
      setIsValid(false)
      openNotification('top')
    }


  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  return (
    <>
      {notificationContextHolder}
      {messageContextHolder}
      <Button onClick={showModal} type='primary' >Добавить склад</Button>
      <Modal width={800} className={styles.root} title="Создание склада" okText={'Создать склад'} cancelText={'Отменить'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className={styles.content} >
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={styles.form} action="">
             <div className={styles.formItem} >
               <label htmlFor='name' >Название</label>
              <Controller
              name='name'
              control={control}
              render ={({field})=> (
                <Input status={!isValid ? 'error': undefined} id='name' {...field} placeholder='Название' />
              )}
              />
             </div>
             <div className={styles.formItem} >
               <label htmlFor='location' >Адрес</label>
              <Controller
              name='location'
              control={control}
              render ={({field})=> (
                <Input id='location' {...field} placeholder='Адрес' />
              )}
              />
             </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default AddStorageModal