import { Controller, useForm } from 'react-hook-form'
import styles from './ReturnUpdateForm.module.scss'
import { Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC, useEffect} from 'react'
import { ISaleItem } from '../../../../../entities/saleItem'
import { useGetAllClientsQuery } from '../../../../../entities/client'
import { ISale} from '../../../../../entities/sale'
import { useGetAllStoragesQuery } from '../../../../../entities/storage'
import { useAppDispatch } from '../../../../../shared/hooks/reduxToolkit'
import { setSaleMessage } from '../../../../(sales)/salePage'
import { GoBriefcase } from 'react-icons/go'



interface IProps {
  saleItems: ISaleItem[]
  sale?: ISale
  setComment: React.Dispatch<React.SetStateAction<string | undefined>>
  comment?: string
}

const ReturnUpdateForm: FC<IProps> = ({sale, saleItems, setComment, comment}) => {
  const {data: clients } = useGetAllClientsQuery()
  const {data: storages} = useGetAllStoragesQuery()
  const dispatch = useAppDispatch()
  const {control, setValue} = useForm<{clientId?: number, comment?: string, storageId?: number}>( {
    defaultValues: {
        clientId: undefined,
        storageId: undefined
    }
  })

    useEffect(()=> {
        setValue('clientId', sale?.client?.id)
        setValue('storageId', sale?.storage?.id)
    }, [sale])


  

  return (
    <form className={styles.root} >
    
    <div className={styles.formPart} >
    <div className={styles.header} >
    <GoBriefcase /> {'Возврат по сделке' + (sale?.id ? ` №${sale?.id}` : '')}
        </div>
        <div className={styles.counterHolder} >
            <div className={styles.counter} >
                <p className={styles.counterTitle} >Сумма возврата</p>
                <p className={styles.counterCount} >
                    {saleItems.reduce((sum, item)=> sum  + (item.discountedPrice || item.product.char_price || 0) * item.quantity, 0 )}
                    
                </p>
            </div>
        </div>

        <div className={styles.formItem} >
            <Controller
                name='clientId'
                control={control}
                render={({ field }) => (
                    <Select disabled value={field.value} onChange={(value)=> {
                    }} options={clients?.data?.map(({ id, name }) => ({ label: name, value: id })) || []} placeholder='Выберите клиента' className={styles.formItem_select}
                        optionFilterProp="label"
                    />
                )}
            />
        </div>
        <div className={styles.formItem} >
                    <TextArea
                    value={comment}
                    onChange={(e)=> setComment(e.target.value)}
                    autoSize={{ minRows: 3 }} placeholder='Комментарий к возврату' />
     
        </div>
    </div>
    <div className={styles.formPart} >
        <div className={styles.formItem} >
            <label>Склад возврата</label>
            <Controller
                name='storageId'
                control={control}
                render={({ field }) =>
                    <Select disabled={sale?.status === 'issued'}
                        value={field.value}
                       onChange={(value)=> {
                        field.onChange(value)
                     
                        dispatch(setSaleMessage('Склад изменен'))
                    }}
                options={storages?.map(({id, name})=> ({label: name, value: id})) || []} />
                }
            />
        </div>
        <div className={styles.divider} />
    
    </div>


</form>
  )
}

export default ReturnUpdateForm