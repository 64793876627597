import styles from './ClientsPage.module.scss'
import React, { useState } from 'react';
import {Input, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { IClient, useGetAllClientsQuery } from '../../../../../entities/client';
import { useNavigate } from 'react-router-dom';
import AddClientModal from '../components/addClientModal';




const columns: TableColumnsType<IClient> = [

  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'ФИО или название',
    dataIndex: 'name',
  },
  {
    title: 'Тип клиента',
    dataIndex: 'isLegalPerson',
    render: (text)=> <p>{text ? 'Юр. лицо': 'Физ. лицо'}</p>
  },
  {
    title: 'Эл.почта',
    dataIndex: 'email',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    render: (text)=> <p>{text ? `+7 (${text.slice(0, 3)}) ${text.slice(3,6)} ${text.slice(6,8)} ${text.slice(8,10)}`: ''}</p>
  },
];


export const ClientsPage: React.FC = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data: clientsData, isLoading} = useGetAllClientsQuery({pagination: {page, pageSize}})
  const clients = clientsData?.data
  const pagination = clientsData?.meta?.pagination


 const onPageChange = (page: number, pageSize: number) => {
     setPage(page)
     setPageSize(pageSize)
 }
  
  return (
    <main className={styles.root} >
     <div className={styles.header} >
    <h1>Клиенты</h1>
   
     </div>
    <div className={styles.content} >
      <div className={styles.content_header} >
        <Input className={styles.search} />
        <AddClientModal/>
      </div>
    <Table
    className={styles.table}
        loading={isLoading}
        columns={columns}
        dataSource={clients?.map((item)=>({...item, key: item.id}))}  
        pagination={{defaultPageSize: 10, showSizeChanger: true, total: pagination?.total, current: page, pageSize, onChange: onPageChange}}
        onRow={(item)=> ({onClick: ()=> navigate(`/clients/${item.id}`)})}
      />
    </div>
    </main>

  );
};


