import { Controller, useForm } from 'react-hook-form'
import styles from './ReturnUpdateForm.module.scss'
import { message, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { FC, useEffect, useMemo, useState} from 'react'
import { useGetAllClientsQuery } from '../../../../../entities/client'
import { useGetAllStoragesQuery } from '../../../../../entities/storage'
import { useAppDispatch } from '../../../../../shared/hooks/reduxToolkit'
import { setSaleMessage } from '../../../../(sales)/salePage'
import { GoBriefcase } from 'react-icons/go'
import { IReturn, useUpdateReturnMutation } from '../../../../../entities/return'



interface IProps {
  returnData?: IReturn
  setReturnMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ReturnUpdateForm: FC<IProps> = ({returnData, setReturnMessage}) => {
  const { data: clients } = useGetAllClientsQuery()
  const {data: storages} = useGetAllStoragesQuery()
  const [comment, setComment] = useState<string>()
  const [updateReturn] = useUpdateReturnMutation()
  const dispatch = useAppDispatch()
  const returnedSum = useMemo(()=> returnData?.returnItems.reduce((sum, item)=> {
    const discountedPrice = item?.saleItem?.discountedPrice
    const price = item?.saleItem?.product?.char_price
    return sum + (discountedPrice || price || 0) * item.quantity
  }, 0), [returnData])
  const {control, setValue} = useForm<{clientId?: number, comment?: string, storageId?: number}>( {
    defaultValues: {
        clientId: undefined,
        storageId: undefined
    }
  })

    useEffect(()=> {
        setValue('clientId', returnData?.sale?.client?.id)
        setValue('storageId', returnData?.sale?.storage?.id)
        setComment(returnData?.comment)
    }, [returnData])


    const onSubmit = async ()=> {
      if(returnData?.id) {
        const res = await updateReturn({
            id: returnData.id,
            body: {
                comment
            }
           })
           if('error' in res) {
            message.error('Ошибка сервера')
           }
      }
    }

  return (
    <form className={styles.root} >
    
    <div className={styles.formPart} >
    <div className={styles.header} >
    <GoBriefcase /> {'Возврат по сделке' + (returnData?.id ? ` №${returnData?.id}` : '')}
        </div>
        <div className={styles.counterHolder} >
            <div className={styles.counter} >
                <p className={styles.counterTitle} >Сумма возврата</p>
                <p className={styles.counterCount} >
                    {returnedSum} 
                </p>
            </div>
        </div>

        <div className={styles.formItem} >
            <Controller
                name='clientId'
                control={control}
                render={({ field }) => (
                    <Select disabled value={field.value}  options={clients?.data?.map(({ id, name }) => ({ label: name, value: id })) || []} placeholder='Выберите клиента' className={styles.formItem_select}
                        optionFilterProp="label"
                    />
                )}
            />
        </div>
        <div className={styles.formItem} >
                    <TextArea 
                    value={comment}
                    onChange={(e)=> setComment(e.target.value)}
                    onBlur={(e)=> {onSubmit(); setReturnMessage('Комментарий изменен')}}
                    autoSize={{ minRows: 3 }} placeholder='Комментарий к возврату' />
        </div>
    </div>
    <div className={styles.formPart} >
        <div className={styles.formItem} >
            <label>Склад возврата</label>
            <Controller
                name='storageId'
                control={control}
                render={({ field }) =>
                    <Select disabled={returnData?.sale.status === 'issued'}
                        value={field.value}
                       onChange={(value)=> {
                        field.onChange(value)
                        dispatch(setSaleMessage('Склад изменен'))
                    }}
                options={storages?.map(({id, name})=> ({label: name, value: id})) || []} />
                }
            />
        </div>
        <div className={styles.divider} />
    
    </div>


</form>
  )
}

export default ReturnUpdateForm