import { Button, Radio } from 'antd'
import styles from './CatalogNavbar.module.scss'
import { Link, useLocation } from 'react-router-dom'



const navItems = [
  {
    id: 1,
    title: 'Автозапчасти',
    url: '/catalog'
  },
  {
    id: 2,
    title: 'Шины и диски',
    url: '/catalog/wheels'
  },
  {
    id: 3,
    title: 'Услуги',
    url: '/catalog/services'
  }
]


export const CatalogNavbar = () => {
  const {pathname} = useLocation()

  const saveChange = (value: string)=> {
    localStorage.setItem('catalog_changedRadio', value)
  }


  return (
    <div className={styles.root} >
      <div className={styles.leftSide}  >
        <h1>Товары и услги</h1>
        <nav className={styles.navbar} >
          {navItems.map(({id, title, url})=> (
            <Link key={id} to={url} ><Button type={url === pathname ? 'primary':'text'} >{title}</Button></Link>
          ))}

        </nav>
      </div>
      <div className={styles.rightSide} >
        <div>Список пуст</div>
        <div >
            <Radio.Group
            onChange={(e)=> saveChange(e.target.value)}
            defaultValue={'sale'}
            optionType='button'
            buttonStyle='solid'
            >
              <Radio.Button  value={'sale'} >Продажа</Radio.Button>
              <Radio.Button value={'edit'} >Редактирование</Radio.Button>
            </Radio.Group>
        </div>
      </div>
    </div>
  )
}
