import { Button, Popconfirm } from 'antd'
import { useGetOneClientQuery } from '../../../../../entities/client'
import UpdateClientForm from '../components/updateClientForm'
import styles from './ClientPage.module.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { IoIosArrowBack } from 'react-icons/io'



export const ClientPage = () => {
  const clientId = window.location.pathname.split('/')[2]
  const [saveBtnTrigger, setSaveBtnTrigger] = useState<boolean>(false)
  const [deleteBtnTrigger, setDeleteBtnTrigger] = useState<boolean>(false)
  const {data: client} = useGetOneClientQuery(clientId)
 const {name} = client || {}
 

  return (

    <main >
      <div className={styles.header} >
      <Link to='/clients' className={styles.returnLink} ><IoIosArrowBack /></Link>
        <div className={styles.title} >{name}</div>
      </div>
      <div className={styles.content} >
        <div className={styles.content_inner} >
          <div className={styles.content_header} >
            <h2 className={styles.content_title} >Данные клиента</h2>
            <div className={styles.buttonsHolder} >
              <Popconfirm
               title="Удаление клиента"
               description="Уверены что хотите удалить?"
               onConfirm={()=> setDeleteBtnTrigger(true)}
               okText="Да"
               cancelText="Нет"
              ><Button danger type='primary' >Удалить</Button>
              </Popconfirm>
              <Button onClick={()=> setSaveBtnTrigger(true)} type='primary' >Сохранить</Button>
            </div>
          </div>
          <div className={styles.content_holder} >
          <div className={styles.updateClientFormHolder} ><UpdateClientForm saveBtnTrigger={saveBtnTrigger} setSaveBtnTrigger={setSaveBtnTrigger} deleteBtnTrigger={deleteBtnTrigger}  client={client} /></div>
          </div>
       
        </div>
      </div>
    </main>

  )
}
