import { FC, useEffect, useState } from 'react'
import styles from './ApplicabilityEdit.module.scss'
import { ApplicabilityModal } from '../../../../../widgets/applicabilityModal'
import { IoExtensionPuzzleOutline } from 'react-icons/io5'
import { IProduct, useUpdateProductMutation } from '../../../../../entities/product'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { bodysSelector, enginesSelector, generationsSelector, marksSelector, modelsSelector, resetApplicabity, setBodys, setEngines, setGenerations, setMarks, setModels } from '../../../../../entities/applicability'
import { message } from 'antd'


interface IProps {
  product?: IProduct
}


export const ApplicabilityEdit: FC<IProps> = ({product}) => {
  const [isApplicabilityModalOpen, setIsApplicabilityModalOpen] = useState<boolean>(false)
  const [updateProduct] = useUpdateProductMutation()
  const checkedMarks = useAppSelector(marksSelector)
  const checkedModels = useAppSelector(modelsSelector)
  const checkedGenerations = useAppSelector(generationsSelector)
  const checkedBodys = useAppSelector(bodysSelector)
  const checkedEngines = useAppSelector(enginesSelector)
  const dispatch = useAppDispatch()


  const setValues = ()=> {
    const {marks, models, generations, bodys, engines } = product || {}
    marks && dispatch(setMarks(marks))
    models && dispatch(setModels(models))
    generations && dispatch(setGenerations(generations))
    bodys && dispatch(setBodys(bodys))
    engines && dispatch(setEngines(engines))
  }
 
 

  useEffect(()=> {
       setValues()
  }, [product])

 
  const applicabilitySubmit = async ()=> {
    const markIds = checkedMarks.map(({id})=> id)
    const modelIds = checkedModels.map(({id})=> id)
    const generationIds = checkedGenerations.map(({id})=> id)
    const bodyIds = checkedBodys.map(({id})=> id)
    const engineIds = checkedEngines.map(({id})=> id)
    if(product) {
      const res = await updateProduct({id: product?.id, body: {markIds, modelIds, generationIds, bodyIds, engineIds}})
       if('data' in res) {
        message.success("Успешно сохранено")
       } else if('error' in res) {
        message.error('Ошибка сервера')
       }
    } 
     dispatch(resetApplicabity())
  }

   const onCancel = ()=> {
    setValues()
   }
  
   useEffect(()=> {
    
    return ()=> {dispatch(resetApplicabity())}
   }, [])

  return (
    <div className={styles.root} >
       <ApplicabilityModal onCancel={onCancel} onConfirm={applicabilitySubmit} isModalOpen={isApplicabilityModalOpen} setIsModalOpen={setIsApplicabilityModalOpen} />
        
               { 
                 checkedMarks.length > 0 ? 
                 <div className={styles.applicabilityEdit} >
                    <p><IoExtensionPuzzleOutline/> Применимость задана</p>
                    <button onClick={()=> setIsApplicabilityModalOpen(true)} >Редактировать</button>
                      </div>:
                 <div className={styles.applicabilityAdd} >
                     <p>Применимость не задана</p>
                     <button onClick={()=> setIsApplicabilityModalOpen(true)} ><IoExtensionPuzzleOutline />  Задать</button>
                  </div> 
                }
    </div>
  )
}
