import { Input, Select, Space } from "antd";
import styles from "./SpeedIndexFormPart.module.scss";
import { Control, Controller } from "react-hook-form";
import { FC } from "react";
import { IWheelsFormValues } from "../../../../../interfaces/wheelsFormValues";
import { tireLoadCoefOptions, tireSpeedIndexOptions } from "../../../../../../../../../../../../entities/product";

interface IProps {
  control: Control<IWheelsFormValues, any>;
}

export const SpeedIndexFormPart: FC<IProps> = ({ control }) => {
  return (
    <div className={styles.rowWrapper}>
      <Space.Compact>
        <div className={styles.formItem}>
          <Controller
            name="char_tireSpeedIndex"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                filterOption={(input, option) =>
                  (String(option?.value) ?? "")
                    .toLocaleLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Ин. скорости"
                options={tireSpeedIndexOptions}
                optionRender={({ data: { label, desc } }) => (
                  <div style={{ display: "flex" }}>
                    {" "}
                    <p style={{ flex: "1" }}>{label}</p>{" "}
                    <p style={{ flex: "1", color: "rgb(18 18 18 / 50%)" }}>
                      {desc}
                    </p>{" "}
                  </div>
                )}
              />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="char_tireLoadCoef"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                showSearch
                filterOption={(input, option) =>
                  (String(option?.value) ?? "")
                    .toLocaleLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Ин. нагрузки"
                options={tireLoadCoefOptions}
                optionRender={({ data: { label, desc } }) => (
                  <div style={{ display: "flex" }}>
                    {" "}
                    <p style={{ flex: "1" }}>{label}</p>{" "}
                    <p style={{ flex: "1", color: "rgb(18 18 18 / 50%)" }}>
                      {desc}
                    </p>{" "}
                  </div>
                )}
              />
            )}
          />
        </div>
      </Space.Compact>
      <Space.Compact>
        <div className={styles.formItem}>
          <Controller
            name="char_tireProducer"
            control={control}
            render={({ field }) => (
              <Input placeholder="Производитель" {...field} />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="char_tireModel"
            control={control}
            render={({ field }) => <Input placeholder="Модель" {...field} />}
          />
        </div>
      </Space.Compact>
      <div className={styles.formItem}>
        <Controller
          name="char_tireProduceDate"
          control={control}
          render={({ field }) => <Input placeholder="Год пр-ва" {...field} />}
        />
      </div>
    </div>
  );
};
