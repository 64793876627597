import { FC } from 'react'
import styles from './Navbar.module.scss'
import cs from 'classnames'




const navList = [
  {
    id: 1, 
    title: 'Новые',
    value: 'draft'
  },
  {
    id: 2, 
    title: 'В работе',
    value: 'reserve'
  },
  {
    id: 3, 
    title: 'Выданные',
    value: 'issued'
  },
  {
    id: 4, 
    title: 'Отмененные',
    value: 'canceled'
  },
  {
    id: 5,
    title: 'Все сделки',
    value: 'all'
  }
]

interface IProps {
  selectedItem: "draft" |"reserve" |"issued" |"canceled"| "all"
  setSelectedItem : React.Dispatch<React.SetStateAction<"draft" |"reserve" |"issued" |"canceled"| "all">>
}

const Navbar: FC<IProps> = ({selectedItem, setSelectedItem}) => {
  

  return (
    <div className={styles.root} >
        <nav className={styles.inner} >
            <ul className={styles.salesNavList} >
               {
                navList.map(({id, title, value})=> (
                  <li key={id} onClick={()=> setSelectedItem(value as "draft" |"reserve" |"issued" |"canceled"| "all")} className={cs(styles.navItem, value === selectedItem && styles.navItem__active)} >{title}</li>
                ))
               }
            </ul>
        </nav>
    </div>
  )
}

export default Navbar