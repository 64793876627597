import styles from './Parts.module.scss'
import { Button, Input, Table } from 'antd'
import type { TableColumnsType } from 'antd';
import { IoOptionsOutline } from 'react-icons/io5'
import { useEffect, useState } from 'react';
import ApplicabilitySearch from '../applicabilitySearch';
import { addProduct, IProduct, productsSelector, removeProduct, useGetAllProductsQuery } from '../../../../entities/product';
import { IMark } from '../../../../entities/mark';
import { IModel } from '../../../../entities/model';
import { IGeneration } from '../../../../entities/generation';
import { IBody } from '../../../../entities/body';
import { IEngine } from '../../../../entities/engine';
import { serverUrl } from '../../../../shared/constants/serverUrl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../shared/hooks/reduxToolkit';
import cs from 'classnames'



const Parts = () => {
  const [searchParams, setSearchParams] = useState<
    { mark?: IMark, model?: IModel, generation?: IGeneration, body?: IBody, engine?: IEngine }
  >({ mark: undefined, model: undefined, generation: undefined, body: undefined, engine: undefined })
  const { mark, model, generation, body, engine } = searchParams
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data: products, isLoading } = useGetAllProductsQuery({
    pagination: { page, pageSize }, category: 'part',
    applicability: {
      mark: mark?.id,
      model: model?.id,
      generation: generation?.id,
      body: body?.id,
      engine: engine?.id
    }
  })
  const { pagination } = products?.meta || {}
  const dispatch = useDispatch()
  const selectedProducts = useAppSelector(productsSelector)



  useEffect(() => {
    const savedPage = sessionStorage.getItem('catalog-table-page')
    const savedPageSize = sessionStorage.getItem('catalog-table-pageSize')
    if (savedPage) {
      setPage(+savedPage)
    }
    if (savedPageSize) {
      setPageSize(+savedPageSize)
    }
  }, [])


  const onPageChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
    sessionStorage.setItem('catalog-table-page', String(page))
    sessionStorage.setItem('catalog-table-pageSize', String(pageSize))
  }

  const onPlusClick = (product: IProduct) => {
    dispatch(addProduct(product))
  }

  const onMinusClick = (product: IProduct) => {
    dispatch(removeProduct(product))
  }

  const columns: TableColumnsType<IProduct> = [
    {
      title: 'ID',
      width: '24px',
      dataIndex: 'id',
    },
    {
      title: 'Превью',
      dataIndex: 'images',
      width: '40px',
      render: (item) => { return <div className={styles.imageHolder} >{item[0]?.url && <img className={styles.previewImage} src={serverUrl + item[0]?.url} />}</div> }
    },
    {
      title: 'Запчасть',
      dataIndex: 'char_part',
    },
    {
      title: 'Марка',
      dataIndex: 'char_carsrc_mark'
    },
    {
      title: 'Модель',
      dataIndex: 'char_carsrc_model'
    },
    {
      title: 'Цена',
      dataIndex: 'char_price'
    },
    {
      title: 'Двигатель',
      dataIndex: 'char_carsrc_engine'
    },
    {
      title: 'Год выпуска',
      dataIndex: 'char_carsrc_year'
    },
    {
      title: 'Передний/Задний',
      dataIndex: 'char_fb',
      render: (item) => item && (item === 'forward' ? 'передний' : 'задний')
    },
    {
      title: 'Левый/Правый',
      dataIndex: 'char_lr',
      render: (item) => item && (item === 'right' ? 'правый' : 'левый')
    },
    {
      title: 'Склад',
      dataIndex: 'income',
      render: (_, product) => product?.income?.storage?.name,
    },
    {
      title: 'Наличие',
      dataIndex: 'stock',
      width: '100px',
      onCell: () => ({ onClick: (e) => { e.stopPropagation() } }),
      render: (item, product) => {
        const active = selectedProducts.some((item) => item.product.id === product.id)
        return <div className={cs(styles.stock, active && styles.stock__active)} >
          <div className={styles.quantityCounter} >
            <Button onClick={() => onMinusClick(product)} size='small' >-</Button>
            <div>{selectedProducts.find(({ product: { id } }) => id === product.id)?.quantity || 0}</div>
            <Button onClick={() => onPlusClick(product)} size='small' >+</Button>
          </div>
          <div className={styles.stockValue}>{item}</div>
        </div>
      }
    }
  ]

  return (

    <div className={styles.root} >
      <div className={styles.header} >
        <div className={styles.searchWrapper} >
          <Input className={styles.searchById} placeholder='Поиск по номеру' />
          <ApplicabilitySearch searchParams={searchParams} setSearchParams={setSearchParams} />
        </div>
        <div className={styles.options} ><IoOptionsOutline /></div>
      </div>
      <div className={styles.content} >
        <Table
          loading={isLoading}
          columns={columns}
          rowKey={(item) => item.id}
          onRow={() => ({ onClick: () => console.log('click on row') })}
          pagination={{ showSizeChanger: true, defaultPageSize: 10, total: pagination?.total, pageSize, current: page, onChange: onPageChange }}
          dataSource={products?.data?.map((item) => ({ ...item, key: item?.id }))}
        />
      </div>
    </div>

  )
}

export default Parts