import { Table, TableColumnsType } from 'antd'
import styles from './WriteoffsTable.module.scss'
import { useNavigate } from 'react-router-dom';
import { FC, useState } from 'react';
import { IWriteoff, useGetAllWriteoffsQuery } from '../../../../../entities/writeoff';



const columns: TableColumnsType<IWriteoff> = [
  {
    title: 'Номер/дата',
    render: (_, item) => {
      const date = item.createdAt && new Date(item.createdAt.toString());
      const day = date?.toLocaleString("ru-Ru", { day: 'numeric' })
      const month = date?.toLocaleString("ru-Ru", { month: "long" });
      const year = date?.toLocaleString("ru-Ru", { year: 'numeric' })
      return (<div className={styles.idWrapper} >
        <p className={styles.id} >{item?.id}</p>
        <p className={styles.date} > {`${day} ${month?.slice(0, 3)} ${year?.slice(2)}`}</p>
      </div>)
    }
  },
  {
    title: 'Склад списания',
    render: (_, item) => item?.storage?.name
  },
  {
    title: 'Статус',
    render: (_, item) => {
      switch (item?.status) {
        case 'draft': return 'Черновик'
        case 'complete': return 'Выполнен'
        case 'canceled': return 'Отменен'
      }
    }
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment'
  }

];


interface IProps {
  status: "draft" | "complete" | "canceled" | "all"
}

const WriteoffsTable: FC<IProps> = ({ status }) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data: WriteoffsData, isLoading } = useGetAllWriteoffsQuery({ status, pagination: { page, pageSize } })
  const pagination = WriteoffsData?.meta?.pagination
  const writeoffs = WriteoffsData?.data
  const navigate = useNavigate()

  const onPageChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }


  return (

    <Table
      loading={isLoading}
      className={styles.table}
      size='small'
      columns={columns}
      dataSource={writeoffs}
      rowKey={(item) => item?.id}
      pagination={{ defaultPageSize: 10, showSizeChanger: true, total: pagination?.total, pageSize, current: page, onChange: onPageChange }}
      onRow={(item) => ({ onClick: () => navigate(`/writeoffs/${item?.id}`) })}
    />
  )
}

export default WriteoffsTable