import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateBodyDto } from "../dtos/createBodyDto";
import { UpdateBodyDto } from "../dtos/updateBodyDto";
import { IBody } from "../interfaces/body";






const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        getOneBody: build.query<IBody, string>({
            query: (id)=> `/bodys/${id}`,
            providesTags: ['Body']
        }),
        getAllBodys: build.query<IBody[], string>({
            query: (generationId)=> `/bodys?generationId=${generationId}`,
            providesTags: ['Body']
        }),
        createBody: build.mutation<IBody, CreateBodyDto>({
            query: (body)=> ({
                url: '/bodys',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Body']
        }),
        updateBody: build.mutation<IBody, {id: number, body: UpdateBodyDto}>({
            query: ({id, body})=> ({
                url: `/bodys/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Body']
        }),
        deleteBody: build.mutation<void, string>({
            query: (id)=> ({
                url: `/bodys/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Body']
        })
    })
})


export const {useGetOneBodyQuery, useGetAllBodysQuery, useLazyGetAllBodysQuery, useCreateBodyMutation, useUpdateBodyMutation, useDeleteBodyMutation} = extendedApi