import styles from './TireFormPart.module.scss'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import cs from 'classnames'
import { Input, InputNumber, Segmented, Select, Switch } from 'antd'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { IProduct, tireDiametrInchOptions, tireDiametrMmOptions, tireHeightInchOptions, tireHeightMmOptions, tireHeightPercentOptions, tireLoadCoefOptions, tireSpeedIndexOptions, tireWearoutOptions, tireWidthInchOptions, tireWidthMmOptions, UpdateProductDto } from '../../../../../../../../../entities/product'
import TemplateSelect from '../templateSelect'
import { tireTemplateOptions } from '../../constants/tireTemplateOptions'
import { RiErrorWarningFill } from 'react-icons/ri'
import { JsxElement } from 'typescript'





interface IProps {
  product?: IProduct
  control: Control<UpdateProductDto, any>
  watch: UseFormWatch<UpdateProductDto>
  setChangedParametrs: React.Dispatch<React.SetStateAction<{
    images: number;
    generalInfo: number;
    mainParameters: number;
    disk: number;
    tire: number;
    donorCar: number;
    placement: number;
  }>>
  setValue: UseFormSetValue<UpdateProductDto>
  setIsError: React.Dispatch<React.SetStateAction<{
    height: boolean;
    width: boolean;
  }>>
}

export const TireFormPart: FC<IProps> = ({ product, control, watch, setChangedParametrs, setValue, setIsError }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [templateValue, setTemplateValue] = useState<string>()
  const [templateTitle, setTemplateTitle] = useState<string>()

  const tireWidthUnits = watch('char_tireWidthUnits');
  const tireHeightUnits = watch('char_tireHeightUnits');
  const tireDiametrUnits = watch('char_tireDiametrUnits');
  const tireProtectorType = watch('char_tireProtectorType');
  const tireVehicleType = watch('char_tireVehicleType');
  const tireWidth = watch('char_tireWidth')
  const tireHeight = watch('char_tireHeight')



  useEffect(() => {
    setTemplateValue(`${watch('char_tireWidthUnits')}, ${watch('char_tireDiametrUnits')}, ${watch('char_tireVehicleType')}, ${watch('char_tireProtectorType')}${watch('char_tireHeightUnits') ? `, ${watch('char_tireHeightUnits')}` : ''}`)
  }, [watch('char_tireWidthUnits'),
    watch('char_tireDiametrUnits'),
    watch('char_tireVehicleType'),
    watch('char_tireProtectorType'),
    watch('char_tireHeightUnits')])



  useEffect(() => {
    setTemplateTitle(tireTemplateOptions.find((item) => item.value === templateValue)?.label)
  }, [templateValue])


  const onItemSelect = (name: string) => {
    setSelectedItems(prev => [...prev, name])
  }




  const shouldDeleteOption = (name: string) => ((name === 'char_tireLT' || name === 'char_tireRunFlat') && (tireProtectorType !== 'Стандартная' || tireVehicleType === 'Спецтехника')) || (name === 'char_tireHeight' && !tireHeightUnits)
  const wrongOption = useCallback((name: string) => {
    return (name === 'char_tireWidth' && tireWidthUnits !== product?.char_tireWidthUnits && tireWidth === product?.char_tireWidth) ||
      (name === 'char_tireHeight' && tireHeightUnits !== product?.char_tireHeightUnits && tireHeight === product?.char_tireHeight && tireHeightUnits)
  }, [tireWidthUnits, tireHeightUnits, tireWidth, tireHeight])



  useEffect(() => {
    const wrongWidth = wrongOption('char_tireWidth')
    const wrongHeight = wrongOption('char_tireHeight')
    if (wrongWidth) {
      setIsError(prev => ({ ...prev, width: true }))
    } else if (!wrongWidth) {
      setIsError(prev => ({ ...prev, width: false }))
    }
    if (wrongHeight) {
      setIsError(prev => ({ ...prev, height: true }))
    } else if (!wrongHeight) {
      setIsError(prev => ({ ...prev, height: false }))
    }
  }, [wrongOption])


  const resetTemplate = () => {
    setTemplateValue(`${product?.char_tireWidthUnits}, ${product?.char_tireDiametrUnits}, ${product?.char_tireVehicleType}, ${product?.char_tireProtectorType}${product?.char_tireHeightUnits ? `, ${product?.char_tireHeightUnits}` : ''}`)
    setValue('char_tireWidth', product?.char_tireWidth)
    setValue('char_tireHeight', product?.char_tireHeight)
    setValue('char_tireDiametr', product?.char_tireDiametr)
    setValue('char_tireVehicleType', product?.char_tireVehicleType)
    setValue('char_tireProtectorType', product?.char_tireProtectorType)
    setValue('char_tireWidthUnits', product?.char_tireWidthUnits)
    setValue('char_tireHeightUnits', product?.char_tireHeightUnits)
    setValue('char_tireDiametrUnits', product?.char_tireDiametrUnits)
  }

  const onCancelItemSelect = (name: any) => {
    setValue(name, product?.[name as keyof IProduct])
    setSelectedItems(prev => prev.filter((item) => name !== item))
    if (name === 'template') {
      resetTemplate()
    }
  }


  useEffect(() => {
    setChangedParametrs((prev) => ({ ...prev, tire: selectedItems.length }))
  }, [selectedItems])

  const widthOptions = useMemo(() => {
    const options = tireWidthUnits === 'мм' ? tireWidthMmOptions : tireWidthInchOptions
    return options.map(value => ({ value }))
  }, [tireWidthUnits])

  const heightOptions = useMemo(() => {
    const options = tireHeightUnits === 'мм' ? tireHeightMmOptions : tireHeightUnits === 'дюйм' ? tireHeightInchOptions : tireHeightPercentOptions
    return options.map(value => ({ value }))
  }, [tireHeightUnits])

  const diametrOptions = useMemo(() => {
    const options = tireDiametrUnits === 'мм' ? tireDiametrMmOptions : tireDiametrInchOptions
    return options.map(value => ({ value }))
  }, [tireDiametrUnits])

  const itemsList = [
    {
      name: 'template',
      title: 'Тип шины',
      value: templateTitle,
      input: <TemplateSelect watch={watch} setValue={setValue} product={product} templateValue={templateValue} />
    },
    {
      name: 'char_tireLT',
      title: 'Легкогрузовая (LT)',
      value: watch('char_tireLT') ? 'Да' : 'Нет',
      input: <Controller
        name='char_tireLT'
        control={control}
        render={({ field }) => (
          < Switch {...field} checkedChildren='Да' unCheckedChildren='Нет' />
        )}
      />
    },
    {
      name: 'char_tireRunFlat',
      title: 'Runflat',
      value: watch('char_tireRunFlat') ? 'Да' : 'Нет',
      input: <Controller
        name='char_tireRunFlat'
        control={control}
        render={({ field }) => (
          <Switch {...field} checkedChildren='Да' unCheckedChildren='Нет' />
        )}
      />
    },
    {
      name: 'char_tireSeason',
      title: 'Сезон',
      value: watch('char_tireSeason') || '-',
      input: <Controller
        name='char_tireSeason'
        control={control}
        render={({ field }) => (
          <Select {...field} options={[
            { label: 'Всесезонная', value: 'Всесезонная' },
            { label: 'Зимняя (липучка)', value: 'Зимняя (липучка)' },
            { label: 'Зимняя с шипами', value: 'Зимняя с шипами' },
            { label: 'Летняя', value: 'Летняя' }
          ]} />
        )}
      />
    },
    {
      name: 'char_tireWidth',
      title: watch('char_tireWidthUnits') === 'мм' ? 'Ширина, мм' : 'Ширина, дюймов',
      value: watch('char_tireWidth') || '-',
      input: <Controller
        name='char_tireWidth'
        control={control}
        render={({ field }) => (
          <Select {...field} options={widthOptions} />
        )}
      />
    },
    {
      name: 'char_tireHeight',
      title: tireHeightUnits === 'дюйм' ? 'Высота, дюйм' : tireHeightUnits === 'мм' ? 'Высота, мм' : 'Высота, %',
      value: watch('char_tireHeight') || '-',
      input: <Controller
        name='char_tireHeight'
        control={control}
        render={({ field }) => (
          <Select {...field} options={heightOptions} />
        )}
      />
    },
    {
      name: 'char_tireCordType',
      title: 'Тип конструкции',
      value: watch('char_tireCordType') || '-',
      input: <Controller
        name='char_tireCordType'
        control={control}
        render={({ field }) => (
          <Segmented {...field} options={[{ label: 'R', value: 'R' }, { label: 'D', value: 'D' }, { label: '-', value: '' }]} />
        )}
      />
    },
    {
      name: 'char_tireDiametr',
      title: watch('char_tireDiametrUnits') === 'мм' ? 'Диаметр, мм' : 'Диаметр, дюйм',
      value: watch('char_tireDiametr') || '-',
      input: <Controller
        name='char_tireDiametr'
        control={control}
        render={({ field }) => (
          <Select {...field} options={diametrOptions} />
        )}
      />
    },
    {
      name: 'char_tireSpeedIndex',
      title: 'Индекс скорости',
      value: watch('char_tireSpeedIndex') || '-',
      input: <Controller
        name='char_tireSpeedIndex'
        control={control}
        render={({ field }) => (
          <Select {...field} options={tireSpeedIndexOptions} />
        )}
      />
    },
    {
      name: 'char_tireLoadCoef',
      title: 'Индекс нагрузки',
      value: watch('char_tireLoadCoef'),
      input: <Controller
        name='char_tireLoadCoef'
        control={control}
        render={({ field }) => (
          <Select {...field} options={tireLoadCoefOptions} />
        )}
      />
    },
    {
      name: 'char_tireProduceDate',
      title: 'Год производства',
      value: watch('char_tireProduceDate'),
      input: <Controller
        name='char_tireProduceDate'
        control={control}
        render={({ field }) => (
          <InputNumber controls={false} {...field} placeholder='Год производства' />
        )}
      />
    },
    {
      name: 'char_tireProducer',
      title: 'Производитель',
      value: watch('char_tireProducer') || '-',
      input: <Controller
        name='char_tireProducer'
        control={control}
        render={({ field }) => (
          <Input {...field} placeholder='Производитель' />
        )}
      />
    },
    {
      name: 'char_tireModel',
      title: 'Модель шины',
      value: watch('char_tireModel') || '-',
      input: <Controller
        name='char_tireModel'
        control={control}
        render={({ field }) => (
          <Input {...field} placeholder='Модель шины' />
        )}
      />
    },
    {
      name: 'char_tireWearout',
      title: 'Износ',
      value: watch('char_tireWearout') ? `${watch('char_tireWearout')}%` : 'Без износа',
      input: <Controller
        name='char_tireWearout'
        control={control}
        render={({ field }) => (
          <Select {...field} options={tireWearoutOptions} />
        )}
      />
    }

  ]

   const itemsListFilter = (item: {
    name: string;
    title: string;
    value: string | number | undefined;
    input: JSX.Element;
}) => {
  const {char_tireProtectorType ,char_tireVehicleType} = product || {}
  if ( char_tireProtectorType !== 'Стандартная' || char_tireVehicleType === 'Спецтехника' ) {
    return (item.name !== 'char_tireLT' && item.name !== 'char_tireRunFlat')
  } else return true
} 
 

  return (
    <div className={styles.root} >
      <div className={styles.header} >
        <h3 className={styles.title} >{watch('char_part') === 'Шина' ? 'Характеристики' : 'Шина'}</h3>
      </div>
      <div className={styles.content} >
        {itemsList.filter(itemsListFilter).map(({ title, name, value, input }) => {
          return <div key={name} className={cs(styles.formItem, selectedItems.includes(name) && styles.formItem__selected, wrongOption(name) && styles.formItem__error, shouldDeleteOption(name) && styles.formItem__delete)} >
            <div className={styles.formItem_title} >{title}</div>
            {selectedItems.includes(name) ? <div className={styles.formItem_input} >{input}</div> :
              <div className={styles.formItem_value} onClick={() => !shouldDeleteOption(name) && onItemSelect(name)} >{value}</div>}
            {!shouldDeleteOption(name) && selectedItems.includes(name) ?
              <button onClick={() => { onCancelItemSelect(name) }} type='button' className={styles.formItem_cancelBtn} >Отменить</button> :
              <button onClick={() => !shouldDeleteOption(name) && onItemSelect(name)} type='button' className={styles.formItem_editBtn} >Изменить</button>}
            {shouldDeleteOption(name) && <div className={styles.formItem_message} ><RiErrorWarningFill />  <p>Будет удалено</p></div>}
          </div>
        })}
      </div>
    </div>
  )
}

