import styles from './MainParametersFormPart.module.scss'
import { FC, useEffect, useState } from 'react'
import cs from 'classnames'
import { Input, Segmented, Select } from 'antd'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { IProduct, UpdateProductDto } from '../../../../../../../entities/product'




interface IProps {
  product?: IProduct
  control: Control<UpdateProductDto, any>
  watch: UseFormWatch<UpdateProductDto>
  setChangedParametrs: React.Dispatch<React.SetStateAction<{
    images: number;
    generalInfo: number;
    mainParameters: number;
    donorCar: number;
    placement: number;
}>>
setValue: UseFormSetValue<UpdateProductDto>
}

const MainParametersFormPart: FC<IProps> = ({product, control, watch, setChangedParametrs, setValue}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
 

  const onItemSelect = (name: string)=> {
     setSelectedItems(prev => [...prev, name])
  
  }

  const onCancelItemSelect = (name: any)=> {
    setValue(name, product?.[name as keyof IProduct])
    setSelectedItems(prev => prev.filter((item)=> name !== item))
  }

  
  useEffect(()=> {
     setChangedParametrs((prev)=> ({...prev, mainParameters: selectedItems.length}))
  }, [selectedItems])

  const itemsList = [
    {
      name: 'char_part',
      title: 'Запчасть',
      value: watch('char_part') || '-',
      input:  <Controller
              name='char_part'
              control={control}
              render={({field})=> (
                <Select {...field} >
                  <Select.Option value={true} >CD-Чейнджер</Select.Option>
                  <Select.Option value={false} >Blow off</Select.Option>
                </Select>
              )}
      />
    },
    {
        name: 'char_fb',
        title: 'Передний/Задний',
        value: watch('char_fb') || '-',
        input: <Controller
                name='char_fb'
                control={control}
                render={({field})=>(
                  <Segmented
                  {...field}
                options={[{ label:'Не задано', value: ''}, {label: 'Передний', value: 'Передний'},
                  {label: 'Задний', value: 'Задний'}
                ]}
        />
                )}
        />
    },
    {
      name: 'char_lr',
      title: 'Левый/Правый',
      value: watch('char_lr') || '-',
      input: <Controller
              name='char_lr'
              control={control}
              render={({field})=> (
                <Segmented
                {...field}
                options={[{ label:'Не задано', value: undefined}, {label: 'Левый', value: 'Левый'},
                {label: 'Правый', value: 'Правый'}
              ]}
      />
              )}
      />
    },
    {
      name: 'manufacturer',
      title: 'Производитель',
      value: watch('manufacturer') || '-',
      input: <Controller
              name='manufacturer'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Производитель' />
              )}
      />
    },
    {
      name: 'mfr_number',
      title: 'Номер производителя',
      value: watch('mfr_number') || '-',
      input: <Controller
              name='mfr_number'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Номер производителя' />
              )}
      />
    },
    {
      name: 'cross_number',
      title: 'Кросс-номера',
      value: watch('cross_number') || '-',
      input: <Controller
              name='cross_number'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Кросс номера' />
              )}
      />
    },
    {
      name: 'char_marking',
      title: 'Маркировка',
      value: watch('char_marking') || '-',
      input: <Controller
              name='char_marking'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Маркировка' />
              )}
      />
    },
    {
      name: 'char_color',
      title: 'Цвет',
      value: watch('char_color') || '-',
      input: <Controller
              name='char_color'
              control={control}
              render={({field})=> (
                <Input {...field} placeholder='Цвет' />
              )}
      />
    }
    
    
   
  ]


  return (
    <div className={styles.root} >
      <div className={styles.header} >
        <h3 className={styles.title} >Основные параметры</h3>
      </div>
      <div className={styles.content} >
        {itemsList.map(({title, name, value, input})=>(
              <div key={name} className={cs(styles.formItem, selectedItems.includes(name) && styles.formItem__selected)} >
              <div className={styles.formItem_title} >{title}</div>
              {selectedItems.includes(name) ?<div className={styles.formItem_input} >{input}</div> :
              <div className={styles.formItem_value} onClick={()=> onItemSelect(name)} >{value}</div>}
              {selectedItems.includes(name) ?
              <button onClick={()=> {onCancelItemSelect(name)}} type='button' className={styles.formItem_cancelBtn} >Отменить</button> :
              <button onClick={()=> onItemSelect(name)} type='button' className={styles.formItem_editBtn} >Изменить</button>}
            </div>
        ))}
      </div>
    </div>
  )
}

export default MainParametersFormPart