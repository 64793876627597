import { Radio, Space, Switch } from 'antd'
import styles from './TypesFormPart.module.scss'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { FC } from 'react'
import { IWheelsFormValues } from '../../../../interfaces/wheelsFormValues'



interface IProps {
  watch: UseFormWatch<IWheelsFormValues>
  control: Control<IWheelsFormValues, any>
  setIsDifferentWidth: React.Dispatch<React.SetStateAction<boolean>>
  isDifferentWidth: boolean
  setIsDoublePCD: React.Dispatch<React.SetStateAction<boolean>>
  isDoublePCD: boolean
}

const TypesFormPart: FC<IProps> = ({watch, control, setIsDifferentWidth, isDifferentWidth, setIsDoublePCD, isDoublePCD}) => {
  return (
    <div className={styles.root} >
            <div className={styles.rowWrapper} >
        <div className={styles.title} >Диск</div>
       <div className={styles.switchesWrapper} >
        <div className={styles.switchHolder} > 
          <Switch
          value={isDoublePCD}
          onChange={(value)=> setIsDoublePCD(value)}
           id='drillingSwitch'
           size='small'
           /> 
          <label htmlFor="drillingSwitch">Двойная сверловка</label>
           </div>
       </div>
        <div className={styles.switchHolder} >
            <Switch 
            value={isDifferentWidth}
            onChange={(value)=> setIsDifferentWidth(value)}
            size='small'
            id='differentWidthSwitch'
            />
            <label htmlFor="differentWidthSwitch">Разноширокие</label>
        </div>
      </div>
      <div className={styles.rowWrapper} >
      <Controller
                name='char_diskType'
                control={control}
                render={({field})=> (
                    <Radio.Group {...field} size='large' buttonStyle='solid' >
                    <Radio.Button value='Литой' >Литой</Radio.Button>
                    <Radio.Button value='Кованый' >Кованый</Radio.Button>
                    <Radio.Button value='Штампованный' >Штампованный</Radio.Button>
                    <Radio.Button value='Разборный' >Разборный</Radio.Button>
                    </Radio.Group>
                )}/>
   
      </div>
    </div>
  )
}

export default TypesFormPart