


export const tireSpeedIndexOptions = [
    {
      value: undefined,
      label: "- Не выбран -",
    },
    {
      value: "H",
      label: "H",
      desc: "210",
    },
    {
      value: "J",
      label: "J",
      desc: "100",
    },
    {
      value: "K",
      label: "K",
      desc: "110",
    },
    {
      value: "L",
      label: "L",
      desc: "120",
    },
    {
      value: "M",
      label: "M",
      desc: "130",
    },
    {
      value: "N",
      label: "N",
      desc: "140",
    },
    {
      value: "Q",
      label: "Q",
      desc: "160",
    },
    {
      value: "R",
      label: "R",
      desc: "170",
    },
    {
      value: "S",
      label: "S",
      desc: "180",
    },
    {
      value: "T",
      label: "T",
      desc: "190",
    },
    {
      value: "U",
      label: "U",
      desc: "200",
    },
    {
      value: "V",
      label: "V",
      desc: "240",
    },
    {
      value: "W",
      label: "W",
      desc: "270",
    },
    {
      value: "Y",
      label: "Y",
      desc: "300",
    },
    {
      value: "Z/ZR",
      label: "Z/ZR",
      desc: ">240",
    },
  ];