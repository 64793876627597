import { Navigate, Outlet } from "react-router-dom"
import { Menu } from "./widgets/menu"
import { useGetMeQuery } from "./entities/auth"



const MainLayout = () => {

  const {data: user, isLoading} = useGetMeQuery()
 

  if(!isLoading && user) {
    return (
      <div className='layout' >
        <Menu/>
        <Outlet/>
    </div>
    )
  } else if(!isLoading && !user) {
    return <Navigate to='/auth' replace={true}/>
  } else {
    return null
  }
}

export default MainLayout