import { Button, InputNumber, Select, Switch } from "antd";
import styles from "./PriceFormPart.module.scss";
import { Control, Controller, UseFormWatch } from "react-hook-form";
import { FC, useEffect } from "react";
import { IWheelsFormValues } from "../../../../interfaces/wheelsFormValues";

interface IProps {
  watch: UseFormWatch<IWheelsFormValues>;
  control: Control<IWheelsFormValues, any>;
  isDifferentWidth: boolean;
  setUniqueOptions: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        images?: number[];
        char_tireWearout?: number;
        price?: number;
        char_comment?: string;
        char_note?: string;
      }[]
    >
  >;
  uniqueOptions: {
    id: number;
    images?: number[];
    char_tireWearout?: number;
    price?: number;
    char_comment?: string;
    char_note?: string;
  }[];
  setIsGeneralImagesAndComments: React.Dispatch<React.SetStateAction<boolean>>;
  isGeneralImagesAndComments: boolean;
  setGeneralPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  generalPrice: number | undefined
}

const PriceFormPart: FC<IProps> = ({
  control,
  isDifferentWidth,
  setIsGeneralImagesAndComments,
  isGeneralImagesAndComments,
  watch,
  setGeneralPrice,
  generalPrice,
  uniqueOptions,
  setUniqueOptions
}) => {


  const tiresSetNumberList = [
    {
      label: `1 ${watch("char_wheelPart") === "Шина" ? "шина" : watch("char_wheelPart") === "Диск" ? "диск" : "колесо"}`,
      value: 1,
    },
    {
      label: `2 ${watch("char_wheelPart") === "Шина" ? "шины" : watch("char_wheelPart") === "Диск" ? "диска" : "колеса"}`,
      value: 2,
    },
    {
      label: `4 ${watch("char_wheelPart") === "Шина" ? "шины" : watch("char_wheelPart") === "Диск" ? "диска" : "колеса"}`,
      value: 4,
    },
    {
      label: `6 ${watch("char_wheelPart") === "Шина" ? "шин" : watch("char_wheelPart") === "Диск" ? "дисков" : "колес"}`,
      value: 6,
    },
    {
      label: `10 ${watch("char_wheelPart") === "Шина" ? "шин" : watch("char_wheelPart") === "Диск" ? "дисков" : "колес"}`,
      value: 10,
    },
  ];


 
  useEffect(() => {
    setGeneralPrice(Math.round(uniqueOptions.reduce((sum, {price})=> sum + (price || 0) , 0)) || undefined)
  }, [uniqueOptions]);


  return (
    <>
      <div className={styles.rowWrapper}>
        <div className={styles.title}>Фотографии и цена</div>
        <div className={styles.switchHolder}>
          <Switch
            value={isGeneralImagesAndComments}
            onChange={(value) => setIsGeneralImagesAndComments(value)}
            size="small"
            id="isGeneralsPhotosSwitch"
          />
          <label htmlFor="isGeneralsPhotosSwitch">
            Общие фото и комментарий
          </label>
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <div className={styles.formItem}>
          <label htmlFor="">Колличество в комплекте</label>
          <Controller
            name="char_wheelSetNumber"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={
                  isDifferentWidth
                    ? tiresSetNumberList.slice(2)
                    : tiresSetNumberList
                }
              />
            )}
          />
        </div>
        <div className={styles.formItem}>
          <label htmlFor="">Цена за комплект</label>
          <InputNumber
            value={generalPrice}
            onChange={ (value)=> setUniqueOptions((prev)=> (
               prev.map((item)=> {
              item.price = value ? value / watch('char_wheelSetNumber') : undefined
              return item
          } )
       ))}
            placeholder="Введите цену"
            controls={false}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="complectId"
            control={control}
            render={({ field }) => (
              <div>
                <Button className={styles.btn}>Привязать к комплекту</Button>
              </div>
            )}
          />
        </div>
        <div className={styles.formItem}>
          <Controller
            name="complectId"
            control={control}
            render={({ field }) => (
              <div>
                <Button className={styles.btn}>Привязать к донору</Button>
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default PriceFormPart;
