import { Table, TableColumnsType } from 'antd'
import { IReturn, useGetAllReturnsQuery } from '../../../../entities/return'
import styles from './ReturnsPage.module.scss'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'


export const ReturnsPage = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { data: returnsData, isLoading } = useGetAllReturnsQuery({ pagination: { page, pageSize } })
  const returns = returnsData?.data
  const pagination = returnsData?.meta?.pagination
  const navigate = useNavigate()


  const columns: TableColumnsType<IReturn> = [
    {
      title: 'Номер/Дата',
      width: 80,
      render: (_, item) => {
        const date = item.createdAt && new Date(item.createdAt.toString());
        const day = date?.toLocaleString("ru-Ru", { day: 'numeric' })
        const month = date?.toLocaleString("ru-Ru", { month: "long" });
        const year = date?.toLocaleString("ru-Ru", { year: 'numeric' })
        return <div className={styles.idWrapper} >
          <p className={styles.id} >{item?.id}</p>
          <p className={styles.date} >{`${day} ${month.slice(0, 3)} ${year.slice(2)}`}</p>
        </div>
      }
    }, {
      title: 'Клиент',
      render: (_, item) => item?.sale?.client?.name
    },
    {
      title: 'По сделке',
      render: (_, item) => item?.sale?.id
    },
    {
      title: 'Склад возврата',
      render: (_, item) => item?.sale?.storage?.name
    },
    {
      title: 'Сумма',
      render: (_, item) => item?.returnItems.reduce((sum, item) => {
        const discountedPrice = item?.saleItem?.discountedPrice
        const price = item?.saleItem?.product?.char_price
        return sum + ((discountedPrice || price || 0) * item?.quantity)
      }, 0)
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment'
    }
  ]

  const onPageChange = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
  }

  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1>Возвраты</h1>
      </div>
      <div className={styles.content} >
        <Table
          loading={isLoading}
          onRow={(item) => ({
            onClick:
              () => navigate(`/clients/${item?.sale?.client?.id}/sales/${item?.sale?.id}/returns/${item?.id}`)
          })}
          pagination={{ showSizeChanger: true, defaultPageSize: 10, total: pagination?.total, pageSize, current: page, onChange: onPageChange }}
          size='small' rowKey={(item) => item?.id} dataSource={returns} columns={columns} />
      </div>
    </main>
  )
}
