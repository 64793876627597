import { Drawer, message, Modal } from 'antd'
import styles from './ProductPage.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGetOneProductQuery, useUpdateProductMutation } from '../../../../../entities/product'
import cs from 'classnames'
import TextArea from 'antd/es/input/TextArea'
import { EditProductModal } from '../editProductModal'
import ProductGallery from '../productGallery'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { ApplicabilityEdit } from '../applicabilityEdit'





export const ProductPage = () => {
 const navigate = useNavigate()
 const location = useLocation()
 const productId = location.pathname.split('/')[3]
 const {data: product} = useGetOneProductQuery(productId)
 const [updateProduct] = useUpdateProductMutation()
  const onCancel = ()=> {
     navigate('/catalog')
  }

  const {char_part, char_price, id, char_comment, char_note, images, isUsed, income, stock, char_fb, char_lr,
    char_carsrc_mark, char_carsrc_model, char_carsrc_year, char_carsrc_body, char_carsrc_engine, manufacturer,
    mfr_number, cross_number, char_marking, char_color
  } = product || {}
  const {shipment} = income || {}
  const {storage} = income || {}
  const {control, handleSubmit, setValue} = useForm<{char_comment?: string}>({
    defaultValues: {
      char_comment: undefined
    }
  })

  const descriptionItems: {name: string, value?: string}[] = [
    {
      name: 'Марка',
      value: char_carsrc_mark
    },
    {
      name: 'Модель',
      value: char_carsrc_model
    },
    {
      name: 'Модель кузова',
      value: char_carsrc_body
    },
    {
      name: 'Модель двигателя',
      value: char_carsrc_engine
    },
    {
      name: 'Год выпуска',
      value: char_carsrc_year
    },
    {
      name: 'Производитель',
      value: manufacturer
    },
    {
      name: 'Номер производителя',
      value: mfr_number
    },
    {
      name: 'Кросс номера',
      value: cross_number
    },
    {
      name: 'Маркировка',
      value: char_marking
    },
    {
      name: 'Цвет',
      value: char_color
    },
  ]

useEffect(()=> {
  char_comment && setValue('char_comment', char_comment)
}, [char_comment])

const onSubmit: SubmitHandler<{char_comment?: string}> = async (body)=> {
  if(product) {
    const res = await updateProduct({id: product?.id, body})
    if('data' in res) {
      message.success('Комментарий сохранен')
    }
  }
   
}



  return (

      <Modal className={styles.root} centered width='100vw' footer={false} open onCancel={onCancel} >
        <div className={styles.inner} >
          <div className={styles.header} >
            <h1>{char_part}</h1>
            <div className={styles.controls} >
            <EditProductModal product={product} />
            </div>
          </div>
          <div className={styles.content} >
            <div className={styles.productDescription} >
              <div className={styles.storage} >
                <div className={styles.storageName} >{storage?.name}</div>
                <div className={styles.storageCount} >{stock}</div>
              </div>
              <div className={styles.ProductInfo} >
                <div className={cs(styles.productInfo_item, styles.price)} >
                 <p>Цена</p><p className={styles.priceCount} >{char_price}
                  
                     {isUsed ? <span style={{background: '#eaaa08'}} className={styles.badge} >Б/У</span>
                      : 
                      <span  style={{background: '#2e90fa'}} className={styles.badge} >Новый</span>} </p>
                    
        
                </div>
                <div className={cs(styles.productInfo_item, styles.productId)} >
                  <p>Номер товара</p>
                  <p>{id}</p>
                </div>
                <div className={cs(styles.productInfo_item, styles.shipment)} >
                  <p>Поставка</p>
                  <p>{shipment?.title}</p>
                </div>
                <div className={styles.comment} >
                  <Controller
                  name='char_comment'
                  control={control}
                  render={({field})=> (
                    <TextArea {...field} onBlur={handleSubmit(onSubmit)}  autoSize={{minRows: 5}} />
                  )}
                  />
                </div>
              </div>
              {char_note && <div className={styles.note} >
                <p className={styles.noteTitle} >Заметка (для вас)</p>
                <p className={styles.noteText} >{char_note}</p>
              </div>}
               <ApplicabilityEdit product={product} />
              <div className={styles.description} >
              {(char_fb || char_lr) && <div className={styles.description_item} >
                  <p className={styles.description_name} >Расположение</p>
                  <p className={styles.description_value} >
                    {char_fb && (char_fb === 'forward' ? 'Перед.': 'Зад.')}
                    {' '}
                    {char_lr && (char_lr ==='left' ? 'Лев.': 'Прав.')}
                    </p>
                </div>}
              
                {
                  descriptionItems.slice(0, 5).map(({name, value})=> {
                    if(value) {
                      return <div key={name} className={styles.description_item} >
                      <p className={styles.description_name} >{name}</p>
                      <p className={styles.description_value} >{value}</p>
                    </div>
                    }
                  })
                }
              </div>
              <div className={styles.description} >
              {
                  descriptionItems.slice(5).map(({name, value})=> {
                    if(value) {
                      return <div key={name} className={styles.description_item} >
                      <p className={styles.description_name} >{name}</p>
                      <p className={styles.description_value} >{value}</p>
                    </div>
                    }
                  })
                }
            </div>
            </div>
      
            <div className={styles.productGallery} >
                {images && images.length > 0 && <ProductGallery images={images} />}
            </div>
          </div>
        </div>
      </Modal>

  )
}
