import { useLocation, useNavigate } from 'react-router-dom'
import styles from './GenerationPage.module.scss'
import { Button, message, Modal, Popconfirm, Table } from 'antd'
import { useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { TableColumnsType } from 'antd/lib'
import { useDeleteGenerationMutation, useGetOneGenerationQuery } from '../../../../../entities/generation'
import AddBodyForm from '../components/addBodyForm'
import { IBody, useGetAllBodysQuery } from '../../../../../entities/body'
import EditGenerationForm from '../components/editGenerationForm'
import { MdDelete, MdEdit } from 'react-icons/md'



const columns: TableColumnsType<IBody> = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: 'Название',
    dataIndex: 'title'
  }
]


export const GenerationPage = () => {
  const [isAddBodyModalOpen, setIsAddBodyModalOpen] = useState<boolean>(false)
  const [isEditGenerationModalOpen, setIsEditGenerationModalOpen] = useState<boolean>(false)
  const location = useLocation()
  const generationId = location.pathname.split('/')[3]
  const {data: generation} = useGetOneGenerationQuery(generationId)
  const {title} = generation || {}
  const {data: bodys} = useGetAllBodysQuery(generationId)
  const navigate = useNavigate()
  const [deleteGeneration] = useDeleteGenerationMutation()
  
 const handleDeleteGeneration = ()=> {
  deleteGeneration(generationId)
  message.success('Поколение успешно удалено')
  navigate(-1)
 }
    
  return (
    <main className={styles.root} >
      <Modal title='Добавить кузов' footer={false} onCancel={()=> setIsAddBodyModalOpen(false)} open={isAddBodyModalOpen} >
        <AddBodyForm setIsModalOpen={setIsAddBodyModalOpen} />
      </Modal>
      <Modal title='Редактирование поколения' footer={false} onCancel={()=> setIsEditGenerationModalOpen(false)} open={isEditGenerationModalOpen} >
        <EditGenerationForm setIsModalOpen={setIsEditGenerationModalOpen} generation={generation} />
      </Modal>
        <div className={styles.header} >
        <button onClick={()=>navigate(-1)} className={styles.returnBtn} ><IoIosArrowBack /></button>
          <h1>Поколение {title}</h1>
          <div className={styles.header_buttonsHolder} >
          <Button onClick={()=> setIsEditGenerationModalOpen(true)} size='large' type='primary' icon={<MdEdit />} ></Button>
          <Popconfirm onConfirm={handleDeleteGeneration} title='Удаление поколения' description='Вы действительно хотите удалить поколение?' ><Button size='large' danger type='primary' icon={<MdDelete />} ></Button></Popconfirm>
        </div>
        </div>
        <div className={styles.content} >
          <div className={styles.contentHeader} >
          <div className={styles.tableTitle}  >
            <h2>Кузовы</h2>
          </div>
            <Button onClick={()=> setIsAddBodyModalOpen(true)} type='primary' >Добавить кузов</Button>
          </div>
          <div className={styles.tableHolder} >
            <Table 
            columns={columns}
            dataSource={bodys?.map((item)=> ({...item, key: item.id}))}
            onRow={(item)=> ({onClick:()=> navigate(`/applicability/body/${item.id}`)})}
            />
          </div>
        </div>
    </main>
  )
}