import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateSaleDto } from "../dtos/createSaleDto";
import { UpdateSaleDto } from "../dtos/updateSaleDto";
import { ISale, ISales } from "../interfaces/sale";
import qs from 'qs'

interface QueryParams {
status: "draft" |"reserve" |"issued" |"canceled"| "all"
pagination?: {page: number, pageSize: number}
}

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        createSale: build.mutation<ISale, CreateSaleDto>({
            query: (body) => ({
                url: '/sales',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Sale']
        }),
        updateSale: build.mutation<ISale, { id: number, body: UpdateSaleDto }>({
            query: ({ id, body }) => ({
                url: `/sales/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Sale', 'Product']
        }),
        getAllSales: build.query<ISales, {params: QueryParams}>({
            query: ({params}) => {
                const queryString = qs.stringify(params)
                return `/sales?${queryString}`
            },
            providesTags: ['Sale']
        }),
        getOneSale: build.query<ISale, number>({
            query: (id) => `/sales/${id}`,
            providesTags: ['Sale']
        })
    })
})


export const { useCreateSaleMutation, useUpdateSaleMutation, useGetAllSalesQuery, useGetOneSaleQuery } = extendedApi