import { Checkbox, Modal } from 'antd'
import styles from './ApplicabilityMarkModal.module.scss'
import Search from 'antd/es/input/Search'
import { useEffect, useState } from 'react'
import { IMark, useGetAllMarksQuery, useLazyGetOneMarkQuery } from '../../../../../entities/mark'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { marksSelector, setMarks } from '../../../../../entities/applicability'






const ApplicabilityMarkModal = ({setIsModalOpen, isModalOpen}: {setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,isModalOpen: boolean}) => {
    const [searchParam, setSearchParam] = useState<string>('')
    const [trigger] = useLazyGetOneMarkQuery()
    const [selectedMarks, setSelectedMarks] = useState<IMark[]>([])
    const dispatch = useAppDispatch()
    const {data: marks} = useGetAllMarksQuery()
    const checkedMarks = useAppSelector(marksSelector)

    const onCheck = async (mark: IMark)=> {
         const {data: fullMark} =  await trigger(String(mark.id))
         if(fullMark) {
            const findedId = selectedMarks.find((item)=> fullMark.id === item.id)
            if(findedId) {
                const filteredArr = selectedMarks.filter((item)=> item.id !== fullMark.id)
                setSelectedMarks(filteredArr)
            } else {
                setSelectedMarks((prev)=> [...prev, fullMark])
    
            }
         }
      
    }

    const onOk = ()=> {
        dispatch(setMarks(selectedMarks))
        setIsModalOpen(false)
    }

    const onCancel = ()=> {
        setSelectedMarks([])
        setIsModalOpen(false)
     }

     useEffect(()=> {
        setSelectedMarks(checkedMarks)
     }, [checkedMarks, isModalOpen])
     

    
    
  return (
    <Modal className={styles.root} onOk={onOk} okText='Добавить' title='Выберите марку' centered width='50vw' onCancel={onCancel} open={isModalOpen} >
    <div className={styles.inner} >
        <div className={styles.search} >
            <Search onChange={(e)=> setSearchParam(e.target.value)} size='large' variant='filled' placeholder='Поиск по марке' />
        </div>
        <ul className={styles.marksList} >
            {
                marks?.filter(({title})=> title.toLowerCase().includes(searchParam.toLowerCase())).map((item)=> (
                    <li key={item.id} ><Checkbox checked={selectedMarks.some(({id})=> id === item.id)} onChange={()=>onCheck(item)} >{item.title}</Checkbox> </li>
                ))
            }
        </ul>
    </div>
    </Modal>
  )
}

export default ApplicabilityMarkModal