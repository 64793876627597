import { Button, Input } from 'antd'
import styles from './SignInForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SignInDto, useGetMeQuery, useSignInMutation } from '../../../../../entities/auth'
import Password from 'antd/es/input/Password'
import { useNavigate } from 'react-router-dom'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'


const SignInForm = () => {
  const {control, handleSubmit, formState: {errors}, setError} = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const [signIn] = useSignInMutation()
 const navigate = useNavigate()
const {refetch} = useGetMeQuery()
  const onSubmit: SubmitHandler<SignInDto> = async (body)=> {
    const {email, password} = body
    if(email && password) {
      const res = await signIn(body)
  
      if('data' in res) {
          refetch()
          navigate('/', {replace: true})
      }
      if('error' in res ) {
        const error = res.error as FetchBaseQueryError
        const {message} = error.data as {message: string}
        if(message === "Wrong credentials") {
          setError('password', {message: "Неправильный логин или пароль"})
        }
      }
    }
      
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
    <h2>Войти в систему</h2>

      <div className={styles.formItem} >
      <Controller
        name='email'
        control={control}
        render={({field})=> (
          <Input {...field} size='large' placeholder='Email' />
        )}
        />
        <p className={styles.error} ></p>
      </div>
      <div className={styles.formItem} >
      <Controller
        name='password'
        control={control}
        render={({field})=> (
          <Password {...field} size='large' type='password' placeholder='Пароль' />
        )}
        />
         <p className={styles.error} >{errors?.password?.message}</p>
      </div>
      <Button className={styles.submitBtn} htmlType='submit' size='large' type='primary' >Отправить</Button>
    </form>
  )
}

export default SignInForm