import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateMarkDto } from "../dtos/createMarkDto";
import { UpdateMarkDto } from "../dtos/updateMarkDto";
import { IMark } from "../interfaces/mark";




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        getOneMark: build.query<IMark, string>({
            query: (id)=> `/marks/${id}`,
            providesTags: ['Mark']
        }),
        getAllMarks: build.query<IMark[], void>({
            query: ()=> '/marks',
            providesTags: ['Mark']
        }),
        createMark: build.mutation<IMark, CreateMarkDto>({
            query: (body)=> ({
                url: '/marks',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Mark']
        }),
        updateMark: build.mutation<IMark, {id: number, body: UpdateMarkDto}>({
            query: ({id, body})=> ({
                url: `/marks/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Mark']
        }),
        deleteMark: build.mutation<void, string>({
            query: (id)=> ({
                url: `/marks/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Mark']
        })
    })
})


export const {useGetOneMarkQuery, useLazyGetOneMarkQuery, useGetAllMarksQuery, useLazyGetAllMarksQuery, useCreateMarkMutation, useUpdateMarkMutation, useDeleteMarkMutation} = extendedApi