import { CatalogNavbar } from '../../widgets/catalogNavbar'
import { Outlet } from 'react-router-dom'


const CatalogLayout = () => {
  return (
    <main>
      <CatalogNavbar/>
      <Outlet/>
    </main>
  )
}

export default CatalogLayout