import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateIncomeDto } from "../dtos/createIncomeDto";
import { UpdateIncomeDto } from "../dtos/updateIncomeDto";
import { IIncome, IIncomes } from "../interfaces/income";
import qs from 'qs'


interface QueryParams {
    status: 'draft'| 'completed' | 'canceled' | 'all'
    pagination?: {page?: number, pageSize?: number}
}



const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getAllIncomes: build.query<IIncomes, {params: QueryParams}>({
            query: ({params}) => {
                const queryString = qs.stringify(params)
                return `/incomes?${queryString}`
            },
            providesTags: ['Income']
        }),
        getOneIncome: build.query<IIncome, string>({
            query: (id) => `/incomes/${id}`,
            providesTags: ['Income']
        }),
        createIncome: build.mutation<IIncome, CreateIncomeDto>({
            query: (body) => ({
                url: '/incomes',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Income', 'Sale', 'SaleItem']
        }),
        updateIncome: build.mutation<IIncome, {id: string , body: UpdateIncomeDto}>({
            query: ({id, body})=> ({
                method: 'PATCH',
                url: `/incomes/${id}`,
                body
            }),
            invalidatesTags: ['Income', 'Product', 'Sale', 'SaleItem']
        }),
        deleteIncome: build.mutation<void, string>({
            query: (id)=> ({
                url: `/incomes/${id}`
            }),
            invalidatesTags: ['Income', 'Sale']
        })
    })
})

export const { useUpdateIncomeMutation, useCreateIncomeMutation, useGetOneIncomeQuery, useGetAllIncomesQuery, useDeleteIncomeMutation } = extendedApi
