import { Control, Controller } from 'react-hook-form'
import styles from './LegalPersonFormPart.module.scss'
import { Input } from 'antd'
import { CreateClientDto } from '../../../../../../entities/client'
import cs from 'classnames'

{}
const LegalPersonFormPart = ({control}: {control: Control<CreateClientDto, any>}) => {
  return (
    <div className={styles.legalPerson_content} >
    <div className={cs(styles.formItem, styles.formItem_wide)} >
        <label htmlFor="organization">Юридическое название организации</label>
        <Controller
         name='organization'
         control={control}
         render={({field})=> (
            <Input size='large' id='organization' placeholder='Введите название организации' {...field} />
         )}
        />
      </div>
    <div className={cs(styles.formItem, styles.formItem_wide)} >
        <label htmlFor="legalAddress">Юридический адрес</label>
        <Controller
         name='legalAddress'
         control={control}
         render={({field})=> (
            <Input size='large' id='legalAddress' placeholder='000000, г. Москва, Ленинский проспект 1, офис 10' {...field} />
         )}
        />
      </div>
    <div className={cs(styles.formItem, styles.formItem_wide)} >
        <label htmlFor="actualAddress">Фактический адрес</label>
        <Controller
         name='actualAddress'
         control={control}
         render={({field})=> (
            <Input size='large' id='actualAddress' placeholder='000000, г. Москва, Ленинский проспект 1, офис 10' {...field} />
         )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="inn">ИНН</label>
        <Controller
         name='inn'
         control={control}
         render={({field})=> (
            <Input size='large' id='inn' placeholder='' {...field} />
         )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="kpp">КПП</label>
        <Controller
         name='kpp'
         control={control}
         render={({field})=> (
            <Input size='large' id='kpp' placeholder='' {...field} />
         )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="bankName">Название банка</label>
        <Controller
         name='bankName'
         control={control}
         render={({field})=> (
            <Input size='large' id='bankName' placeholder='' {...field} />
         )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="bic">БИК</label>
        <Controller
         name='bic'
         control={control}
         render={({field})=> (
            <Input size='large' id='bic' placeholder='' {...field} />
         )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="checkingAccount">Расчетный счёт</label>
        <Controller
         name='checkingAccount'
         control={control}
         render={({field})=> (
            <Input size='large' id='checkingAccount' placeholder='' {...field} />
         )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="correspondentAccount">Корреспондентский счёт</label>
        <Controller
         name='correspondentAccount'
         control={control}
         render={({field})=> (
            <Input size='large' id='correspondentAccount' placeholder='' {...field} />
         )}
        />
      </div>
      
    </div>
  )
}

export default LegalPersonFormPart