import { FC } from 'react'
import styles from './AddGenerationForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input, InputNumber } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import cs from 'classnames'
import { CreateGenerationDto, useCreateGenerationMutation } from '../../../../../../entities/generation'
import { useLocation } from 'react-router-dom'


interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddGenerationForm: FC<IProps> = ({setIsModalOpen}) => {
  const location = useLocation()
  const modelId = location.pathname.split('/')[3]
  const [messageApi, contextHolder] = useMessage()
  const [createGeneration] = useCreateGenerationMutation()
const {control, handleSubmit, reset} = useForm({
  defaultValues: {
    title: '',
    genVersion: undefined,
    years: '',
    modelId: +modelId
  }
})

const onSubmit: SubmitHandler<CreateGenerationDto> = async (data)=> {
      await createGeneration(data)
      messageApi.success('Поколение успешно создано')
      reset()
      setIsModalOpen(false)
}



  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={cs(styles.formItem, styles.genVersion)} >
        <label htmlFor="genVersion">№ поколения</label>
        <Controller
        name='genVersion'
        control={control}
        render={({field})=> (
          <InputNumber controls={false} id='genVersion' {...field}   />
        )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input id='title' {...field}   />
        )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="years">Года</label>
        <Controller
        name='years'
        control={control}
        render={({field})=> (
          <Input id='years' {...field}   />
        )}
        />
      </div>
      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default AddGenerationForm