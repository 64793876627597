import { useState } from 'react'
import Navbar from '../navbar'
import styles from './SalesPage.module.scss'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import WriteoffsTable from '../writeoffsTable'


export const WriteoffsPage = () => {
  const [selectedItem, setSelectedItem] = useState<"draft" |"complete" |"canceled"| "all">('draft')

  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1 className={styles.title} >Списания</h1>
      </div>
      <div className={styles.inner} >
        <Navbar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <div className={styles.content} >
          <div className={styles.contentHeader} >
            <Button  className={styles.filtersResetBtn} disabled={true} >Фильтры не заданы</Button>
              <Link to={'/writeoffs/new'} ><Button type='primary' className={styles.addIncomeBtn} >Новое списание</Button></Link>
          </div>
          <div className={styles.table} >
             <WriteoffsTable status={selectedItem} />
          </div>
        </div>
      </div>
    </main>
  )
}

