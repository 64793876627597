const diskDiametrOptions = [
    3, 4, 4.5, 5, 5.5, 6, 6.25, 6.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.75, 12,
    12.25, 12.5, 13, 13.5, 14, 14.5, 15, 15.3, 15.5, 16, 16.1, 16.5, 17,
    17.5, 17.75, 18, 18.5, 19, 19.5, 20, 20.5, 21, 22, 22.5, 23, 24, 24.5,
    25, 26, 26.5, 27, 28, 28.5, 29, 30, 30.5, 31, 32, 33, 34, 35, 36, 38, 39,
    40, 42, 44, 45, 46, 48, 49, 50, 51, 52, 54, 55, 56, 57
]



const diskDiaOptions = [
    50, 52, 52.5, 54, 54.1, 55, 56, 56.1, 56.5, 56.55, 56.6, 56.7, 57, 57.06,
    57.1, 58, 58.1, 58.5, 58.6, 59, 60, 60.1, 60.2, 60.3, 60.5, 63.3, 63.34,
    63.35, 63.4, 64, 64.1, 65, 65.06, 65.1, 66, 66.1, 66.45, 66.46, 66.5, 66.56,
    66.6, 67, 67.1, 67.2, 67.4, 67.6, 68, 69.1, 70, 70.1, 70.2, 70.3, 70.6, 71,
    71.1, 71.5, 71.56, 71.6, 72, 72.3, 72.5, 72.56, 72.6, 73, 73.1, 73.3, 74.1,
    74.2, 75, 75.1, 76, 77.1, 77.8, 77.9, 78.1, 78.3, 78.5, 79, 82, 84, 84.1,
    84.2, 87.1, 89, 89.1, 90, 92.3, 92.5, 92.6, 93, 93.1, 95.1, 95.3, 98, 98.1,
    98.5, 98.6, 100.1, 106, 106.1, 106.2, 106.3, 107, 107.1, 108, 108.1, 108.2,
    108.3, 108.5, 108.6, 108.7, 109, 109.7, 110, 110.1, 110.2, 110.3, 110.5,
    111.6, 112, 112.1, 113.1, 114.3, 116.1, 117, 120.1, 130, 130.1, 131, 133,
    138.8, 146, 150, 160, 161, 163, 164, 164.2, 176, 202, 220, 221, 222, 281,
]




const diskEtOptions = [-140, -98, -88, -76, -72, -70, -65, -55, -50, -48, -46, -45, -44, -40, -38, -36, -35, -34, -33, -32,
-30, -29, -28, -27, -26, -24, -22, -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4,
-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 11.5, 12, 13, 14, 15, 16, 17, 18, 19, 20, 20.5, 21, 22, 23, 23.5,
    24, 25, 25.5, 26, 27, 28, 29, 30, 31, 31.5, 32, 33, 34, 34.5, 35, 35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40,
    40.5, 41, 41.5, 42, 42.5, 43, 43.5, 43.8, 44, 45, 46, 46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50, 50.5, 50.8, 51, 51.5,
    52, 52.5, 53, 54, 55, 55.5, 56, 57, 58, 59, 60, 61, 61.5, 62, 62.5, 62.6, 64, 65, 67, 68, 69, 70, 71, 75, 100, 102,
    105, 106, 109.5, 110, 114, 115, 116, 117, 120, 121.5, 122, 123, 124.5, 125, 126, 127, 128, 128.2, 129, 129.5, 130,
    131, 132.5, 133, 134, 135, 136, 140, 142, 145, 145.5, 146, 148, 150, 150.5, 152, 153, 154, 155, 155.5, 157, 159,
    161, 162, 164, 165, 167, 168, 169, 171, 175, 175, 178, 180, 181, 185
]



const diskWidthOptions = [3, 3.5, 4, 4.5, 5, 5.25, 5.5, 6, 6.5, 6.75, 7, 7.5, 8, 8.25, 8.5, 8.75, 9, 9.25, 9.5, 10,
    10.5, 10.75, 11, 11.5, 11.75, 12, 12.5, 13, 13.5, 14, 14.5, 15]



const diskPcdOptions = ['3x100', '3x105', '3x112.5', '3x115', '3x120', '3x125', '3x150', '3x98', '4x100', '4x101.6', '4x108',
    '4x110', '4x114.3', '4x115', '4x120', '4x130', '4x140', '4x150', '4x160', '4x170', '4x190', '4x95.25', '4x98',
    '5x100', '5x105', '5x106', '5x108', '5x110', '5x112', '5x114.3', '5x115', '5x118', '5x120', '5x120.65', '5x127',
    '5x128', '5x130', '5x135', '5x139.7', '5x140', '5x150', '5x152.4', '5x154.94', '5x160', '5x165.1', '5x170',
    '5x190', '5x197', '5x203.2', '5x205', '5x208', '5x98', '6x114.3', '6x115', '6x120', '6x125', '6x127', '6x130',
    '6x132', '6x135', '6x139.4', '6x139.7', '6x170', '6x180', '6x200', '6x205', '6x222.2', '6x222.25', '6x185',
    '7x150', '8x165.1', '8x170', '8x180', '8x200', '8x210', '8x225', '8x275', '8x285'

]


export { diskDiametrOptions, diskDiaOptions, diskEtOptions, diskWidthOptions, diskPcdOptions }