import { Button, Input } from 'antd'
import styles from './SignUpForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SignUpDto, useGetMeQuery, useSignUpMutation } from '../../../../../entities/auth'
import Password from 'antd/es/input/Password'
import { useNavigate } from 'react-router-dom'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'




const SignUpForm = () => {
  const { control, handleSubmit, formState: { errors }, setError } = useForm<SignUpDto>({
    defaultValues: {
      login: '',
      email: '',
      password: ''
    }
  })

  const [signUp] = useSignUpMutation()
  const navigate = useNavigate()
  const { refetch } = useGetMeQuery()
  const onSubmit: SubmitHandler<SignUpDto> = async (body) => {
    const {email, login, password} = body
    if(email && login && password) {
      const res = await signUp(body)
      if ('data' in res) {
        refetch()
        navigate('/', { replace: true })
      }
      if ('error' in res) {
        const error = res.error as FetchBaseQueryError
        const { message } = error.data as { message: string }
        if (message === "User with this email already exists") {
          setError('email', { message: "Пользователь с таким email уже существует" })
        }
        if (message === "User with this login already exists") {
          setError('login', { message: "Пользователь с таким логином уже существует" })
        }
  
      }
    }
   
  }

  return (

    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      <h2>Регистрация</h2>
      <div className={styles.formItem} >
        <Controller
          name='login'
          control={control}
          rules={{
            required: "это поле обязательно",
            minLength: { value: 4, message: 'длина должна быть не менее 4 символов' }
          }}
          render={({ field }) => (
            <Input {...field} size='large' placeholder='Логин' />
          )}
        />
        <p className={styles.error} >{errors?.login?.message}</p>
      </div>
      <div className={styles.formItem} >
        <Controller
          name='email'
          control={control}
          rules={{ required: "это поле обязательно", pattern: { value: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, message: 'пожалуйста введите корректный email' } }}
          render={({ field }) => (
            <Input {...field} size='large' placeholder='Email' />
          )}
        />
        <p className={styles.error} >{errors?.email?.message}</p>
      </div>
      <div className={styles.formItem} >
        <Controller
          name='password'
          control={control}
          rules={{
            required: "это поле обязательно",
            minLength: { value: 5, message: 'длина должна быть не менее 5 символов' }
          }}
          render={({ field }) => (
            <Password {...field} size='large' type='password' placeholder='Пароль' />
          )}
        />
        <p className={styles.error} >{errors?.password?.message}</p>
      </div>
      <Button className={styles.submitBtn} htmlType='submit' size='large' type='primary' >Отправить</Button>
    </form>
  )
}

export default SignUpForm