import { IWheelsFormValues } from "../interfaces/wheelsFormValues";




export const defaultValues: IWheelsFormValues = {
    char_wheelSetNumber: 4,
    char_wheelPart: "Шина",
    char_tireVehicleType: "Легковая",
    char_tireProtectorType: "Стандартная",
    char_tireCordType_F: "R",
    char_tireCordType_B: "R",
    char_tireWidth_F: undefined,
    char_tireWidth_B: undefined,
    char_tireWidthUnits: "мм",
    char_tireHeight_F: undefined,
    char_tireHeight_B: undefined,
    char_tireHeightUnits: "%",
    char_tireDiametr_F: undefined,
    char_tireDiametr_B: undefined,
    char_tireDiametrUnits: "дюйм",
    char_tireWearout: undefined,
    char_tireProducer: undefined,
    char_tireModel: undefined,
    char_tireSeason: "Летняя",
    char_tireProduceDate: undefined,
    price: undefined,
    char_comment: undefined,
    char_note: undefined,
    char_used: undefined,
    char_tireMarkingType: "Метрическая",
    char_tireLT: false,
    char_tireRunFlat: false,
    char_tireSpeedIndex: undefined,
    char_tireLoadCoef: undefined,
    char_carsrcNumber: undefined,
    complectId: undefined,
    char_diskType: "Литой",
    char_diskWidth_F: undefined,
    char_diskWidth_B: undefined,
    char_diskDiametr_F: undefined,
    char_diskDiametr_B: undefined,
    char_diskET_F: undefined,
    char_diskET_B: undefined,
    char_diskPCD_F: ['', ''],
    char_diskPCD_B: ['', ''],
    char_diskDIA_F: undefined,
    char_diskDIA_B: undefined,
    char_diskProducer: undefined,
    char_diskModel: undefined,
    char_diskMfrnumber_F: undefined,
    char_diskMfrnumber_B: undefined,
  }