import { Divider, InputNumber, Table } from 'antd'
import styles from './ItemsTable.module.scss'
import { ColumnsType } from 'antd/es/table'
import { ISaleItem, UpdateSaleItemDto, useUpdateSaleItemMutation } from '../../../../../entities/saleItem'
import { ISale } from '../../../../../entities/sale'
import { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import { setSaleMessage, setSaleSelectedItems } from '../../store/salePageSlice'
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci'
import cs from 'classnames'
import { TableRowSelection } from 'antd/es/table/interface'
import { saleItemsSelector } from '../../store/selectors'

interface IProps {
  sale?: ISale,
  setQuantityError: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormValues {
  quantity?: number,
  price?: number,
  discountedPrice?: number
}

const ItemsTable: FC<IProps> = ({ sale, setQuantityError }) => {
  const { saleItems } = sale || {}
  const [updateSaleItem] = useUpdateSaleItemMutation()
  const [saleItemId, setSaleItemId] = useState<number>()
  const [quantity, setQuantity] = useState<number>()
  const dispatch = useAppDispatch()
  const returns = sale?.returns
  const selectedSaleItems = useAppSelector(saleItemsSelector)
  const { control } = useForm<FormValues>({
    defaultValues: {
      quantity: 0,
      price: 0,
      discountedPrice: 0
    }
  })



  const onSubmit = async (id: number, body: UpdateSaleItemDto) => {
    const res = await updateSaleItem({ id, body })
    if ('data' in res) {
      dispatch(setSaleMessage('Изменения сохранены'))
    }
  }


  useEffect(() => {
    saleItemId && onSubmit(saleItemId, { quantity })
  }, [saleItemId, quantity])

  useEffect(() => {
    if (saleItems) {
      const error = saleItems.some(({ product, quantity }) => product?.stock < quantity)
      setQuantityError(error)
    }
  }, [saleItems])

const calculateReturnQuantity = (saleItemId: number) => {
 const returnItems = returns?.map((item)=>  item?.returnItems?.filter((item)=> item?.saleItem?.id === saleItemId))
return returnItems?.flat().reduce((sum, item)=> sum + item.quantity, 0) || 0
}

  const columns: ColumnsType<ISaleItem> = [
    {
      title: 'Номер',
      render: (_, { product }) => product?.id
    },
    {
      title: 'Наименование',
      render: (_, { product }) => product?.char_part
    },
    {
      title: 'Кол-во',
      align: 'center',
      render: (_, { id, product, quantity }) => {
      
      return sale?.status !== 'issued' ?
       <div className={styles.quantity} >
        <div className={styles.quantityCounter} >
          <button onClick={() => {
            setSaleItemId(id);
            setQuantity(() => (quantity > 1 ? quantity - 1 : quantity));
          }}  >{<CiSquareMinus />}</button>
          <div>{quantity}</div>
          <button
            onClick={() => {
              setSaleItemId(id);
              setQuantity(() => (quantity + 1));
            }}
          >{<CiSquarePlus />}</button>
        </div>
        <div className={cs(styles.stock, product?.stock < quantity && styles.stock__error)} >Остаток {product?.stock}</div>
      </div> : 
      <div className={styles.quatity} >
        <div>{quantity}</div>
        {calculateReturnQuantity(id) > 0 && <div className={styles.returned} >{`Возвр. ${calculateReturnQuantity(id)}`}</div>}
      </div>
      }
    },
    {
      title: 'Цена',
      align: 'center',
      render: (_, {id, product, discountedPrice }) => {
        return sale?.status !== 'issued' ? <div className={styles.price} >
        <Controller
          name='price'
          control={control}
          render={({field}) =>
            <InputNumber value={discountedPrice != null && discountedPrice !== product?.char_price ? discountedPrice : product?.char_price} onBlur={()=> onSubmit(id, {discountedPrice: field.value})} style={{ background: 'transparent' }} controls={false} onChange={field.onChange} />
          }
        />
        { discountedPrice != null && discountedPrice !== product?.char_price && <div className={styles.oldPrice} >{product?.char_price}</div>}
      </div> : <div>{discountedPrice != null && discountedPrice !== product?.char_price ? discountedPrice : product?.char_price}</div>
      }
     
    }
  ]

 

  const rowSelection: TableRowSelection<ISaleItem> = {
    type: 'checkbox',
    selectedRowKeys: selectedSaleItems.map(({id})=> id),
    onChange: (value, saleItem) => dispatch(setSaleSelectedItems(saleItem))
  }

  return (
    <Table
      loading={!sale}
      className={styles.root}
      columns={columns}
      rowKey={(item) => item?.id}
      rowSelection={rowSelection}
      dataSource={saleItems}
    
    />
  )
}

export default ItemsTable