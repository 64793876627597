import { FC, useEffect } from 'react'
import styles from './EditEngineForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input, InputNumber, Select } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import cs from 'classnames'
import {IEngine, UpdateEngineDto, useUpdateEngineMutation } from '../../../../../../entities/engine'
import { useLocation } from 'react-router-dom'


interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
engine?: IEngine
}

const EditEngineForm: FC<IProps> = ({setIsModalOpen, engine}) => {
  const location = useLocation()
  const [messageApi, contextHolder] = useMessage()
  const [updateEngine] = useUpdateEngineMutation()
const {control, handleSubmit, setValue} = useForm<UpdateEngineDto>({
  defaultValues: {
    title: '',
    type: 'gasoline',
    capacity: undefined
  }
})

const onSubmit: SubmitHandler<UpdateEngineDto> = async (data)=> {
  if(engine) {
    await updateEngine({id: engine.id, body: data})
    messageApi.success('Двигатель успешно обновлен')
    setIsModalOpen(false)
  }
    
}

useEffect(()=> {
if(engine) {
  const {title, capacity, type} = engine
  setValue('title', title)
  capacity && setValue('capacity', capacity)
  setValue('type', type)
}
}, [engine])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input  id='title' {...field}/>
        )}
        />
      </div>
      <div className={cs(styles.formItem, styles.engineType)} >
        <label htmlFor="type">Тип двигателя</label>
        <Controller
        name='type'
        control={control}
        render={({field})=> (
          <Select
          className={styles.typeSelect}
          defaultValue='gasoline'
          {...field}
          options={[
            {value: 'gasoline', label: 'бензин'},
            {value: 'gas', label: 'газ'},
            {value: 'diesel', label: 'дизель'},
            {value: 'electric', label: 'электрический'},
            {value: 'hybrid', label: 'гибридный'}
          ]}
          />
        )}
        />
      </div>
      <div className={cs(styles.formItem, styles.capacity)} >
        <label htmlFor="capacity">Объем</label>
        <Controller
        name='capacity'
        control={control}
        render={({field})=> (
          <InputNumber controls={false} className={styles.capacityInput} id='capacity' {...field}   />
        )}
        />
      </div>
      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default EditEngineForm