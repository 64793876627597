


export const tireTemplateOptions = [
    {
      label: 'Легковая (метрическая) / Стандарная',
      value: 'мм, дюйм, Легковая, Стандартная, %'
      
    },
    {
      label: 'Легковая (метрическая) / Грязевая (АТ)',
      value: 'мм, дюйм, Легковая, Грязевая (АТ), %'
    },
    {
      label: 'Легковая (метрическая) / Грязевая (МТ)',
      value: 'мм, дюйм, Легковая, Грязевая (МТ), %'
    },
    {
      label: 'Легковая (дюймовая) / Стандарная',
      value: 'дюйм, дюйм, Легковая, Стандартная, дюйм'
    },
    {
      label: 'Легковая (дюймовая) / Грязевая (АТ)',
      value: 'дюйм, дюйм, Легковая, Грязевая (АТ), дюйм'
    },
    {
      label: 'Легковая (дюймовая) / Грязевая (МТ)',
      value: 'дюйм, дюйм, Легковая, Грязевая (МТ), дюйм'
    },
    {
      label: 'Грузовая (шаблон 12.00R16)',
      value: 'дюйм, дюйм, Грузовая, Стандартная'
    },
    {
      label: 'Грузовая (шаблон 340/55R16)',
      value: 'мм, %, Грузовая, Стандартная, дюйм'
    },
    {
      label: 'Грузовая (шаблон 13.0/55R16)',
      value: 'дюйм, %, Грузовая, Стандартная, дюйм'
    },
    {
      label: 'Спецтехника (шаблон 12.00R16)',
      value: 'дюйм, дюйм, Спецтехника, Стандартная'
    },
    {
      label: 'Спецтехника (шаблон 340/55R16)',
      value: 'мм, %, Спецтехника, Стандартная, дюйм'
    },
    {
      label: 'Спецтехника (шаблон 13.0/55R16)',
      value: 'дюйм, %, Спецтехника, Стандартная, дюйм'
    }
    
]