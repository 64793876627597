import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateReturnDto } from "../dtos/createReturnDto";
import { UpdateReturnDto } from "../dtos/updateReturnDto";
import { IReturn, IReturns } from "../interfaces/return";
import qs from 'qs'

interface QueryParams {
    pagination: {
        page: number
        pageSize: number
    }
}

const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        createReturn: build.mutation<IReturn, CreateReturnDto>({
            query: (body)=> ({
                url: '/returns',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Return', 'Product', 'Sale']
        }),
        updateReturn: build.mutation<IReturn, {id: number, body: UpdateReturnDto}>({
            query: ({id, body})=> ({
                url: `/returns/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Return']
        }),
        getOneReturn: build.query<IReturn, number>({
            query: (id)=> `/returns/${id}`,
            providesTags: ['Return']
        }),
        getAllReturns: build.query<IReturns, QueryParams>({
            query: (query)=> {
                const queryString = qs.stringify(query)
                return `/returns?${queryString}`
            },
            providesTags: ['Return']
        }),
        deleteReturn: build.mutation({
            query: (id)=> ({
                url: `/returns/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Return']
        })
    })
})

export const {useCreateReturnMutation, useUpdateReturnMutation, useGetOneReturnQuery, useGetAllReturnsQuery, useDeleteReturnMutation} = extendedApi