import { Button, Modal, Popconfirm, Switch } from 'antd'
import styles from './ApplicabilityModal.module.scss'
import { FC, useEffect, useMemo, useState } from 'react'
import { GoPlus } from 'react-icons/go'
import cs from 'classnames'
import { deleteMark, deleteModel, enginesSelector, generationsSelector, marksSelector, modelsSelector, resetApplicabity, setEngines } from '../../../../entities/applicability'
import { IoMdClose } from 'react-icons/io'
import ApplicabilityMarkModal from '../components/applicabilityMarkModal'
import ApplicabilityModelModal from '../components/applicabilityModelModal'
import ApplicabilityGeneration from '../components/applicabilityGeneration'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/reduxToolkit'


interface IProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm?: ()=> void
  onCancel?: ()=> void
}

export const ApplicabilityModal: FC<IProps> = ({ isModalOpen, setIsModalOpen, onConfirm, onCancel }) => {
  const [checkEngineImportant, setCheckEngineImportant] = useState<boolean>(true)
  const [isMarkModalOpen, setIsMarkModalOpen] = useState<boolean>(false)
  const [isEngineImportant, setIsEngineImportant] = useState<boolean>(false)
  const [isModelModalOpen, setIsModelModalOpen] = useState<boolean>(false)
  const checkedMarks = useAppSelector(marksSelector)
  const [activeMark, setActiveMark] = useState<number>()
  const [activeModel, setActiveModel] = useState<number>()
  const models = useAppSelector(modelsSelector)
  const checkedModels = useMemo(() => models.filter(({ markId }) => markId === activeMark), [models, activeMark])
  const checkedGenerations = useAppSelector(generationsSelector)
  const checkedEngines = useAppSelector(enginesSelector)

  const dispatch = useAppDispatch()

 
  useEffect(() => {
    if (checkedMarks.length > 0) {
      setActiveMark(checkedMarks.slice(-1)[0].id)
    } else {
      setActiveMark(undefined)
    }
  }, [checkedMarks])

  useEffect(() => {
    if (checkedModels.length > 0) {
      setActiveModel(checkedModels.slice(-1)[0].id)
    } else {
      setActiveModel(undefined)
    }
  }, [checkedModels])

  useEffect(() => {
    if (!isEngineImportant) {
      dispatch(setEngines([]))
    }
  }, [isEngineImportant])

  useEffect(()=> {
      if(checkEngineImportant && (checkedEngines.length > 0)) {
        setIsEngineImportant(true)
        setCheckEngineImportant(false)
      } 
    
  }, [checkedEngines, checkEngineImportant])


  const onMarkRemove = (markId: number) => {
    dispatch(deleteMark(markId))
  }

  const onModelRemove = (modelId: number) => {
    dispatch(deleteModel(modelId))
  }

  const handleCancel = () => {
    dispatch(resetApplicabity())
    setIsModalOpen(false)
    onCancel && onCancel()
  }

   const handleConfirm = ()=> {
        setIsModalOpen(false)
        onConfirm && onConfirm()
   }

   
  return (
    <Modal title='Редактирование применимости' className={styles.root} centered footer={false} onCancel={handleCancel} open={isModalOpen} >
      <div className={styles.inner} >

        <ApplicabilityMarkModal isModalOpen={isMarkModalOpen} setIsModalOpen={setIsMarkModalOpen} />
        <ApplicabilityModelModal activeMark={activeMark} isModalOpen={isModelModalOpen} setIsModalOpen={setIsModelModalOpen} />

        <div className={styles.leftPanel} ></div>
        <div className={styles.rightPanel} >
          <div className={styles.marks} >
            <div className={styles.marks_inner} >
              <div className={styles.marks_tags} >
                {checkedMarks?.length < 1 && <Button onClick={() => setIsMarkModalOpen(true)} size='large' type='primary' >Выбрать марку</Button>}
                {
                  checkedMarks?.map(({ id, title }) => (
                    <div key={id} onClick={() => setActiveMark(id)} className={cs(styles.marks_tag, id === activeMark && styles.marks_tag__active)} >
                      <div>{title}</div>
                      <div className={styles.marks_tagOptions} >
                        <div className={styles.marks_count}>{models.filter(({ markId }) => markId === id).length}</div>
                        <Popconfirm title='Удаление марки' description='Все выбранные данные у этой марки будут сброшены' onConfirm={() => onMarkRemove(id)} ><button className={styles.marks_removeBtn}><IoMdClose /></button></Popconfirm>
                      </div>
                    </div>
                  ))
                }
                {checkedMarks?.length > 0 && <Button icon={<GoPlus />} onClick={() => setIsMarkModalOpen(true)} className={styles.marks_plusBtn} size='large' type='text'  ></Button>}
              </div>
              <div className={styles.marks_engineToggle} >
                <Switch
                  value={isEngineImportant}
                  onChange={(value) => setIsEngineImportant(value)}
                  id='engine-toggle'
                  size='small' />

                <label htmlFor="engine-toggle"> Двигатель важен </label>
              </div>
            </div>

          </div>
          <div className={styles.models} >
            <div className={styles.models_tags} >
              {(checkedMarks?.length > 0 && checkedModels?.length < 1) && <Button onClick={() => setIsModelModalOpen(true)} size='middle' type='primary' >Выбрать модель</Button>}
              {
                checkedModels?.map(({ id, title, generations }) => (
                  <div key={id} onClick={() => setActiveModel(id)} className={cs(styles.models_tag, id === activeModel && styles.models_tag__active)} >
                    <div>{title}</div>
                    <div className={styles.marks_tagOptions} >
                      <div className={styles.marks_count}>{generations?.filter(({ id }) => checkedGenerations.some((item) => item.id === id)).length}</div>
                      <Popconfirm title='Удаление модели' description='Все данные у этой модели будут сброшены' onConfirm={() => onModelRemove(id)} ><button className={styles.models_removeBtn}><IoMdClose /></button></Popconfirm>
                    </div>
                  </div>
                ))
              }
              {checkedModels?.length > 0 && <Button icon={<GoPlus />} onClick={() => setIsModelModalOpen(true)} className={styles.marks_plusBtn} size='middle' type='text'  ></Button>}
            </div>
          </div>
          <div className={styles.generation} >
            <ApplicabilityGeneration isEngineImportant={isEngineImportant} activeModel={activeModel} />
          </div>
          <div className={styles.footer} >
        <div className={styles.footer_btnsWrapper} ><Button onClick={handleCancel} size='large' >Отмена</Button>  <Button onClick={handleConfirm} size='large' type='primary' >Применить</Button></div>
          </div>
        </div>
      </div>

    </Modal>
  )
}

