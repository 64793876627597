import { useGetMeQuery, useSignOutMutation } from '../../../entities/auth';
import styles from './Menu.module.scss'
import { IoIosLogOut } from "react-icons/io";
import { LuMenuSquare } from "react-icons/lu";
import { useAppDispatch } from '../../../shared/hooks/reduxToolkit';
import { emptySplitApi } from '../../../shared/configs/rtkBase';
import { Popconfirm } from 'antd';
import { useState } from 'react';
import cs from 'classnames'
import { Link, useLocation } from 'react-router-dom';
import { menuList } from '../constants/menuList';
import { IoSettingsOutline } from 'react-icons/io5';






export const Menu: React.FC = () => {
   const {data: user} = useGetMeQuery()
   const [signOut] = useSignOutMutation()
   const dispatch = useAppDispatch()
   const [collapsedMenu, setCollapsedMenu] = useState<boolean>(false)
   const location = useLocation()
   const handleSignOut = async ()=> {
     await signOut()
     localStorage.removeItem('jwt')
    dispatch(emptySplitApi.util.resetApiState())
}
  const path1 = location.pathname.split('/')[1]
  const path2 = location.pathname.split('/')[3]
  const path3 = location.pathname.split('/')[5]
 
  return (
    <div className={cs(styles.root, collapsedMenu && styles.root__collapsed)} >
      <nav className={styles.menu} >
        <div className={styles.header}>
          <button onClick={()=> setCollapsedMenu(!collapsedMenu)} className={styles.menuToggleBtn} >
          <LuMenuSquare/>
          </button>
          <div className={styles.userInfo} >
            <div className={styles.login} >{user?.login}</div>
            <Popconfirm
            title='Выход'
            description = 'Выйти из аккаунта?'
            onConfirm={handleSignOut}
            okText="Да"
            cancelText="Нет"
            ><button className={styles.logoutBtn} ><IoIosLogOut/></button></Popconfirm>
             </div>
          </div>
          <ul className={styles.menuList} >
            {menuList.map(({id, title, url, icon})=> (
              <li key={id} className={cs(styles.menuList_item, (path3 ? path3 === url : path2 ? path2 === url: path1 === url ) && styles.menuList_item__active)} >
                <Link to={url} >
                  <div className={styles.menuList_iconHolder} >{icon}</div>
                  <div className={styles.menuLits_title} >{title}</div>
                </Link>
              </li>
            ))}
          </ul>
          <div className={cs(styles.settings, path1 === '/settings' && styles.settings__active)} >
            <Link to='/settings' >
            <div className={styles.settings_iconHolder} >
            <IoSettingsOutline />
            </div>
            <div className={styles.settings_title} >Сервис и настройки</div>
            </Link>
          </div>
      </nav>
    </div>
  );
};

