import { Link, useNavigate } from 'react-router-dom'
import styles from './AddWriteoffPage.module.scss'
import { IoIosArrowBack } from 'react-icons/io'
import { Button, message, notification } from 'antd'
import { PiWarningCircleFill } from "react-icons/pi";
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks/reduxToolkit'
import ItemsTable from '../itemsTable'
import WriteoffUpdateForm from '../writeoffUpdateForm'
import { clearSelectedProducts, IProduct, productsSelector } from '../../../../../entities/product'
import { SubmitHandler, useForm } from 'react-hook-form';
import { CreateWriteoffDto, useCreateWriteoffMutation } from '../../../../../entities/writeoff';
import { GoPlus } from 'react-icons/go';
import { CatalogModal } from '../../../../../widgets/catalogModal';





export const AddWriteoffPage = () => {
    const [isCatalogModaleOpen, setIsCatalogModalOpen] = useState<boolean>(false)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [itemsToDelete, setItemsToDelete] = useState<{product: IProduct, quantity: number}[]>([])
    const [createWriteoff] = useCreateWriteoffMutation()
    const selectedProducts = useAppSelector(productsSelector)
    const {control, setValue, handleSubmit} = useForm<CreateWriteoffDto>( {
        defaultValues: {
            storageId: undefined,
            comment: undefined,
            status: 'draft'
        }
      })
    
 

    const onSubmit: SubmitHandler<CreateWriteoffDto> = async (data)=> {
        const writeoffItems = selectedProducts.map((item)=> ({productId: item?.product?.id, quantity: item?.quantity}))
        const res = await createWriteoff({...data, writeoffItems})
        if('data' in res) {
            message.success('Списание успешно создано')
            navigate(`/writeoffs/${res?.data?.id}`)
        } else if ('error' in res) {
            message.error('Ошибка сервера')
        }   
    }
    
    useEffect(()=> {
      return ()=> {dispatch(clearSelectedProducts())}
    }, [])
  
    

    return (
        <main className={styles.root} >
            <CatalogModal page='addWriteoff' isModalOpen={isCatalogModaleOpen} setIsModalOpen={setIsCatalogModalOpen} />
            <div className={styles.inner} >
                <div className={styles.header} >
                    <Link to='/writeoffs' className={styles.returnLink} ><IoIosArrowBack /></Link>
                    <h1 className={styles.title} >Списания</h1>
                </div>
                <div className={styles.content} >
                    <div className={styles.content_inner} >
                        <div className={styles.return} >
                            {itemsToDelete.length > 0 ?
                                <div className={styles.return_header} >
                                    <h2>{`Выбрано ${selectedProducts.length}`}</h2>
                                    <div className={styles.return_btnHolder} >
                                        <button className={styles.noticeButton} >
                                            Удалить из списания
                                        </button>
                                    </div>
                                    <Button type='text' onClick={() => dispatch(clearSelectedProducts())} >Отменить</Button>
                                </div>
                                :
                                <div className={styles.return_header} >

                                    <div className={styles.return_headerTitle} >

                                        <h2>Новое списание</h2>

                                    </div>
                             <div className={styles.return_btnHolder} >
                                        <Button onClick={handleSubmit(onSubmit)} type='primary' > Создать списание </Button>
                                        <Button onClick={()=> navigate('/returns')} type='default' > Отменить </Button>
                                    </div>
                                    <div className={styles.return_settings} ></div>
                                </div>}
                                <div className={styles.notice} > <PiWarningCircleFill /> Перед проводкой документа проверьте список товаров. Списанные товары нельзя восстановить</div>

                            <div className={styles.return_content} >
                                <WriteoffUpdateForm setValue={setValue} control={control} />
                                <div className={styles.return_tableHolder} >
                                <div className={styles.addBtnWrapper} >
                                        <button onClick={()=> setIsCatalogModalOpen(true)} >
                                            <GoPlus />
                                            Добавить
                                        </button>
                                    </div>
                                    <div className={styles.return_table} >
                                        {selectedProducts.length > 0 ? 
                                        <ItemsTable />
                                            :
                                            <div className={styles.emptyTableNotice} >
                                                <div className={styles.emptyTableNotice_inner} >
                                                    <h4>Документ пуст</h4>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.history} >
                            <div className={styles.history_header} >
                                <h2>История документа</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}