import styles from "./ImagesAndPriceFormPart.module.scss";
import { ImagesFormPart } from "../imagesFormPart";
import PriceFormPart from "../priceFormPart";
import { FC, useState } from "react";
import { Control, UseFormWatch } from "react-hook-form";
import { IWheelsFormValues } from "../../../../interfaces/wheelsFormValues";

interface IProps {
  watch: UseFormWatch<IWheelsFormValues>;
  setUniqueOptions: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        images?: number[];
        char_tireWearout?: number;
        price?: number;
        char_comment?: string;
        char_note?: string;
      }[]
    >
  >;
  control: Control<IWheelsFormValues, any>;
  isDifferentWidth: boolean;
  uniqueOptions: {
    id: number;
    images?: number[];
    char_tireWearout?: number;
    price?: number;
    char_comment?: string;
    char_note?: string;
  }[];
  isGeneralImagesAndComments: boolean;
  setIsGeneralImagesAndComments: React.Dispatch<React.SetStateAction<boolean>>;
  isNotValid: boolean;
}

export const ImagesAndPriceFormPart: FC<IProps> = ({
  watch,
  setUniqueOptions,
  isDifferentWidth,
  control,
  uniqueOptions,
  isGeneralImagesAndComments,
  setIsGeneralImagesAndComments,
  isNotValid
}) => {
  const [generalPrice, setGeneralPrice] = useState<number>();

  return (
    <>
      <PriceFormPart
        generalPrice={generalPrice}
        setGeneralPrice={setGeneralPrice}
        watch={watch}
        isGeneralImagesAndComments={isGeneralImagesAndComments}
        setIsGeneralImagesAndComments={setIsGeneralImagesAndComments}
        uniqueOptions={uniqueOptions}
        setUniqueOptions={setUniqueOptions}
        isDifferentWidth={isDifferentWidth}
        control={control}
      />
      <ImagesFormPart
        isNotValid={isNotValid}
        generalPrice={generalPrice}
        setGeneralPrice={setGeneralPrice}
        isGeneralImagesAndComments={isGeneralImagesAndComments}
        control={control}
        watch={watch}
        isDifferentWidth={isDifferentWidth}
        setUniqueOptions={setUniqueOptions}
        uniqueOptions={uniqueOptions}
      />
    </>
  );
};
