import { FC, useEffect } from 'react'
import styles from './EditGenerationForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input, InputNumber } from 'antd'
import useMessage from 'antd/es/message/useMessage'
import cs from 'classnames'
import { IGeneration, UpdateGenerationDto, useUpdateGenerationMutation } from '../../../../../../entities/generation'



interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
generation?: IGeneration
}

const EditGenerationForm: FC<IProps> = ({setIsModalOpen, generation}) => {
  const [messageApi, contextHolder] = useMessage()
  const [updateGeneration] = useUpdateGenerationMutation()
const {control, handleSubmit, reset, setValue} = useForm<UpdateGenerationDto>({
  defaultValues: {
    title: '',
    genVersion: undefined,
    years: '',
  }
})

const onSubmit: SubmitHandler<UpdateGenerationDto> = async (data)=> {
    if(generation) {
        await updateGeneration({id: generation.id, body: data})
        messageApi.success('Поколение успешно обновлено')
        reset()
        setIsModalOpen(false)
    }

}

 
useEffect(()=> {
    if(generation) {
        const {title, genVersion, years} = generation
        setValue('title', title)
        setValue('genVersion', genVersion)
        setValue('years', years)
    }
}, [generation])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={cs(styles.formItem, styles.genVersion)} >
        <label htmlFor="genVersion">№ поколения</label>
        <Controller
        name='genVersion'
        control={control}
        render={({field})=> (
          <InputNumber controls={false} id='genVersion' {...field}   />
        )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input id='title' {...field}   />
        )}
        />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="years">Года</label>
        <Controller
        name='years'
        control={control}
        render={({field})=> (
          <Input id='years' {...field}   />
        )}
        />
      </div>
      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default EditGenerationForm