import { FC, useEffect } from "react";
import styles from "./ImagesFormPart.module.scss";
import FormItem from "../formItem";
import { Control, UseFormWatch } from "react-hook-form";
import { IWheelsFormValues } from "../../../../../../interfaces/wheelsFormValues";

interface IProps {
  control: Control<IWheelsFormValues, any>;
  watch: UseFormWatch<IWheelsFormValues>;
  isDifferentWidth: boolean;
  setUniqueOptions: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        images?: number[];
        char_tireWearout?: number;
        price?: number;
        char_comment?: string;
        char_note?: string;
      }[]
    >
  >;
  uniqueOptions: {
    id: number;
    images?: number[];
    char_tireWearout?: number;
    price?: number;
    char_comment?: string;
    char_note?: string;
  }[];
  isGeneralImagesAndComments: boolean;
  setGeneralPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
  generalPrice: number | undefined;
  isNotValid: boolean
}

export const ImagesFormPart: FC<IProps> = ({
  watch,
  isDifferentWidth,
  setUniqueOptions,
  uniqueOptions,
  isGeneralImagesAndComments,
  setGeneralPrice,
  generalPrice,
  isNotValid
}) => {


  useEffect(() => {
    setUniqueOptions((prev) => prev.slice(0, watch("char_wheelSetNumber")));
  }, [watch("char_wheelSetNumber")]);

  const items = [];
  for (let i = 1; i <= watch("char_wheelSetNumber"); i++) {
    items.push(
      <FormItem
      isNotValid={isNotValid}
      generalPrice={generalPrice}
      setGeneralPrice={setGeneralPrice}
        isGeneralImagesAndComments={isGeneralImagesAndComments}
        uniqueOptions={uniqueOptions}
        key={i}
        setUniqueOptions={setUniqueOptions}
        id={i}
        watch={watch}
        isDifferentWith={isDifferentWidth}
      />
    );
  }

  return (
    <div className={styles.root}>
      {items}
    </div>
  );
};
