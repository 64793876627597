import { url } from "inspector";
import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { IUser } from "../../user";
import { SignInDto } from "../dtos/signInDto";
import { SignUpDto } from "../dtos/signUpDto";




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        signUp: build.mutation<IUser, SignUpDto>({
            query: (body) => ({
                url: '/auth/sign-up',
                method: 'POST',
                body
            })
        }),
        signIn: build.mutation<IUser, SignInDto>({
            query: (body) => ({
                url: '/auth/sign-in',
                method: 'POST',
                body
            })
        }),
        signOut: build.mutation<void, void>({
            query: () => ({
                url: '/auth/sign-out',
                method: 'POST',
            })
        }),
        getMe: build.query<IUser, void>({
            query: () => '/auth/me'
        })
    })
})



export const { useSignUpMutation, useSignInMutation, useSignOutMutation, useGetMeQuery } = extendedApi