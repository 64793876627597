import { Table } from 'antd'
import styles from './ItemsTable.module.scss'
import { ColumnsType } from 'antd/es/table'
import { FC, Key, useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci'
import { TableRowSelection } from 'antd/es/table/interface'
import { IWriteoffItem, useUpdateWriteoffItemMutation } from '../../../../../entities/writeoffItem'
import cs from 'classnames'


interface IProps {
  status?: 'draft' | 'complete' | 'canceled'
  writeoffItems?: IWriteoffItem[]
  deletedItems?: number[]
  setDeletedItems: React.Dispatch<React.SetStateAction<number[]>>
}
  

interface FormValues {
  quantity?: number,
  price?: number,
  discountedPrice?: number
  
}

const ItemsTable: FC<IProps> = ({writeoffItems, deletedItems, setDeletedItems, status}) => {
  const [writeoffItemId, setWriteoffItemId] = useState<number>()
  const [quantity, setQuantity] = useState<number>()
  const [updateWriteoffItem] = useUpdateWriteoffItemMutation()
  const { control } = useForm<FormValues>({
    defaultValues: {
      quantity: 0,
      price: 0,
      discountedPrice: 0
    }
  })


   useEffect(()=> {
    if(writeoffItemId && quantity) {
      updateWriteoffItem({id: writeoffItemId, body: {quantity}})
    }
   }, [writeoffItemId, quantity])
 

  const columns: ColumnsType<IWriteoffItem> = [
    {
      title: 'Номер',
      dataIndex: 'id'
    },
    {
      title: 'Наименование',
      render: (_, { product }) => product?.char_part
    },
    {
      title: 'Кол-во',
      align: 'center',
      render: (_, { id, product, quantity }) => {
      return <div className={styles.quantity} >
      {status === 'draft' ? <div className={styles.quantityCounter} >
        <button onClick={()=> {
          setWriteoffItemId(id)
          setQuantity(quantity > 1 ? --quantity : 1)
        }} >{<CiSquareMinus />}</button>
        <div>{quantity}</div>
        <button onClick={()=> {
          setWriteoffItemId(id)
          setQuantity(++quantity)
        }} >{<CiSquarePlus />}</button>
      </div> :
      <div>{quantity}</div>
      }
     { status === 'draft' && <div className={cs(styles.stock, product?.stock < quantity && styles.stock__error)}  >Остаток {product?.stock}</div>}
    </div> 
      }
    }
  ]


  const rowSelection: TableRowSelection<IWriteoffItem> = {
    type: 'checkbox',
    selectedRowKeys: deletedItems,
    onChange: (keys) => setDeletedItems(keys as number[])
  }

  return (
    <Table
      className={styles.root}
      columns={columns}
      rowKey={(item) => item?.id}
      rowSelection={ status ==='draft' ? rowSelection : undefined}
      dataSource={writeoffItems}
    
    />
  )
}

export default ItemsTable