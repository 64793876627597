import { Button } from 'antd'
import styles from './PaymentsPage.module.scss'
import {useState } from 'react'
import Navbar from '../navbar'
import PaymentsTable from '../paymentsTable'






export const PaymentsPage = () => {
  const [selectedItem, setSelectedItem] = useState<'all'| 'gt' | 'lt'| 'checks' >('all')

  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1 className={styles.title} >Поступления</h1>
      </div>
      <div className={styles.inner} >
        <Navbar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        <div className={styles.content} >
          <div className={styles.contentHeader} >
            <Button className={styles.filtersResetBtn} disabled={true} >Фильтры не заданы</Button>
          </div>
          <div className={styles.table} >
   
             { selectedItem !== 'checks' && <PaymentsTable selectedItem={selectedItem} />}
            
            
          </div>
        </div>
      </div>
    </main>
  )
}

