import { Select } from "antd"
import { FC } from "react"
import { IProduct, UpdateProductDto } from "../../../../../../../../../entities/product"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { tireTemplateOptions } from "../../constants/tireTemplateOptions"


interface IProps {
    product?: IProduct
    setValue: UseFormSetValue<UpdateProductDto>
    watch: UseFormWatch<UpdateProductDto>
    templateValue?: string
}
    


const TemplateSelect: FC<IProps> = ({product, templateValue, setValue, watch}) => {


  const onTemplateChange = (value: string)=> {
    const [char_tireWidthUnits, char_tireDiametrUnits, char_tireVehicleType, char_tireProtectorType, char_tireHeightUnits] = value.split(', ')
    setValue('char_tireWidthUnits', char_tireWidthUnits)
    setValue('char_tireDiametrUnits', char_tireDiametrUnits)
    setValue('char_tireProtectorType', char_tireProtectorType)
    setValue('char_tireVehicleType', char_tireVehicleType)
    setValue('char_tireHeightUnits', char_tireHeightUnits)
    setValue('char_tireWidth', product?.char_tireWidth)
    setValue('char_tireDiametr', product?.char_tireDiametr)
    if(char_tireProtectorType !== 'Стандартная' || char_tireVehicleType === 'Спецтехника') {
      setValue('char_tireLT', false)
    } else {
      setValue('char_tireLT', product?.char_tireLT)
    }
   
    if(!char_tireHeightUnits) {
      setValue('char_tireHeight', null)
      setValue('char_tireHeightUnits', null)
    } else if(char_tireHeightUnits) {
      setValue('char_tireHeight', product?.char_tireHeight)
    }
  }
  
  

   


  return (
    <Select onChange={onTemplateChange} value={templateValue}  options={tireTemplateOptions} />
  )
}

export default TemplateSelect