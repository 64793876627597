import { FC, ReactNode, useEffect, useState } from 'react'
import styles from './CatalogModal.module.scss'
import { Modal } from 'antd'
import Parts from '../parts'
import Wheels from '../wheels'
import Services from '../services'
import { useAppDispatch } from '../../../../shared/hooks/reduxToolkit'
import { clearSelectedProducts } from '../../../../entities/product'
import { Navbar } from '../navbar'

interface IProps {
  isModalOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>> 
  page: 'sale' | 'addWriteoff' | 'writeoff'
}

export const CatalogModal: FC<IProps> = ({isModalOpen, setIsModalOpen, page}) => {
  const [selectedTab, setSelectedTab] = useState<'parts' | 'wheels' | 'services'>('parts')
  const [renderedComponent, setRenderedComponent] = useState<ReactNode>()
  const dispatch = useAppDispatch()
   useEffect(()=> {
      switch(selectedTab) {
        case 'parts': setRenderedComponent(<Parts/>)
        break
        case 'wheels': setRenderedComponent(<Wheels/>)
        break
        case 'services': setRenderedComponent(<Services/>)
      }
   }, [selectedTab])

   const onCancel = ()=> {
    if(page !== 'addWriteoff') {
      dispatch(clearSelectedProducts())
    }
    setIsModalOpen(false)

   }

  return (
    <Modal onCancel={onCancel} className={styles.root} centered width='100vw' footer={false} open={isModalOpen} >
      <Navbar page={page} setIsCatalogModalOpen={setIsModalOpen} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {renderedComponent}
      </Modal>

  )
}
