import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styles from './AddMarkForm.module.scss'
import { Button, Input } from 'antd'
import { CreateMarkDto, useCreateMarkMutation } from '../../../../../../entities/mark'
import { FC } from 'react'
import useMessage from 'antd/es/message/useMessage'


interface IProps  {
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AddMarkForm: FC<IProps> = ({setIsModalOpen}) => {
    const [messageApi, contextHolder] = useMessage()
    const [createMark] = useCreateMarkMutation()
    const {control, handleSubmit, reset} = useForm({
        defaultValues: {
            title: ''
        }
    })


    const onSubmit: SubmitHandler<CreateMarkDto> = async (data)=> {
         await createMark(data)
         messageApi.success('Марка успешно создана')
         reset()
         setIsModalOpen(false)
    }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
        {contextHolder}
        <div className={styles.formItem} >
            <label htmlFor="title">Название</label>
            <Controller
            name='title'
            control={control}
            render={({field})=> (
                <Input id='title' placeholder='Название' {...field} />
            )}
            />
        </div>
        <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button htmlType='submit' type='primary' >Сохранить</Button></div>
    </form>
  )
}

export default AddMarkForm