import styles from "./wheelsForm.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { forwardRef, useEffect, useState } from "react";
import { TyresFormPart } from "../tyresFormPart";
import { DiskFormPart } from "../diskFormPart";
import { ImagesAndPriceFormPart } from "../imagesAndPriceFormPart";
import { IWheelsFormValues } from "../../interfaces/wheelsFormValues";
import { useLocation } from "react-router-dom";
import { defaultValues } from "../../constants/defaultValues";
import { message, notification } from "antd";
import { useCreateProductMutation } from "../../../../../../../../../entities/product";
import { useCreateWheelsSetMutation } from "../../../../../../../../../entities/wheelsSet";


interface IProps {
  activeTab: "Запчасти" | "Шины" | "Диски" | "Колеса";
  setWheelsQuantity: React.Dispatch<React.SetStateAction<number>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WheelsForm = forwardRef<HTMLFormElement, IProps>(
  ({ activeTab, setWheelsQuantity, setIsModalOpen }, ref) => {
    const [uniqueOptions, setUniqueOptions] = useState<
      {
        id: number;
        images?: number[];
        char_tireWearout?: number;
        price?: number;
        char_comment?: string;
        char_note?: string;
      }[]
    >([]);
    const [isGeneralImagesAndComments, setIsGeneralImagesAndComments] = useState<boolean>(false)
    const [isDifferentWidth, setIsDifferentWidth] = useState<boolean>(false);
    const [createProduct] = useCreateProductMutation()
    const [isNotValid, setIsNotValid] = useState<boolean>(false)
    const location = useLocation()
    const incomeId = +location.pathname.split('/')[2]
    const { control, watch, handleSubmit, setValue, reset } = useForm<IWheelsFormValues>({defaultValues})
    const [createWheelsSet] = useCreateWheelsSetMutation()




    useEffect(() => {
      setWheelsQuantity(watch("char_wheelSetNumber"));
    }, [watch("char_wheelSetNumber")]);


    const resetForm = () => {
      uniqueOptions.length > 0 && setUniqueOptions(uniqueOptions.map((item)=> {
        item.images = []
        item.char_tireWearout = undefined
        item.char_comment = undefined
        item.char_note = undefined
        item.price = undefined
        return item
      }))
      reset()
      setIsDifferentWidth(false)
      setIsGeneralImagesAndComments(false)
    }

      useEffect(()=> {
        setIsNotValid(false)
        resetForm()
        switch(activeTab) {
          case 'Шины': setValue('char_wheelPart', 'Шина')
          break
          case 'Диски': setValue('char_wheelPart', 'Диск')
          break
          case 'Колеса': setValue('char_wheelPart', 'Колесо')
          break
        } 
    }, [activeTab])

  


    const onSubmit: SubmitHandler<IWheelsFormValues> = async (data) => {

      const hasRequiredfields = (fields: (string | number | undefined)[]) => { return fields.every((item) => !!item) }

      const isTireValid = () => {
        return ((activeTab === 'Шины' || activeTab === 'Колеса')
          && hasRequiredfields([watch('char_tireWidth_F'), watch('char_tireHeight_F'), watch('char_tireDiametr_F')])
          && (!isDifferentWidth
            || hasRequiredfields([watch('char_tireWidth_B'), watch('char_tireHeight_B'), watch('char_tireDiametr_B')]))
        ) ? true : false

      }

      const isDiskValid = () => {
        return ((activeTab === 'Диски' || activeTab === 'Колеса' ) &&
          hasRequiredfields([
            watch('char_diskWidth_F'), watch('char_diskDiametr_F'), watch('char_diskDIA_F'),
            watch('char_diskET_F'), watch('char_diskPCD_F')[0]
          ]) && (!isDifferentWidth || isDifferentWidth
            && hasRequiredfields([
              watch('char_diskWidth_B'), watch('char_diskDiametr_B'), watch('char_diskDIA_B'),
              watch('char_diskET_B'), watch('char_diskPCD_B')[0]
            ])
          )) ? true : false
      }

      const isImagesAndPriceValid = ()=> {
        if(activeTab !== 'Диски' ) {
          return(isGeneralImagesAndComments ? uniqueOptions[0].char_tireWearout !== undefined : uniqueOptions.every(({char_tireWearout})=> char_tireWearout !== undefined ) )
        } else return true
              
      }

     
      if ((activeTab === 'Колеса' ? (isTireValid() && isDiskValid()) : (isTireValid() || isDiskValid())) && isImagesAndPriceValid()) {
        const {
          char_tireCordType_F,
          char_tireCordType_B,
          char_tireWidth_F,
          char_tireWidth_B,
          char_tireHeight_F,
          char_tireHeight_B,
          char_tireDiametr_F,
          char_tireDiametr_B,
          char_diskWidth_F,
          char_diskWidth_B,
          char_diskDiametr_F,
          char_diskDiametr_B,
          char_diskET_F,
          char_diskET_B,
          char_diskPCD_F,
          char_diskPCD_B,
          char_diskDIA_F,
          char_diskDIA_B,
          char_diskMfrnumber_F,
          char_diskMfrnumber_B,
          char_wheelSetNumber,
          char_carsrcNumber,
          complectId,
          char_wheelPart,
          char_tireMarkingType,
          ...body
        } = data;

        const wheelsSet = await createWheelsSet()
        const promises = [];

        for (let i = 0; i < watch("char_wheelSetNumber"); i++) {
          const { id, price, ...options } = uniqueOptions[isGeneralImagesAndComments ? 0 : i];
          const createWheelData = (options: {
            images?: number[];
            char_tireWearout?: number;
            char_comment?: string;
            char_note?: string;
          }, isBack: boolean) => ({
            ...body,
            ...options,
            char_price: price,
            char_tireCordType: isBack ? char_tireCordType_B : char_tireCordType_F,
            char_tireWidth: isBack ? char_tireWidth_B : char_tireWidth_F,
            char_tireHeight: isBack ? char_tireHeight_B : char_tireHeight_F,
            char_tireDiametr: isBack ? char_tireDiametr_B : char_tireDiametr_F,
            char_diskWidth: isBack ? char_diskWidth_B : char_diskWidth_F,
            char_diskDiametr: isBack ? char_diskDiametr_B : char_diskDiametr_F,
            char_diskET: isBack ? char_diskET_B : char_diskET_F,
            char_diskPCD: isBack ? char_diskPCD_B : char_diskPCD_F,
            char_diskDIA: isBack ? char_diskDIA_B : char_diskDIA_F,
            char_diskMfrnumber: isBack ? char_diskMfrnumber_B : char_diskMfrnumber_F,
            char_part: char_wheelPart,
            incomeId
          })

          const isBack = isDifferentWidth && i > 2
          promises.push(
            createProduct({isUsed: true, category: 'wheel', wheelsSetId: wheelsSet?.data?.id, ...createWheelData(options, isBack)})
          )
        }
      
        const results = await Promise.all(promises)
        const isError = results.some((res) => "error" in res);
        if (isError) {
          message.error("Ошибка сервера");
        } else {
          setIsModalOpen(false);
          message.success("Товары успешно добавлены");
        }
      } else {
        setIsNotValid(true)
        notification.warning({
          message: 'Не удалось сохранить',
          description: 'Заполните обязательные поля',
          placement: 'bottom'
        }
        )
      }

    };



    return (
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} className={styles.root}>
        {(activeTab === "Шины" || activeTab === "Колеса") && (
          <TyresFormPart
            isNotValid={isNotValid}
            setValue={setValue}
            setIsDifferentWidth={setIsDifferentWidth}
            isDifferentWidth={isDifferentWidth}
            setUniqueOptions={setUniqueOptions}
            watch={watch}
            control={control}
          />
        )}
        {(activeTab === "Диски" || activeTab === "Колеса")
         && <DiskFormPart isNotValid={isNotValid} setValue={setValue} isDifferentWidth={isDifferentWidth} setIsDifferentWidth={setIsDifferentWidth} watch={watch} control={control} />}
        <ImagesAndPriceFormPart
          isNotValid={isNotValid}
          isGeneralImagesAndComments={isGeneralImagesAndComments}
          setIsGeneralImagesAndComments={setIsGeneralImagesAndComments}
          uniqueOptions={uniqueOptions}
          control={control}
          watch={watch}
          isDifferentWidth={isDifferentWidth}
          setUniqueOptions={setUniqueOptions}
        />
      </form>
    );
  }
);
