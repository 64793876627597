import { FC, useEffect } from 'react'
import styles from './EditModelForm.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Input } from 'antd'
import { IModel, UpdateModelDto, useUpdateModelMutation } from '../../../../../../entities/model'
import useMessage from 'antd/es/message/useMessage'



interface IProps {
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
model?: IModel
}

const EditModelForm: FC<IProps> = ({setIsModalOpen, model}) => {
  const [messageApi, contextHolder] = useMessage()
  const [updateModel] = useUpdateModelMutation()
  const {control, handleSubmit, setValue} = useForm({
  defaultValues: {
    title: ''
  }
})

useEffect(()=> {
    if(model) {
        setValue('title', model.title)
    }
}, [model])

const onSubmit: SubmitHandler<UpdateModelDto> = async (data)=> {
    if(model)  {
        await updateModel({id: model.id, body: data})
        messageApi.success('Модель успешно обновлена')
        setIsModalOpen(false)
    }
  
}

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
      {contextHolder}
      <div className={styles.formItem} >
        <label htmlFor="title">Название</label>
        <Controller
        name='title'
        control={control}
        render={({field})=> (
          <Input id='title' {...field}   />
        )}
        />
      </div>
      <div className={styles.footer} > <Button onClick={()=> setIsModalOpen(false)} >Отмена</Button> <Button type='primary' htmlType='submit' >Сохранить</Button> </div>
    </form>
  )
}

export default EditModelForm