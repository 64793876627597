import { Button, Modal } from 'antd'
import styles from './UsedProductModal.module.scss'
import { FC, useMemo, useRef, useState } from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'
import { useAppDispatch } from '../../../../../../../shared/hooks/reduxToolkit'
import { resetApplicabity } from '../../../../../../../entities/applicability'
import { WheelsForm } from '../wheelsForm'
import { ProductForm } from '../productForm'



interface IProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

type NavbarItem = {
    id: number
    title: 'Запчасти'| 'Шины' | 'Диски' | 'Колеса'
}

const navbarItems : NavbarItem[] = [
    {
        id: 1,
        title: 'Запчасти'
    },
    {
        id: 2,
        title: 'Шины'
    },
    {
        id: 3,
        title: 'Диски'
    },
    {
        id: 4,
        title: 'Колеса'
    }
]


export const UsedProductModal: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
    const productFormRef = useRef<HTMLFormElement>(null)
    const wheelsFormRef = useRef<HTMLFormElement>(null)
    const dispatch = useAppDispatch()
    const [activeTab, setActiveTab] = useState<NavbarItem['title']>('Запчасти')
    const [wheelsQuantity, setWheelsQuantity] = useState<number>(4)


    const productSubmit = () => {
        productFormRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
    
    const wheelsSubmit = () => {
        wheelsFormRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })) 
    }
    
    


const submitBtn = useMemo(()=> {
        switch(activeTab) {
         case 'Запчасти': return <Button onClick={productSubmit} icon={<FaArrowRightLong />} iconPosition='end' type='primary' >Создать товар</Button>
         case 'Шины': return <Button onClick={wheelsSubmit} icon={<FaArrowRightLong />} iconPosition='end' type='primary' >{`Создать ${wheelsQuantity} шин`}</Button>
         case 'Диски': return <Button onClick={wheelsSubmit} icon={<FaArrowRightLong />} iconPosition='end' type='primary' >{`Создать ${wheelsQuantity} дисков`}</Button>
         case 'Колеса': return <Button onClick={wheelsSubmit} icon={<FaArrowRightLong />} iconPosition='end' type='primary' >{`Создать ${wheelsQuantity} колес`}</Button>
         default: return <Button onClick={productSubmit} icon={<FaArrowRightLong />} iconPosition='end' type='primary' >Создать товар</Button>
        } 
    }, [activeTab, wheelsQuantity] ) 
    
    const onCancel = ()=> {
        setIsModalOpen(false)
        dispatch(resetApplicabity())
    }

   

    return (
        <Modal destroyOnClose footer={false} centered open={isModalOpen} className={styles.root} onClose={onCancel} onCancel={onCancel } >
            <div className={styles.header} >
                <h1 className={styles.title} >Добавление б/у товара</h1>
                <ul className={styles.tabsList} >
                    {
                        navbarItems.map(({ id, title }) => (
                            <li key={id} ><Button onClick={() => setActiveTab(title)} type={activeTab === title ? 'primary' : 'text'} >{title}</Button></li>
                        ))
                    }
                </ul>
            </div>
            <div className={styles.content} >

                <div className={styles.formWrapper} >
                    <div className={styles.formHolder}>
                        {activeTab === 'Запчасти' ? 
                        <ProductForm setIsModalOpen={setIsModalOpen} ref={productFormRef} />
                         : 
                         <WheelsForm setIsModalOpen={setIsModalOpen} ref={wheelsFormRef} setWheelsQuantity={setWheelsQuantity} activeTab={activeTab} />}
                    </div>
                    <div className={styles.formWrapper_footer} >{submitBtn}</div>
                </div>


                <div className={styles.tableHolder} ></div>
            </div>

        </Modal>
    )
}

