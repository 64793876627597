import { emptySplitApi } from "../../../shared/configs/rtkBase";
import { CreateStorageDto } from "../dtos/createStorageDto";
import { IStorage } from "../interfaces/storage";




const extendedApi = emptySplitApi.injectEndpoints({
    endpoints: (build)=> ({
        createStorage: build.mutation<IStorage, CreateStorageDto>({
            query: (body)=> ({
                url: '/storages',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Storage']
        }),
        updateStorage: build.mutation<IStorage, {id: number, body: CreateStorageDto}>({
            query: ({id, body})=> ({
                url: `/storage/${id}`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Storage']
        }),
        getAllStorages: build.query<IStorage[], void>({
            query: ()=> '/storages',
            providesTags: ['Storage']
        }),
        getOneStorage: build.query<IStorage, number>({
            query: (id)=> `/storages/${id}`,
            providesTags: ['Storage']
        })
    })
})


export const {useCreateStorageMutation, useUpdateStorageMutation, useGetAllStoragesQuery, useGetOneStorageQuery} = extendedApi