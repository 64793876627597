import { InputNumber, Table } from 'antd'
import styles from './ItemsTable.module.scss'
import { ColumnsType } from 'antd/es/table'
import { ISaleItem, UpdateSaleItemDto, useUpdateSaleItemMutation } from '../../../../../entities/saleItem'
import { ISale } from '../../../../../entities/sale'
import { FC} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch } from '../../../../../shared/hooks/reduxToolkit'
import { CiSquareMinus, CiSquarePlus } from 'react-icons/ci'
import { TableRowSelection } from 'antd/es/table/interface'
import { setSaleMessage } from '../../../../(sales)/salePage'


interface IProps {
  sale?: ISale,
  items?: ISaleItem[],
  setItems: React.Dispatch<React.SetStateAction<ISaleItem[]>>
  deletedItems: number[],
  setDeletedItems: React.Dispatch<React.SetStateAction<number[]>>
}

interface FormValues {
  quantity?: number,
  price?: number,
  discountedPrice?: number
}

const ItemsTable: FC<IProps> = ({ sale, items, setItems, deletedItems, setDeletedItems }) => {
  const [updateSaleItem] = useUpdateSaleItemMutation()
  const dispatch = useAppDispatch()
  const returns = sale?.returns
  const { control } = useForm<FormValues>({
    defaultValues: {
      quantity: 0,
      price: 0,
      discountedPrice: 0
    }
  })



  const onSubmit = async (id: number, body: UpdateSaleItemDto) => {
    const res = await updateSaleItem({ id, body })
    if ('data' in res) {
      dispatch(setSaleMessage('Изменения сохранены'))
    }
  }


  const calculateReturnQuantity = (saleItemId: number) => {
    const returnItems = returns?.map((item)=>  item?.returnItems?.filter((item)=> item?.saleItem?.id === saleItemId))
    return returnItems?.flat().reduce((sum, item)=> sum + item.quantity, 0) || 0
   }

 

  const onPlusClick = (id: number)=> {
    const newValue = items?.map((item)=> {
      if(item.id === id ) {
        return {...item, quantity: item.quantity + 1}
      } else {
        return item
      }
    })
     setItems(newValue || [])
  }

  const onMinusClick = (id: number)=> {
       const newValue = items?.map((item)=> {
        if(id === item.id && item.quantity > 1) {
            return {...item, quantity: item.quantity - 1}
        } else {
          return item
        }
       })
       setItems(newValue || [])
  }

  const columns: ColumnsType<ISaleItem> = [
    {
      title: 'Номер',
      render: (_, { product }) => product?.id
    },
    {
      title: 'Наименование',
      render: (_, { product }) => product?.char_part
    },
    {
      title: 'Кол-во',
      align: 'center',
      render: (_, { id, quantity }) => {
      return <div className={styles.quantity} >
        <div className={styles.quantityCounter} >
          <button onClick={() => onMinusClick(id)}  >{<CiSquareMinus />}</button>
          <div>{quantity}</div>
          <button
            onClick={() => onPlusClick(id)}
          >{<CiSquarePlus />}</button>
        </div>
        {calculateReturnQuantity(id) > 0 && <div className={styles.returned} >{`Возвр. ${calculateReturnQuantity(id)}`}</div>}
      </div> 
      }
    },
    {
      title: 'Цена',
      align: 'center',
      render: (_, {id, product, discountedPrice }) => {
        return sale?.status !== 'issued' ? <div className={styles.price} >
        <Controller
          name='price'
          control={control}
          render={({field}) =>
            <InputNumber value={discountedPrice != null && discountedPrice !== product?.char_price ? discountedPrice : product?.char_price} onBlur={()=> onSubmit(id, {discountedPrice: field.value})} style={{ background: 'transparent' }} controls={false} onChange={field.onChange} />
          }
        />
        { discountedPrice != null && discountedPrice !== product?.char_price && <div className={styles.oldPrice} >{product?.char_price}</div>}
      </div> : <div>{discountedPrice != null && discountedPrice !== product?.char_price ? discountedPrice : product?.char_price}</div>
      }
     
    }
  ]


  const rowSelection: TableRowSelection<ISaleItem> = {
    type: 'checkbox',
    selectedRowKeys: deletedItems.map((id)=> id),
    onChange: (value, saleItem) => setDeletedItems(saleItem.map(({id})=> id ))
  }

  return (
    <Table
      className={styles.root}
      columns={columns}
      rowKey={(item) => item?.id}
      rowSelection={rowSelection}
      dataSource={items}
    
    />
  )
}

export default ItemsTable