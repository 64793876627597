import { Table, TableColumnsType } from 'antd'
import styles from './IncomesTable.module.scss'
import { IIncome, useGetAllIncomesQuery } from '../../../../../../entities/income';
import { useNavigate } from 'react-router-dom';
import { FC, useState } from 'react';



const columns: TableColumnsType<IIncome> = [

  {
    title: 'Номер',
    dataIndex: 'id',
  },
  {
    title: 'Дата',
    dataIndex: 'createdAt',
    render: (text)=> text.slice(0,10).split('-').reverse().join('-')
  },

  {
    title: 'Поставка',
    dataIndex: 'shipment',
    render: (text)=> text?.title
  },
  {
    title: 'Склад Оприходывания',
    dataIndex: 'storage',
    render: (text)=> text?.name
  },
  {
    title: 'Сумма',
    dataIndex: 'summ',
    render: (text, item) => <div className={styles.tablePrice} ><div >{(item?.summ || 0) + (item?.overheadExpenses || 0)}</div> <div className={styles.tablePrice_description} >{`${item.summ || 0} + ${item.overheadExpenses || 0}`}</div></div>
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (text)=> (text === 'draft' && 'Черновик')
  },
  {
    title: 'Комментарий к поступлению',
    dataIndex: 'comment',
  },
];


interface IProps  {
    status: 'draft'| 'completed' | 'canceled' | 'all'
}

const IncomesTable: FC<IProps> = ({status}) => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data: incomesData, isLoading} = useGetAllIncomesQuery({params: {status, pagination: {page, pageSize}}})
  const incomes = incomesData?.data
  const pagination = incomesData?.meta?.pagination
  const navigate = useNavigate()
  
  const onPageChange = (page: number, pageSize: number)=> {
     setPage(page)
     setPageSize(pageSize)
  } 
  
  return (
   
    <Table
    loading={isLoading}
    className={styles.table}
        columns={columns}
        dataSource={incomes?.map((item)=>({...item, key: item.id}))}  
        pagination={{defaultPageSize: 10, showSizeChanger: true, total: pagination?.total, pageSize, current: page, onChange: onPageChange }}
        onRow={(item)=> ({onClick: ()=> navigate(`/incomes/${item.id}`)})}
      />
  )
}

export default IncomesTable